import { useObserver } from 'mobx-react';
import React, { FC, useState } from 'react';
import {
    AppText,
    Button,
    ButtonRow,
    Header,
    ListView,
    Modal,
    Row,
    styled,
    Theme,
    ToastTypes,
} from '@streem/ui-react';
import { APITypes, StreemAPI } from '@streem/api';
import {
    useListGroupUsersStore,
    useListGroupMemberMetricsStore,
} from '../../../hooks/list_store_hooks';
import { useActiveCompanyCode } from '../../../hooks/use_active_company_code';
import { columnBuilder } from './group_list_columns';
import { Redirect } from 'react-router-dom';
import { ColumnHeader } from '../../lists/list_view_with_pagination';
import appLogger from '../../../util/logging/app_logger';
import { useGlobalStore } from '../../../hooks/use_global_context';
import { roomMetricsMapper } from '../../../util/metrics';

const log = appLogger.extend('Groups Details Page');

interface GroupMetricsRow extends APITypes.StreemApiUser {
    key: string;
    avgCallCountPerHour?: number;
    avgCallDurationInMins?: number;
    totalCallCount?: number;
    missedCallCount?: number;
    totalActiveHours?: number;
    unavailabilityPercentage?: number;
}

const GroupMetricsUsersList: FC<{ groupName: string }> = ({ groupName }) => {
    const [activeRecord, setActiveRecord] = useState<GroupMetricsRow>();
    const [isOpen, setIsOpen] = useState(false);

    const { uiStore, companySettingsStore } = useGlobalStore();
    const companyCode = useActiveCompanyCode();
    const groupUsersStore = useListGroupUsersStore();
    const groupUserMetricsStore = useListGroupMemberMetricsStore();

    return useObserver(() => {
        const groupUsers = groupUsersStore.results.reduce((acc, groupUser) => {
            if (acc.findIndex(a => a.sid === groupUser.sid) === -1) {
                acc.push(groupUser);
                return acc;
            } else {
                return acc;
            }
        }, []);

        const groupUsersWithMetrics = groupUsers.map(groupUser => {
            const userMetric = groupUserMetricsStore.results.find(
                r => r.aggregatedBy.userSid === groupUser.sid,
            );
            const { roomMetrics, timeRange } = roomMetricsMapper(userMetric);
            return {
                key: groupUser.sid,
                timeRange,
                ...roomMetrics,
                ...groupUser,
            };
        });

        const removeMemberFromGroup = async () => {
            try {
                await StreemAPI.groups.deleteGroupUser(companyCode, groupName, activeRecord.sid);
                groupUsersStore.refresh();
                setIsOpen(false);
                uiStore.addToast({
                    flavor: ToastTypes.SUCCESS,
                    id: `user-deleted-from-group-${activeRecord.sid}`,
                    content: 'User successfully removed from group',
                });
            } catch (e) {
                log.error('Failed to remove user from group:', e);
                uiStore.addToast({
                    flavor: ToastTypes.ERROR,
                    id: `user-deleted-from-group-error-${activeRecord.sid}`,
                    content: e,
                });
            }
        };

        const handleDelete = (row: GroupMetricsRow, e: any) => {
            e.stopPropagation();
            e.preventDefault();
            setIsOpen(true);
            setActiveRecord(row);
        };

        const columns = [
            columnBuilder.nameColumn(false),
            columnBuilder.avgCallDurationInMinsColumn(),
            columnBuilder.missedCallCountColumn(),
            columnBuilder.totalCallCountColumn(),
            columnBuilder.deleteColumn({ handleDelete }),
        ];
        if (companySettingsStore.expertAvailabilityEnabled) {
            columns.splice(1, 0, columnBuilder.avgCallCountPerHourColumn());
            columns.splice(
                -1,
                0,
                columnBuilder.totalActiveHoursColumn(),
                columnBuilder.unavailabilityPercentageColumn(),
            );
        }
        const columnSize = `2fr repeat(${columns.length - 2}, 1fr) 100px`;

        return groupUsersStore.lastError && groupUsersStore.lastError.status === 500 ? (
            <Redirect to="/error" />
        ) : (
            <>
                <ListViewGrid columns={columnSize} style={{ boxShadow: Theme.shadow.bottom }}>
                    {columns.map(column => (
                        <ColumnHeader
                            key={column.key}
                            column={column}
                            sortOrder={'ASC'}
                            contentJustify={column.contentJustify}
                        />
                    ))}
                </ListViewGrid>
                <ScrollableContainer>
                    <ListView
                        columns={columns}
                        data={groupUsersWithMetrics}
                        loading={groupUsersStore.loading}
                        loadingMore={groupUsersStore.loadingMore}
                        data-testid="group-list"
                        buildRowTestId={(row: any) => `group-row-${row.name}`}
                        gridTemplateColumns={columnSize}
                    />
                </ScrollableContainer>
                <DeleteConfirmationModal
                    isOpen={isOpen}
                    closeModal={() => {
                        setIsOpen(false);
                    }}
                    handleDelete={removeMemberFromGroup}
                    selectedUser={activeRecord?.name}
                    groupName={groupName}
                />
            </>
        );
    });
};

export default GroupMetricsUsersList;

const ListViewGrid = styled.div<{ columns?: string }>(({ columns }) => ({
    display: 'grid',
    gridTemplateColumns: columns ?? '1fr auto auto',
    margin: '0 10px',
    border: `1px solid ${Theme.colors.grey10}`,
}));

const ScrollableContainer = styled.div({
    padding: '0 10px',
    overflowX: 'auto',
});

interface DeleteConfirmationModalProps {
    isOpen: boolean;
    closeModal: () => void;
    groupName: string;
    handleDelete: () => void;
    selectedUser: string;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
    isOpen,
    closeModal,
    groupName,
    handleDelete,
    selectedUser,
}) => {
    return (
        <Modal
            onClose={closeModal}
            isOpen={isOpen}
            styleOverride={{
                content: {
                    maxWidth: '600px',
                    padding: Theme.spacing.l,
                },
            }}
        >
            <Row mb={4}>
                <Header data-testid="group-member-removal-warning-header" size="large">
                    {`Are you sure you want to remove ${selectedUser} from ${groupName}?`}
                </Header>
            </Row>
            <Row mb={5}>
                <AppText>This cannot be undone.</AppText>
            </Row>
            <ModalButtonRow>
                <Button variant="secondary" onClick={closeModal}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Delete
                </Button>
            </ModalButtonRow>
        </Modal>
    );
};

const ModalButtonRow = styled(ButtonRow)({
    justifyContent: 'flex-end',
    width: '100%',
});
