import { CallNotificationProps, SystemNotification } from '@streem/sdk-react';

export const CallNotification = ({ invitation }: CallNotificationProps) => {
    const userName = invitation?.fromUser?.name ?? 'Unknown';
    // this component will only be in the DOM when a call is received, so we want to show the notification
    //     any time the tab is hidden (i.e. the user is not on the page)
    const tabHidden = document.visibilityState === 'hidden';

    return (
        <SystemNotification
            body={`${userName}\nIncoming call`}
            title="Incoming Call"
            iconPath="/favicon.ico"
            onClick={window.focus}
            showNotification={tabHidden}
        />
    );
};
