import * as yup from 'yup';

export const inviteValidationSchema = yup.object().shape({
    name: yup
        .string()
        .required('*Name is required')
        .max(255, 'Name cannot be more than 255 characters')
        .matches(/\S/, 'Name must contain at least one character.'),
    countryCode: yup.string().when('inviteType', {
        is: 'phone',
        then: yup.string().required(),
        otherwise: yup.string(),
    }),
    phone: yup.string().when('inviteType', {
        is: 'phone',
        then: yup.string().required('Valid mobile number required'),
        otherwise: yup.string(),
    }),
    referenceId: yup
        .string()
        .max(100, 'Reference number cannot be more than 100 characters')
        .matches(/\S/, 'Reference ID must contain at least one character.'),
    inviteType: yup.string(),
    customerEmail: yup.string().when('inviteType', {
        is: 'email',
        then: yup.string().email('Valid email is required').required('Valid email is required'),
        otherwise: yup.string(),
    }),
});
