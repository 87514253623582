import { AppText, styled } from '@streem/ui-react';

export const BannerContainer = styled.section({
    width: '100%',
});

export const TruncatedText = styled(AppText)({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
});

export const ReservedPill = styled(AppText)(({ theme }) => ({
    backgroundColor: theme.colors.yellow10,
    padding: '2px 8px',
    borderRadius: '6px',
    marginLeft: '12px',
    marginRight: '12px',
}));

export const ReconnectPill = styled(AppText)(({ theme }) => ({
    backgroundColor: theme.colors.blue05,
    padding: '2px 8px',
    borderRadius: '6px',
    marginRight: '12px',
}));
