import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { ToastTypes } from '@streem/ui-react';
import { StreemAPI } from '@streem/api';
import { possession } from '../../util/strings';
import { useActiveCompanyCode } from '../../hooks/use_active_company_code';
import { createGetCompanyStore } from '../../hooks/detail_store_hooks';
import { createListGroupsStore, ListGroupsProvider } from '../../hooks/list_store_hooks';
import { APINotFound } from '../../components/routing/error_handlers';
import { useGlobalStore } from '../../hooks/use_global_context';
import { PageColumn } from '../page_column';
import GroupsList from '../../components/users/groups/groups_view';
import CreateGroupForm from '../../forms/create_group_form';
import { GroupsPageHeader } from '../../components/page_header/groups_page_header';
import { mapRoomMetricsForCompany, RoomMetricsAggregateBy } from '../../util/metrics';
import appLogger from '../../util/logging/app_logger';

const log = appLogger.extend('Groups Page');

export const GroupMetricsPage: FC = observer(() => {
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [isSuccessfullyLoaded, setIsSuccessfullyLoaded] = useState(false);
    const [timeRange, setTimeRange] = useState(null);
    const [roomMetrics, setRoomMetrics] = useState(null);

    const { uiStore } = useGlobalStore();
    const companyStore = createGetCompanyStore();
    const groupsStore = createListGroupsStore();
    const companyCode = useActiveCompanyCode();

    useEffect(() => {
        const promises = [companyStore.fetch(companyCode), groupsStore.fetch(companyCode)];
        return () => promises.forEach(p => p.cancel());
    }, [companyStore, groupsStore, companyCode]);

    useEffect(() => {
        const fetchCompanyMetrics = async () => {
            setIsSuccessfullyLoaded(false);
            if (companyStore.result && companyStore.result?.sid) {
                try {
                    const companySid = companyStore.result.sid;
                    const aggregateRoomMetricsForCompanyResponse =
                        await StreemAPI.analytics.aggregateRoomMetricsForCompany(companySid, {
                            aggregateBy: RoomMetricsAggregateBy.COMPANYSID,
                        });
                    const { roomMetrics, timeRange } = mapRoomMetricsForCompany(
                        aggregateRoomMetricsForCompanyResponse,
                        companySid,
                    );
                    setTimeRange(timeRange);
                    setRoomMetrics(roomMetrics);
                    setIsSuccessfullyLoaded(true);
                } catch (e) {
                    throw new Error(
                        `There was an error fetching metrics for companySid: ${companyStore.result.sid}: ${e}`,
                    );
                }
            }
        };

        fetchCompanyMetrics().catch(e => log.error(e));
    }, [companyStore.result, companyStore.result?.sid]);

    const handleCreateGroup = async ({
        name,
        description,
    }: {
        name: string;
        description: string;
    }) => {
        await StreemAPI.companies.createGroup(companyCode, {
            name,
            description,
        });
        uiStore.addToast({
            content: `Successfully added new group: ${name}`,
            flavor: ToastTypes.SUCCESS,
            id: `group-added-${name}`,
        });
        groupsStore.refresh();
    };

    const closeModal = () => setShowGroupModal(s => !s);
    return (
        <ListGroupsProvider value={groupsStore}>
            <APINotFound stores={[companyStore, groupsStore]} />
            <CreateGroupForm
                isOpen={showGroupModal}
                handleCancel={() => setShowGroupModal(false)}
                handleSubmit={handleCreateGroup}
            />
            <PageColumn
                dataTestId="group-metrics-page"
                style={{
                    paddingLeft: '16px',
                    paddingRight: '16px',
                }}
            >
                <GroupsPageHeader
                    title={
                        companyStore.result?.name
                            ? `${possession(companyStore.result.name)} Groups`
                            : ''
                    }
                    iconName={'AddIcon'}
                    iconContext={'Create New Group'}
                    handleClick={closeModal}
                    timeRange={timeRange}
                    roomMetrics={roomMetrics}
                    isSuccessfullyLoaded={isSuccessfullyLoaded}
                />
                <GroupsList />
            </PageColumn>
        </ListGroupsProvider>
    );
});
