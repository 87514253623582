import { useRef } from 'react';
import ReactCropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './avatar_cropper.css';
import { Box, Column, Header, Row, Button, Modal, ButtonRow, styled } from '@streem/ui-react';
import { AvatarAsset } from '../../util/images';

interface Props {
    avatar: AvatarAsset;
    changeValue: (val?: AvatarAsset) => void;
    undoAvatarChange: () => void;
    onClose: () => void;
    isOpen: boolean;
}

const CROPPER_HEIGHT = 400;

export const AvatarCropperModal: React.FC<Props> = ({
    avatar,
    changeValue,
    undoAvatarChange,
    onClose,
    isOpen,
}) => {
    const cropperRef = useRef<null | ReactCropperElement>(null);
    const dataURLtoFile = (dataUrl: string, filename: string) => {
        // src: https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const handleCancel = () => {
        undoAvatarChange();
        onClose();
    };
    const getAvatarDataUrl = () => {
        return cropperRef.current!.cropper.getCroppedCanvas().toDataURL();
    };
    const handleSave = () => {
        const avatarDataUrl = getAvatarDataUrl();
        const avatarFile = dataURLtoFile(avatarDataUrl, avatar.fileName);
        const avatarUrl = URL.createObjectURL(avatarFile);
        const croppedAvatarAsset: AvatarAsset = {
            dataUrl: avatarUrl,
            fileName: avatar.fileName,
            contentType: avatar.contentType,
        };
        changeValue(croppedAvatarAsset);
        onClose();
    };
    return (
        <Modal isOpen={isOpen} onClose={handleCancel} closeDelayMS={200}>
            <Column maxWidth="800px" flexShrink={1}>
                <Box style={{ paddingBottom: '48px' }}>
                    <Header size="xxlarge">Crop Photo</Header>
                </Box>
                <Row
                    alignItems="center"
                    justifyContent="center"
                    height="400px"
                    minWidth="400px"
                    style={{ marginRight: '24px' }}
                >
                    <ReactCropper
                        ref={cropperRef}
                        src={avatar.dataUrl}
                        style={{
                            width: '100%',
                            height: `${CROPPER_HEIGHT}px`,
                        }}
                        initialAspectRatio={1}
                        viewMode={1}
                        autoCropArea={1.0}
                        guides={false}
                    />
                </Row>
                <Footer>
                    <Button
                        data-testid="cancel-crop-photo"
                        variant="secondary"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button variant="primary" data-testid="save-crop-photo" onClick={handleSave}>
                        Save
                    </Button>
                </Footer>
            </Column>
        </Modal>
    );
};

const Footer = styled(ButtonRow)`
    justify-content: flex-end;
    margin-top: 48px;
`;
