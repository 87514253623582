import React from 'react';
import { AppIcon, Box, Column, AppText, Label, Row, styled } from '@streem/ui-react';
import { useGlobalStore } from '../../../hooks/use_global_context';

interface DividerProps {
    section?: boolean;
}

const Divider = styled(Box)<DividerProps>(({ theme, section }) => ({
    marginTop: theme.space[3],
    width: '100%',
    background: section ? theme.colors.grey30 : theme.colors.grey05,
    height: '1px',
}));

interface SectionProps {
    title: string;
}

export const Section: React.FC<SectionProps> = ({ title, children }) => {
    return (
        <Column width="100%">
            <Box ml={4}>
                <AppText as="h3" headingFontFamily bold size="xlarge">
                    {title}
                </AppText>
            </Box>
            <Divider section />
            {children}
        </Column>
    );
};

interface CustomizationProps {
    superAdmin?: boolean;
    title: string;
    description: string | React.ReactNode;
    childOrientation?: 'row' | 'column';
}

export const Customization: React.FC<CustomizationProps> = ({
    description,
    title,
    superAdmin,
    childOrientation = 'row',
    ...props
}) => {
    const { authStore } = useGlobalStore();

    if (superAdmin === true && !authStore.isSuperAdmin) {
        return null;
    }

    return (
        <Box maxWidth="100%" minWidth="400px">
            <Row my={4} ml={4} justifyContent="space-between" alignItems="center">
                <Row py={2}>
                    {superAdmin && (
                        <Column mr={2} flexShrink={0}>
                            <AppIcon name="SuperAdminIcon" />
                        </Column>
                    )}
                    <Column maxWidth={'700px'}>
                        <Box mb={2}>
                            <AppText as="h4" size="large" headingFontFamily>
                                {title}
                            </AppText>
                        </Box>
                        <Label style={{ wordBreak: 'break-word' }}>{description}</Label>
                        {childOrientation === 'column' && props.children}
                    </Column>
                </Row>
                {childOrientation === 'row' && (
                    <Box justifySelf="flex-end" pr={4}>
                        {props.children}
                    </Box>
                )}
            </Row>
            <Divider />
        </Box>
    );
};
