import React from 'react';
import { styled, Box, Row, Column, IconButton, ButtonRow } from '@streem/ui-react';
import { Form } from 'formik';

export const FlexCentered = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
});

export const ContainedForm = styled(Form)<{}>({
    position: 'relative',
    width: '80vw',
    maxHeight: '80vh',
    maxWidth: '680px',
    overflow: 'auto',
});

export const Inputs = styled(Box)({
    height: '100%',
    flexGrow: 1,
    position: 'relative',
});

export const UserProfile = styled(Column)(({ theme }) => ({
    marginLeft: '64px',
    height: '100%',
    flex: '0 0 auto',
}));

export const Container = styled(Box)<{}>(({ theme }) => ({
    position: 'relative',
    width: '80vw',
    maxWidth: '650px',
    display: 'flex',
    flexDirection: 'column',
    padding: `
    ${theme.space[5]}px
    ${theme.space[6]}px`,
    background: 'white',
    boxShadow: theme.shadow.large,
    overflow: 'auto',
}));

export const TopRow = styled(FlexCentered)(({ theme }) => ({
    marginBottom: `48px`,
    flexGrow: 1,
    justifyContent: 'flex-start',
}));

export const MiddleRow = styled(FlexCentered)({
    alignItems: 'flex-start',
    flexGrow: 4,
    marginRight: '24px',
});

export const BottomRow = styled(Box)(({ theme }) => ({
    flexGrow: 3,
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    marginTop: `48px`,
    padding: '4px 0px',
}));

export const CloseContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    right: `0`,
    top: `0`,
}));

export const ErrorContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: `${theme.space[5]}px`,
    marginTop: `${theme.space[4]}px`,
}));

export const BaseEntry = styled(Row)(({ theme }) => ({
    position: 'relative',
}));

export const FormButtons = styled(ButtonRow)({});

interface CloseButtonProps {
    id: string;
    onClick(event?: React.SyntheticEvent): void;
}

export const CloseButton = (props: CloseButtonProps) => (
    <IconButton
        iconName="CloseIcon"
        size="medium"
        shape="circle"
        label="close"
        onClick={props.onClick}
        background="azure"
        fill="white"
        data-testid={props.id}
    />
);
