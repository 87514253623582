/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiArtifact,
    StreemApiArtifactFromJSON,
    StreemApiArtifactFromJSONTyped,
    StreemApiArtifactToJSON,
    StreemApiRoom,
    StreemApiRoomFromJSON,
    StreemApiRoomFromJSONTyped,
    StreemApiRoomToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiEventsArtifactUpdated
 */
export interface StreemApiEventsArtifactUpdated {
    /**
     * 
     * @type {StreemApiRoom}
     * @memberof StreemApiEventsArtifactUpdated
     */
    room?: StreemApiRoom;
    /**
     * 
     * @type {StreemApiArtifact}
     * @memberof StreemApiEventsArtifactUpdated
     */
    previousArtifact?: StreemApiArtifact;
    /**
     * 
     * @type {StreemApiArtifact}
     * @memberof StreemApiEventsArtifactUpdated
     */
    updatedArtifact?: StreemApiArtifact;
}

export function StreemApiEventsArtifactUpdatedFromJSON(json: any): StreemApiEventsArtifactUpdated {
    return StreemApiEventsArtifactUpdatedFromJSONTyped(json, false);
}

export function StreemApiEventsArtifactUpdatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiEventsArtifactUpdated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'room': !exists(json, 'room') ? undefined : StreemApiRoomFromJSON(json['room']),
        'previousArtifact': !exists(json, 'previous_artifact') ? undefined : StreemApiArtifactFromJSON(json['previous_artifact']),
        'updatedArtifact': !exists(json, 'updated_artifact') ? undefined : StreemApiArtifactFromJSON(json['updated_artifact']),
    };
}

export function StreemApiEventsArtifactUpdatedToJSON(value?: StreemApiEventsArtifactUpdated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room': StreemApiRoomToJSON(value.room),
        'previous_artifact': StreemApiArtifactToJSON(value.previousArtifact),
        'updated_artifact': StreemApiArtifactToJSON(value.updatedArtifact),
    };
}


