import { streem } from 'streem-sdk-protobuf';
import { AppIcon, AppText, Option, styled, Theme } from '@streem/ui-react';
import AvailabilityStatus = streem.api.AvailabilityStatus;

export type StatusOptionValue = {
    key: streem.api.AvailabilityStatus;
    keyArray: streem.api.AvailabilityStatus[];
    icon: JSX.Element;
    label: string;
    description: string;
    color: string;
};

export type StatusOptionValueType = Option<StatusOptionValue>;

const IconWrapper = styled.div<{ color: string }>(({ color }) => ({
    borderRadius: 50,
    backgroundColor: color,
    marginRight: '8px',
    padding: '1px',
    height: '24px',
}));

export const statusOptions: Readonly<StatusOptionValueType[]> = [
    {
        label: 'Ready',
        value: {
            key: AvailabilityStatus.AVAILABILITY_STATUS_AVAILABLE,
            keyArray: [AvailabilityStatus.AVAILABILITY_STATUS_AVAILABLE],
            icon: (
                <IconWrapper color={Theme.colors.green10}>
                    <AppIcon name="DoneIcon" color={Theme.colors.green50} />
                </IconWrapper>
            ),
            label: 'Ready',
            description: 'Available to receive calls',
            color: Theme.colors.green50,
        },
    },
    {
        label: 'Not Ready',
        value: {
            key: AvailabilityStatus.AVAILABILITY_STATUS_NOT_AVAILABLE,
            keyArray: [AvailabilityStatus.AVAILABILITY_STATUS_NOT_AVAILABLE],
            icon: (
                <IconWrapper color={Theme.colors.red10}>
                    <AppIcon name="CloseIcon" color={Theme.colors.red50} />
                </IconWrapper>
            ),
            label: 'Not Ready',
            description: 'Unavailable to receive calls',
            color: Theme.colors.red50,
        },
    },
    {
        label: 'Reserved',
        value: {
            key: AvailabilityStatus.AVAILABILITY_STATUS_RESERVED,
            keyArray: [
                AvailabilityStatus.AVAILABILITY_STATUS_RESERVED,
                AvailabilityStatus.AVAILABILITY_STATUS_CONFIRMING,
            ],
            icon: (
                <IconWrapper
                    color={Theme.colors.yellow10}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '26px',
                    }}
                >
                    <AppText
                        size="large"
                        style={{
                            width: '26px',
                            textAlign: 'center',
                            marginBottom: '15px',
                            fontSize: '30px',
                            color: Theme.colors.grey50,
                        }}
                    >
                        ...
                    </AppText>
                </IconWrapper>
            ),
            label: 'Reserved',
            description: 'Reserved for a call',
            color: Theme.colors.grey50,
        },
    },
    {
        label: 'In Call',
        value: {
            key: AvailabilityStatus.AVAILABILITY_STATUS_IN_CALL,
            keyArray: [AvailabilityStatus.AVAILABILITY_STATUS_IN_CALL],
            icon: (
                <IconWrapper color={Theme.colors.blue50}>
                    <AppIcon name="PhoneIconPlain" size="small" color={Theme.colors.white} />
                </IconWrapper>
            ),
            label: 'In Call',
            description: 'Actively in a call',
            color: Theme.colors.blue50,
        },
    },
];
