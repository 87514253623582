import { invariant } from '@streem/toolbox';
import { google } from 'streem-sdk-protobuf';
import { GoogleProtobufDuration } from '@streem/api';

export function getDateFromProtobufTimestamp(timestamp: google.protobuf.ITimestamp) {
    invariant(timestamp.seconds, 'Invalid timestamp');

    const seconds = timestamp.seconds.toNumber();
    const nanos = timestamp.nanos ? Math.floor(timestamp.nanos / 1e6) : 0;
    return new Date(seconds * 1000 + nanos);
}

// Note: this duplicates the implementation in web/support-app/src/utils/seconds_from_protobuf_duration.ts.
//       I could not find an easy way to share this code between the two apps.
export const getSecondsFromProtobufDuration = (
    duration?: GoogleProtobufDuration,
): number | null => {
    if (!duration) return null;
    const { seconds, nanos } = duration;
    return Math.round((seconds ?? 0) + (nanos ?? 0) / 1e9);
};
