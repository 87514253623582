import { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import {
    Row,
    FavoriteStarCheckedIcon,
    FavoriteStarUncheckedIcon,
    Tooltip,
    styled,
} from '@streem/ui-react';
import { StreemAPI } from '@streem/api';
import { recordCallLogFavoriteUpdated } from '@streem/analytics';
import debounce from 'lodash.debounce';

interface FavoriteButtonProps {
    initialValue: boolean;
    roomSid: string;
    userSid: string;
    handleError: (message: string, e: Error) => void;
    showTooltip?: boolean;
    disabled?: boolean;
    onToggleFavorite?: () => void;
    debounceDelay?: number;
}

export const FavoriteButton: React.FC<FavoriteButtonProps> = ({
    initialValue,
    roomSid,
    userSid,
    handleError,
    showTooltip = false,
    disabled = false,
    onToggleFavorite,
    debounceDelay = 0,
}) => {
    const uncheckedStarRef = useRef(null);
    const checkedStarRef = useRef(null);

    const FADE_OUT_DURATION = 150;
    const BOUNCE_DURATION = 150;
    const [favoriteHovered, setFavoriteHovered] = useState(false);
    const [favorited, setFavorited] = useState(initialValue ?? false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceUpdateFavoriteAPI = useCallback(
        debounce(async (value: boolean) => {
            try {
                await StreemAPI.users.updateUserCallLogEntry(userSid, roomSid, {
                    favorite: value,
                });
                recordCallLogFavoriteUpdated(value);
                if (onToggleFavorite) {
                    onToggleFavorite();
                }
            } catch (e: any) {
                handleError('Failed to update room favorite status: ', e);
            }
        }, debounceDelay),
        [],
    );

    useEffect(() => {
        if (initialValue !== favorited) {
            setFavorited(initialValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    useLayoutEffect(() => {
        if (!checkedStarRef.current) {
            return;
        }
        if (favorited) {
            checkedStarRef.current.style.opacity = '1';
        } else {
            checkedStarRef.current.style.opacity = '0';
        }
    }, [favorited]);

    const toggleFavoritedUI = (e: any) => {
        if (disabled) {
            return;
        }
        // prevent event from bubbling upward to trigger call log row click redirect
        e.stopPropagation();
        e.preventDefault();

        if (!uncheckedStarRef.current || !checkedStarRef.current) {
            return;
        }
        if (favorited) {
            const bounceAndFadeOutKeyframes = [
                {
                    scale: 1,
                },
                {
                    scale: 1.3,
                },
                {
                    scale: 1,
                    offset: BOUNCE_DURATION / (FADE_OUT_DURATION + BOUNCE_DURATION),
                },
                {
                    opacity: 1,
                },
                {
                    opacity: 0,
                },
            ];
            const checkedAnimation = checkedStarRef.current.animate(bounceAndFadeOutKeyframes, {
                duration: BOUNCE_DURATION + FADE_OUT_DURATION,
                easing: 'ease-in-out',
                fill: 'forwards',
            });
            checkedAnimation.onfinish = async () => {
                setFavorited(f => !f);
                await debounceUpdateFavoriteAPI(!favorited);
            };
        } else {
            const bounceKeyframes = [
                {
                    scale: 1,
                },
                {
                    scale: 1.3,
                },
                {
                    scale: 1,
                },
            ];
            const uncheckedAnimation = uncheckedStarRef.current.animate(bounceKeyframes, {
                duration: BOUNCE_DURATION,
                easing: 'ease-in-out',
            });
            uncheckedAnimation.onfinish = () => {
                const checkedAnimation = checkedStarRef.current.animate(
                    [
                        {
                            opacity: 0,
                        },
                        {
                            opacity: 1,
                        },
                    ],
                    {
                        duration: FADE_OUT_DURATION,
                        fill: 'forwards',
                    },
                );
                checkedAnimation.onfinish = async () => {
                    setFavorited(f => !f);
                    await debounceUpdateFavoriteAPI(!favorited);
                };
            };
        }
    };

    return (
        <Container>
            <Button
                favorited={favorited}
                onClick={toggleFavoritedUI}
                onMouseEnter={() => !disabled && setFavoriteHovered(true)}
                onMouseLeave={() => !disabled && setFavoriteHovered(false)}
                data-testid={`favorite-button-${favorited ? 'on' : 'off'}`}
                disabled={disabled}
            >
                <Tooltip
                    message={`${favorited ? 'Unfavorite' : 'Favorite'} this call`}
                    width="125px"
                    showTooltip={showTooltip && favoriteHovered}
                >
                    <Row alignContent="center" position="relative">
                        <FavoriteStarCheckedIcon
                            refRef={checkedStarRef}
                            style={{ position: 'absolute' }}
                        />
                        <FavoriteStarUncheckedIcon refRef={uncheckedStarRef} />
                    </Row>
                </Tooltip>
            </Button>
        </Container>
    );
};

const Button = styled.button<{ favorited: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    :hover:enabled {
        cursor: pointer;
        background: #f2f8fe;
    }
    :active {
        background: ${props => (props.favorited ? '#B7DAF9' : '#E6F2FD')};
    }
    :disabled {
        cursor: not-allowed;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;
