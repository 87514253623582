import { EmbedSessionKeys } from '../types/project.types';

export function setIsEmbedWhenExpertOnEmbedRoutes(pathname: string) {
    if (pathname.startsWith('/embed') || pathname.startsWith('/streems')) {
        /* Need to rely on correct embed pathnames to conditionally show our Back to Streem button on error routes and
         * prevent a redirect to EV2 for Embed users. This SessionStorage key is not intended as a way to determine if
         * we are currently in Embed for other cases. Please use the useGetEmbedEnabledSettings hook or rely on
         * EmbedRoute instead.
         */
        sessionStorage.setItem(EmbedSessionKeys.IS_EMBED, 'true');
    }
}
