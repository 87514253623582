import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { StreemLogo, styled } from '@streem/ui-react';
import {
    convertSessionStorageObjectToUrlQueryParamsString,
    removeSegments,
} from '../../util/routing';
import { EmbedSessionKeys } from '../../types/project.types';
import { useGlobalStore } from '../../hooks/use_global_context';
import { useGetEmbedEnabledSettings } from '../../hooks/use_get_embed_enabled_settings';
import appLogger from '../../util/logging/app_logger';
import { FavoriteButton } from '../buttons/favorite_button';
import { ShareLink } from '../share/share_link';
import { streem } from 'streem-sdk-protobuf';
import { useAnonymousShareChecker } from '../../hooks/use_anonymous_share_checker';
import ToolbarBackButton from '../buttons/back_button';

/**
 * Toolbar at the top of the call details view
 */
export const CallDetailsToolbar: FC<{ room: streem.api.IRoom; favorited: boolean }> = ({
    room,
    favorited,
}) => {
    const history = useHistory();
    const { authStore, companySettingsStore, configStore } = useGlobalStore();
    const { isEmbedView } = useGetEmbedEnabledSettings();
    const logger = appLogger.extend('call details');

    function embedClickHandler(embedSessionKey: string) {
        const embedParamsForUrl = convertSessionStorageObjectToUrlQueryParamsString(
            sessionStorage.getItem(embedSessionKey),
        );
        sessionStorage.removeItem(embedSessionKey);
        history.push(removeSegments(history.location.pathname, 1) + embedParamsForUrl);
    }

    const showBackButton = authStore.isUserSignedIn;

    const isAnonymousSharePage = useAnonymousShareChecker(authStore);

    const RightToolbar = () => (
        <ToolbarGroup align={isAnonymousSharePage ? 'center' : 'end'}>
            {!isEmbedView && companySettingsStore.callFavoritingEnabled && (
                <FavoriteButton
                    handleError={(message: string, e: Error) => logger.error(message, room?.sid, e)}
                    userSid={authStore.userId}
                    roomSid={room?.sid}
                    initialValue={favorited}
                />
            )}
            {!isAnonymousSharePage && <ShareLink roomId={room?.sid} />}
        </ToolbarGroup>
    );

    if (window.location.pathname.startsWith('/embed/')) {
        return (
            <Toolbar>
                <ToolbarGroup>
                    {showBackButton && (
                        <ToolbarBackButton
                            text={'Back to Invite'}
                            dataTestId={'back-to-embed-invite-button'}
                            clickHandler={() => embedClickHandler(EmbedSessionKeys.INVITE)}
                        />
                    )}
                </ToolbarGroup>
                <RightToolbar />
            </Toolbar>
        );
    }

    if (window.location.pathname.startsWith('/streems/')) {
        return (
            <Toolbar>
                <ToolbarGroup>
                    {showBackButton && (
                        <ToolbarBackButton
                            text={'Back to Calls'}
                            dataTestId={'back-to-embed-calls-button'}
                            clickHandler={() => embedClickHandler(EmbedSessionKeys.CALL_LOG)}
                        />
                    )}
                </ToolbarGroup>
                <RightToolbar />
            </Toolbar>
        );
    }
    return (
        <Toolbar>
            <ToolbarGroup>
                {isAnonymousSharePage &&
                    (configStore.companyLogoUrl ? (
                        <CompanyLogo src={configStore.companyLogoUrl} />
                    ) : (
                        <StreemLogo />
                    ))}
                {showBackButton && (
                    <ToolbarBackButton
                        text={'Back to Calls'}
                        dataTestId={'back-to-calls-button'}
                        clickHandler={() =>
                            history.push(removeSegments(history.location.pathname, 1))
                        }
                    />
                )}
            </ToolbarGroup>
            <RightToolbar />
        </Toolbar>
    );
};

const Toolbar = styled.nav`
    background-color: ${props => props.theme.colors.white || 'white'};
    border-bottom-color: ${props => props.theme.colors.grey10};
    border-bottom-style: solid;
    border-bottom-width: 1px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    line-height: 24px;
    padding: 8px 24px;
    position: sticky;
    top: 0;
    z-index: 2; /* see https://stackoverflow.com/questions/69033816/css-transform-not-working-correctly-with-position-sticky */
`;

const ToolbarGroup = styled.div<{ align?: string }>`
    align-self: ${props => props.align || 'start'};
    display: flex;
    align-items: center;
    justify-content: ${props => props.align || 'start'};
`;

export const CompanyLogo = styled.img({
    height: '56px',
    width: '56px',
    objectFit: 'contain',
});
