// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../tools/webpack/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../tools/webpack/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-form {
    width: 90%;
}

@media only screen and (min-width: 1800px) {
    .survey-form {
        width: 50%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/calls/survey_form.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".survey-form {\n    width: 90%;\n}\n\n@media only screen and (min-width: 1800px) {\n    .survey-form {\n        width: 50%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
