import { styled } from '@streem/ui-react';
import { BaseEntry } from './shared_form.styles';

export const CompanyNameEntry = styled(BaseEntry)({});

export const CompanyCodeEntry = styled(BaseEntry)({
    alignItems: 'flex-end',
});

export const OwnerEmailEntry = styled(BaseEntry)({});

export const OwnerNameEntry = styled(BaseEntry)({});
