import { Column, styled, Theme } from '@streem/ui-react';
import { FC } from 'react';
import { useCallDetailHeaderInfo } from '../../../../hooks/use_call_detail_header_info';
import { getDateFromProtobufTimestamp } from '../../../../util/protobuf';
import { LogoCustomerExpert } from './logo_customer_expert';
import { DateDurationReference } from './date_duration_reference';

export const CallDetailHeader: FC = () => {
    const { callDetail, expert, openedAt, callSource, durationString, reference } =
        useCallDetailHeaderInfo();
    const startDate = openedAt && getDateFromProtobufTimestamp(openedAt);

    if (!callDetail) {
        return null;
    }

    return (
        <CallDetailHeaderWrapper>
            <LogoCustomerExpert callSource={callSource} callDetail={callDetail} expert={expert} />
            <DateDurationReference
                startDate={startDate}
                duration={durationString}
                reference={reference}
            />
        </CallDetailHeaderWrapper>
    );
};

const CallDetailHeaderWrapper = styled(Column)({
    alignItems: 'center',
    marginBottom: '1.5rem',
    border: `1px solid ${Theme.colors.grey10}`,
    borderRadius: '4px',
    rowGap: '1.5rem',
});
