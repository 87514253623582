import { useState } from 'react';
import { InviteCard } from '../invite/invite_card';
import { EmbedPostInviteScreen } from './embed_post_invite_screen';

export const Embed = (): JSX.Element => {
    const params = new URLSearchParams(window.location.search);
    /* STREEM-15109 Note: this behavior is a preliminary step to having our users switch from using a refId
     * to using an integrationId until backend support for this feature is implemented.
     * For the time being, an integrationId or refId can be used as the reference ID value we store in the
     * database when creating an invitation. Update after refId sunset grace period has ended.
     */
    const integrationOrRefId = params.get('integrationId') ?? params.get('refId');
    const initialValues = {
        phone: params.get('phone'),
        name: params.get('name'),
        referenceId: integrationOrRefId,
        integrationId: params.get('integrationId'),
        customerEmail: params.get('email'),
        callingCode: params.get('callingCode'),
        redirect: params.get('redirect'),
    };

    const [inviteDelivered, setInviteDelivered] = useState(false);
    const [isLinkInvite, setIsLinkInvite] = useState(false);

    return (
        <div
            data-testid="embed-container"
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}
        >
            {inviteDelivered ? (
                <EmbedPostInviteScreen isLinkInvite={isLinkInvite} />
            ) : (
                <InviteCard
                    onDone={inviteType => {
                        setInviteDelivered(true);
                        setIsLinkInvite(inviteType === 'link');
                    }}
                    isEmbedded
                    initialFormData={initialValues}
                />
            )}
        </div>
    );
};
