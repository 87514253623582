/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiRoom,
    StreemApiRoomFromJSON,
    StreemApiRoomFromJSONTyped,
    StreemApiRoomToJSON,
    StreemApiRoomParticipant,
    StreemApiRoomParticipantFromJSON,
    StreemApiRoomParticipantFromJSONTyped,
    StreemApiRoomParticipantToJSON,
    StreemApiUser,
    StreemApiUserFromJSON,
    StreemApiUserFromJSONTyped,
    StreemApiUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiEventsRoomParticipantCreated
 */
export interface StreemApiEventsRoomParticipantCreated {
    /**
     * 
     * @type {StreemApiUser}
     * @memberof StreemApiEventsRoomParticipantCreated
     */
    user?: StreemApiUser;
    /**
     * 
     * @type {StreemApiRoom}
     * @memberof StreemApiEventsRoomParticipantCreated
     */
    room?: StreemApiRoom;
    /**
     * 
     * @type {StreemApiRoomParticipant}
     * @memberof StreemApiEventsRoomParticipantCreated
     */
    participant?: StreemApiRoomParticipant;
}

export function StreemApiEventsRoomParticipantCreatedFromJSON(json: any): StreemApiEventsRoomParticipantCreated {
    return StreemApiEventsRoomParticipantCreatedFromJSONTyped(json, false);
}

export function StreemApiEventsRoomParticipantCreatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiEventsRoomParticipantCreated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : StreemApiUserFromJSON(json['user']),
        'room': !exists(json, 'room') ? undefined : StreemApiRoomFromJSON(json['room']),
        'participant': !exists(json, 'participant') ? undefined : StreemApiRoomParticipantFromJSON(json['participant']),
    };
}

export function StreemApiEventsRoomParticipantCreatedToJSON(value?: StreemApiEventsRoomParticipantCreated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': StreemApiUserToJSON(value.user),
        'room': StreemApiRoomToJSON(value.room),
        'participant': StreemApiRoomParticipantToJSON(value.participant),
    };
}


