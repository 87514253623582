import { FC, InputHTMLAttributes } from 'react';
import { Field, FieldProps } from 'formik';
import { Option, Box, AppText } from '@streem/ui-react';

interface Props<T> extends InputHTMLAttributes<HTMLSelectElement> {
    name: string;
    options: Option<T>[];
    component: FC<any>;
    required?: boolean;
}

export function DropdownField<T>({ name, component: RenderComponent, ...rest }: Props<T>) {
    return (
        <Field name={name}>
            {({ field, meta, form: { setFieldValue, setFieldTouched } }: FieldProps) => {
                return (
                    <Box>
                        <RenderComponent
                            {...field}
                            {...rest}
                            handleBlur={() => setFieldTouched(name, true)}
                            handleSelect={(option: Option<T>) => setFieldValue(name, option.value)}
                            name={name}
                            value={field.value}
                        />
                        {meta.touched && meta.error && (
                            <Box mt={1} mb={4}>
                                <AppText color="red50" as={'p'}>
                                    {meta.error || ''}
                                </AppText>
                            </Box>
                        )}
                    </Box>
                );
            }}
        </Field>
    );
}
