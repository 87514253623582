import { observer } from 'mobx-react';
import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom';
import * as React from 'react';
import { useCentralLoginStore } from '../hooks/use_central_login_context';

interface RouterLocation {
    pathname: string;
    state: {
        [key: string]: any;
    };
}

export const CentralLoginAnonymousRoute: React.FC<RouteProps> = observer(props => {
    const { authStore } = useCentralLoginStore();
    const location = useLocation() as RouterLocation;
    const from = location.state?.from || { pathname: '/' };

    // Observing isUserSignedIn is what makes redirecting after login possible.
    // It also ensures logged in users can't access the anonymous page.
    if (authStore.isUserSignedIn) {
        return <Redirect to={from} />;
    }
    return <Route {...props}>{props.children}</Route>;
});
