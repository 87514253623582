/**
 * Returns a pluralized noun if there are 0 or more than 1 of that nouns
 * ```
 * const artifactsCounts = {streemshot: 24, video: 1, banana: 0}
 *
 * const s = pluralize(artifactsCount.streemshot, "Streemshot")
 * // s = "23 Streemshots"
 *
 * const v = pluralize(artifactsCount.video, "Video")
 * // v = "1 Video"
 *
 *  const b = pluralize(artifactsCount.banana, "Banana")
 * // b = "0 Bananas"
 * ```
 * @param count The number of "things" that exist
 * @param noun The noun that could potentially be plural
 */

export function pluralize(count?: number | null, noun?: string | null): string {
    if (!noun || !noun.length) return '';
    if (count === null || count === undefined) return noun;
    if (count === 0) return `${count} ${noun}s`;
    if (count === 1) return `${count} ${noun}`;
    if (count > 1) return `${count} ${noun}s`;
    return noun;
}

/**
 * Returns a kebab case version of a string
 * @example one two -> one-two
 * @example oneTwo -> one-two
 * @example one_two -> one-two
 * @example ONE_TWO -> one-two
 * @param input Any string which needs to be converted to kebab case
 */
export function kebabCase(input: string): string {
    return input
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase();
}

/**
 * Transforms a word to be possessed. Ex:
 * ```
 * var message = possession("Sam") + " cookies are delicious"
 * // "Sam's cookies are delicious"
 *
 * message = `The ${possession("parents", true)} bedroom is upstairs`
 * // "The parents' bedroom is upstairs"
 *
 * ```
 * @param name The word that will be possessed
 * @param pluralNounEndsWithS The possessive of a plural noun is formed by adding only an apostrophe when the noun ends in s
 */
export const possession = (name?: string | null, pluralNounEndsWithS = false): string => {
    if (!name || !name.length) {
        return '';
    }

    if (pluralNounEndsWithS) {
        return `${name}'`;
    }

    if (name.endsWith("'s")) {
        return `${name}`;
    }

    return `${name}'s`;
};

/**
 * Does the string ends with a `/`
 * @param string
 */
export const hasTrailingSlash = (string: string): boolean => {
    return /\/$/.test(string);
};
