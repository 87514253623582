import { StreemshotId } from '@streem/domain-id';
import { ModelSerialFeedback, ModelSerialList, useObservable } from '@streem/sdk-react';
import { WallItemSubtype } from '@streem/sdk-types';
import { EditableNote, styled } from '@streem/ui-react';
import { FC, useMemo, useState } from 'react';
import { streem, WallItem } from 'streem-sdk-protobuf';
import { TagsList } from '../../components/lists/tags_list';
import { useDetailSession } from '../../hooks/detail_session_hooks';
import appLogger from '../../util/logging/app_logger';

/**
 * Artifact Details that appear alongside an artifact in the artifact details page
 */
export const StreemshotDetailsSidebar: FC<{
    artifact: WallItemSubtype<WallItem, 'streemshot'> & {
        streemshotRevision?: Partial<WallItem['streemshotRevision']>;
    };
    readOnly: boolean;
    isOpen: boolean;
    noteCharacterLimit?: number;
}> = ({ artifact, isOpen, readOnly, noteCharacterLimit }) => {
    const detailSession = useDetailSession();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const log = appLogger.extend('edit artifact notes');
    const streemshotId = useMemo(
        () => new StreemshotId(artifact?.streemshotRevision?.streemshotId || artifact.id),
        [artifact],
    );

    const [note] = useObservable(detailSession.note.getStreemshotNote(streemshotId));
    const [modelSerial = []] = useObservable(
        detailSession.detection.getDetections({
            streemshotId,
            types: [
                streem.api.Detection.Type.DETECTION_TYPE_MODEL,
                streem.api.Detection.Type.DETECTION_TYPE_SERIAL,
            ],
        }),
    );
    const [labelObject = []] = useObservable(
        detailSession.detection.getDetections({
            streemshotId,
            types: [
                streem.api.Detection.Type.DETECTION_TYPE_LABEL,
                streem.api.Detection.Type.DETECTION_TYPE_OBJECT,
            ],
        }),
    );
    const [streemshots = []] = useObservable(detailSession.streemshots.responses);

    return (
        <ArtifactDetailsWrapper>
            <ArtifactDetailsSidebarNotes>
                <EditableNote
                    testId="artifact-notes"
                    hasFootBar={false}
                    description="Streemshot Notes"
                    placeholderText={'Add streemshot notes'}
                    characterLimit={noteCharacterLimit || 225}
                    noteText={note?.text ?? ''}
                    disableEditing={readOnly || !isOpen}
                    errorMessage={errorMessage}
                    onSave={async (noteText: string) => {
                        try {
                            setErrorMessage(undefined);
                            await detailSession.note.saveStreemshotNote(streemshotId, noteText);
                        } catch (error) {
                            setErrorMessage('Error updating notes.');
                            log.error('Error attempting to edit notes: ', error);
                        }
                    }}
                />
            </ArtifactDetailsSidebarNotes>
            <ArtifactDetailsSidebarMSList>
                {readOnly ? (
                    <ModelSerialList
                        modelSerialDetections={modelSerial}
                        streemshots={streemshots}
                    />
                ) : (
                    <ModelSerialFeedback
                        modelSerialDetections={modelSerial}
                        onAdd={(description: string, type: streem.api.Detection.Type) =>
                            detailSession.detection.createDetection(streemshotId, description, type)
                        }
                        onEdit={(detectionId, newDetection) =>
                            detailSession.detection.updateDetection(
                                detectionId,
                                newDetection.description,
                                newDetection.type,
                            )
                        }
                        onDelete={detectionId =>
                            detailSession.detection.deleteDetection(detectionId)
                        }
                        isTrayOpen={isOpen}
                    />
                )}
            </ArtifactDetailsSidebarMSList>
            {labelObject.length ? (
                <ArtifactDetailsSidebarTagsList>
                    <TagsList labelObjectDetections={labelObject} />
                </ArtifactDetailsSidebarTagsList>
            ) : null}
        </ArtifactDetailsWrapper>
    );
};

const ArtifactDetailsWrapper = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.white,
    minHeight: `calc(100vh - 56px)`,
}));

const ArtifactDetailsSidebarNotes = styled.div({
    padding: '16px 32px 32px',
});

const ArtifactDetailsSidebarMSList = styled.div(({ theme }) => ({
    borderTop: `solid 5px ${theme.colors.grey05}`,
    padding: '16px 32px 32px',
}));

const ArtifactDetailsSidebarTagsList = styled.div(({ theme }) => ({
    borderTop: `solid 5px ${theme.colors.grey05}`,
    padding: '16px 32px  32px',
}));
