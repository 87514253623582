import { MouseEvent } from 'react';
import { AppIcon, Button, styled } from '@streem/ui-react';

interface ToolbarBackButtonProps {
    text: string;
    dataTestId: string;
    clickHandler: (e: MouseEvent) => void;
}

export const ToolbarBackButton = (props: ToolbarBackButtonProps) => {
    const { text, dataTestId, clickHandler } = props;
    return (
        <BackButton data-testid={dataTestId} variant="tertiary" onClick={clickHandler}>
            <BackIcon name={'BackIcon'} />
            {text}
        </BackButton>
    );
};

const BackIcon = styled(AppIcon)`
    margin-left: -6px;
`;

const BackButton = styled(Button)`
    transform: translateX(-16px);
    color: ${props => props.theme.colors.grey90};
`;

export default ToolbarBackButton;
