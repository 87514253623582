import { FC, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useGlobalStore } from '../../hooks/use_global_context';
import { MessagePageWithOptionalButton } from '../errors/message_page_with_optional_button';
import { EmbedSessionKeys } from '../../types/project.types';

export const NotFoundRoute: FC<RouteComponentProps> = ({ history }) => {
    const { authStore } = useGlobalStore();

    const isEmbed = sessionStorage.getItem(EmbedSessionKeys.IS_EMBED);

    const handleBackClick = useCallback(() => {
        if (authStore.isUserSignedIn) {
            history.goBack();
        } else {
            history.push('/');
        }
    }, [authStore, history]);

    const notFoundHeader = "Oops! We can't find this page";
    const notFoundMessage = "This link is broken, or you don't have permission to view this page.";

    return (
        <>
            <MessagePageWithOptionalButton
                dataTestId="not-found-page"
                header={notFoundHeader}
                message={notFoundMessage}
                hasButton={!isEmbed}
                clickHandler={handleBackClick}
            />
        </>
    );
};
