import { AllowNotificationsModal, PROFILE_MODAL_BODY } from './allow_notifications_modal';
import { OkayModal } from './okay_modal';

const BROWSER_SETTINGS_HEADER = 'Enable browser notifications';
const BROWSER_SETTINGS_BODY =
    'To allow Streem to send notifications, please go into your browser settings and enable notifications.';
const ALREADY_ALLOWED_HEADER = 'Notifications already enabled';
const ALREADY_ALLOWED_BODY =
    'You have already enabled notifications for Streem in your browser.  If you are not seeing notifications, please check and make sure your browser has permission to show notifications';
const UNSUPPORTED_HEADER = 'Notifications not supported';
const UNSUPPORTED_BODY = 'Your browser does not support notifications.';

export const SystemNotifications = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}): JSX.Element => {
    const permission = global.Notification?.permission;
    if (!global.Notification) {
        return (
            <OkayModal
                open={isOpen}
                handleClose={onClose}
                body={UNSUPPORTED_BODY}
                headerText={UNSUPPORTED_HEADER}
                testId="notifications-not-supported"
            />
        );
    } else if (permission === 'granted') {
        return (
            <OkayModal
                open={isOpen}
                handleClose={onClose}
                body={ALREADY_ALLOWED_BODY}
                headerText={ALREADY_ALLOWED_HEADER}
                testId="already-allowed"
            />
        );
    } else if (permission === 'denied') {
        return (
            <OkayModal
                open={isOpen}
                handleClose={onClose}
                body={BROWSER_SETTINGS_BODY}
                headerText={BROWSER_SETTINGS_HEADER}
                testId="browser-settings"
            />
        );
    } else {
        return (
            <AllowNotificationsModal open={isOpen} onClose={onClose} body={PROFILE_MODAL_BODY} />
        );
    }
};
