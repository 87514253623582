import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { useCentralLoginStore } from '../../hooks/use_central_login_context';
import RedirectToWorkspacesPage from './redirect_to_workspaces_page';
import { SESSION_SWAGA_POST_LOGIN_REDIRECT } from '../../types/project.types';
import appLogger from '../../util/logging/app_logger';

interface LocationState {
    from?: {
        pathname: string;
    };
}

const log = appLogger.extend('CentralLoginPage');

export const CentralLoginPage: React.FC = () => {
    const { authStore } = useCentralLoginStore();

    const authError = useObserver(() => authStore.error);
    const location = useLocation<LocationState | undefined>();

    useEffect(() => {
        if (authError === undefined) {
            window.sessionStorage.setItem(
                SESSION_SWAGA_POST_LOGIN_REDIRECT,
                location.state?.from?.pathname ?? '/',
            );
            authStore
                .loginWithOauthRedirect()
                .catch(reason => log.error('Error logging in via OAuth redirect', reason));
        }
    }, [authStore, authError, location]);

    return authError ? <RedirectToWorkspacesPage onLogOut={() => authStore.logout()} /> : null;
};
