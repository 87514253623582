import { StreemshotDetection } from '@streem/sdk-core';
import { styled } from '@streem/ui-react';
import React from 'react';
import { Subheader } from '../typography/typography';

/**
 * Lists the descriptions of detections that are "tags" as defined by the getTags function
 * @param artifacts
 * @constructor
 */
export const TagsList: React.FC<{ labelObjectDetections: StreemshotDetection[] }> = ({
    labelObjectDetections,
}) => {
    if (!labelObjectDetections.length) return null;

    return (
        <>
            <Subheader size="mediumLarge">Object Recognition Labels</Subheader>
            <TagsListList>
                {labelObjectDetections.map(detection => (
                    <TagsListItem key={detection.detectionId}>{detection.description}</TagsListItem>
                ))}
            </TagsListList>
        </>
    );
};

const TagsListList = styled.div({
    margin: '6px -8px 0',
    '::-webkit-scrollbar': {
        WebkitAppearance: 'none',
        width: '7px',
    },
    '::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        backgroundColor: 'rgba(0, 0, 0, .5)',
        boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
});

const TagsListItem = styled.span(({ theme }) => ({
    display: 'inline-block',
    alignSelf: 'center',
    justifySelf: 'start',
    background: theme.colors.grey05,
    borderRadius: '4px',
    color: theme.colors.grey90,
    padding: '6px',
    margin: '8px',
}));
