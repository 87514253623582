import * as React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { useGlobalStore } from '../hooks/use_global_context';
import { LoadingOverlay } from '@streem/ui-react';
import { AdminRoute, defaultRoutes } from './routes';
import { RouteComponentProps } from 'react-router';

/* This interface can be removed as part of the work for https://streempro.atlassian.net/browse/ST-2096
 * It allows us to provide an alternative component to the GroupsRoute and determine which to show
 * based on a feature flag.
 */
interface Props extends RouteProps {
    alternativeComponent?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const GroupsRoute: React.FC<Props> = props => {
    const { authStore, companySettingsStore } = useGlobalStore();

    const { alternativeComponent, component, ...routeProps } = props;

    return useObserver(() => {
        /* We need to perform this check because of the way our Routes are currently
         * structured. We use the ProtectedRoute to assert that a user is logged in
         * which is nested in our Route types that check for certain permissions.
         * Unfortunately, our other stores have dependencies on the authStore and
         * we need to know this information sooner in our Routing flow. Work must be
         * done to restructure the app where all routing passes through the
         * ProtectedRoute first in the hierarchy.
         */
        if (!authStore.isUserSignedIn) {
            return (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location },
                    }}
                />
            );
        }

        if (companySettingsStore.loading) {
            return <LoadingOverlay />;
        }

        if (
            (companySettingsStore.callMetricsEnabled &&
                companySettingsStore.groupManagementEnabled) ||
            authStore.isSuperAdmin
        ) {
            return <AdminRoute component={component} {...routeProps} />;
        }

        /* This code path can be removed as part of the work for https://streempro.atlassian.net/browse/ST-2096
         * once the product decision has been made to always include call metrics when a company uses group management.
         */
        if (companySettingsStore.groupManagementEnabled) {
            return <AdminRoute component={alternativeComponent} {...routeProps} />;
        }

        return <Redirect to={defaultRoutes[authStore.role] ?? '/error'} />;
    });
};
