import { styled, Column } from '@streem/ui-react';

export const HelpBox = styled.a(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '100%',
    textDecoration: 'none',
    color: theme.colors.grey90,
    alignItems: 'center',
}));

export const HelpBoxContainer = styled(Column)(() => ({
    boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.1);',
    height: '125px',
    maxWidth: '625px',
    borderRadius: '10px',
    margin: '5px',
    alignSelf: 'center',
    marginTop: '24px',
}));
