import { FC, useLayoutEffect, useMemo, useRef } from 'react';
import {
    AppText,
    Box,
    IconButton,
    Label,
    Row,
    Select,
    useTheme,
    ReactSelectComponents,
} from '@streem/ui-react';
import { Diagnosis } from '../../types/disposition.types';
import {
    customFilterOption,
    getFilteredOptions,
    getInitialValue,
} from '../../util/call_disposition_helpers';
import { AddButton } from './room_outcome_form_styles';

interface DiagnosisDetailSelectionProps {
    maxDiagnoses?: number;
    diagnosisOptions: Diagnosis[];
    diagnoses: Diagnosis[];
    handleSetDiagnoses: (
        callback: (diagnosis: Diagnosis) => Diagnosis,
        newDiagnosis?: Diagnosis,
    ) => void;
}
export const DiagnosisDetailSelection: FC<DiagnosisDetailSelectionProps> = ({
    diagnosisOptions,
    diagnoses,
    handleSetDiagnoses,
    maxDiagnoses = 3,
}) => {
    const firstRender = useRef(true);

    const editDiagnosis = (prevDiagnosis: Diagnosis, newDiagnosis: Diagnosis) => {
        handleSetDiagnoses((d: Diagnosis) => {
            if (d.code === prevDiagnosis.code) {
                return newDiagnosis;
            } else {
                return d;
            }
        });
    };

    const deleteDiagnosis = (diagnosis: Diagnosis) => {
        handleSetDiagnoses((d: Diagnosis) => {
            if (d.code === diagnosis.code) {
                return null;
            } else {
                return d;
            }
        });
    };

    const addDiagnosis = () => {
        const uniqueKey = Date.now().toString();
        handleSetDiagnoses(diagnosis => diagnosis, {
            code: uniqueKey,
            label: '',
        });
    };

    const inputOptions = useMemo(() => {
        return diagnosisOptions.map(d => ({
            value: d.code,
            label: d.label,
        }));
    }, [diagnosisOptions]);

    const shouldShowAddDiagnosisButton =
        diagnosisOptions.length - diagnoses.length > 0 && diagnoses.length < maxDiagnoses;

    useLayoutEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
        }

        const inputToFocus = document.querySelector(
            `[aria-label="call diagnosis options ${diagnoses.length}"]`,
        ) as HTMLInputElement;

        if (inputToFocus) {
            inputToFocus.focus();
        }
    }, [diagnoses]);

    return (
        <Box width="100%">
            {diagnoses.map((diagnosis, idx) => {
                return (
                    <LabelSearchInput
                        key={diagnosis.code}
                        ariaLabel={`call diagnosis options ${idx + 1}`}
                        placeholder="Search here"
                        label="Diagnosis"
                        deletable={diagnoses.length > 1}
                        labelNumber={idx + 1}
                        handleDelete={() => {
                            deleteDiagnosis(diagnosis);
                        }}
                        handleChange={val =>
                            editDiagnosis(diagnosis, { code: val.value, label: val.label })
                        }
                        options={getFilteredOptions(diagnosis, diagnoses, inputOptions)}
                        required={idx === 0}
                        initialValue={getInitialValue(diagnosis, diagnosisOptions)}
                    />
                );
            })}
            {shouldShowAddDiagnosisButton && (
                <Row ml={2} mt={4}>
                    <AddButton onClick={addDiagnosis} label="diagnosis" />
                    <Box ml={2}>
                        <AppText semibold headingFontFamily>
                            Add Diagnosis
                        </AppText>
                    </Box>
                </Row>
            )}
        </Box>
    );
};

interface LabelSearchInputProps {
    label: string;
    handleDelete?: (val: any) => void;
    options: any;
    placeholder?: string;
    initialValue?: { label: string; value: string };
    handleChange: (val: any) => void;
    ariaLabel: string;
    deletable?: boolean;
    required?: boolean;
    labelNumber?: number;
    isDisabled?: boolean;
}
export const LabelSearchInput: FC<LabelSearchInputProps> = ({
    label,
    deletable,
    handleDelete,
    options,
    handleChange,
    initialValue,
    ariaLabel,
    placeholder,
    labelNumber = 1,
    required = true,
    isDisabled,
}) => {
    const theme = useTheme();
    const { red50 } = theme.colors;

    return (
        <>
            <Box mb={3} mt={4}>
                <Label semibold>{label}</Label>
            </Box>
            <Row>
                <Box width="100%">
                    <Select
                        isDisabled={isDisabled}
                        filterOption={customFilterOption}
                        aria-label={ariaLabel}
                        size="medium"
                        border
                        onSelect={handleChange}
                        isSearchable
                        placeholder={placeholder}
                        options={options}
                        initialValue={initialValue}
                        components={{
                            SelectContainer: ({ children, ...props }) => (
                                <ReactSelectComponents.SelectContainer
                                    {...props}
                                    innerProps={{
                                        ...props.innerProps,
                                        'data-testid': `${label}-select-${labelNumber}`,
                                    }}
                                >
                                    {children}
                                </ReactSelectComponents.SelectContainer>
                            ),
                            Input: ({ children, ...props }) => (
                                <ReactSelectComponents.Input
                                    {...props}
                                    data-testid={`${label}-input-${labelNumber}`}
                                >
                                    {children}
                                </ReactSelectComponents.Input>
                            ),
                        }}
                        formatOptionLabel={(option: any) => (
                            <AppText data-testid={`${label}-option-${option.label}`}>
                                {option.label}
                            </AppText>
                        )}
                    />
                </Box>
                {deletable && (
                    <IconButton
                        iconName={'CloseIcon'}
                        label={'delete-btn'}
                        data-testid={`delete-${label}-${labelNumber}`}
                        hoverFill={red50}
                        onClick={handleDelete}
                    />
                )}
            </Row>
            {required && (
                <Box my={2}>
                    <AppText semibold size="small">
                        Required*
                    </AppText>
                </Box>
            )}
        </>
    );
};
