import { useObserver } from 'mobx-react';
import React, { FC, useState } from 'react';
import { AppText, Row, Switch, Box } from '@streem/ui-react';
import { APITypes } from '@streem/api';
import { useListUsersStore } from '../../../hooks/list_store_hooks';
import { columnBuilder } from './agent_list_columns';
import { Redirect } from 'react-router-dom';
import { UserFormStrategy } from '../../../types/project.types';
import UserFormModal from '../../../forms/user_form';
import { useGlobalStore } from '../../../hooks/use_global_context';
import { ListViewWithPagination } from '../../lists/list_view_with_pagination';
import { useLocalStorage, LocalStorageKey } from '../../../hooks/use_local_storage';
interface AgentRow extends APITypes.StreemApiUser {
    key: string;
}

interface Props {
    handleSort: (sortKey: string, sortOrder: string) => void;
}
const CompanyAgentList: FC<Props> = ({ handleSort }) => {
    const agentsStore = useListUsersStore();
    const { authStore } = useGlobalStore();
    const [showModal, setShowModal] = useState(false);
    const [activeRecord, setActiveRecord] = useState<AgentRow>();
    const { value: hideInactiveUsers, setValue: setHideInactiveUsers } = useLocalStorage(
        LocalStorageKey.HIDE_INACTIVE_USERS,
        false,
    );
    const MINIMUM_VISIBLE_ACTIVE_USERS = 85;

    return useObserver(() => {
        // remove duplicate users to prevent React duplicate keys error
        const agents = hideInactiveUsers
            ? agentsStore.results
                  .reduce((acc, agent) => {
                      if (acc.findIndex(a => a.sid === agent.sid) === -1) {
                          acc.push(agent);
                          return acc;
                      } else {
                          return acc;
                      }
                  }, [])
                  .filter(agent => agent.active)
            : agentsStore.results.reduce((acc, agent) => {
                  if (acc.findIndex(a => a.sid === agent.sid) === -1) {
                      acc.push(agent);
                      return acc;
                  } else {
                      return acc;
                  }
              }, []);

        if (
            hideInactiveUsers &&
            agents.length < MINIMUM_VISIBLE_ACTIVE_USERS &&
            agentsStore.hasNextPageToken
        ) {
            agentsStore.fetchNextPage();
        }

        const handleNameCellClick = (row: AgentRow) => {
            setActiveRecord(row);
            setShowModal(true);
        };

        const columns = [
            columnBuilder.nameColumn({ handleCellClickCallback: handleNameCellClick }),
            columnBuilder.roleColumn(),
            columnBuilder.statusColumn(),
        ];

        return agentsStore.lastError && agentsStore.lastError.status === 500 ? (
            <Redirect to="/error" />
        ) : (
            <>
                <HideInactiveUsersSwitch
                    checked={hideInactiveUsers}
                    handleCheck={() => setHideInactiveUsers(!hideInactiveUsers)}
                />
                <ListViewWithPagination
                    columns={columns}
                    data={agents.map(u => ({ key: u.sid!, ...u } as AgentRow))}
                    onSort={handleSort}
                    loading={agentsStore.loading}
                    loadingMore={agentsStore.loadingMore}
                    data-testid="user-list"
                    buildRowTestId={(row: any) => `user-row-${row.name}`}
                    store={agentsStore}
                    canRowClick={row => !!row}
                    gridTemplateColumns="1fr minmax(200px, auto) minmax(150px, auto)"
                />
                <UserFormModal
                    isOpen={showModal}
                    strategy={
                        activeRecord?.sid === authStore.userId
                            ? UserFormStrategy.EDIT_SELF
                            : UserFormStrategy.EDIT_OTHER
                    }
                    user={activeRecord}
                    onCancel={() => setShowModal(false)}
                    onSuccess={() => {
                        agentsStore.refresh();
                        setShowModal(false);
                    }}
                />
            </>
        );
    });
};

export default CompanyAgentList;

interface HideInactiveUsersSwitchProps {
    handleCheck: () => void;
    checked: boolean;
}
const HideInactiveUsersSwitch: React.FC<HideInactiveUsersSwitchProps> = ({
    checked,
    handleCheck,
}) => {
    return (
        <Row alignItems="center" justifyContent="flex-end" mr="45px" mb={3}>
            <Box mr={3}>
                <AppText medium headingFontFamily>
                    Hide Inactive Experts
                </AppText>
            </Box>
            <Switch checked={checked} label="Hide Inactive Users" onToggle={handleCheck} />
        </Row>
    );
};
