import { FC, useEffect, useState } from 'react';
import { observer, Observer } from 'mobx-react';
import { ToastTypes } from '@streem/ui-react';
import { StreemAPI } from '@streem/api';
import { possession } from '../../util/strings';
import { useActiveCompanyCode } from '../../hooks/use_active_company_code';
import { createGetCompanyStore } from '../../hooks/detail_store_hooks';
import {
    ListUsersProvider,
    createListUsersStore,
    createListGroupsStore,
    ListGroupsProvider,
} from '../../hooks/list_store_hooks';
import { APINotFound } from '../../components/routing/error_handlers';
import { useGlobalStore } from '../../hooks/use_global_context';
import { PageColumn } from '../page_column';
import PageHeader from '../../components/page_header/page_header';
import GroupsList from '../../components/users/groups/groups_view';
import CreateGroupForm from '../../forms/create_group_form';

export const GroupPage: FC = observer(() => {
    const [showGroupModal, setShowGroupModal] = useState(false);
    const { uiStore } = useGlobalStore();

    const companyCode = useActiveCompanyCode();
    const companyStore = createGetCompanyStore();
    const agentsStore = createListUsersStore();
    const groupsStore = createListGroupsStore();

    useEffect(() => {
        const promises = [companyStore.fetch(companyCode), groupsStore.fetch(companyCode)];
        return () => promises.forEach(p => p.cancel());
    }, [companyStore, groupsStore, companyCode]);

    const handleCreateGroup = async ({
        name,
        description,
    }: {
        name: string;
        description: string;
    }) => {
        await StreemAPI.companies.createGroup(companyCode, {
            name,
            description,
        });
        uiStore.addToast({
            content: `Successfully added new group: ${name}`,
            flavor: ToastTypes.SUCCESS,
            id: `group-added-${name}`,
        });
        groupsStore.refresh();
    };

    const closeModal = () => setShowGroupModal(s => !s);

    return (
        <ListGroupsProvider value={groupsStore}>
            <ListUsersProvider value={agentsStore}>
                <APINotFound stores={[companyStore, agentsStore, groupsStore]} />
                <CreateGroupForm
                    isOpen={showGroupModal}
                    handleCancel={() => setShowGroupModal(false)}
                    handleSubmit={handleCreateGroup}
                />
                <PageColumn
                    dataTestId="groups-page"
                    style={{
                        paddingLeft: '16px',
                        paddingRight: '16px',
                    }}
                >
                    <Observer>
                        {() => (
                            <PageHeader
                                title={
                                    companyStore.result?.name
                                        ? `${possession(companyStore.result.name)} Groups`
                                        : ''
                                }
                                iconName="AddIcon"
                                handleClick={closeModal}
                                iconContext="Create New group"
                            />
                        )}
                    </Observer>
                    <GroupsList />
                </PageColumn>
            </ListUsersProvider>
        </ListGroupsProvider>
    );
});
