import { autorun } from 'mobx';
import { useEffect, createContext, useContext } from 'react';
import { CentralLoginAuthStore } from '../stores/central_login_auth_store';
import { WorkspacesStore } from '../stores/workspaces_store';

export interface CentralLoginContext {
    authStore: CentralLoginAuthStore;
    workspacesStore: WorkspacesStore;
}

const centralLoginContext = createContext<CentralLoginContext | undefined>(undefined);

export function useInitializeCentralLoginStores(): CentralLoginContext {
    const authStore = new CentralLoginAuthStore();
    const workspacesStore = new WorkspacesStore(authStore);

    useEffect(() => {
        authStore.connect();
        autorun(() => {
            if (authStore.userId) {
                workspacesStore.connect();
            }
        });
        return () => {
            authStore.disconnect();
            workspacesStore.disconnect();
        };
    }, [authStore, workspacesStore]);

    return {
        authStore,
        workspacesStore,
    };
}

export function useCentralLoginStore(): CentralLoginContext {
    const context = useContext(centralLoginContext);

    if (!context) {
        throw new Error(
            'Unable to useCentralLoginStore without parent <CentralLoginStoreProvider />',
        );
    }

    return context;
}

export const CentralLoginStoreProvider = centralLoginContext.Provider;
