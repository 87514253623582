import { AppText, Card, Column, LoadingOverlay, MeshBackground, Row } from '@streem/ui-react';
import appLogger from '../../util/logging/app_logger';
import { observer } from 'mobx-react';
import { CallLogRow } from '../calls/call_log_columns';
import { ListViewWithPagination } from '../lists/list_view_with_pagination';
import { useGetCompanyCallLogStore } from '../../hooks/list_store_hooks';
import { useGetEmbedCallLogColumns } from '../../hooks/use_get_embed_call_log_columns';
import { useGetEmbedCallLogRows } from '../../hooks/use_get_embed_call_log_rows';
import { joinPaths } from '../../util/routing';
import { useHistory } from 'react-router-dom';
import { EmbedSessionKeys, EmbedSessionParams } from '../../types/project.types';

const log = appLogger.extend('embedCallLog');

interface EmbedCallLogMessageViewProps {
    message: string;
    dataTestId: string;
}

export const EmbedCallLog = observer(() => {
    const params = new URLSearchParams(window.location.search);

    /* STREEM-15109 Note: this behavior is a preliminary step to having our users switch from using a refId
     * to using an integrationId until backend support for this feature is implemented.
     * For the time being, an integrationId or refId can be used to retrieve results that
     * match a refId in the database. Update after STREEM-14888 has been merged.
     */
    const integrationId = params.get('integrationId') ?? params.get('refId');

    const history = useHistory();
    const { callLogColumns, gridTemplateColumnSizeBehavior } = useGetEmbedCallLogColumns();
    const callLogEntryRows: CallLogRow[] = useGetEmbedCallLogRows(integrationId);
    const companyCallLogStore = useGetCompanyCallLogStore();

    const permissionsError =
        companyCallLogStore.lastError && companyCallLogStore.lastError.status === 403;
    const checkConfigurationMessage =
        'Please reach out to your Streem contact to ensure that Streem is configured correctly';

    const handleRowClick = (row: CallLogRow) => {
        const embedSessionParams: EmbedSessionParams = {
            refId: integrationId,
            integrationId: integrationId,
        };
        sessionStorage.setItem(EmbedSessionKeys.CALL_LOG, JSON.stringify(embedSessionParams));
        history.push(joinPaths(history.location.pathname, row.roomSid));
    };

    const isRowClickable = (row: CallLogRow) => row.roomSid && (row.callStatus === 'STATUS_SUCCESSFUL');

    if (!integrationId) {
        log.error(
            'Embed 2.0: Implementation error, developer did not put an integrationId or refId as a param on /streems',
        );
        return (
            <EmbedCallLogMessageView
                message={checkConfigurationMessage}
                dataTestId="embed-implementation-error-card"
            />
        );
    }

    if (permissionsError) {
        log.error('Embed 2.0: Shared call logs is not enabled');
        return (
            <EmbedCallLogMessageView
                message={checkConfigurationMessage}
                dataTestId="embed-permissions-error-card"
            />
        );
    }

    if (!callLogEntryRows) {
        return <LoadingOverlay />;
    }

    if (callLogEntryRows.length === 0) {
        log.info(
            `Embed 2.0: No calls found for integrationId: ${integrationId} or refId: ${integrationId}`,
        );
        return (
            <EmbedCallLogMessageView message="No Calls Found" dataTestId="embed-no-calls-card" />
        );
    }

    return (
        <Column width="100%" height="100%">
            <ListViewWithPagination
                initialScrollPosition={companyCallLogStore.scrollPosition}
                data-testid="embed-call-log-list-view"
                store={companyCallLogStore}
                loading={companyCallLogStore.loading}
                loadingMore={companyCallLogStore.loadingMore}
                onRowClick={handleRowClick}
                canRowClick={isRowClickable}
                data={callLogEntryRows}
                buildRowTestId={row => 'embed-call-log-row-' + row.roomSid}
                columns={callLogColumns}
                gridTemplateColumns={gridTemplateColumnSizeBehavior}
            />
        </Column>
    );
});

export const EmbedCallLogMessageView = (props: EmbedCallLogMessageViewProps) => {
    const { message, dataTestId } = props;
    return (
        <div
            data-testid="embed-call-log-message-screen"
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                overflow: 'hidden',
            }}
        >
            <MeshBackground>
                <Row width="100%" margin="auto" minWidth="1000">
                    <Row alignItems="center" justifyContent="center" height="100vh" width="100vw">
                        <Card
                            style={{
                                height: '300px',
                                width: '400px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                            data-testid={dataTestId}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <AppText bold headingFontFamily size="xlarge">
                                    {message}
                                </AppText>
                            </div>
                        </Card>
                    </Row>
                </Row>
            </MeshBackground>
        </div>
    );
};
