import { useEffect } from 'react';
import { Field, FieldProps } from 'formik';
import { Select, ReactSelectComponents } from '@streem/ui-react';
import { createGetCountriesStore } from '../../hooks/list_store_hooks';
import { observer } from 'mobx-react';

interface Props {
    name: string;
    onChange: (val: string) => void;
}

const SelectContainer = ({ children, ...props }: React.PropsWithChildren<any>) => {
    const innerProps = Object.assign({}, props.innerProps, { 'data-testid': 'country-code' });
    return (
        <ReactSelectComponents.SelectContainer {...props} innerProps={innerProps}>
            {children}
        </ReactSelectComponents.SelectContainer>
    );
};

export const CountryCodeField: React.FC<Props> = observer(({ name, onChange }) => {
    const getCountriesStore = createGetCountriesStore();

    useEffect(() => {
        const promise = getCountriesStore.fetch();
        return () => promise.cancel();
    }, [getCountriesStore]);

    return (
        getCountriesStore.results && (
            <Field name={name}>
                {({ meta }: FieldProps) => (
                    <Select
                        components={{ SelectContainer }}
                        initialValue={{ label: `+${meta.value ?? ''}`, value: meta.value ?? '' }}
                        onSelect={option => onChange(option.value)}
                        aria-label={'Select a country code'}
                        border={true}
                        maxMenuHeight={120}
                        size={'medium'}
                        options={getCountriesStore.results.map(c => ({
                            label: `+${c.callingCode ?? ''}`,
                            value: c.callingCode ?? '',
                        }))}
                    />
                )}
            </Field>
        )
    );
});
