import { FC, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { APIError } from '@streem/api';
import { Box, Header, WhiteInput, AppText, Button } from '@streem/ui-react';
import {
    ContainedForm,
    TopRow,
    MiddleRow,
    BottomRow,
    Inputs,
    ErrorContainer,
    FormButtons,
} from './shared_form.styles';
import {
    CompanyNameEntry,
    CompanyCodeEntry,
    OwnerEmailEntry,
    OwnerNameEntry,
} from './create_company_form.styles';
import config from '../config';
import appLogger from '../util/logging/app_logger';
import { ValidatedField } from '../components/inputs/validated_field';
import { trimFormValues } from '../util/form';

interface Props {
    closeModal: () => void;
    handleSubmitFormValues: (request: any) => void;
    companyName?: string;
    companyCode?: string;
    ownerEmail?: string;
    ownerName?: string;
}

const CreateCompanyForm: FC<Props> = ({
    ownerName,
    ownerEmail,
    companyName,
    companyCode,
    closeModal,
    handleSubmitFormValues,
}) => {
    const MAX_COMPANY_CODE_LENGTH = 30;
    const MIN_COMPANY_NAME_LENGTH = 2;
    const MAX_COMPANY_NAME_LENGTH = 50;
    const MAX_OWNER_NAME_LENGTH = 50;
    const [genericSubmitError, setGenericSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);
    const log = appLogger.extend('create company form');
    return (
        <Formik
            validateOnMount={true}
            initialValues={{
                companyName: companyName || '',
                companyCode: companyCode || '',
                ownerEmail: ownerEmail || '',
                ownerName: ownerName || '',
            }}
            validationSchema={yup.object().shape({
                companyName: yup
                    .string()
                    .required('*Required')
                    .min(
                        MIN_COMPANY_NAME_LENGTH,
                        `Company name must be at least ${MIN_COMPANY_NAME_LENGTH} characters`,
                    )
                    .max(
                        MAX_COMPANY_NAME_LENGTH,
                        `Company name must be at most ${MAX_COMPANY_NAME_LENGTH} characters`,
                    )
                    .matches(/\S/, 'Company name must contain at least one character.'),
                companyCode: yup
                    .string()
                    .required('*Required')
                    .matches(/^[a-z]/, 'Must begin with a lowercase letter')
                    .matches(/^[a-z0-9-]+$/, 'Only lowercase letters, numbers, or hyphens')
                    .min(
                        MIN_COMPANY_NAME_LENGTH,
                        `Company code must be at least ${MIN_COMPANY_NAME_LENGTH} characters`,
                    )
                    .max(
                        MAX_COMPANY_CODE_LENGTH,
                        `Company code must be at most ${MAX_COMPANY_CODE_LENGTH} characters`,
                    ),
                ownerName: yup
                    .string()
                    .required('*Required')
                    .max(
                        MAX_OWNER_NAME_LENGTH,
                        `Owner name must be at most ${MAX_OWNER_NAME_LENGTH} characters.`,
                    )
                    .matches(/\S/, 'Owner name must contain at least one character.'),
                ownerEmail: yup.string().email('Invalid email address').required('*Required'),
            })}
            onSubmit={async (values, { setFieldError }) => {
                const safeFormValues = trimFormValues(values, [
                    'companyName',
                    'companyCode',
                    'ownerEmail',
                    'ownerName',
                ]);
                setLoading(true);
                try {
                    setGenericSubmitError(false);
                    await handleSubmitFormValues(safeFormValues);
                    closeModal();
                } catch (error) {
                    setGenericSubmitError(true);
                    if (error instanceof APIError) {
                        const status = error.response.status;
                        status === 409 &&
                            setFieldError(
                                'companyCode',
                                'A company with that company code already exists.',
                            );
                    }

                    log.error('Failed to create company', error);
                } finally {
                    setLoading(false);
                }
            }}
        >
            {({ isValid, isSubmitting, dirty }) => {
                return (
                    // TODO: fix this janky solution when the card component is ready accept scrollable content
                    <ContainedForm data-testid="create-company-form">
                        <TopRow>
                            <Header style={{ wordBreak: 'break-word' }} size="xxlarge" color="dark">
                                Create a New Company
                            </Header>
                        </TopRow>
                        <MiddleRow>
                            <Inputs>
                                <CompanyNameEntry>
                                    <ValidatedField
                                        autoFocus={true}
                                        required
                                        type="text"
                                        name="companyName"
                                        placeholder=""
                                        data-testid="company-name-field"
                                        component={WhiteInput}
                                        label="company name"
                                        labelColor="dark"
                                    />
                                </CompanyNameEntry>
                                <CompanyCodeEntry>
                                    <Box width={'calc(100% - 110px)'}>
                                        <ValidatedField
                                            required
                                            type="text"
                                            name="companyCode"
                                            placeholder=""
                                            data-testid="company-code-field"
                                            component={WhiteInput}
                                            label="company code"
                                            labelColor="dark"
                                        />
                                    </Box>
                                    <Box position={'absolute'} top={'40px'} right={'0'}>
                                        <AppText bold color="dark">
                                            {`.${config.vanityDomain}`}
                                        </AppText>
                                    </Box>
                                </CompanyCodeEntry>
                                <OwnerEmailEntry>
                                    <ValidatedField
                                        type="email"
                                        name="ownerEmail"
                                        placeholder={'Email Address'}
                                        data-testid="owner-email-field"
                                        component={WhiteInput}
                                        label="owner email"
                                        required
                                        labelColor="dark"
                                    />
                                </OwnerEmailEntry>
                                <OwnerNameEntry justifyContent={'space-between'}>
                                    <ValidatedField
                                        type="text"
                                        name="ownerName"
                                        placeholder=""
                                        component={WhiteInput}
                                        data-testid="owner-name-field"
                                        label="owner name"
                                        required
                                        labelColor="dark"
                                    />
                                </OwnerNameEntry>
                            </Inputs>
                        </MiddleRow>
                        {genericSubmitError && (
                            <ErrorContainer>
                                <AppText color="red50">
                                    Form submission error. Please try again.
                                </AppText>
                            </ErrorContainer>
                        )}
                        <BottomRow>
                            <FormButtons>
                                <Button
                                    variant="secondary"
                                    data-testid="cancel-create-company"
                                    onClick={closeModal}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    variant="primary"
                                    disabled={!dirty || !isValid || isSubmitting}
                                    type="submit"
                                    data-testid="submit-create-company"
                                    loading={loading}
                                >
                                    Create Company
                                </Button>
                            </FormButtons>
                        </BottomRow>
                    </ContainedForm>
                );
            }}
        </Formik>
    );
};

export default CreateCompanyForm;
