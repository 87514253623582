import React, { useState, useEffect } from 'react';
import { Select, Option, styled } from '@streem/ui-react';
import { createListGroupUsersStore } from '../../hooks/list_store_hooks';
import { APITypes } from '@streem/api';
import { useActiveCompanyCode } from '../../hooks/use_active_company_code';
import { Observer } from 'mobx-react';

type StreemAuthUserType = APITypes.StreemApiUser;
type StreemAuthUserOptionType = Option<StreemAuthUserType>;

const sortUsersByNameAsc = (a: APITypes.StreemApiUser, b: APITypes.StreemApiUser) => {
    const aName = a.name?.toUpperCase() || '';
    const bName = b.name?.toUpperCase() || '';
    if (aName < bName) {
        return -1;
    }
    if (aName > bName) {
        return 1;
    }

    return 0;
};

const NameAndEmailOptionContainer = styled.div<{ active: boolean }>`
    display: flex;
    flex-direction: column;
    line-height: 1.2;
    padding: 16px 0px 0px 0px;
    &:after {
        content: '';
        border-bottom: 1px solid #eee;
        margin-top: 16px;
    }
    ${props => props.active && `&:after { border-color: transparent; }`}
`;

const SelectedUserContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 7px;
`;
const SelectedName = styled.span`
    line-height: 1.2;
    font-size: 16px;
`;

const SelectedEmail = styled.span`
    font-size: 12px;
`;

const UserName = styled.span`
    font-size: 16px;
`;

const Email = styled.span`
    margin-top: 0.5rem;
    font-size: 12px;
`;

interface Props {
    defaultSelectedUserId?: string;
    onSelect: (user: StreemAuthUserType) => void;
    groupName: string;
    onlyActive: boolean;
}

export const GroupUserPicker: React.FC<Props> = ({
    defaultSelectedUserId,
    onSelect,
    onlyActive,
    groupName,
}: Props) => {
    const groupUsersStore = createListGroupUsersStore();
    const companyCode = useActiveCompanyCode();

    useEffect(() => {
        const request = groupUsersStore.fetch(companyCode, groupName);
        return () => request.cancel();
    }, [groupUsersStore, companyCode, groupName]);

    const [selectedValue, setSelectedValue] = useState(defaultSelectedUserId);

    return (
        <Observer>
            {() => {
                if (groupUsersStore.loading) {
                    return null;
                }
                const options: StreemAuthUserOptionType[] = groupUsersStore.results
                    .filter(user => (onlyActive ? user.active : true))
                    .sort(sortUsersByNameAsc)
                    .map(user => ({ label: user?.name || '', value: user }));

                const selectedUser: StreemAuthUserOptionType | undefined = options.find(
                    option => option.value.sid === selectedValue,
                );
                return (
                    <Select<StreemAuthUserType>
                        initialValue={selectedUser}
                        isClearable={true}
                        components={{
                            ClearIndicator: () => null,
                            IndicatorSeparator: () => null,
                            SingleValue: ({ data }) => {
                                return (
                                    <SelectedUserContainer data-testid="selected-user">
                                        <SelectedName>{data.label}</SelectedName>
                                        <SelectedEmail>{data.value.email}</SelectedEmail>
                                    </SelectedUserContainer>
                                );
                            },
                        }}
                        formatOptionLabel={data => {
                            const { value }: { value: StreemAuthUserType } = data as {
                                value: StreemAuthUserType;
                            };

                            return (
                                <NameAndEmailOptionContainer
                                    data-testid={`user-${value.sid}`}
                                    active={value.sid === selectedValue}
                                >
                                    <UserName>{value.name}</UserName>
                                    <Email>{value.email}</Email>
                                </NameAndEmailOptionContainer>
                            );
                        }}
                        filterOption={({ data }: { data: any }, term: string) => {
                            const re = new RegExp(term, 'gi');
                            return (
                                re.test(data.value.email || '') || re.test(data.value.name || '')
                            );
                        }}
                        placeholder="Select team member or start typing."
                        noOptionsMessage={() => 'No team members found.'}
                        options={options}
                        onSelect={option => {
                            setSelectedValue(option?.value.sid);
                            onSelect(option?.value);
                        }}
                        styles={{
                            control: (base: any, { menuIsOpen }: { menuIsOpen: boolean }) => ({
                                ...base,
                                width: '430px',
                                padding: '8px 0px',
                                borderRadius: '8px',
                                borderBottomLeftRadius: menuIsOpen ? '0px' : '8px',
                                borderBottomRightRadius: menuIsOpen ? '0px' : '8px',
                                transition: 'none',
                            }),
                            menu: (base: any) => ({
                                ...base,
                                marginTop: '0px',
                                borderRadius: 0,
                                borderTopLeftRadius: '0px',
                                borderTopRightRadius: '0px',
                                borderBottomLeftRadius: '8px',
                                borderBottomRightRadius: '8px',
                            }),
                            menuList: (base: any) => ({
                                ...base,
                                padding: 0,
                            }),
                            option: (base: any) => ({
                                ...base,
                                padding: '0px 16px',
                            }),
                        }}
                    />
                );
            }}
        </Observer>
    );
};
