import debounce from 'lodash.debounce';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import UserFormModal from '../../forms/user_form';
import { UserFormStrategy } from '../../types/project.types';
import { possession } from '../../util/strings';
import { useActiveCompanyCode } from '../../hooks/use_active_company_code';
import { createGetCompanyStore } from '../../hooks/detail_store_hooks';
import { ListUsersProvider, createListUsersStore } from '../../hooks/list_store_hooks';
import { sortParamFormatter, ListUsersForCompanyUserTypes } from '@streem/api';
import { observer, Observer } from 'mobx-react';
import { APINotFound } from '../../components/routing/error_handlers';
import PageHeader from '../../components/page_header/page_header';
import CompanyAgentList from '../../components/users/team/agent_list';
import { useGlobalStore } from '../../hooks/use_global_context';
import { PageColumn } from '../page_column';

const TeamPage: FC = observer(() => {
    const [showModal, setShowModal] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const { userStore } = useGlobalStore();

    const companyCode = useActiveCompanyCode();
    const companyStore = createGetCompanyStore();
    const agentsStore = createListUsersStore();

    useEffect(() => {
        const promises = [
            companyStore.fetch(companyCode),
            agentsStore.fetchFirstPage({
                companyCodeOrSid: companyCode,
                pageSize: 100,
                filter: searchString,
                sort:
                    sortBy === 'name'
                        ? sortParamFormatter('name', sortOrder)
                        : [
                              sortParamFormatter(sortBy, sortOrder),
                              sortParamFormatter('name', sortOrder),
                          ].join(','),
                userTypes: [ListUsersForCompanyUserTypes.LOGIN],
            }),
        ];
        return () => promises.forEach(p => p.cancel());
    }, [companyStore, agentsStore, searchString, sortBy, sortOrder, companyCode]);

    // If the user updates their own profile via means
    // other than the agents list, this refreshes the agents list
    useEffect(() => {
        if (agentsStore.refreshable()) {
            agentsStore.refresh();
        }
    }, [userStore.user, agentsStore]);

    const sortSwitch = (columnKey: string, sortOrder: string) => {
        setSortOrder(sortOrder.toLowerCase());
        setSortBy(columnKey);
    };

    const debounceSearch = debounce(setSearchString, 1000);

    const filterSearch = (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();
        debounceSearch(e.target.value.trim());
    };

    const closeModal = () => setShowModal(s => !s);

    return (
        <ListUsersProvider value={agentsStore}>
            <APINotFound stores={[companyStore, agentsStore]} />
            <UserFormModal
                isOpen={showModal}
                strategy={UserFormStrategy.CREATE}
                onCancel={closeModal}
                onSuccess={() => {
                    agentsStore.refresh();
                    closeModal();
                }}
            />
            <PageColumn
                dataTestId="team-page"
                style={{
                    paddingLeft: '16px',
                    paddingRight: '16px',
                }}
            >
                <Observer>
                    {() => (
                        <PageHeader
                            title={
                                companyStore.result?.name
                                    ? `${possession(companyStore.result.name)} Team`
                                    : ''
                            }
                            searchPlaceholder={'Search Team Members'}
                            onSearch={filterSearch}
                            iconName={'AddIcon'}
                            iconContext={'Add Team Member'}
                            handleClick={closeModal}
                        />
                    )}
                </Observer>
                <CompanyAgentList handleSort={sortSwitch} />
            </PageColumn>
        </ListUsersProvider>
    );
});

export default TeamPage;
