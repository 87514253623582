import { FC, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { observer } from 'mobx-react';
import { styled, Box, Checkbox, Button, Header, AppText, Column } from '@streem/ui-react';
import { useGlobalStore } from '../hooks/use_global_context';
import { ErrorContainer } from './shared_form.styles';
import { Redirect } from 'react-router-dom';
import appLogger from '../util/logging/app_logger';

interface FormValues {
    customProTCsSettingsEnabled: boolean;
    termsAndConditions: boolean;
    privacyPolicy: boolean;
    customProTCsAccepted: boolean;
}

export const TermsAndConditionsForm: FC = observer(() => {
    const log = appLogger.extend('terms and conditions form');
    const { authStore, companySettingsStore } = useGlobalStore();
    const [genericSubmitError, setGenericSubmitError] = useState(false);
    const customProTCsSettingsEnabled = !!companySettingsStore.proTsAndCsUrl;
    const customProTCsUrl = companySettingsStore.proTsAndCsUrl;

    const termsValidation = yup.object().shape({
        termsAndConditions: yup.boolean().oneOf([true]).required(),
        privacyPolicy: yup.boolean().oneOf([true]).required(),
        customProTCsAccepted: yup.boolean().when('customProTCsSettingsEnabled', {
            is: true,
            then: yup.boolean().oneOf([true]),
            otherwise: yup.boolean().oneOf([false]).required(),
        }),
    });

    if (authStore.acceptedLatestTCs) {
        return <Redirect to="/" />;
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                termsAndConditions: false,
                privacyPolicy: false,
                customProTCsSettingsEnabled: customProTCsSettingsEnabled,
                customProTCsAccepted: false,
            }}
            validateOnMount={true}
            validationSchema={termsValidation}
            onSubmit={async () => {
                try {
                    setGenericSubmitError(false);
                    await authStore.acceptLatestTCs();
                } catch (e) {
                    log.warn('failed on terms and conditions form: ', e);
                    setGenericSubmitError(true);
                }
            }}
        >
            {({ isSubmitting, values, setFieldValue, isValid }: FormikProps<FormValues>) => {
                return (
                    <TermsForm data-testid="terms-form">
                        <Box marginBottom="32px" maxWidth={388} marginLeft="25.5px">
                            <Box mb={2} mr={6} marginTop="9.5px">
                                <Header size="xlarge">Terms&nbsp;&&nbsp;Conditions</Header>
                            </Box>
                            <Box marginBottom="12px">
                                <AppText size="medium">
                                    Before using a Streem Video Call, it’s important that you
                                    understand what we collect, use, and how you control your
                                    information.
                                </AppText>
                            </Box>
                            <Column>
                                <CheckboxWrap>
                                    <Checkbox
                                        data-testid="termsandconditions-checkbox"
                                        checked={values.termsAndConditions}
                                        onChange={() => {
                                            setFieldValue(
                                                'termsAndConditions',
                                                !values.termsAndConditions,
                                            );
                                        }}
                                        label="termsandconditions-checkbox"
                                    />
                                    <CheckboxLabel>
                                        <span>I agree to Streem Inc's </span>
                                        <HighlightLink
                                            href="https://www.streem.com/terms-and-conditions#providers"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Terms of Use
                                        </HighlightLink>
                                    </CheckboxLabel>
                                </CheckboxWrap>
                                <CheckboxWrap>
                                    <Checkbox
                                        data-testid="privacy-checkbox"
                                        checked={values.privacyPolicy}
                                        onChange={() => {
                                            setFieldValue('privacyPolicy', !values.privacyPolicy);
                                        }}
                                        label="privacy-checkbox"
                                    />
                                    <CheckboxLabel>
                                        <span>I agree to Streem Inc's </span>
                                        <HighlightLink
                                            href="https://www.streem.com/privacy-policy"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </HighlightLink>
                                    </CheckboxLabel>
                                </CheckboxWrap>
                                {values.customProTCsSettingsEnabled && (
                                    <CheckboxWrap>
                                        <Checkbox
                                            data-testid="customTCs-checkbox"
                                            /* this is used to track the checkbox boolean value */
                                            checked={values.customProTCsAccepted}
                                            onChange={() => {
                                                setFieldValue(
                                                    'customProTCsAccepted',
                                                    !values.customProTCsAccepted,
                                                );
                                            }}
                                            label="customTCs-checkbox"
                                        />
                                        <CheckboxLabel>
                                            <span>I agree to additional </span>
                                            <HighlightLink
                                                href={customProTCsUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Terms of Use
                                            </HighlightLink>
                                        </CheckboxLabel>
                                    </CheckboxWrap>
                                )}
                            </Column>
                        </Box>
                        {genericSubmitError && (
                            <ErrorContainer>
                                <AppText color="red50">
                                    Form submission error. Please try again.
                                </AppText>
                            </ErrorContainer>
                        )}
                        <ButtonWrap>
                            <Box display="flex" alignSelf="flex-end">
                                <Box mr="20px">
                                    <Button
                                        variant="secondary"
                                        data-testid="decline-button"
                                        onClick={() => {
                                            authStore.logout();
                                        }}
                                        type="button"
                                        width={150}
                                    >
                                        Decline
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        data-testid="submit-button"
                                        width={150}
                                        disabled={
                                            isSubmitting ||
                                            !isValid ||
                                            !values.termsAndConditions ||
                                            !values.privacyPolicy ||
                                            (values.customProTCsSettingsEnabled
                                                ? !values.customProTCsAccepted
                                                : false)
                                        }
                                        loading={isSubmitting}
                                    >
                                        Accept
                                    </Button>
                                </Box>
                            </Box>
                        </ButtonWrap>
                    </TermsForm>
                );
            }}
        </Formik>
    );
});

export const HighlightLink = styled.a(({ theme }) => ({
    color: theme.colors.azure,
    textDecoration: 'none',
}));

export const ButtonWrap = styled.div`
    padding-left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;
export const CheckboxWrap = styled.div`
    display: flex;
    margin-top: 15px;
    align-items: center;
`;

export const CheckboxLabel = styled.span(({ theme }) => ({
    color: theme.colors.grey70,
    fontSize: '16px',
    fontWeight: 500,
    marginLeft: '16px',
}));

export const TermsForm = styled(Form)`
    position: relative;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${props => props.theme.colors.white};
    padding: 30.5px 40px;
    box-shadow: ${props => props.theme.shadow.large};
    border-radius: 4px;
`;
