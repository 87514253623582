import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom';
import * as React from 'react';
import { useObserver } from 'mobx-react';
import { useGlobalStore } from '../hooks/use_global_context';
import { RolesAuth } from '../components/authorization/roles_auth';
import { Role } from '../types/project.types';
import { TermsAndConditionsPage } from '../pages/authentication/terms_and_conditions_page';
interface Props extends RouteProps {
    only: Role[];
}

export const ProtectedRoute: React.FC<Props> = props => {
    const { authStore } = useGlobalStore();
    const location = useLocation();

    const { only, ...routeProps } = props;

    return useObserver(() => {
        if (!authStore.isUserSignedIn) {
            return (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: location },
                    }}
                />
            );
        }

        if (!authStore.acceptedLatestTCs) {
            if (routeProps.path === '/termsandconditions') {
                return <TermsAndConditionsPage />;
            }
            return (
                <Redirect
                    to={{
                        pathname: '/termsandconditions',
                        state: { from: location },
                    }}
                />
            );
        }

        return (
            <RolesAuth only={only}>
                {(isAllowed: boolean) =>
                    isAllowed ? <Route {...routeProps} /> : <Redirect to="/" />
                }
            </RolesAuth>
        );
    });
};
