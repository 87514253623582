import { recordArtifactViewed } from '@streem/analytics';
import { RecordingId, StreemshotId } from '@streem/domain-id';
import { useMediaBreakpoint, useObservable } from '@streem/sdk-react';
import {
    AppIcon,
    AppText,
    Box,
    Button,
    ButtonRow,
    Card,
    IconButton,
    Row,
    Skeleton,
    styled,
    Subheader,
    useTheme,
} from '@streem/ui-react';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { useObserver } from 'mobx-react';
import { FC, useEffect, useRef, useState } from 'react';
import { streem, WallItem } from 'streem-sdk-protobuf';
import { invariant } from '@streem/toolbox';
import { useDetailSession } from '../../hooks/detail_session_hooks';
import { StreemshotData } from '../../hooks/use_fetch_streemshot_data';
import { useGlobalStore } from '../../hooks/use_global_context';
import { isRecordingReady, isPreviewReady, isRecordingPending } from '../../util/artifacts';
import { isChrome } from '../../util/is_chrome';
import appLogger from '../../util/logging/app_logger';
import { DeleteConfirmation } from './delete_confirmation';
import { RecordingThumbnail, RecordingTrackThumbnail, StreemshotThumbnail } from './thumbnail';
import { TimeStampController } from '../../util/timestamp_controller';
import {
    TimeStampControllerProvider,
    useTimeStampController,
} from '../../hooks/use_timestamp_controller';
import { MediaGalleryBookmark } from './bookmark';
import { useGetEmbedEnabledSettings } from '../../hooks/use_get_embed_enabled_settings';
import { useRawTranscription } from '../../hooks/use_raw_transcription';
import { CallTranscription } from './call_transcription';

const log = appLogger.extend('Media Gallery');

/**
 * Responsive mosaic grid of recordings and streemshots
 */
export const MediaGallery: FC<{
    readOnly: boolean;
    videoPreviewEnabled?: boolean;
}> = ({ readOnly, videoPreviewEnabled }) => {
    const detailSession = useDetailSession();
    const [errorMessage, setErrorMessage] = useState('');
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const { uiStore, authStore, companySettingsStore } = useGlobalStore();
    const { isEmbedView } = useGetEmbedEnabledSettings();
    const { isMobile } = useMediaBreakpoint();
    const { transcriptionsEnabled } = companySettingsStore;

    const [streemshots = []] = useObservable(detailSession.streemshots.responses);
    const [recordings = []] = useObservable(detailSession.recording.recordings);
    const [bookmarks = []] = useObservable(detailSession.bookmarks.bookmarks);
    const [callStartTime] = useObservable(detailSession.bookmarks.callStartTime);
    const [transcriptions = []] = useObservable(detailSession.transcription.transcriptions);

    const transcriptionArtifact =
        transcriptionsEnabled && transcriptions.length > 0 ? transcriptions[0] : null;

    const [[selectedStreemshotIds], setSelectedStreemshotIds] = useState<[Set<string>]>([
        new Set(),
    ]);
    const getSelectedStreemshots = () =>
        streemshots.filter(
            ss => selectedStreemshotIds.has(ss.id) || selectedStreemshotIds.has(ss?.streemshotId),
        );

    const [[selectedRecordingIds], setSelectedRecordingIds] = useState<[Set<string>]>([new Set()]);
    const getSelectedRecordings = () => recordings.filter(r => selectedRecordingIds.has(r.id));

    const selectionSize = selectedRecordingIds.size + selectedStreemshotIds.size;
    const selectionVisible = selectionSize > 0 || isMobile;

    const [showVideoPreviewInfoCard, setShowVideoPreviewInfoCard] = useState(false);
    const [videoPreviewTermsAccepted, setVideoPreviewTermsAccepted] = useState(false);
    const browserCanPlayPreview = isChrome();

    const previewReady = isPreviewReady(recordings);
    const recordingReady = isRecordingReady(recordings);
    const recordingPending = isRecordingPending(recordings);
    const showVideoPreview =
        videoPreviewEnabled && videoPreviewTermsAccepted && previewReady && !recordingReady;
    const streemshotsImageDataRef = useRef<{ [url: string]: StreemshotData }>({});

    const rawTranscription = useRawTranscription(transcriptionArtifact?.transcription?.url ?? null);

    useEffect(() => {
        log.info(`Media gallery is rendering ${streemshots.length} streemshots`);
    }, [streemshots.length]);

    useEffect(() => {
        const streemshotImageDataCache = streemshotsImageDataRef.current;
        return function dataUrlCleanup() {
            // Clean url's data from browser
            const keys = Object.keys(streemshotImageDataCache);
            keys.forEach(key => {
                const { dataURL } = streemshotImageDataCache[key];
                URL.revokeObjectURL(dataURL);
            });
        };
    }, [streemshotsImageDataRef]);

    const handleArtifactSelect = (artifactType: WallItem.ArtifactType, artifactSid: string) => {
        setErrorMessage('');
        const toggleInclusion = (set: Set<string>) => {
            const included = set.delete(artifactSid);
            if (!included) {
                set.add(artifactSid);
            }

            return set;
        };

        switch (artifactType) {
            case WallItem.ArtifactType.RECORDING:
                setSelectedRecordingIds([toggleInclusion(selectedRecordingIds)]);
                break;
            case WallItem.ArtifactType.STREEMSHOT:
                setSelectedStreemshotIds([toggleInclusion(selectedStreemshotIds)]);
                break;
            default:
                log.error('Attempted to select artifact of unsupported type ' + artifactType);
                return;
        }
    };

    const clearSelection = () => {
        setSelectedRecordingIds([new Set()]);
        setSelectedStreemshotIds([new Set()]);
    };

    /**
     * Only returns 'true' when all selected streemshots have successfully loaded
     */
    const awaitSelectionCache = (): Promise<boolean> => {
        // TODO: use this logic somewhere for UI update (need designs)
        // let progress = ''

        const selectedStreemshots = getSelectedStreemshots();
        return new Promise(res => {
            const checkCacheMatches = setInterval(() => {
                const cacheMatches = selectedStreemshots.filter(ss => {
                    const downloadURL = ss.downloadUrl;
                    return downloadURL && downloadURL in streemshotsImageDataRef.current;
                });
                /* TODO: use this logic somewhere for UI update (need designs)
    const progressUpdate = (`${cacheMatches.length} of ${selectedStreemshots.length} images cached.`);
    if (progressUpdate !== progress) {
        progress = `${cacheMatches.length} of ${selectedStreemshots.length} images cached.`
        console.log(progress)
    }
*/
                if (cacheMatches.length === selectedStreemshots.length) {
                    res(true);
                    clearInterval(checkCacheMatches);
                }
            }, 250);
        });
    };

    const objectUrlToBlob = (objectURL: string): Promise<Blob> => {
        return new Promise((res, rej) => {
            fetch(objectURL)
                .then(r => res(r.blob()))
                .catch(err => rej(err));
        });
    };

    const saveAssetsToDisk = async () => {
        const zip = new JSZip();
        const imageFolder = zip.folder('images');
        let validStreemshotZip = true;
        let validRecordingZip = true;

        const selectedStreemshots = getSelectedStreemshots();

        if (selectedStreemshots.length > 0) {
            validStreemshotZip = await getStreemshotBlobPromises();
        }

        const selectedRecordings = getSelectedRecordings();
        if (selectedRecordings.length > 0) {
            validRecordingZip = await getRecordingBlobPromises();
        }

        if (validStreemshotZip && validRecordingZip) {
            try {
                const content = await imageFolder?.generateAsync({ type: 'blob' });
                invariant(content, 'Error generating content for assets for download');
                saveAs(content, 'assets.zip');
            } catch (e) {
                setErrorMessage('Error generating .zip file.');
                log.error('Error generating streemshot .zip file: ', e);
            }
        } else {
            setErrorMessage('Error validating .zip file.');
            log.error('Error validating assets.zip file: ');
        }

        async function getStreemshotBlobPromises() {
            const streemshotBlobPromises = selectedStreemshots.reduce(
                (blobArray: Promise<Blob>[], streemshot) => {
                    const downloadURL = streemshot.downloadUrl;
                    if (
                        downloadURL &&
                        Object.prototype.hasOwnProperty.call(
                            streemshotsImageDataRef.current,
                            downloadURL,
                        )
                    ) {
                        const streemshotBlob = objectUrlToBlob(
                            streemshotsImageDataRef.current[downloadURL].dataURL,
                        );
                        blobArray.push(streemshotBlob);
                    }
                    return blobArray;
                },
                [],
            );
            const streemshotResults = await Promise.allSettled(streemshotBlobPromises);
            return addBlobToFile(imageFolder, streemshotResults);
        }

        async function getRecordingBlobPromises() {
            const recordingBlobPromise = selectedRecordings.reduce(
                (blobArray: Promise<Blob>[], recording) => {
                    const downloadURL = recording.recording.downloadUrl;
                    if (downloadURL) {
                        const recordingBlob = objectUrlToBlob(downloadURL);
                        blobArray.push(recordingBlob);
                    }
                    return blobArray;
                },
                [],
            );
            const recordingResults = await Promise.allSettled(recordingBlobPromise);
            return addBlobToFile(imageFolder, recordingResults);
        }
    };

    const addBlobToFile = (
        imageFolder: JSZip,
        promiseResults: PromiseSettledResult<Blob>[],
    ): boolean => {
        let validZip = false;
        promiseResults.forEach((result, index) => {
            if (result.status === 'rejected') {
                setErrorMessage('Some images could not be downloaded');
                log.error('Error attempting to download streemshot: ', result.reason);
            }
            if (result.status === 'fulfilled' && result.value) {
                const streemshotBlob = result.value;
                const streemshotFormat = streemshotBlob.type;
                let streemshotExtension = '';
                let fileName = '';
                switch (streemshotFormat) {
                    case 'image/png':
                        streemshotExtension = 'png';
                        fileName = `streemshot-${index + 1}.${streemshotExtension}`;
                        break;
                    case 'image/jpeg':
                        streemshotExtension = 'jpg';
                        fileName = `streemshot-${index + 1}.${streemshotExtension}`;
                        break;
                    case 'video/mp4':
                        streemshotExtension = 'mp4';
                        fileName = `record-${index + 1}.${streemshotExtension}`;
                        break;
                    default:
                        // https://caniuse.com/mdn-api_blob_type
                        // 8/2/21: blob.type unsupported on Safari iOS
                        // Most streemshots going forward will be in jpg, so use as default
                        log.warn(
                            'Unknown streemshot format:',
                            streemshotFormat,
                            'Defaulting to jpg',
                        );
                        streemshotExtension = 'jpg';
                        break;
                }
                imageFolder?.file(fileName, streemshotBlob);
                validZip = true;
            }
        });
        return validZip;
    };

    const handleDownload = async () => {
        setDownloading(true);
        const allSelectionsCached = await awaitSelectionCache();
        if (allSelectionsCached) {
            await saveAssetsToDisk();
            setDownloading(false);
        }
    };

    const handleDelete = async () => {
        setDeleteInProgress(true);
        setErrorMessage('');

        const deletionTasks: Promise<void>[] = [];
        for (const sid of selectedStreemshotIds) {
            const task = detailSession.streemshots.deleteStreemshot(new StreemshotId(sid));
            deletionTasks.push(task);
        }

        for (const sid of selectedRecordingIds) {
            const task = detailSession.recording.deleteRecording(new RecordingId(sid));
            deletionTasks.push(task);
        }

        try {
            await Promise.allSettled(deletionTasks);
            clearSelection();
        } catch (e) {
            setErrorMessage('Error attempting to delete streemshots');
            log.error('Error attempting to delete a streemshot', e);
        }

        setDeleteInProgress(false);
        setIsDeleteConfirmationOpen(false);
    };

    return useObserver(() => {
        return (
            <section data-testid="media-gallery">
                <MediaGalleryHeader as="h2" size="mediumLarge">
                    Media Gallery
                </MediaGalleryHeader>
                <MediaGalleryGrid>
                    <TimeStampControllerProvider value={new TimeStampController(recordings)}>
                        {recordings.length > 0 && (
                            <VideoContainer hideBookmarks={readOnly && bookmarks.length === 0}>
                                {recordingPending && !videoPreviewEnabled && (
                                    // If the company setting for video preview is FALSE and the recording is pending we only want a skeleton.
                                    <Skeleton></Skeleton>
                                )}
                                {recordingPending && videoPreviewEnabled && !showVideoPreview && (
                                    // If the company setting for video preview is TRUE, the recording is pending, and the preview is NOT ready we should only see the skeleton.
                                    // Once the preview is ready, then we should see a skeleton and a preview button.
                                    // Once we click the preview button, we should see the skeleton and the video preview info card.
                                    // Once we accept terms, the skeleton and info card should disappear and we should see the video preview.
                                    <>
                                        <Skeleton>
                                            {previewReady &&
                                                browserCanPlayPreview &&
                                                !showVideoPreviewInfoCard && (
                                                    <PreviewButton
                                                        onClick={() =>
                                                            setShowVideoPreviewInfoCard(true)
                                                        }
                                                    />
                                                )}
                                            {previewReady && !browserCanPlayPreview && (
                                                <UnsupportedBrowserInfoCard />
                                            )}
                                            {previewReady &&
                                                showVideoPreviewInfoCard &&
                                                !videoPreviewTermsAccepted && (
                                                    <VideoPreviewInfoCard
                                                        handleOkay={() =>
                                                            setVideoPreviewTermsAccepted(true)
                                                        }
                                                        handleCancel={() =>
                                                            setShowVideoPreviewInfoCard(false)
                                                        }
                                                    />
                                                )}
                                        </Skeleton>
                                    </>
                                )}
                                {showVideoPreview &&
                                    recordings.map(recording => (
                                        <RecordingTrackThumbnail
                                            canSelect={false}
                                            isSelected={false}
                                            isSelectVisible={selectionVisible}
                                            key={`preview-${recording.id}`}
                                            artifact={recording}
                                            onPlay={() =>
                                                recordArtifactViewed({
                                                    artifactSid: recording.id,
                                                    roomSid: detailSession.roomId.toString(),
                                                    recordingTrack: {},
                                                })
                                            }
                                        />
                                    ))}
                                {recordingReady &&
                                    recordings.map(recording => {
                                        return (
                                            <RecordingThumbnail
                                                canSelect={authStore.isAdmin}
                                                isSelected={selectedRecordingIds.has(recording.id)}
                                                isSelectVisible={selectionVisible}
                                                key={recording.id}
                                                onSelect={() =>
                                                    handleArtifactSelect(
                                                        WallItem.ArtifactType.RECORDING,
                                                        recording.id,
                                                    )
                                                }
                                                artifact={recording}
                                                transcriptionArtifact={transcriptionArtifact}
                                                onPlay={() =>
                                                    recordArtifactViewed({
                                                        artifactSid: recording.id,
                                                        roomSid: detailSession.roomId.toString(),
                                                        recording: {},
                                                    })
                                                }
                                            />
                                        );
                                    })}
                                {recordings.length > 0 && !(readOnly && bookmarks.length === 0) && (
                                    <BookmarkSection>
                                        <BookmarksHeaderContainer>
                                            <AppText
                                                as="h3"
                                                headingFontFamily
                                                style={{ fontSize: '18px' }}
                                            >
                                                Video Bookmarks
                                            </AppText>
                                        </BookmarksHeaderContainer>
                                        {callStartTime && !recordingPending && !readOnly && (
                                            <AddNewBookmark
                                                addBookmark={bookmark =>
                                                    detailSession.bookmarks.addBookmark(bookmark)
                                                }
                                            />
                                        )}
                                        <BookmarkContainer>
                                            {bookmarks.length > 0 &&
                                                callStartTime &&
                                                bookmarks
                                                    .sort((a, b) => {
                                                        const aCreatedAtTime =
                                                            a.bookmark.timestamp?.seconds.low +
                                                            a.bookmark.timestamp?.nanos /
                                                                1000000000;
                                                        const bCreatedAtTime =
                                                            b.bookmark.timestamp?.seconds.low +
                                                            b.bookmark.timestamp?.nanos /
                                                                1000000000;
                                                        if (aCreatedAtTime > bCreatedAtTime) {
                                                            return 1;
                                                        } else {
                                                            return -1;
                                                        }
                                                    })
                                                    .map(bookmark => (
                                                        <MediaGalleryBookmark
                                                            enableToolTip={recordingPending}
                                                            disableTimestamp={recordingPending}
                                                            key={bookmark.id}
                                                            bookmarkArtifact={bookmark}
                                                            isBookmarkOwner={
                                                                bookmark.ownerUserId ===
                                                                authStore.userId
                                                            }
                                                            updateBookmark={(label: string) => {
                                                                const newBookmark = Object.assign(
                                                                    bookmark.bookmark,
                                                                );
                                                                detailSession.bookmarks.updateBookmark(
                                                                    bookmark.id,
                                                                    { ...newBookmark, label },
                                                                );
                                                            }}
                                                            deleteBookmark={async () => {
                                                                await detailSession.bookmarks.deleteBookmark(
                                                                    bookmark.id,
                                                                );
                                                            }}
                                                        />
                                                    ))}
                                        </BookmarkContainer>
                                    </BookmarkSection>
                                )}
                            </VideoContainer>
                        )}
                        {rawTranscription && transcriptionsEnabled && (
                            <CallTranscription rawTranscription={rawTranscription} />
                        )}
                        {streemshots.map(streemshot => {
                            return (
                                <StreemshotThumbnail
                                    showTimestamp={recordingReady && recordings.length > 0}
                                    canSelect={true}
                                    imageDataRef={streemshotsImageDataRef}
                                    isSelected={
                                        selectedStreemshotIds.has(streemshot.id) ||
                                        selectedStreemshotIds.has(streemshot?.streemshotId)
                                    }
                                    isSelectVisible={selectionVisible}
                                    key={streemshot.id}
                                    onSelect={() =>
                                        handleArtifactSelect(
                                            WallItem.ArtifactType.STREEMSHOT,
                                            streemshot?.streemshotId || streemshot.id,
                                        )
                                    }
                                    streemshot={streemshot}
                                />
                            );
                        })}
                    </TimeStampControllerProvider>
                </MediaGalleryGrid>

                <MediaGallerySelectedPopup
                    isVisible={selectionSize > 0}
                    isVertNavOpen={uiStore.mainNavExpanded}
                    isEmbed={isEmbedView}
                    isMobile={isMobile}
                >
                    <MediaGallerySelectedPopupCard decoratorPosition="side">
                        <IconButton
                            data-testid="media-gallery-unselect-selected-button"
                            disabled={selectionSize === 0}
                            iconName="CloseIcon"
                            label="Unselect streemshots"
                            onClick={() => {
                                setErrorMessage('');
                                clearSelection();
                            }}
                        />
                        {!isMobile && (
                            <SelectedText as="p" data-testid="media-gallery-selected-count-text">
                                {errorMessage ? (
                                    <AppText color="red50">{errorMessage}</AppText>
                                ) : (
                                    `${selectionSize} Selected`
                                )}
                            </SelectedText>
                        )}
                        <Button
                            data-testid="media-gallery-select-all-button"
                            disabled={
                                !selectionVisible ||
                                selectionSize === streemshots.length + recordings.length
                            }
                            variant={isMobile ? 'inline' : 'secondary'}
                            onClick={() => {
                                setErrorMessage('');
                                setSelectedStreemshotIds([new Set(streemshots.map(ss => ss.id))]);
                            }}
                            style={{ marginLeft: isMobile ? 'auto' : 'unset' }}
                        >
                            Select All
                        </Button>
                        <RightHandButtonRow>
                            {!readOnly && (
                                <>
                                    <Button
                                        data-testid="media-gallery-open-delete-confirmation-modal-button"
                                        disabled={!selectionVisible}
                                        variant="secondary"
                                        onClick={() => setIsDeleteConfirmationOpen(true)}
                                    >
                                        Delete
                                    </Button>
                                    <DeleteConfirmation
                                        isOpen={isDeleteConfirmationOpen}
                                        onClose={() => setIsDeleteConfirmationOpen(false)}
                                        numStreemshots={selectedStreemshotIds.size}
                                        numVideos={selectedRecordingIds.size}
                                        deleteInProgress={deleteInProgress}
                                        handleDelete={handleDelete}
                                    />
                                </>
                            )}

                            <Button
                                data-testid="media-gallery-download-button"
                                disabled={downloading}
                                loading={downloading}
                                variant={isMobile ? 'inline' : 'secondary'}
                                onClick={handleDownload}
                            >
                                Download
                            </Button>
                        </RightHandButtonRow>
                    </MediaGallerySelectedPopupCard>
                </MediaGallerySelectedPopup>
            </section>
        );
    });
};

const MediaGalleryGrid = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gridAutoRows: '400px',
    gridAutoFlow: 'dense',
    gridGap: '16px',
});

const BookmarkContainer = styled.div`
    overflow: auto;
    padding: 0 40px 0 32px;

    @media (max-width: 1075px) {
        padding: 0px;
    }
`;

const BookmarksHeaderContainer = styled(Box)`
    margin: 0px 0px 20px 32px;
    @media (max-width: 1075px) {
        margin: 0px 0px 20px 0px;
    }
`;

const VideoContainer = styled('div')(({ hideBookmarks }: { hideBookmarks: boolean }) => ({
    display: 'flex',
    overflow: 'hidden',
    gridColumn: hideBookmarks ? 'span 3' : 'span 4',
    '@media (max-width: 1075px)': {
        gridRow: hideBookmarks ? 'auto' : 'span 2',
        gridColumn: '1 / -1',
        flexDirection: 'column',
    },
}));

const BookmarkSection = styled.div`
    position: relative;
    grid-column: span 2;
    display: flex;
    flex: 1 0 400px;
    max-width: 460px;
    overflow: hidden;
    flex-direction: column;

    @media (max-width: 1075px) {
        margin-top: 16px;
        flex-grow: 0;
        flex-shrink: 1;
        max-width: 100%;
    }
`;

const MediaGalleryHeader = styled(Subheader)`
    margin-bottom: 16px;
`;

const MediaGallerySelectedPopup = styled('div')<{
    isVisible: boolean;
    isVertNavOpen: boolean;
    isEmbed: boolean;
    isMobile: boolean;
}>(({ isVisible, isVertNavOpen, isEmbed, isMobile, theme }) => ({
    zIndex: theme.layers.ui,
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    left: isEmbed || isMobile ? '16px' : isVertNavOpen ? '216px' : '72px',
    transition: 'all 500ms',
    transform: isVisible ? '' : 'translateY(calc(100% + 64px))',
}));

const MediaGallerySelectedPopupCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    [`@media ${theme.mediaBreakpoints.xsmall}`]: {
        padding: '8px',
    },
}));
const SelectedText = styled(AppText)`
    margin: 0 32px 0 16px;
`;

const RightHandButtonRow = styled(ButtonRow)(({ theme }) => ({
    marginLeft: 'auto',
    [`@media ${theme.mediaBreakpoints.xsmall}`]: {
        marginLeft: 'unset',
    },
}));

interface InfoCardProps {
    handleCancel: () => void;
    handleOkay: () => void;
}

const VideoPreviewInfoCard: FC<InfoCardProps> = ({ handleCancel, handleOkay }) => {
    const theme = useTheme();
    return (
        <InfoCardContainer>
            <Card
                decoratorPosition="side"
                style={{
                    position: 'relative',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div style={{ position: 'absolute', top: '30px', right: '30px' }}>
                    <IconButton
                        iconName={'CloseIcon'}
                        size="medium"
                        label="info-card-cancel-button"
                        onClick={handleCancel}
                        background="azure"
                        fill="white"
                    />
                </div>
                <Subheader>Video Preview</Subheader>
                <div
                    style={{
                        flexGrow: 1,
                        marginTop: theme.space[5],
                    }}
                >
                    <AppText>
                        This is a preview of your video and does not include any audio. We are still
                        processing your final video and it will be available ASAP.
                    </AppText>
                </div>
                <Button
                    width="150px"
                    onClick={handleOkay}
                    data-testid="info-card-okay-button"
                    style={{
                        alignSelf: 'flex-end',
                    }}
                >
                    Okay
                </Button>
            </Card>
        </InfoCardContainer>
    );
};

const InfoCardContainer = styled('div')`
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 1;
    width: 80%;
    height: 80%;
`;

const GetChromeLink = styled('a')`
    text-decoration: none;
    color: ${props => props.theme.colors.azure};

    &:visited {
        color: ${props => props.theme.colors.azure};
    }
`;

const UnsupportedBrowserInfoCard = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexGrow: 1,
            }}
        >
            <Card
                decoratorPosition="side"
                style={{
                    margin: '0 20px',
                    alignSelf: 'center',
                }}
            >
                <AppText>
                    Want a preview? You can preview this video without audio by using Google Chrome.{' '}
                    <GetChromeLink
                        href="https://www.google.com/chrome/"
                        rel="noopener noreferrer"
                        target="_blank"
                        data-testid="get-chrome-link"
                    >
                        Get Chrome here.
                    </GetChromeLink>
                </AppText>
            </Card>
        </div>
    );
};

interface PreviewButtonProps {
    onClick: () => void;
}

const PreviewButton: FC<PreviewButtonProps> = ({ onClick }) => {
    return (
        <Box mt={5}>
            <Button
                style={{ background: 'transparent' }}
                onClick={onClick}
                variant="secondary"
                data-testid="preview-video-button"
            >
                Preview
                <span style={{ transform: 'translateY(1px)', marginLeft: '5px' }}>&#9658;</span>
            </Button>
        </Box>
    );
};

const AddNewBookmark: FC<{
    addBookmark: (bookmark: streem.api.Artifact.IBookmark) => void;
}> = ({ addBookmark }) => {
    const { getNewBookmarkTimeStamp, convertSecondsToTimestamp } = useTimeStampController();
    const theme = useTheme();

    return (
        <Row justifyContent="center" marginBottom={'12px'}>
            <Button
                variant="tertiary"
                onClick={() => {
                    const time = getNewBookmarkTimeStamp();
                    addBookmark({
                        timestamp: convertSecondsToTimestamp(time),
                    });
                }}
            >
                <AppIcon color={theme.colors.azure} name="AddBookmarkIcon" />{' '}
                <span style={{ marginLeft: '6px', fontSize: '18px' }}>Add Bookmark</span>
            </Button>
        </Row>
    );
};
