import { Box, Row } from '@streem/ui-react';
import { streem } from 'streem-sdk-protobuf';
import { useFormikContext } from 'formik';
import { LabelSearchInput } from './call_diagnosis';
import { BlueCheckBox } from './room_outcome_form_styles';
import { RoomOutcomeFormPayload } from '../../types/disposition.types';

interface RefundSectionProps {
    shouldRefund: boolean;
    initialRefundReason: {
        label: string;
        value: string;
    };
    customFieldOptions: {
        refund?: streem.api.CompanyRoomOutcomeConfig.ISection;
    };
    handleToggleShouldRefund: () => void;
}
export const RefundSection = ({
    shouldRefund,
    handleToggleShouldRefund,
    customFieldOptions,
    initialRefundReason,
}: RefundSectionProps): JSX.Element => {
    const { setFieldValue, values } = useFormikContext<RoomOutcomeFormPayload>();

    const options = customFieldOptions?.refund?.options.map(r => ({
        value: r.entry.code,
        label: r.entry.label,
    }));

    const handleSetRefundReason = (reason: { value: string; label: string }) => {
        const { customFields } = values;
        const { refund } = customFields;

        setFieldValue('customFields', {
            ...customFields,
            refund: {
                ...refund,
                reason: {
                    code: reason.value,
                    label: reason.label,
                },
            },
        });
    };

    return (
        <>
            <Row>
                <BlueCheckBox
                    id="request refund"
                    dataTestId="request-refund-checkbox"
                    description="Request Refund"
                    checked={shouldRefund}
                    handleChange={handleToggleShouldRefund}
                />
            </Row>

            <Box style={{ visibility: shouldRefund ? 'visible' : 'hidden' }}>
                <LabelSearchInput
                    isDisabled={!shouldRefund}
                    ariaLabel="refund reason options"
                    placeholder="Search here"
                    label="Reason for Refund"
                    handleChange={handleSetRefundReason}
                    options={options}
                    initialValue={initialRefundReason}
                />
            </Box>
        </>
    );
};
