import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { removeSegments } from '../../../util/routing';
import { styled } from '@streem/ui-react';
import ToolbarBackButton from '../../buttons/back_button';

export const GroupsDetailToolbar: FC = () => {
    const history = useHistory();

    const goBackEvent = () => {
        history.push(removeSegments(history.location.pathname, 1));
    };

    return (
        <Toolbar>
            <ToolbarGroup>
                <ToolbarBackButton
                    text="Back to Groups"
                    dataTestId="back-to-groups-button"
                    clickHandler={goBackEvent}
                />
            </ToolbarGroup>
        </Toolbar>
    );
};

const Toolbar = styled.nav`
    background-color: ${props => props.theme.colors.white || 'white'};
    border-bottom-color: ${props => props.theme.colors.grey10};
    border-bottom-style: solid;
    border-bottom-width: 1px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    line-height: 24px;
    padding: 8px 24px;
    position: sticky;
    top: 0;
    z-index: 2; /* see https://stackoverflow.com/questions/69033816/css-transform-not-working-correctly-with-position-sticky */
`;

const ToolbarGroup = styled.div<{ align?: string }>`
    align-self: ${props => props.align || 'start'};
    display: flex;
    align-items: center;
    justify-content: ${props => props.align || 'start'};
`;
