import { useState, SyntheticEvent } from 'react';
import { Modal, SimpleSelect } from '@streem/ui-react';
import { StreemAPI } from '@streem/api';
import { useActiveCompanyCode } from '../../hooks/use_active_company_code';
import { AgentRow } from '../users/team/agent_list_columns';
import appLogger from '../../util/logging/app_logger';
import UserStatus from '../users/user_status';
import { useGlobalStore } from '../../hooks/use_global_context';
import { useListUsersStore } from '../../hooks/list_store_hooks';

const userActiveOptions = [
    {
        label: 'Active',
        value: true,
    },
    {
        label: 'Inactive',
        value: false,
    },
];

export function StatusColumn({ row }: { row: AgentRow }) {
    const companyCode = useActiveCompanyCode();
    const agentsStore = useListUsersStore();
    const logStatusError = appLogger.extend('user status update');
    const { authStore } = useGlobalStore();

    const [showModal, setShowModal] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(false);
    const [selectedOption, setSelectedOption] = useState(
        userActiveOptions.find(option => option.value === row.active),
    );

    const promptUser = (e: SyntheticEvent<HTMLSelectElement>) => {
        const selected = userActiveOptions.find(
            opt => opt.value.toString() === e.currentTarget.value,
        );
        if (selected?.value === row.active) {
            return;
        }
        setShowModal(true);
        setSelectedOption(selected);
    };

    const confirmStatus = async () => {
        setIsUpdating(true);
        if (selectedOption === undefined || row.sid === undefined) {
            return;
        }
        try {
            if (selectedOption.value === true) {
                await StreemAPI.users.activate(row.sid);
            } else if (selectedOption.value === false) {
                await StreemAPI.users.deactivate(row.sid);
            }
            setShowModal(false);
        } catch (error) {
            setUpdateError(true);
            logStatusError.error(
                `Failed to update user status for company "${companyCode}"`,
                error,
            );
        } finally {
            setIsUpdating(false);
        }

        try {
            agentsStore.refresh();
        } catch (error) {
            logStatusError.error(
                'Failed to refresh list of users after updating their status',
                error,
            );
        }
    };

    const closeModalHandler = () => {
        setShowModal(false);
        setTimeout(() => {
            setSelectedOption(userActiveOptions.find(option => option.value === row.active));
        }, 201);
    };

    if (row.active === undefined || selectedOption === undefined) {
        return null;
    }

    return (
        <>
            <SimpleSelect
                onChange={promptUser}
                disabled={row.roles?.includes('COMPANY_OWNER') || row.sid === authStore.userId}
                value={selectedOption.value.toString()}
                options={userActiveOptions}
                data-testid="user-list-status-select"
            />
            <Modal isOpen={showModal} closeDelayMS={200} onClose={closeModalHandler}>
                <UserStatus
                    currentStatus={updateError ? 'error' : selectedOption.value}
                    onClose={closeModalHandler}
                    onConfirm={confirmStatus}
                    isUpdating={isUpdating}
                />
            </Modal>
        </>
    );
}
