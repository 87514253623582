import { Column, styled, Box } from '@streem/ui-react';
import { position, PositionProps } from 'styled-system';

export const MobileContainer = styled(Column)<{ bg?: string }>(({ theme, bg }) => ({
    userSelect: 'none',
    height: '560px',
    width: '290px',
    overflow: 'hidden',
    position: 'relative',
    flexShrink: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.space[4],
    background: bg,
    border: `10px solid ${theme.colors.grey90}`,
    borderRadius: '26px',
}));

export const NonInteractiveOverlay = styled.div(({ theme }) => ({
    userSelect: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: theme.colors.transparent,
    zIndex: theme.layers.ui,
}));

const DropShadow = styled(Box)(({ theme }) => ({
    alignSelf: 'center',
    width: '85%',
    height: '20px',
    backgroundColor: theme.colors.grey30,
    filter: 'blur(15px)',
}));

export const MobileExample = (props: any) => {
    return (
        <Column mr={4} flexShrink={0}>
            <MobileContainer bg={props.background}>
                <NonInteractiveOverlay />
                {props.children}
            </MobileContainer>
            <DropShadow mt={4} />
        </Column>
    );
};

export const BackgroundImage = styled.img<PositionProps>(
    {
        userSelect: 'none',
        position: 'absolute',
        width: '100%',
        zIndex: -1,
    },
    position,
);
