import ReactDOM from 'react-dom';
import App from './app';
import config from './config';
import appLogger from './util/logging/app_logger';
import { contextValues, getDocumentHash, logUncaughtErrors } from '@streem/logger';
import Analytics, { recordAppBackgrounded, recordAppForegrounded } from '@streem/analytics';
import { getCompanyCodeFromUrl } from '@streem/toolbox';
import CentralLoginApp from './centralized_login_app';
import { RegionAwareApp } from './region_aware_app';
import StreemAuth from '@streem/auth';
import EmbeddedSsoTokenHandler from './components/authentication/embedded_sso_token_handler';

const companyCode = getCompanyCodeFromUrl();

appLogger.setContextValue(contextValues.GIT_REF, getDocumentHash());
appLogger.setContextValue(contextValues.USER_AGENT, navigator.userAgent);
logUncaughtErrors(appLogger);

const isWorkspacesApp = companyCode === config.centralLoginAppName;

document.addEventListener('visibilitychange', () => {
    if (!Analytics.configured) {
        appLogger.warn('Ignoring app visibility event: analytics not yet configured');
        return;
    }

    if (document.visibilityState === 'visible') {
        appLogger.info('The app has been foregrounded');
        recordAppForegrounded();
    } else {
        recordAppBackgrounded();
        appLogger.info('The app has been backgrounded');
    }
});

function getCentralLoginApp() {
    // regionalApiUrl is always 'us' here but workspaces is a global app and doesn't use any region Streem Auth endpoints
    StreemAuth.configure({
        regionalApiUrl: `https://api.${config.reactAppEnv}-us.streem.cloud`,
        globalApiUrl: config.urls.globalApi,
        appId: config.appId,
        logger: appLogger.extend('auth'),
        centralLoginAppName: config.centralLoginAppName,
    });

    return <CentralLoginApp />;
}

function getEV2() {
    return (
        <EmbeddedSsoTokenHandler>
            <RegionAwareApp>
                <App />
            </RegionAwareApp>
        </EmbeddedSsoTokenHandler>
    );
}

ReactDOM.render(
    <>{isWorkspacesApp ? getCentralLoginApp() : getEV2()}</>,
    document.getElementById('root'),
);
