import { CompanyRegistration, listLoginIdentityCompanies } from '@streem/toolbox';
import { observable, action, runInAction } from 'mobx';
import { CentralLoginAuthStore } from './central_login_auth_store';
import config from '../../src/config';
import StreemAuth from '@streem/auth';

export class WorkspacesStore {
    public constructor(private readonly authStore: CentralLoginAuthStore) {}

    @observable
    public workspaces: CompanyRegistration[];

    @action.bound
    private setWorkspaces(workspaces: CompanyRegistration[]): void {
        this.workspaces = workspaces;
    }

    @action
    private async fetchWorkspaces() {
        if (!this.authStore.userId) {
            throw new Error('Unable to fetch login identity companies without authenticated user.');
        }

        const token = await StreemAuth.fetchBearerToken();
        const data = await listLoginIdentityCompanies({
            loginIdentityId: this.authStore.userId,
            globalDomain: config.globalDomain,
            token,
        });
        const companies = data.companies.filter(
            company => company.switchWorkspacesEnabled === true,
        );
        runInAction(() => {
            this.setWorkspaces(companies);
        });
    }

    @action.bound
    public connect(): void {
        this.fetchWorkspaces();
    }

    @action.bound
    public disconnect(): void {
        this.setWorkspaces(undefined);
    }
}
