import { AppText, Button, Modal, styled } from '@streem/ui-react';
import { useGlobalStore } from '../../hooks/use_global_context';
import { observer } from 'mobx-react';
import { useUserAuthenticatedAndSettingsLoaded } from '../../hooks/use_user_authenticated_and_settings_loaded';
import { addBrowserNotificationPermissionToDatadogRumSession } from '../../util/datadog';
import appLogger from '../../util/logging/app_logger';

export const PROFILE_MODAL_BODY = `By enabling notifications, you'll receive important alerts about incoming calls.`;
export const INITIAL_MODAL_BODY = `${PROFILE_MODAL_BODY}  You can update your selection by going to System Notifications in your profile.`;

// on the main page, show this modal once after the user is authenticated and their settings are loaded
export const AllowNotificationsModalOnce = observer(() => {
    const { uiStore } = useGlobalStore();
    const userAuthenticatedAndSettingsLoaded = useUserAuthenticatedAndSettingsLoaded();

    const open = !uiStore.allowNotificationsModalShown && userAuthenticatedAndSettingsLoaded;

    return (
        <AllowNotificationsModal
            open={open}
            onClose={uiStore.setAllowNotificationsModalShown}
            body={INITIAL_MODAL_BODY}
        />
    );
});

interface AllowNotificationsModalProps {
    open: boolean;
    onClose: () => void;
    body: string;
}
export const AllowNotificationsModal = ({ onClose, open, body }: AllowNotificationsModalProps) => {
    const { authStore } = useGlobalStore();

    const onAllow = () => {
        // close window immediately prior to calling Notification.requestPermission() so that
        //     this dialog is not stuck open while waiting for the user to respond to the browser
        //     prompt
        onClose();
        // this returns a promise, but we don't need to wait for it to resolve.  On Edge, the user
        //     may miss the browser prompt, so we don't want to leave this modal open while waiting.
        global.Notification?.requestPermission().then(permission => {
            addBrowserNotificationPermissionToDatadogRumSession(permission);
            appLogger.info(
                `Web Browser Notification permissionStatus=${permission} for user ${authStore.userId} has been updated.`,
            );
        });
    };

    const displayModal = open && global.Notification?.permission === 'default';

    return (
        <Modal
            closeOnEscape={false}
            closeOnOverlayClick={false}
            data-testid="browser-settings-modal"
            hideCloseButton={true}
            onClose={onClose}
            styleOverride={{
                content: {
                    maxWidth: '45rem',
                },
            }}
            isOpen={displayModal}
        >
            <AppText
                size="xlarge"
                bold
                headingFontFamily
                data-testid="allow-notifications-header"
                style={{
                    display: 'block',
                }}
            >
                Allow Streem to send you notifications
            </AppText>
            <AppText
                size="mediumLarge"
                style={{
                    display: 'block',
                    marginTop: '1.5rem',
                }}
                data-testid="allow-notifications-body"
            >
                {body}
            </AppText>
            <ButtonWrapper>
                <Button
                    onClick={onClose}
                    variant="secondary"
                    data-testid="allow-notifications-deny-button"
                    data-dd-action-name="Deny Notifications"
                >
                    Deny
                </Button>
                <Button
                    onClick={onAllow}
                    data-testid="allow-notifications-allow-button"
                    data-dd-action-name="Allow Notifications"
                >
                    Allow
                </Button>
            </ButtonWrapper>
        </Modal>
    );
};

const ButtonWrapper = styled.div({
    display: 'flex',
    gap: '1rem',
    justifyContent: 'flex-end',
    marginTop: '2.75rem',
});
