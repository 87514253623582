import { FC } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { useListCompaniesStore } from '../../hooks/list_store_hooks';
import { columnBuilder, CompanyRow } from './companies_list_columns';
import { useGlobalStore } from '../../hooks/use_global_context';
import { ListViewWithPagination } from '../lists/list_view_with_pagination';

interface Props {
    searchString: string;
    sortSwitch: (columnKey: string, sortOrder: string) => void;
}

function buildCompaniesListColumns() {
    return [columnBuilder.nameColumn(), columnBuilder.statusColumn(), columnBuilder.dateColumn()];
}

const CompaniesList: FC<Props> = ({ sortSwitch }) => {
    const companiesStore = useListCompaniesStore();
    const { uiStore } = useGlobalStore();
    const columns = buildCompaniesListColumns();
    const history = useHistory();

    return useObserver(() => {
        const reducedCompanies = companiesStore.results.reduce((acc: CompanyRow[], company) => {
            if (company.sid) {
                acc.push({ ...company, key: company.sid });
            }
            return acc;
        }, []);

        return companiesStore.lastError && companiesStore.lastError.status === 500 ? (
            <Redirect to={'/error'} />
        ) : (
            <ListViewWithPagination
                store={companiesStore}
                columns={columns}
                data={reducedCompanies}
                onSort={sortSwitch}
                loading={companiesStore.loading}
                loadingMore={companiesStore.loadingMore}
                data-testid="companies-list-component"
                buildRowTestId={row => `company-row-${row.code}`}
                onRowClick={e => {
                    uiStore.updateCompanyName(e.name!);
                }}
                linkTo={e => history.push(`/companies/${e.code}/settings`)}
                canRowClick={row => !!row}
                gridTemplateColumns="1fr minmax(200px, auto) minmax(150px, auto)"
            />
        );
    });
};

export default CompaniesList;
