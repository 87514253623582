import { FC } from 'react';
import { MemberAndItems } from './call_detail_member_and_items';
import { CollapsibleContent } from './collapsible_content';
import { CallDetailDiagnosis } from './call_detail_diagnosis';
import { CallDetailHeader } from './header/call_detail_header';

export const CallDetailForReservation: FC = () => {
    return (
        <section>
            <CallDetailHeader />
            <CollapsibleContent title="Call Details" id="post-call-details">
                <MemberAndItems />
                <CallDetailDiagnosis />
            </CollapsibleContent>
        </section>
    );
};
