/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiRoom,
    StreemApiRoomFromJSON,
    StreemApiRoomFromJSONTyped,
    StreemApiRoomToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiEventsRoomArchived
 */
export interface StreemApiEventsRoomArchived {
    /**
     * 
     * @type {StreemApiRoom}
     * @memberof StreemApiEventsRoomArchived
     */
    room?: StreemApiRoom;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiEventsRoomArchived
     */
    archivedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiEventsRoomArchived
     */
    archiveS3FolderPath?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiEventsRoomArchived
     */
    recordingArtifactId?: string;
}

export function StreemApiEventsRoomArchivedFromJSON(json: any): StreemApiEventsRoomArchived {
    return StreemApiEventsRoomArchivedFromJSONTyped(json, false);
}

export function StreemApiEventsRoomArchivedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiEventsRoomArchived {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'room': !exists(json, 'room') ? undefined : StreemApiRoomFromJSON(json['room']),
        'archivedAt': !exists(json, 'archived_at') ? undefined : (new Date(json['archived_at'])),
        'archiveS3FolderPath': !exists(json, 'archive_s3_folder_path') ? undefined : json['archive_s3_folder_path'],
        'recordingArtifactId': !exists(json, 'recording_artifact_id') ? undefined : json['recording_artifact_id'],
    };
}

export function StreemApiEventsRoomArchivedToJSON(value?: StreemApiEventsRoomArchived | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room': StreemApiRoomToJSON(value.room),
        'archived_at': value.archivedAt === undefined ? undefined : (value.archivedAt.toISOString()),
        'archive_s3_folder_path': value.archiveS3FolderPath,
        'recording_artifact_id': value.recordingArtifactId,
    };
}


