import { FC, useEffect, useState } from 'react';
import { google, streem } from 'streem-sdk-protobuf';

import { AppText, Box, Column, IconButton, Row, styled, Subheader, Theme } from '@streem/ui-react';
import { useGroupReservationTimer } from './hooks/use_group_reservation_timer';
import { getReservationExpirationMessage } from './util/group_reservation_helpers';
import {
    BannerContainer,
    ReconnectPill,
    ReservedPill,
    TruncatedText,
} from './reservation_banner_content';

/* The Legacy Reservation Banner component is used for companies that have our group reservation
 * features enabled (expert availability and group management) but do not have the ahs virtual
 * expert feature flag enabled, a company call source on the reservation and the new structured
 * model for call detail (ICallDetail) on the reservation.
 */
export const LegacyReservationBanner: FC<{
    customerDetails: streem.api.GroupReservation.IDetail[] | null;
    expirationTime: google.protobuf.ITimestamp;
    secondsReserved: number;
    isReconnect: boolean;
}> = ({ customerDetails, expirationTime, secondsReserved, isReconnect }) => {
    const [expanded, setExpanded] = useState(true);
    const [customerName, setCustomerName] = useState('');

    const handleSetExpanded = () => {
        setExpanded(!expanded);
    };

    const timeToDisplay = useGroupReservationTimer(expirationTime, secondsReserved);

    useEffect(() => {
        if (customerDetails && customerDetails.length > 0) {
            const nameField = customerDetails.find(detail => {
                return (
                    detail.label.toLowerCase().includes('customer') ||
                    detail.label.toLowerCase().includes('name')
                );
            });
            setCustomerName(nameField?.value || '');
        }
    }, [customerDetails]);

    // The banner should clear when the reservation is expired or the user status changes, but just in case
    // we will show 'less than 1 minute' as our lowest possible countdown to not show 0 or negative numbers
    return (
        <BannerContainer data-testid="banner-container">
            <Row
                style={{ height: '42px', width: '100%' }}
                alignItems="center"
                justifyContent="space-between"
            >
                <Row alignItems="center">
                    <Subheader data-testid="reservation-banner-header" size="xlarge" bold>
                        Upcoming call {customerName && `with ${customerName}`}
                    </Subheader>
                    <ReservedPill data-testid="reserved-icon" headingFontFamily bold>
                        Reserved
                    </ReservedPill>
                    {isReconnect && (
                        <ReconnectPill data-testid="reconnect-icon" headingFontFamily bold>
                            Reconnection
                        </ReconnectPill>
                    )}
                    <AppText data-testid="expiration-text">
                        {getReservationExpirationMessage(timeToDisplay)}
                    </AppText>
                </Row>
                <IconButton
                    iconName="CaretIcon"
                    label="expand"
                    fill={Theme.colors.black}
                    data-testid="expand-banner-button"
                    style={{
                        transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.2s ease-in-out',
                    }}
                    onClick={handleSetExpanded}
                />
            </Row>
            {expanded && (
                <ExpandedBanner>
                    <DetailsContainer data-testid="call-details-section">
                        {customerDetails
                            .filter(item => item.value !== customerName)
                            .map(({ label, value }) => {
                                return (
                                    <Column key={label}>
                                        <AppText
                                            as="h3"
                                            data-testid={`${label}-label`}
                                            headingFontFamily
                                            semibold
                                        >
                                            {label}
                                        </AppText>
                                        <TruncatedText data-testid={`${label}-value`}>
                                            {value}
                                        </TruncatedText>
                                    </Column>
                                );
                            })}
                    </DetailsContainer>
                </ExpandedBanner>
            )}
        </BannerContainer>
    );
};

const ExpandedBanner = styled(Box)({
    width: '100%',
    paddingTop: '18px',
    paddingLeft: '24px',
    paddingRight: '24px',
});

const DetailsContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    rowGap: '20px',
    gridTemplateColumns: 'repeat(5, 1fr)',
    columnGap: '32px',
    marginTop: '16px',
    overflowY: 'auto',
    border: `solid 1px ${theme.colors.grey30}`,
    borderRadius: '10px',
    padding: '24px',
}));
