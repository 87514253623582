import {
    AppIcon,
    AppText,
    Button,
    ButtonRow,
    Column,
    Header,
    Modal,
    Row,
    styled,
    Theme,
} from '@streem/ui-react';

interface ArchiveConfirmationModalProps {
    isOpen: boolean;
    closeModal: () => void;
    handleArchive: () => void;
}

export const ArchiveConfirmationModal: React.FC<ArchiveConfirmationModalProps> = ({
    isOpen,
    closeModal,
    handleArchive,
}) => {
    return (
        <>
            <Modal
                data-testid="archive-room-confirmation-modal"
                onClose={closeModal}
                hideCloseButton={true}
                variant="danger"
                isOpen={isOpen}
                styleOverride={{
                    content: {
                        maxWidth: '600px',
                        padding: Theme.spacing.l,
                    },
                }}
            >
                <Row justifyContent="left" alignItems="center" mb={5}>
                    <Column width="40px" justifyContent="center">
                        <AppIcon name="AlertIcon" color={Theme.colors.red60} iconSize="32px" />
                    </Column>
                    <ModalHeader data-testid="archive-room-warning-header" size="xlarge">
                        Deactivate this customer?
                    </ModalHeader>
                </Row>
                <Row mb={5}>
                    <AppText>
                        This will trigger member deactivation and delete this call. Only use in
                        extreme circumstances, like an LIL call.
                    </AppText>
                </Row>
                <ModalButtonRow>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        data-testid="archive-room-confirmation"
                        onClick={handleArchive}
                    >
                        Confirm
                    </Button>
                </ModalButtonRow>
            </Modal>
        </>
    );
};

const ModalButtonRow = styled(ButtonRow)({
    justifyContent: 'flex-end',
    width: '100%',
});

const ModalHeader = styled(Header)({
    width: '85%',
});
