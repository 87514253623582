import {
    styled,
    Row,
    Column,
    ExtractProps,
    AppIcon,
    ListRowFeaturedCell,
    BoxProps,
} from '@streem/ui-react';
import { Setting } from '../../../util/company_settings';

interface Props {
    monochrome?: boolean;
    customPadding?: string;
}

export const SettingRow = styled(Row)<Props & BoxProps>(
    ({ monochrome, customPadding = '16px', theme }) => ({
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${monochrome ? theme.colors.phosphorGreen : theme.colors.grey05}`,
        padding: customPadding,
    }),
);

export const SettingColumn = styled(Column)(({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.grey05}`,
    padding: 16,
}));

interface SettingsFeaturedCellProps extends ExtractProps<typeof Row> {
    setting: Setting;
    customization?: boolean;
}

export function SettingFeaturedCell({
    setting,
    customization = false,
    ...rowProps
}: SettingsFeaturedCellProps) {
    return (
        <Row {...rowProps}>
            {setting.superAdmin === true && (
                <Column marginRight={'5px'} flexShrink={0}>
                    <AppIcon name="SuperAdminIcon" />
                </Column>
            )}
            <ListRowFeaturedCell
                headline={setting.label}
                subHeadline={setting.description}
                headlineSize={customization ? 'large' : 'mediumLarge'}
            />
        </Row>
    );
}
