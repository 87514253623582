import React, { useCallback } from 'react';
import { styled, ButtonRow, Modal, Button, Header, AppText, Theme, Box } from '@streem/ui-react';
import { recordElementClicked } from '@streem/analytics';

// Completely pre-built modal for in app announcements that takes in values to populate the text areas and interactions

type ModalValues = {
    header: string;
    mainMessage: string;
    isModalOpen: boolean;
    closeModal: () => void;
    dismiss?: () => void;
    submit?: () => void; // in case something other than the close function is desired
    dismissText?: string;
    submitText: string;
    testId: string;
};

export const MessageModal: React.FC<ModalValues> = ({
    header,
    mainMessage,
    isModalOpen,
    closeModal,
    dismiss,
    submit,
    dismissText,
    submitText,
    testId,
}) => {
    const handleSubmitClick = useCallback(() => {
        recordElementClicked('ev2ModalAccept');
        if (submit) {
            submit();
        } else {
            closeModal();
        }
    }, [submit, closeModal]);

    const handleDismiss = useCallback(() => {
        recordElementClicked('ev2ModalRevert');
        dismiss();
    }, [dismiss]);

    return (
        <Modal
            closeOnOverlayClick={false}
            closeOnEscape={false}
            isOpen={isModalOpen}
            onClose={closeModal}
            data-testid={`${testId}-modal`}
        >
            <ModalContent>
                <Box style={{ marginBottom: '24px' }}>
                    <Header size="xlarge">{header}</Header>
                </Box>
                <MessageText size="large" as={'p'}>
                    {mainMessage}
                </MessageText>
                <Footer>
                    {dismiss && dismissText && (
                        <Button
                            variant="secondary"
                            onClick={handleDismiss}
                            data-testid={`${testId}-dismiss`}
                        >
                            {dismissText}
                        </Button>
                    )}
                    <Button
                        pl={Theme.space[6]}
                        pr={Theme.space[6]}
                        variant="primary"
                        onClick={handleSubmitClick}
                        data-testid={`${testId}-submit`}
                    >
                        {submitText}
                    </Button>
                </Footer>
            </ModalContent>
        </Modal>
    );
};

const ModalContent = styled.div`
    width: 675px;
`;
const Footer = styled(ButtonRow)`
    display: flex;
    margin-top: 48px;
    justify-content: flex-end;
`;
const MessageText = styled(AppText)`
    line-height: 25px;
    width: 550px;
    margin-top: 16px;
`;
