import { computed } from 'mobx';
import { APICallStore, APICall, APICallResponse, APICallState } from './api_call_store';

export enum ListStoreState {
    loading,
    complete,
    error,
    refreshing,
}

type ResponseFilter<T extends APICall, R> = (response: APICallResponse<T>) => Array<R>;

interface ListStoreOpts<T extends APICall, R> {
    filter: ResponseFilter<T, R>;
    fnName: string;
}

export class ListStore<T extends APICall, R> {
    private apiCallStore: APICallStore<T>;

    constructor(apiCall: T, private readonly opts: ListStoreOpts<T, R>) {
        this.apiCallStore = new APICallStore(apiCall, opts.fnName);
    }

    @computed
    public get state() {
        return this.apiCallStore.state;
    }

    @computed
    public get lastError() {
        return this.apiCallStore.lastError;
    }

    @computed
    public get results(): R[] {
        const response = this.apiCallStore.response;
        if (response) {
            return this.opts.filter(response);
        }
        return [];
    }

    @computed
    public get loading() {
        return this.state === APICallState.loading;
    }

    @computed
    public get loadingMore() {
        return this.state === APICallState.loadingMore;
    }

    public fetch(...args: Parameters<T>) {
        return this.apiCallStore.fetch(...args);
    }

    public refresh() {
        return this.apiCallStore.refresh();
    }
}
