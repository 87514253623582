import { useState } from 'react';
import { StreemAPI, StreemApiSendInvitationRequestDeliveryType } from '@streem/api';
import { useActiveCompanyCode } from './use_active_company_code';
import { useGlobalStore } from './use_global_context';
import { AsYouType } from 'libphonenumber-js';
import { InviteType, InviteValues, InviteStatus, InviteState } from '../components/invite/types';
import appLogger from '../util/logging/app_logger';
import { recordInviteCreated } from '@streem/analytics';

const log = appLogger.extend('invite');

/**
 * Formats country code + phone without requiring complete, valid number.
 * @param countryCode
 * @param phone
 */
export function useFormatPhoneNumber() {
    return (countryCode: string, phone: string): string => {
        const countryCodeWithPrefix = `+${countryCode}`;
        const plusPrefixAndCountryCode = new RegExp('(\\' + countryCodeWithPrefix + '\\s*)', 'g');
        const phoneAsYouType = new AsYouType().input(countryCodeWithPrefix + phone);
        return phoneAsYouType.replace(plusPrefixAndCountryCode, '');
    };
}

/**
 * Hook to send invite request to backend.
 * @param inviteType
 * @param values: (Formik) form object with payload values
 */
export function useInviteToStreem(): [
    (inviteType: InviteType, values: InviteValues) => void,
    InviteState,
] {
    const { authStore } = useGlobalStore();
    const companyCode = useActiveCompanyCode();
    const [state, setState] = useState<InviteState>({
        status: InviteStatus.Initial,
        meta: {},
    });

    const invite = async (inviteType: InviteType, values: InviteValues) => {
        setState({ ...state, status: InviteStatus.Pending });

        const { name, phone, referenceId, integrationId } = values;

        const inviteTypeMap: { [key: string]: StreemApiSendInvitationRequestDeliveryType } = {
            phone: StreemApiSendInvitationRequestDeliveryType.SMS,
            link: StreemApiSendInvitationRequestDeliveryType.LINK,
            email: StreemApiSendInvitationRequestDeliveryType.LINK,
        };

        const deliveryType =
            inviteTypeMap[inviteType] ?? StreemApiSendInvitationRequestDeliveryType.INVALID;

        try {
            const response = await StreemAPI.default.sendInvitationSms({
                fromUserSid: authStore.userId,
                toUserName: name,
                phone,
                referenceId,
                integrationId,
                companyCode,
                deliveryType,
            });
            if (response.invitation?.invitationSid) {
                const method = inviteType === 'phone' ? 'SMS' : 'LINK';
                recordInviteCreated(response.invitation.invitationSid, method);
            }

            let status;
            if (!response.messageDeliveredSuccessfully) {
                status = InviteStatus.Error;

                log.error(
                    `User attempted to send invite ${response.invitation?.invitationSid} with ${deliveryType} and it was not successful`,
                );
            } else if (inviteType === 'phone') {
                status = InviteStatus.Pending;

                log.info(
                    `User attempted to send invite ${response.invitation?.invitationSid} with ${deliveryType} with initial (unconfirmed) success.`,
                );
            } else {
                status = InviteStatus.Success;

                log.info(
                    `User attempted to send invite ${response.invitation?.invitationSid} with ${deliveryType} and it was successful`,
                );
            }

            setState({ status, meta: { response, values } });
        } catch (error) {
            log.error(`Error sending ${inviteType} invite`, error);
            setState({ status: InviteStatus.Error, meta: { error, values } });
        }
    };

    return [invite, state];
}
