import { useState, useEffect, ChangeEvent } from 'react';
import { Modal } from '@streem/ui-react';
import PageHeader from '../../components/page_header/page_header';
import CompaniesList from '../../components/companies/companies_list';
import CreateCompanyForm from '../../forms/create_company_form';
import { debounce } from 'lodash';
import { StreemAPI, sortParamFormatter } from '@streem/api';
import { createListCompaniesStore, ListCompaniesProvider } from '../../hooks/list_store_hooks';
import { PageColumn } from '../page_column';

interface Props {
    'data-testid'?: string;
}

const CompaniesPage: React.FC<Props> = props => {
    const companiesStore = createListCompaniesStore();
    const [showModal, setShowModal] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');

    const sortSwitch = (columnKey: string, sortOrder: string) => {
        setSortOrder(sortOrder.toLowerCase());
        setSortBy(columnKey);
    };

    const filter = (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();
        const debouncer = debounce(() => setSearchString(e.target.value.trim()), 1000);
        debouncer();
    };

    useEffect(() => {
        const sort = sortParamFormatter(sortBy, sortOrder);
        const promise = companiesStore.fetchFirstPage({
            pageSize: 100,
            sort: sort,
            filter: searchString,
        });
        return () => promise.cancel();
    }, [companiesStore, searchString, sortBy, sortOrder]);

    return (
        <ListCompaniesProvider value={companiesStore}>
            <Modal isOpen={showModal} onClose={() => setShowModal(s => !s)} closeDelayMS={200}>
                <CreateCompanyForm
                    closeModal={() => setShowModal(s => !s)}
                    handleSubmitFormValues={async ({
                        companyName,
                        companyCode,
                        ownerEmail,
                        ownerName,
                    }) => {
                        await StreemAPI.companies.createCompany({
                            code: companyCode,
                            name: companyName,
                            ownerEmail,
                            ownerName,
                        });
                        await companiesStore.refresh();
                    }}
                />
            </Modal>
            <PageColumn style={{ paddingLeft: '16px ' }} dataTestId={props['data-testid']}>
                <PageHeader
                    title="All Companies"
                    onSearch={e => filter(e)}
                    searchPlaceholder="Search Companies"
                    iconName={'AddIcon'}
                    iconContext={'Add A New Company'}
                    handleClick={() => setShowModal(s => !s)}
                />
                <CompaniesList searchString={searchString} sortSwitch={sortSwitch} />
            </PageColumn>
        </ListCompaniesProvider>
    );
};

export default CompaniesPage;
