import { useState, useEffect } from 'react';
import { EmbedMessageScreen } from './embed_message_screen';

interface EmbedPostInviteScreenProps {
    isLinkInvite?: boolean;
}

export const EmbedPostInviteScreen = ({
    isLinkInvite = false,
}: EmbedPostInviteScreenProps): JSX.Element => {
    const [showWaitingScreen, setShowWaitingScreen] = useState(false);

    // change the post-invite screen that's shown after 4 seconds
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowWaitingScreen(true);
        }, 4000);

        return () => clearTimeout(timeoutId);
    });

    const showWaitingText = showWaitingScreen || isLinkInvite;
    const textToShow = showWaitingText
        ? 'Waiting for your customer to connect'
        : 'Your invitation was delivered';
    return <EmbedMessageScreen message={textToShow} />;
};
