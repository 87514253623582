import React, { useState } from 'react';
import { RoleEntry } from '../../forms/user_form.styles';
import { Label, Select, Row, AppText, Box } from '@streem/ui-react';
import { RoleOption } from '../../types/project.types';

interface Props {
    value: string;
    options: RoleOption[];
    handleSelect: (option: any) => void;
    handleFocus?: () => void;
    handleBlur?: () => void;
}

export const RoleSelector: React.FC<Props> = ({
    value,
    options,
    handleSelect,
    handleFocus,
    handleBlur,
}) => {
    const [description, setDescription] = useState(
        // Get the description for the initial option value
        options.filter((option: RoleOption) => option.value === value).pop()?.description,
    );

    const initialValue = options.find(option => option.value === value);

    return (
        <RoleEntry>
            <Row>
                <Box marginBottom="8px">
                    <Label color={'dark'} headingFontFamily>
                        Role
                    </Label>
                </Box>
            </Row>
            <Row alignItems="center" justifyContent="center">
                <Box width="100px">
                    <Select
                        isDisabled={value === 'COMPANY_OWNER'}
                        onSelect={selectedOpt => {
                            handleSelect(selectedOpt as RoleOption);
                            setDescription((selectedOpt as RoleOption).description);
                        }}
                        initialValue={initialValue}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        placeholder={'Select'}
                        aria-label={'Select a role'}
                        border={true}
                        isSearchable
                        options={
                            value === 'COMPANY_OWNER'
                                ? options
                                : options.filter(option => option.groupName !== 'COMPANY_OWNER')
                        }
                    />
                </Box>
                <Box flex={1} ml={3}>
                    <AppText as={'p'} size={'small'} color={'medium'}>
                        {description}
                    </AppText>
                </Box>
            </Row>
        </RoleEntry>
    );
};
