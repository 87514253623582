import { useEffect, useState, createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';

// Tracking in-app page view history allows us to conditionally
// refresh list data depending on which page the user is navigating from:
//    - Call Detail Page --> Call Log Page === show previous search results + scroll position
//    - Settings/Agent Page --> Call Log Page === load new list + initial scroll position
// Due to browser security rules, previous location in the navigation history is not available using react-router-dom's useLocation hook, so we track it ourselves to use as needed.

type AppHistoryContext = Array<string>;

const appHistoryContext = createContext<AppHistoryContext>([]);

export function useInitializeAppHistory() {
    const location = useLocation();

    const [appHistory, setAppHistory] = useState([]);
    useEffect(() => {
        setAppHistory([...appHistory, location.pathname]);
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, [location.pathname]);

    return appHistory;
}

export function useAppHistory() {
    const context = useContext(appHistoryContext);

    if (!context) {
        throw new Error('Unable to useAppHistory without parent <AppHistoryProvider />');
    }

    return context;
}

export const AppHistoryProvider = appHistoryContext.Provider;
