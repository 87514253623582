import {
    MeshBackground,
    RadialFade,
    Row,
    StreemStackedLogoLegal,
    styled,
    Card,
    Header,
    AppText,
    Button,
    Box,
} from '@streem/ui-react';
import { LoginWrapper } from './login_page';

export const LogoutSuccessPage = ({ logInAgain }: { logInAgain: () => void }) => {
    return (
        <div
            data-testid="login-page"
            style={{
                width: '100%',
                overflow: 'hidden',
            }}
        >
            <MeshBackground>
                <LoginWrapper>
                    <Row alignItems="center" justifyContent="center">
                        <RadialFade>
                            <StreemStackedLogoLegal color="white" width="240px" height="180px" />
                        </RadialFade>
                    </Row>
                    <Card shadow="large">
                        <Header
                            style={{ marginBottom: 16, minWidth: 388 }}
                            data-testid="logout-success-header"
                        >
                            Successfully Logged Out
                        </Header>
                        <AppText as="p" style={{ marginBottom: 16 }}>
                            You may now safely close this browser tab or return to the login screen.
                        </AppText>
                        <ButtonWrap>
                            <Box alignSelf="flex-end">
                                <Button
                                    data-testid="logout-success-return-to-login"
                                    variant="inline"
                                    onClick={logInAgain}
                                >
                                    Return to Log in
                                </Button>
                            </Box>
                        </ButtonWrap>
                    </Card>
                </LoginWrapper>
            </MeshBackground>
        </div>
    );
};

export const ButtonWrap = styled.div`
    padding-left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;
