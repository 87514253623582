import { useEffect, useState } from 'react';
import { APIError, StreemAPI } from '@streem/api';
import { StreemApiGetCompanyRoomOutcomeConfigResponse } from '@streem/api/src/openapi';
import { streem } from 'streem-sdk-protobuf';
import appLogger from '../util/logging/app_logger';
const log = appLogger.extend('useRoomOutcomeFormConfig');
import { useGetCompanyStore } from './detail_store_hooks';

export const getCompanyRoomOutcomeConfig = async (
    companySid: string,
): Promise<StreemApiGetCompanyRoomOutcomeConfigResponse> => {
    const response = await StreemAPI.companies.getCompanyRoomOutcomeConfig(companySid);
    return response;
};

export const useRoomOutcomeFormConfig = () => {
    const [formConfig, setFormConfig] = useState<streem.api.ICompanyRoomOutcomeConfig>();
    const companyStore = useGetCompanyStore();

    useEffect(() => {
        if (!companyStore || !companyStore.result) {
            return;
        }

        async function fetchCallDispositionData() {
            try {
                const roomOutcomeConfig = await getCompanyRoomOutcomeConfig(
                    companyStore?.result?.sid,
                );
                const data = await fetch(roomOutcomeConfig.roomOutcomeConfigUrl);
                const options = await data.json();
                setFormConfig(options);
            } catch (error) {
                if (error instanceof APIError && error.status === 404) {
                    log.debug('Room outcome not configured', error);
                } else {
                    log.error('Error fetching room outcome config: ', error);
                }
            }
        }

        fetchCallDispositionData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyStore]);

    return formConfig;
};
