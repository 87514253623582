import { Logger } from '@streem/logger';
import { streem } from 'streem-sdk-protobuf';
import Papa from 'papaparse';
import { Diagnosis, OutOfScopeReason } from '../types/disposition.types';

export interface CSVInput {
    type:
        | 'callDiagnosisOption'
        | 'callDispositionOption'
        | 'refundOption'
        | 'trainingOption'
        | 'outOfScopeOption';
    label: string;
    code?: string;
    source?: string | undefined;
    trade?: string;
    item?: string;
    style?: string;
}

export interface Properties {
    trade: string;
    item: string;
    style: string;
}

export interface TransformedData {
    diagnosis: streem.api.CompanyRoomOutcomeConfig.ISection;
    disposition: streem.api.CompanyRoomOutcomeConfig.ISection;
    customFields: { [key: string]: streem.api.CompanyRoomOutcomeConfig.ISection };
}

export const transformOptions = (options: CSVInput[]) => {
    const transformedData: TransformedData = {
        diagnosis: {
            options: [],
        },
        disposition: {
            options: [],
        },
        customFields: {},
    };

    options.forEach(option => {
        const { type, label, trade, item, style, source } = option;
        const code = option.code?.trim() || label.toLowerCase().trim();
        const callSourceCodes =
            source !== undefined ? source.split(';').map(s => s.toLocaleLowerCase().trim()) : [];
        const shouldIncludeCallSourceCodes = callSourceCodes.length > 0;
        const entry = {
            label,
            code,
            ...(shouldIncludeCallSourceCodes && { callSourceCodes }),
        };

        let properties;
        if (trade || item || style) {
            properties = { trade: trade || '', item: item || '', style: style || '' };
        }

        if (type === 'callDiagnosisOption') {
            transformedData.diagnosis.options.push({
                entry,
                ...(properties && { properties }),
            });
        } else if (type === 'callDispositionOption') {
            transformedData.disposition.options.push({ entry });
        } else {
            const customField = type.replace('Option', '') as 'training' | 'refund' | 'outOfScope';
            if (customField === 'training') {
                // training does not have options
                transformedData.customFields.training = { callSourceCodes };
            } else {
                if (!transformedData.customFields[customField]) {
                    transformedData.customFields[customField] = { options: [], callSourceCodes };
                }
                transformedData.customFields[customField].options.push({ entry });
            }
        }
    });

    return transformedData;
};

export const truncateFileName = (fileName?: string, maxLength = 15) => {
    if (!fileName) return '';
    if (fileName.length <= maxLength) {
        return fileName;
    }

    const extensionIndex = fileName.lastIndexOf('.');
    const extension = fileName.slice(extensionIndex);
    const truncatedName = fileName.slice(0, maxLength - extension.length);

    return `${truncatedName}..${extension}`;
};

export const validateCsvUpload = (input: Papa.ParseResult<CSVInput>): string | null => {
    if (input.data.length === 0) {
        return 'File cannot be empty';
    }
    if (input.meta.fields.some(field => field.includes('_'))) {
        return 'Duplicate headers detected';
    }
    for (const item of input.data) {
        if (!('type' in item) || !('label' in item)) {
            return 'Invalid headers: missing type or label';
        }
    }

    // All input is valid
    return null;
};

export const customFilterOption = (candidate: { label: string; value: string }, input: string) => {
    if (input) {
        const words = input
            .trim()
            .toLowerCase()
            .split(/,\s*|\s+/);
        return words.every(word => candidate.label.toLowerCase().includes(word));
    }
    return true;
};

export const buildDiagnosisListOptions = (
    diagnosisOptions: streem.api.ICompanyRoomOutcomeConfig['diagnosis'],
    log: Logger,
    tradeFilter?: string,
    callSourceCode?: string,
): { code: string; label: string }[] => {
    // Get the cumulative list of trades from the provided options
    const tradeList = [
        ...new Set(diagnosisOptions?.options?.map(opt => opt.properties?.trade).filter(Boolean)),
    ];

    // If a trade filter is provided, check that it matches one of the provided trades in the options
    const tradeFilterMatched = tradeList.includes(tradeFilter);
    if (tradeList.length > 0 && tradeFilter && !tradeFilterMatched) {
        log.info('No trade match found in provided options list for: ', tradeFilter);
    }

    const filteredOptions = diagnosisOptions.options
        .filter(option => {
            if (tradeFilter && tradeFilterMatched) {
                return (
                    option.properties &&
                    option.properties.trade &&
                    option.properties.trade === tradeFilter
                );
            } else {
                return true;
            }
        })
        .filter(opt => {
            if (callSourceCode && opt.entry.callSourceCodes) {
                return opt.entry.callSourceCodes.includes(callSourceCode);
            } else {
                return true;
            }
        });

    // If we get in a state where a tradeFilter and callSourceCode were provided
    // but no matches are found we want to return an unfiltered list
    const optionsToUse = filteredOptions.length > 0 ? filteredOptions : diagnosisOptions.options;
    return optionsToUse
        .map(option => {
            const trade =
                option.properties && option.properties.trade ? option.properties.trade + ' - ' : '';
            return {
                code: option.entry.code,
                label: `${tradeFilter && tradeFilterMatched ? '' : trade}${option.entry.label}`,
            };
        })
        .sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            } else {
                return 1;
            }
        });
};

export const getInitialValue = <T extends { code: string; label: string }>(
    value: T,
    Options: T[],
): { label: string; value: string } => {
    const initialValue = Options.find(o => value.code === o.code);
    return {
        label: initialValue?.label ?? '',
        value: initialValue?.code ?? '',
    };
};

export const getFilteredOptions = (
    reason: OutOfScopeReason | Diagnosis,
    options: OutOfScopeReason[] | Diagnosis[],
    inputOptions: { value: string; label: string }[],
) => {
    const currentlySelectedOutOfScopeCodes = options
        .filter(o => o.code !== reason.code)
        .map(outOfScopeOption => outOfScopeOption.code);

    return inputOptions.filter(opt => !currentlySelectedOutOfScopeCodes.includes(opt.value));
};
