import { APITypes } from '@streem/api';
import { UserInfo } from '@streem/sdk-core';
import { useObservable } from '@streem/sdk-react';
import { useMemo } from 'react';
import { google, streem } from 'streem-sdk-protobuf';
import {
    useCustomerExpertUserInfo,
    useDetailSession,
    useRoomDuration,
} from './detail_session_hooks';
import { useCallSourceData } from './use_call_source_data';

export interface CallDetailInfo {
    callDetail?: streem.api.ICallDetail;
    groupName?: string;
    expert?: UserInfo;
    openedAt?: google.protobuf.ITimestamp;
    callSource?: APITypes.StreemApiCallSource | undefined;
    durationString?: string;
    reference?: string;
}
export const useCallDetailHeaderInfo = (): CallDetailInfo => {
    const detailSession = useDetailSession();
    const [room] = useObservable(detailSession.room.room);
    const { expert } = useCustomerExpertUserInfo(detailSession);
    const callSource = useCallSourceData();
    const durationString = useRoomDuration(room?.sid);

    const callDetailInfo: CallDetailInfo = useMemo(() => {
        return {
            callDetail: room?.callDetail,
            groupName: room?.reservationGroup?.name,
            expert,
            openedAt: room?.openedAt,
            callSource: callSource.callSource,
            durationString,
            reference: room?.referenceId,
        };
    }, [
        room?.callDetail,
        room?.reservationGroup?.name,
        expert,
        room?.openedAt,
        callSource.callSource,
        durationString,
        room?.referenceId,
    ]);
    return callDetailInfo;
};
