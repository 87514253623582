export enum InviteStatus {
    Initial = 0,
    Pending = 1,
    Success = 2,
    Error = 3,
}

export interface InviteState {
    status: InviteStatus;
    meta: any;
}

export type InviteType = 'phone' | 'link';

export interface InviteValues {
    inviteType: InviteType;
    name: string;
    referenceId?: string;
    integrationId?: string;
    phone?: string;
    customerEmail?: string;
}
