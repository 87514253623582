import { FC, useCallback, useMemo, useState } from 'react';
import {
    ModelSerialList,
    useObservable,
    useMediaBreakpoint,
    dedupeDetections,
} from '@streem/sdk-react';
import { MarkDownNotes, EditableNote, PoweredByStreem, styled, Theme } from '@streem/ui-react';
import { observer } from 'mobx-react';
import { streem } from 'streem-sdk-protobuf';
import { useDetailSession } from '../../hooks/detail_session_hooks';
import { useAnonymousShareChecker } from '../../hooks/use_anonymous_share_checker';
import { useGlobalStore } from '../../hooks/use_global_context';
import appLogger from '../../util/logging/app_logger';
import { MediaGallery } from '../media_gallery/media_gallery';
import { CallDetailsCustomerDetails } from './legacy/call_details_customer_details';
import { CallDetailsLocation } from './call_details_location';
import { CallDetailsToolbar } from './call_details_toolbar';
import { Streemshot, StreemshotDetection } from '@streem/sdk-core';
import { useHistory, useLocation } from 'react-router-dom';
import { CallDetailForReservation } from './reservation/call_detail_for_reservation';

/**
 * Call Details page UI
 */
export const CallDetailsView: FC<{
    readOnly?: boolean;
}> = observer(({ readOnly = false }) => {
    const detailSession = useDetailSession();
    const log = appLogger.extend('edit call notes');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const { authStore, configStore, companySettingsStore } = useGlobalStore();
    const { isMobile } = useMediaBreakpoint();
    const location = useLocation();
    const history = useHistory();

    // must fetch the public (pre-auth) settings from configStore for this view
    // since it's displayed as an anonymous share link
    const gpsEnabled = configStore.gpsEnabled;
    const videoPreviewEnabled = configStore.videoTrackPreviewEnabled;
    const [roomNote] = useObservable(detailSession.note.roomNote);
    const [room] = useObservable(detailSession.room.room);
    const [favorited] = useObservable(detailSession.personalizations.favorited);
    const [streemshots = []] = useObservable(detailSession.streemshots.responses);
    const [showMarkdown, setShowMarkdown] = useState(true);

    const [modelSerial = []] = useObservable(
        detailSession.detection.getDetections({
            types: [
                streem.api.Detection.Type.DETECTION_TYPE_MODEL,
                streem.api.Detection.Type.DETECTION_TYPE_SERIAL,
            ],
        }),
    );

    const handleEdit = useCallback(() => {
        setShowMarkdown(false);
    }, []);

    const onSave = async (noteText: string) => {
        setShowMarkdown(true);
        try {
            setErrorMessage(undefined);
            await detailSession.note.saveRoomNote(noteText);
        } catch (error) {
            setErrorMessage('Error attempting to edit call notes.');
            log.error('Error attempting to edit call notes: ', error);
        }
    };

    const handleModelSerialClick = (
        detection: StreemshotDetection,
        streemshotToDisplay?: Streemshot,
    ) => {
        const url = `${location.pathname}/artifact/${
            streemshotToDisplay?.id || detection.streemshotSid
        }`;
        history.push(url);
    };

    const isAnonymousSharePage = useAnonymousShareChecker(authStore);

    const showCallDetailsYn =
        companySettingsStore.ahsVirtualExpertEnabled && room?.callDetail != null;
    const callDetails = showCallDetailsYn ? (
        <CallDetailForReservation />
    ) : (
        <CallDetailsCustomerDetails />
    );
    const dedupedModelSerial = useMemo(() => dedupeDetections(modelSerial), [modelSerial]);
    return (
        <div style={{ width: '100%' }}>
            <CallDetailsToolbar favorited={favorited} room={room} />
            <PageWrapper data-testid="call-details">
                {callDetails}
                <HorizontalBreak />
                {isAnonymousSharePage && isMobile && (
                    <div style={{ marginBottom: '40px' }}>
                        <MediaGallery
                            videoPreviewEnabled={videoPreviewEnabled}
                            readOnly={readOnly}
                        />
                    </div>
                )}
                <TwoColumns isAnonymousSharePage={isAnonymousSharePage}>
                    <CallNotes
                        showMarkdown={showMarkdown}
                        text={roomNote?.text}
                        handleEdit={handleEdit}
                        isAnonymousSharePage={isAnonymousSharePage}
                        roomNoteCharLimit={companySettingsStore.roomNoteCharLimit}
                        onSave={onSave}
                        readOnly={readOnly}
                        errorMessage={errorMessage}
                    />
                    <div>
                        {gpsEnabled && <CallDetailsLocation />}
                        <ModelSerialList
                            modelSerialDetections={dedupedModelSerial}
                            streemshotBaseUrl={`${location.pathname}/artifact`}
                            streemshots={streemshots}
                            hasFootbar={false}
                            onClickHandler={handleModelSerialClick}
                        />
                    </div>
                </TwoColumns>
                {(!isAnonymousSharePage || !isMobile) && (
                    <MediaGallery videoPreviewEnabled={videoPreviewEnabled} readOnly={readOnly} />
                )}
                {isAnonymousSharePage && (
                    <StreemFooterContainer>
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a href="https://www.streem.com/try-remote-video" target="_blank">
                            <PoweredByStreem />
                        </a>
                    </StreemFooterContainer>
                )}
            </PageWrapper>
        </div>
    );
});

const CallNotes: FC<{
    showMarkdown: boolean;
    text: string;
    handleEdit: () => void;
    isAnonymousSharePage: boolean;
    roomNoteCharLimit: number;
    onSave: (noteText: string) => Promise<void>;
    readOnly?: boolean;
    errorMessage?: string;
}> = ({
    showMarkdown,
    text,
    handleEdit,
    isAnonymousSharePage,
    roomNoteCharLimit,
    onSave,
    readOnly,
    errorMessage,
}) => {
    return showMarkdown && text !== undefined ? (
        <MarkDownNotes
            text={text}
            onEdit={handleEdit}
            showTitle={true}
            readOnly={isAnonymousSharePage}
        />
    ) : (
        <EditableNote
            description="Call Notes"
            placeholderText="Add call notes"
            characterLimit={roomNoteCharLimit}
            noteText={text}
            disableEditing={readOnly}
            onSave={onSave}
            errorMessage={errorMessage}
            hasFootBar={false}
            showChrome={false}
            isEditing={!showMarkdown}
        />
    );
};

const HorizontalBreak = styled.hr(({ theme }) => ({
    height: '1px',
    backgroundColor: theme.colors.grey10,
    border: 'none',
    margin: '24px 0',
}));

const PageWrapper = styled.article`
    margin: ${props => props.theme.space[4]}px ${props => props.theme.space[5]}px;
`;

const TwoColumns = styled.div<{ isAnonymousSharePage: boolean }>(
    ({ isAnonymousSharePage, theme }) => ({
        display: 'grid',
        gridTemplateColumns: 'minMax(0, 1fr) minMax(0, 1fr)',
        gridTemplateRows: 'auto',
        columnGap: '32px',
        marginBottom: '24px',
        paddingBottom: '24px',
        borderBottom: isAnonymousSharePage ? 'none' : `solid 1px ${theme.colors.grey10}`,
        '@media (max-width: 500px)': {
            gridTemplateColumns: '1fr',
            gridRowGap: '32px',
        },
    }),
);

const StreemFooterContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Theme.colors.grey05,
    width: '100%',
    marginTop: '40px',
});
