import { Fragment, useEffect, useState } from 'react';
import appLogger from '../../util/logging/app_logger';
import config from '../../config';
import { getHashParamValue, getUrlWithoutHashParams } from '@streem/toolbox';
import { SESSION_EMBED_SOURCE_CONTEXT, SESSION_SDK_TOKEN } from '../../types/project.types';

const log = appLogger.extend('embeddedSsoTokenHandler');

const EmbeddedSsoTokenHandler: React.FC = props => {
    log.setContextValue('appId', config.appId);

    const [readyToRender, setReadyToRender] = useState(false);
    useEffect(() => {
        const embedSourceContext = getHashParamValue('context');
        if (embedSourceContext) {
            sessionStorage.setItem(SESSION_EMBED_SOURCE_CONTEXT, embedSourceContext);
        }

        const sdkToken = getHashParamValue('token');
        if (sdkToken) {
            log.info('Initializing with embedded sso token');
            sessionStorage.setItem(SESSION_SDK_TOKEN, sdkToken);
            window.location.href = getUrlWithoutHashParams();
        } else {
            setReadyToRender(true);
        }
    }, []);

    if (readyToRender) {
        return <Fragment>{props.children}</Fragment>;
    } else {
        return null;
    }
};

export default EmbeddedSsoTokenHandler;
