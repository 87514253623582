import { GlobalStyles, LoadingOverlay, ThemeProvider } from '@streem/ui-react';
import { Observer } from 'mobx-react';
import { FC } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import {
    CentralLoginStoreProvider,
    useInitializeCentralLoginStores,
} from './hooks/use_central_login_context';
import { CentralLoginForgotPasswordPage } from './pages/authentication/central_login_forgot_password_page';
import { CentralLoginPage } from './pages/authentication/central_login_page';
import { CentralLoginAnonymousRoute } from './routing/anonymous_route_central_login';
import { CentralLoginBaseRoutes } from './routing/routes_central_login';
import { ErrorBoundary } from './util/error/error_boundary';
import appLogger from './util/logging/app_logger';
import { addRegionCodeToDatadogRumSession } from './util/datadog';
import { CookiesDisabledPage } from './pages/authentication/cookies_disabled_page';

const CentralLoginApp: FC = () => {
    const globalStores = useInitializeCentralLoginStores();
    const { authStore } = globalStores;

    appLogger.setContextValue('regionCode', 'global');
    addRegionCodeToDatadogRumSession('global');

    return (
        <ErrorBoundary>
            <ThemeProvider>
                <CentralLoginStoreProvider value={globalStores}>
                    <GlobalStyles />
                    <BrowserRouter>
                        <Observer>
                            {() => {
                                if (!window.navigator.cookieEnabled) {
                                    return <CookiesDisabledPage />;
                                }
                                if (!authStore.initialized) {
                                    return <LoadingOverlay />;
                                }

                                return (
                                    <Switch>
                                        <CentralLoginAnonymousRoute
                                            path="/login"
                                            exact={true}
                                            component={CentralLoginPage}
                                        />
                                        <CentralLoginAnonymousRoute
                                            path="/forgot"
                                            exact={true}
                                            component={CentralLoginForgotPasswordPage}
                                        />
                                        <CentralLoginBaseRoutes />
                                    </Switch>
                                );
                            }}
                        </Observer>
                    </BrowserRouter>
                </CentralLoginStoreProvider>
            </ThemeProvider>
        </ErrorBoundary>
    );
};

export default CentralLoginApp;
