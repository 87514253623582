import { useEffect, useState } from "react";

//Code snippet from https://blog.bitsrc.io/creating-the-konami-code-as-react-hook-1bb69756b67a
export const useInputEvent = () => {
    const [key, setKey] = useState(null);
    useEffect(() => {
        const keyDownHandler = ({ code }: KeyboardEvent) => setKey(code);
        const keyUpHandler = () => setKey(null);
        global.addEventListener('keydown', keyDownHandler);
        global.addEventListener('keyup', keyUpHandler);
        return () => {
            global.removeEventListener("keydown", keyDownHandler);
            global.removeEventListener("keyup", keyUpHandler)
        }
    }, []);
    return key;
};
