import { useEffect, useState } from 'react';
import { convertProtobufToSeconds } from '../util/group_reservation_helpers';
import { google } from 'streem-sdk-protobuf';

export const useGroupReservationTimer = (
    expirationTime: google.protobuf.ITimestamp,
    secondsReserved: number,
) => {
    const [minutesToDisplay, setMinutesToDisplay] = useState(Math.round(secondsReserved / 60));

    useEffect(() => {
        const expirationTimeInSeconds = convertProtobufToSeconds(expirationTime);
        const timer = window.setInterval(() => {
            setMinutesToDisplay(calculateMinutesRemaining(expirationTimeInSeconds));
        }, 1_000);
        return () => window.clearInterval(timer);
    }, [expirationTime]);

    return minutesToDisplay;
};

const calculateMinutesRemaining = (expirationTime: number): number => {
    const currentTimeInSeconds = Date.now() / 1000;
    return Math.round((expirationTime - currentTimeInSeconds) / 60);
};
