import { APITypes, StreemAPI } from '@streem/api';
import {
    AppIcon,
    AppText,
    BaseInput,
    Box,
    Button,
    Card,
    FocusWithinContainer,
    InputValidationError,
    Label,
    styled,
    useTheme,
    WhiteInput,
} from '@streem/ui-react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { autorun } from 'mobx';
import {
    ButtonHTMLAttributes,
    FC,
    KeyboardEvent,
    PropsWithChildren,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useFormatPhoneNumber, useInviteToStreem } from '../../hooks/invite_hooks';
import { useGlobalStore } from '../../hooks/use_global_context';
import { trimFormValues } from '../../util/form';
import appLogger from '../../util/logging/app_logger';
import { parsePhoneNumber } from '../../util/parse_phone_number';
import { ValidatedField } from '../inputs/validated_field';
import { CountryCodeField } from './country_code_field';
import { InviteStatus, InviteType, InviteValues } from './types';
import { inviteValidationSchema } from './validation_schema';

const log = appLogger.extend('invite');

interface FormValues {
    name: string;
    countryCode: string;
    phone: string;
    referenceId: string;
    integrationId: string;
    inviteType: string;
    customerEmail: string;
}

interface Props {
    name?: string;
    referenceId?: string;
    integrationId?: string;
    phone?: string;
    customerEmail?: string;
    callingCode?: string;
    inviteType?: string;
    onSuccess: (resp: APITypes.StreemApiSendInvitationResponse) => void;
    onCancel: () => void;
    isEmbedded?: boolean;
}

const submitTextMap: Record<InviteType, string> = {
    phone: 'Invite',
    link: 'Create Link',
};

const DEFAULT_COUNTRY_CODE = '1';

// see https://www.twilio.com/docs/sms/api/message-resource#message-status-values
const SMS_FAIL_STATUSES = ['undelivered', 'failed'];
const SMS_SUCCESS_STATUS = 'delivered';

const InviteTypeButton: FC<
    PropsWithChildren<
        ButtonHTMLAttributes<HTMLLabelElement> & {
            isActive: boolean;
            onClick: () => void;
            htmlFor: string;
            'data-testid': string;
        }
    >
> = ({ children, isActive, onClick, htmlFor, 'data-testid': testId }) => {
    const handleKeydown = useCallback(
        (event: KeyboardEvent<HTMLLabelElement>) => {
            if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                onClick();
            }
        },
        [onClick],
    );

    return (
        <LabelButton
            htmlFor={htmlFor}
            role="button"
            tabIndex={0}
            onClick={onClick}
            isActive={isActive}
            onKeyDown={handleKeydown}
            data-testid={testId}
        >
            {children}
        </LabelButton>
    );
};

enum FormSubmissionErrorType {
    Landline,
    NotDelivered,
    Timeout,
    Unknown,
}

interface FormSubmissionError {
    errorType: FormSubmissionErrorType;
    message: ReactElement;
}

export const InviteForm: FC<Props> = ({
    name,
    referenceId,
    integrationId,
    phone: rawPhone,
    customerEmail,
    inviteType,
    callingCode,
    onSuccess,
    onCancel,
    isEmbedded,
}) => {
    const { sdkStore } = useGlobalStore();
    const [done, setDone] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [smsInvitationSid, setSmsInvitationSid] = useState('');
    const [smsDeliveryStatus, setSmsDeliveryStatus] = useState('');
    const theme = useTheme();
    const formatPhone = useFormatPhoneNumber();
    const { phone, countryCode } = parsePhoneNumber(rawPhone ?? '', callingCode);
    const [invite, inviteState] = useInviteToStreem();
    const [error, setError] = useState<FormSubmissionError>({
        errorType: null,
        message: null,
    });

    const handleSubmit = useCallback(
        (values: FormValues) => {
            setSubmitting(true);
            const inviteType = values.inviteType as InviteType;
            const safeFormValues = trimFormValues(values, [
                'name',
                'customerEmail',
                'referenceId',
                'integrationId',
            ]);

            const payload: InviteValues = {
                inviteType: inviteType,
                name: safeFormValues.name,
                referenceId: safeFormValues.referenceId,
                integrationId: safeFormValues.integrationId,
                customerEmail: safeFormValues.customerEmail,
            };

            if (inviteType === 'phone') {
                const parsedPhone = parsePhoneNumberFromString(
                    `+${values.countryCode}${values.phone}`,
                );

                if (parsedPhone) {
                    payload.phone = parsedPhone.format('E.164');
                }
            }

            invite(inviteType, payload);
        },
        [invite],
    );

    const onError = (errorType: FormSubmissionErrorType) => {
        setSubmitting(false);

        switch (errorType) {
            case FormSubmissionErrorType.Landline:
                return setError({
                    errorType: FormSubmissionErrorType.Landline,
                    message: (
                        <span>
                            This phone number belongs to a landline. Try again with a mobile phone
                            number.
                        </span>
                    ),
                });
            case FormSubmissionErrorType.Timeout:
                return setError({
                    errorType: FormSubmissionErrorType.Timeout,
                    message: (
                        <span>
                            Oops! The connection timed out. Click{' '}
                            <HighlightText>Invite</HighlightText> to retry.
                        </span>
                    ),
                });
            case FormSubmissionErrorType.NotDelivered:
                return setError({
                    errorType: FormSubmissionErrorType.NotDelivered,
                    message: (
                        <span>
                            Unable to deliver SMS invitation (spam filtering or restricted number).
                            Click <HighlightText>Invite</HighlightText> to retry, or try a{' '}
                            <HighlightText>Link & Code</HighlightText> invitation.
                        </span>
                    ),
                });
            default:
                return setError({
                    errorType: FormSubmissionErrorType.Unknown,
                    message: <span>Something went wrong. Please try again.</span>,
                });
        }
    };

    /**
     * if phone sms invite is still in 'submitting' state after timeout delay
     * last-ditch effort to get SMS delivery status from API
     * error if we still cannot confirm whether the message succeeded or failed
     *
     * TODO STREEM-9393: remove fallback to API once delivery status is reliably found on the user wall
     */
    useEffect(() => {
        const errorIfUnconfirmedSmsDelivery = setTimeout(async () => {
            if (submitting && smsInvitationSid && inviteType === 'phone') {
                let confirmedMessage = null;
                try {
                    const { smsDeliveryStatuses } =
                        await StreemAPI.default.getInvitationSmsStatuses(smsInvitationSid);
                    confirmedMessage = smsDeliveryStatuses?.find(
                        status =>
                            status.deliveryStatus === SMS_SUCCESS_STATUS ||
                            SMS_FAIL_STATUSES.includes(status.deliveryStatus),
                    );
                } catch (error) {
                    // no-op -- this is handled below in the else block
                }

                if (confirmedMessage) {
                    log.info(
                        `Confirmed SMS delivery status via API. status: '${confirmedMessage.deliveryStatus}', invitation sid: '${smsInvitationSid}', message id: '${confirmedMessage.messageId}'`,
                    );
                    setSmsDeliveryStatus(confirmedMessage.deliveryStatus);
                } else {
                    log.error(
                        `Timed out before confirmed SMS delivery for invitation sid: '${smsInvitationSid}'`,
                    );
                    onError(FormSubmissionErrorType.Timeout);
                }
            }
        }, 20_000);
        return () => clearTimeout(errorIfUnconfirmedSmsDelivery);
        // only firing use effect hook on needed dependencies to prevent setTimeout from restarting unnecessarily
        // eslint-disable-next-line
    }, [submitting, smsInvitationSid]);

    /**
     * while in 'submitting' state, request sms delivery status from user wall for phone invites
     * using 'autorun' which is provided by mobx for when a hook depends on an observable
     */
    useEffect(() => {
        autorun(() => {
            if (submitting && inviteType === 'phone' && smsInvitationSid) {
                const status = sdkStore.getSmsInviteStatusBySid(smsInvitationSid)?.current;
                setSmsDeliveryStatus(status?.deliveryStatus);
            }
        });
    }, [sdkStore, submitting, smsInvitationSid, inviteType]);

    useEffect(() => {
        if (!done) {
            const { status, meta } = inviteState;

            const invitationSid = meta?.response?.invitation?.invitationSid;
            if (invitationSid && inviteType === 'phone') {
                setSmsInvitationSid(invitationSid);
            }

            const isSmsInvite = meta.values?.inviteType === 'phone';
            const success = isSmsInvite
                ? smsDeliveryStatus === SMS_SUCCESS_STATUS
                : status === InviteStatus.Success;

            if (success) {
                onSuccess(inviteState.meta);
                setSubmitting(false);
                setDone(true);
            }

            if (status === InviteStatus.Error) {
                if (meta?.response?.landline) {
                    onError(FormSubmissionErrorType.Landline);
                } else {
                    onError(FormSubmissionErrorType.Unknown);
                }
            } else if (SMS_FAIL_STATUSES.includes(smsDeliveryStatus)) {
                onError(FormSubmissionErrorType.NotDelivered);
            }
        }
    }, [done, inviteState, inviteType, onSuccess, smsInvitationSid, smsDeliveryStatus]);

    return (
        <Formik
            enableReinitialize
            initialValues={{
                name: name || '',
                countryCode: countryCode || DEFAULT_COUNTRY_CODE,
                phone: phone || '',
                referenceId: referenceId || '',
                integrationId: integrationId || '',
                inviteType: inviteType || 'phone',
                customerEmail: customerEmail || '',
            }}
            // Yup is great for most validation, but doesn't give us an easy
            // way to validate two fields at once, such as countryCode with phone,
            // so we do that manually via the `validate` prop.
            validationSchema={inviteValidationSchema}
            validate={(values: FormValues) => {
                const errors: { [key: string]: string } = {};
                if (values.inviteType === 'phone') {
                    const phoneNumber = parsePhoneNumberFromString(
                        `+${values.countryCode}${values.phone}`,
                    );
                    const validPhone = Boolean(phoneNumber?.isPossible() && phoneNumber?.isValid());
                    if (!validPhone) errors.phone = 'Valid mobile number required';
                }
                return errors;
            }}
            validateOnMount={false}
            validateOnChange={true}
            onSubmit={handleSubmit}
        >
            {({ setFieldValue, values, ...props }: any) => {
                const isLinkInvite = values.inviteType === 'link';
                const isPhoneInvite = values.inviteType === 'phone';

                const isLandlineError = error.errorType === FormSubmissionErrorType.Landline;
                const isFormSubmissionError = error.errorType !== null;

                return (
                    <Form data-testid="invite-to-streem-form">
                        <Field name="inviteType">
                            {({ field }: FieldProps) => <input type="hidden" {...field} />}
                        </Field>
                        <ValidatedField
                            required
                            autoFocus={true}
                            label="Name"
                            type="text"
                            name="name"
                            component={WhiteInput}
                            data-testid="invite-name"
                            labelColor="dark"
                            placeholder="First Name Last Name"
                        />
                        <ValidatedField
                            label="Reference"
                            type="text"
                            name="referenceId"
                            component={WhiteInput}
                            data-testid="invite-reference-id"
                            labelColor="dark"
                            placeholder="Reference"
                        />
                        <Field name="integrationId">
                            {({ field }: FieldProps) => (
                                <input
                                    data-testid="invite-integration-id"
                                    type="hidden"
                                    {...field}
                                />
                            )}
                        </Field>

                        <DeliveryMethodField>
                            <DeliveryMethod>
                                <InviteTypeButton
                                    role="button"
                                    htmlFor="phone"
                                    tabIndex={0}
                                    isActive={isPhoneInvite}
                                    data-testid="inviteType-phone-btn"
                                    onClick={() => setFieldValue('inviteType', 'phone')}
                                >
                                    <AppIcon
                                        name="SMSIcon"
                                        size="medium"
                                        opacity={isPhoneInvite ? '1' : '0.5'}
                                        color={
                                            isPhoneInvite ? theme.colors.azure : theme.colors.grey90
                                        }
                                    />
                                    <InviteTypeText
                                        bold
                                        headingFontFamily
                                        size="mediumLarge"
                                        color={isPhoneInvite ? 'azure' : 'medium'}
                                        data-testid="inviteType-phone-label"
                                    >
                                        Send SMS
                                    </InviteTypeText>
                                </InviteTypeButton>
                                <InviteTypeButton
                                    role="button"
                                    htmlFor="link"
                                    tabIndex={0}
                                    isActive={isLinkInvite}
                                    data-testid="inviteType-link-btn"
                                    onClick={() => setFieldValue('inviteType', 'link')}
                                >
                                    <AppIcon
                                        name="LinkIcon"
                                        size="medium"
                                        opacity={isLinkInvite ? '1' : '0.5'}
                                        color={
                                            isLinkInvite ? theme.colors.azure : theme.colors.grey90
                                        }
                                    />
                                    <InviteTypeText
                                        bold
                                        headingFontFamily
                                        size="mediumLarge"
                                        color={isLinkInvite ? 'azure' : 'medium'}
                                        data-testid="inviteType-link-label"
                                    >
                                        Link & Code
                                    </InviteTypeText>
                                </InviteTypeButton>

                                <DeliveryMethodMetaGrid>
                                    {isPhoneInvite && (
                                        <PhoneNumberFieldGrid error={isLandlineError}>
                                            <FieldLabel size="large">
                                                Mobile Phone Number*
                                            </FieldLabel>
                                            <CountryCodeField
                                                name="countryCode"
                                                onChange={val => setFieldValue('countryCode', val)}
                                            />
                                            <Field name="phone" type="tel">
                                                {({
                                                    field,
                                                    form,
                                                    meta,
                                                }: FieldProps<string, FormValues>) => {
                                                    const phoneNumberError =
                                                        (meta.touched && Boolean(meta.error)) ||
                                                        isLandlineError;
                                                    return (
                                                        <>
                                                            <PhoneNumberInputWrapper
                                                                error={phoneNumberError}
                                                            >
                                                                <PhoneInput
                                                                    {...field}
                                                                    required
                                                                    data-testid="invite-phone"
                                                                    label="Mobile Phone Number"
                                                                    hideLabel={true}
                                                                    value={formatPhone(
                                                                        form.values.countryCode,
                                                                        meta.value,
                                                                    )}
                                                                />
                                                            </PhoneNumberInputWrapper>
                                                            <PhoneNumberValidationText
                                                                data-testid={`validation-error-for-phone`}
                                                                color={
                                                                    phoneNumberError
                                                                        ? theme.colors.red50
                                                                        : theme.colors.grey40
                                                                }
                                                            >
                                                                {phoneNumberError && meta.error
                                                                    ? meta.error
                                                                    : '*Mobile Phone Number is required'}
                                                            </PhoneNumberValidationText>
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                            {isFormSubmissionError && (
                                                <FormSubmissionErrorContainer
                                                    data-testid={`form-submission-error`}
                                                >
                                                    <AlertAppIcon
                                                        name="AlertIcon"
                                                        color={theme.colors.red50}
                                                        size="large"
                                                    />
                                                    <FormSubmissionErrorText
                                                        data-testid={`form-submission-error-text`}
                                                        size="medium"
                                                        color="dark"
                                                    >
                                                        {error.message}
                                                    </FormSubmissionErrorText>
                                                </FormSubmissionErrorContainer>
                                            )}
                                        </PhoneNumberFieldGrid>
                                    )}
                                    {isLinkInvite && (
                                        <InformationCard>
                                            <InformationAppIcon
                                                name="InformationIcon"
                                                color={theme.colors.azure}
                                                size="large"
                                            />
                                            <AppText size="medium" color="dark">
                                                To send a Streem invitation by email, copy the URL
                                                and paste it into your customer email tool.
                                            </AppText>
                                        </InformationCard>
                                    )}
                                </DeliveryMethodMetaGrid>
                            </DeliveryMethod>
                        </DeliveryMethodField>
                        <FormFooter>
                            {!isEmbedded && (
                                <Button
                                    data-testid="invite-cancel-btn"
                                    variant="secondary"
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            )}
                            <Button
                                data-testid="invite-submit-btn"
                                type="submit"
                                variant="primary"
                                loading={submitting}
                                disabled={!props.isValid || submitting}
                            >
                                {submitTextMap[values.inviteType as InviteType] ?? 'Submit'}
                            </Button>
                        </FormFooter>
                    </Form>
                );
            }}
        </Formik>
    );
};

const InformationCard = styled(Card)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
`;

const InformationAppIcon = styled(AppIcon)`
    min-width: 16px;
    margin-right: 16px;
`;

const InviteTypeText = styled(AppText)`
    margin-left: 8px;
`;

const FormFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    text-align: right;
    margin-right: -16px;

    > button {
        margin-left: 16px;
    }
`;

const DeliveryMethodField = styled('div')`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 'label field';
`;

const DeliveryMethod = styled('div')`
    display: grid;
    grid-template-rows: 40px 150px;
    grid-template-columns: 1fr 1fr;
`;

const DeliveryMethodMetaGrid = styled('div')`
    grid-row: 2/2;
    grid-column: 1/3;
    display: grid;
    align-items: center;
    margin-bottom: 16px;
`;

const LabelButton = styled.label<{ isActive: boolean }>`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 0px;
    border-bottom: 2px solid ${props => props.theme.colors.grey20};
    cursor: pointer;
    background: transparent;
    color: ${props => (props.isActive ? props.theme.colors.white : props.theme.colors.grey20)};
    &:focus {
        outline: none;
    }
    &::after {
        visibility: ${props => (props.isActive ? 'visible' : 'hidden')};
        position: absolute;
        width: 100%;
        bottom: -3px;
        content: ' ';
        height: 4px;
        background: ${props => props.theme.gradients.lightGradient};
    }
`;

const PhoneNumberFieldGrid = styled('div')<{ error: boolean }>`
    display: grid;
    grid-template-columns: 86px 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        'label label'
        'countryCode phone'
        'error error';

    .ui-select__control {
        height: 40px;
        border-radius: 0;
        border: 1px solid
            ${({ error, theme }) => (error ? theme.colors.red50 : theme.colors.grey90)};
    }
`;

const PhoneNumberInputWrapper = styled(FocusWithinContainer)<{ error: boolean }>`
    height: 40px;
    border: 1px solid ${({ error, theme }) => (error ? theme.colors.red50 : theme.colors.grey90)};
    display: flex;
    align-items: center;
    transform: translateX(-1px);
    margin-bottom: 10px;
    margin-left: 8px;
    width: 250px;
`;

const PhoneInput = styled(BaseInput)`
    font-size: 1rem;
    height: 100%;
    padding: 0 8px;
    opacity: ${({ value }) => (value ? '1' : '0.5')};
`;

const FieldLabel = styled(Label)`
    font-weight: 500;
    font-family: ${({ theme }) => theme.text.headingFontFamily};
    font-size: 1rem;
    margin-bottom: 8px;
    grid-area: label;
`;

const PhoneNumberValidationText = styled(InputValidationError)`
    grid-area: error;
`;

const FormSubmissionErrorContainer = styled(Box)`
    display: flex;
    align-items: center;
    padding: 2px 10px;
    border: 1px solid ${({ theme }) => theme.colors.red50};
    grid-area: error;
    margin-top: 16px;
    margin-bottom: -16px;
`;

const FormSubmissionErrorText = styled(AppText)`
    padding: 0 8px;
`;

const HighlightText = styled.span`
    font-weight: 600;
`;

const AlertAppIcon = styled(AppIcon)`
    flex: 0 0 1em;
    margin: 0 10px;
`;
