import { FC, useState, useEffect } from 'react';
import {
    Header,
    AppText,
    Column,
    IconButton,
    useTheme,
    Box,
    Row,
    Modal,
    Button,
    CopyButton,
    AppIcon,
    styled,
    ButtonRow,
    ToastTypes,
} from '@streem/ui-react';
import { HelpBox, HelpBoxContainer } from './api_key_page.styles';
import { StreemAPI, APITypes } from '@streem/api';
import { useActiveCompanyCode } from '../../hooks/use_active_company_code';
import appLogger from '../../util/logging/app_logger';
import { createListCompanyApiKeysStore } from '../../hooks/list_store_hooks';
import { useGlobalStore } from '../../hooks/use_global_context';
import { useObserver } from 'mobx-react';
import { PageColumn } from '../page_column';

const ApiKeyField: FC<{
    label: string;
    value: string;
    testid: string;
}> = ({ label, value, testid }) => {
    return (
        <Field data-testid={testid}>
            <Header size={'large'}>{label}</Header>
            <AppText as="p" style={{ overflowWrap: 'break-word' }}>
                {value}
                <CopyButton data-testid={`copy-${testid}`} label={'copy key'} value={value} />
            </AppText>
        </Field>
    );
};

const APIKeyPage: FC = () => {
    const theme = useTheme();
    const companyCode = useActiveCompanyCode();
    const listCompanyApiKeys = createListCompanyApiKeysStore();
    const { uiStore } = useGlobalStore();
    const [showModal, setShowModal] = useState(false);
    const [toDeleteAPIKey, setToDeleteAPIKey] = useState<string | undefined>('');
    const [ApiKey, setApiKey] = useState<APITypes.StreemApiCompanyApiKey | undefined>({
        apiKeyId: '',
        apiKeySecret: '',
    });

    useEffect(() => {
        const promise = listCompanyApiKeys.fetch(companyCode);
        return () => {
            promise.cancel();
        };
    }, [companyCode, listCompanyApiKeys]);

    const formatApiKeyLabel = (dateLabel: string | undefined) => {
        if (dateLabel === undefined) {
            return 'API Key';
        }
        const date = new Date(dateLabel);
        const options = {
            timeStyle: 'short',
            dateStyle: 'long',
        };
        // @ts-ignore https://github.com/microsoft/TypeScript/issues/35865
        return `API Key ${date.toLocaleString(navigator.language, options)}`;
    };

    const generateDateLabel = () => {
        return new Date().toISOString();
    };

    const createApiKey = async () => {
        try {
            const result = await StreemAPI.companies.createCompanyApiKey(companyCode, {
                label: generateDateLabel(),
            });
            setApiKey(result.apiKey);
            listCompanyApiKeys.refresh();
            setShowModal(s => !s);
        } catch (error) {
            appLogger.error('Error creating API key', error);
            uiStore.addToast({
                content: 'Something went wrong creating the API key. Please try again.',
                flavor: ToastTypes.ERROR,
                id: 'api-creation-fail',
            });
        }
    };

    const deleteApiKey = async (companyCodeOrSid: string, apiKeyId: string) => {
        try {
            await StreemAPI.companies.revokeCompanyApiKey(companyCodeOrSid, apiKeyId);
            listCompanyApiKeys.refresh();
            closeDeleteModal();
        } catch (error) {
            appLogger.error('Error deleting API key', error);
            uiStore.addToast({
                content: 'Something went wrong deleting the API key. Please try again.',
                flavor: ToastTypes.ERROR,
                id: 'api-deletion-fail',
            });
        }
    };

    const sortByDate = (a: APITypes.StreemApiCompanyApiKey, b: APITypes.StreemApiCompanyApiKey) => {
        const dateA = new Date(a.label as string);
        const dateB = new Date(b.label as string);
        if (dateA < dateB) {
            return -1;
        }
        if (dateB < dateA) {
            return 1;
        }
        return 0;
    };

    const closeDeleteModal = () => {
        setToDeleteAPIKey('');
    };

    return useObserver(() => {
        const listOfApiKeys = listCompanyApiKeys.results
            .slice()
            .sort(sortByDate)
            .map(apiKey => (
                <APIKeyContainer key={apiKey.apiKeyId}>
                    <Box>
                        <Header size="large" style={{ marginBottom: '10px' }}>
                            {formatApiKeyLabel(apiKey.label)}
                        </Header>
                        <AppText bold={true}>API Key </AppText>
                        <AppText> {apiKey.apiKeyId!}</AppText>
                    </Box>
                    <IconButton
                        iconName="TrashIcon"
                        size="medium"
                        hoverFill={theme.colors.red50}
                        shape="square"
                        data-testid="delete-api-key"
                        background="transparent"
                        label="delete API key"
                        onClick={() => setToDeleteAPIKey(apiKey.apiKeyId)}
                    />
                    <Modal
                        data-testid="delete-api-key-modal"
                        isOpen={toDeleteAPIKey === apiKey.apiKeyId}
                        onClose={closeDeleteModal}
                    >
                        <DeleteContent>
                            <Header size="xlarge">Delete {formatApiKeyLabel(apiKey?.label)}</Header>

                            <AppText as="p">
                                Deleting this API Key will remove it permanently. Any integrations
                                using this API Key will stop working.
                            </AppText>
                            <Footer>
                                <Button
                                    variant="secondary"
                                    data-testid="cancel-delete-api-key"
                                    onClick={closeDeleteModal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => deleteApiKey(companyCode, apiKey.apiKeyId!)}
                                    data-testid="submit-delete-api-key"
                                >
                                    Delete
                                </Button>
                            </Footer>
                        </DeleteContent>
                    </Modal>
                </APIKeyContainer>
            ));

        return (
            <PageColumn
                style={{
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    paddingTop: '32px',
                }}
            >
                <Modal
                    data-testid="create-api-key-modal"
                    isOpen={showModal}
                    onClose={() => setShowModal(s => !s)}
                    closeDelayMS={200}
                >
                    <CreateContent>
                        <Header size="xlarge">
                            {formatApiKeyLabel(ApiKey?.label ? ApiKey.label : 'API Key')}
                        </Header>
                        <AppText as="p">
                            Make sure to copy this secret API Key and store it somewhere safe. You
                            won’t be able to see it again!
                        </AppText>

                        <ApiKeyField
                            label="Public API Key"
                            value={ApiKey?.apiKeyId || ''}
                            testid="public-api-key"
                        />
                        <ApiKeyField
                            label="Secret API Key"
                            value={ApiKey?.apiKeySecret || ''}
                            testid="secret-api-key"
                        />
                        <Footer>
                            <Button
                                variant={'primary'}
                                onClick={() => setShowModal(s => !s)}
                                data-testid="create-api-key-done"
                            >
                                Done
                            </Button>
                        </Footer>
                    </CreateContent>
                </Modal>
                <Column mb={5} ml={4}>
                    <Header size="xxlarge">API Keys</Header>
                    <Box display="flex" mt={3} width="100%" justifyContent="space-between">
                        <AppText
                            size="large"
                            style={{ lineHeight: '25px', marginTop: '10px', width: '70%' }}
                        >
                            Get your API keys here when you’re ready to integrate Streem into your
                            native apps.
                        </AppText>
                        <IconButton
                            data-testid="add-api-key"
                            iconName={'AddIcon'}
                            label={'Add API Key'}
                            background={'azure'}
                            fill={'white'}
                            onClick={() => createApiKey()}
                        />
                    </Box>
                </Column>
                <Row>
                    <Column data-testid="api-keys-list" flexGrow={1} ml={4}>
                        {listOfApiKeys}
                    </Column>
                </Row>
                <HelpBoxContainer>
                    <HelpBox
                        target="_blank"
                        rel="noopener noreferrer"
                        href={'https://developer.streem.com/docs'}
                    >
                        <Row mr={3}>
                            <Box mt={4} width={'100px'} textAlign={'center'}>
                                <AppIcon name={'DeveloperIcon'}></AppIcon>
                            </Box>
                            <Column mr={5} flexGrow={2}>
                                <Header style={{ marginBottom: '10px' }} size="large">
                                    Need Help?
                                </Header>
                                <AppText>
                                    Click here to open the Streem Developer Portal in a new tab.
                                </AppText>
                            </Column>
                        </Row>
                    </HelpBox>
                </HelpBoxContainer>
            </PageColumn>
        );
    });
};

const DeleteContent = styled.div`
    display: grid;
    grid-gap: ${props => props.theme.spacing.l};
    width: 675px;
`;

const CreateContent = styled.div`
    display: grid;
    grid-gap: ${props => props.theme.spacing.l};
    width: 570px;
`;

const Footer = styled(ButtonRow)`
    justify-content: flex-end;
`;

const Field = styled.div`
    display: grid;
    grid-gap: ${props => props.theme.spacing.s};
    word-break: break-word;
`;

const APIKeyContainer = styled(Row)({
    padding: '16px',
    boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.1)',
    justifyContent: 'space-between',
    alignItems: 'center',
});

export default APIKeyPage;
