import '@salesforce/canvas-js-sdk';
import appLogger from '../util/logging/app_logger';
import { SESSION_EMBED_SOURCE_CONTEXT } from '../types/project.types';

const log = appLogger.extend('usePublishCanvasEvent');

export enum CanvasEvents {
    COMPLETED = 'call.completed',
    INVITE_CREATED = 'invite.created',
    INVITE_DELIVERED = 'invite.delivered',
    // TODO: add the rest of the events here
}

export function usePublishCanvasEvent(): (name: CanvasEvents, context: unknown) => void {
    return (name, context) => {
        const eventKey = generateEventKey(name, context);
        if (!isAlreadySent(eventKey)) {
            const event = buildEvent(name, context);
            publishEvent(event);
            markAsSent(eventKey);
        }
    };
}

function generateEventKey(name: CanvasEvents, context: unknown): string {
    const contentHash = btoa(JSON.stringify(context));
    return `${name}_${contentHash}`;
}

function buildEvent(name: CanvasEvents, context: unknown): Sfdc.canvas.client.Event {
    return {
        name: name,
        payload: {
            context: context,
            timestamp: Date.now(),
        },
    };
}

function publishEvent(event: Sfdc.canvas.client.Event) {
    const sfdc = window.Sfdc as typeof Sfdc;

    // uncomment to enable debugging
    if (sfdc.canvas.console !== undefined) sfdc.canvas.console.enable();

    const context = getSalesforceClientContext();

    if (context) {
        log.info(
            `Publishing a canvas event with name: ${event.name} and payload: ${JSON.stringify(
                event.payload,
            )}`,
        );
        sfdc.canvas.client.publish(context, event);
    }
}

function markAsSent(eventKey: string) {
    sessionStorage.setItem(eventKey, String(true));
}

function isAlreadySent(eventKey: string): boolean {
    return sessionStorage.getItem(eventKey) === 'true';
}

/**
 * Example of the client context expected by the Salesforce app (decoded):
    {
        'instanceId': 'Streem:canvasapp:etc',
        'targetOrigin': 'https://customer-org.my.salesforce.com',
        'oauthToken': 'some-token',
        'instanceUrl': 'https://customer-org.my.salesforce.com',
        'refreshToken': null
    }
*/
function getSalesforceClientContext(): Sfdc.canvas.Client | null {
    const canvasAppContext = sessionStorage.getItem(SESSION_EMBED_SOURCE_CONTEXT);
    if (canvasAppContext) {
        log.debug(`Found Canvas App Context in storage: ${canvasAppContext}`);
    } else {
        return;
    }

    const base64DecodedContext = atob(canvasAppContext);
    log.debug(`Base64 decoded context: ${base64DecodedContext}`);

    const context = JSON.parse(base64DecodedContext);
    log.debug(`Successfully JSON parsed context`);

    if (!isValidClientContext(context)) {
        log.warn(`Canvas app context failed validation; cannot publish event`);
        return;
    }

    return context;
}

function isValidClientContext(context: any): boolean {
    return context.instanceId && context.targetOrigin && context.oauthToken && context.instanceUrl;
}
