import { FC, useCallback } from 'react';
import { useGlobalStore } from '../../hooks/use_global_context';
import { EmbedSessionKeys } from '../../types/project.types';
import { MessagePageWithOptionalButton } from '../errors/message_page_with_optional_button';
import { RouteComponentProps } from 'react-router-dom';

export const SessionExpiredRoute: FC<RouteComponentProps> = ({ history }) => {
    const { authStore } = useGlobalStore();

    const isEmbed = sessionStorage.getItem(EmbedSessionKeys.IS_EMBED);

    const handleBackClick = useCallback(() => {
        if (authStore.isUserSignedIn) history.goBack();
        else history.push('/');
    }, [authStore, history]);

    return (
        <>
            <MessagePageWithOptionalButton
                dataTestId="session-expired-page"
                header="Session Expired"
                message="Your Streem session has expired"
                hasButton={!isEmbed}
                clickHandler={handleBackClick}
            />
        </>
    );
};
