import { useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import { DetailSession } from '@streem/sdk-core';
import { AppText, Box, Column, CompanyWatermark, Row, useTheme } from '@streem/ui-react';
import { Diagnosis, OutOfScopeReason, RoomOutcomeFormPayload } from '../../types/disposition.types';
import { streem } from 'streem-sdk-protobuf';
import { DiagnosisDetailSelection } from './call_diagnosis';
import { DispositionDetailSection } from './call_disposition';
import { useRoomOutcomeFormConfig } from '../../hooks/use_room_outcome_form_config';
import { convertRoomOutcomePayloadToProtobuf } from '../../util/convert_room_outcome_protobuf';
import { buildDiagnosisListOptions } from '../../util/call_disposition_helpers';
import { useCallSourceData } from '../../hooks/use_call_source_data';
import { Dash, DiagnosisIndicatorIcon, DispositionIndicatorIcon } from './room_outcome_form_styles';
import appLogger from '../../util/logging/app_logger';
import { ButtonFooter } from './room_outcome_form_footer';
import { useWillOfferOptions } from '../../hooks/use_will_offer_options';
const log = appLogger.extend('roomOutcomeForm');

interface Props {
    trade?: string;
    onSuccess: () => void;
    detailSession: DetailSession;
}

export type DispositionFormStatus =
    | 'diagnosis'
    | 'disposition'
    | 'dispositionOnly' /* Only disposition is allowed on this form */;

const initialFormValues: RoomOutcomeFormPayload = {
    diagnoses: [],
    disposition: { code: '', label: '' },
    customFields: {},
};

const setInitialFormValuesFromConfig = (
    formConfig: streem.api.ICompanyRoomOutcomeConfig,
    willOfferRefundOptions?: boolean,
    willOfferOutOfScopeOptions?: boolean,
) => {
    initialFormValues.customFields = {}; // Reset custom fields

    if (formConfig?.customFields?.training) {
        initialFormValues.customFields.training = false;
    }

    if (willOfferRefundOptions) {
        initialFormValues.customFields.refund = {
            shouldRefund: false,
            reason: undefined,
        };
    }

    if (willOfferOutOfScopeOptions) {
        initialFormValues.customFields.outOfScope = {
            isOutOfScopeDiscussion: false,
            reasons: undefined,
        };
    }
};

const UNIQUE_KEY = Date.now().toString();
export const RoomOutcomeForm = ({ onSuccess, detailSession, trade }: Props): JSX.Element => {
    const formConfig = useRoomOutcomeFormConfig();
    const { callSource } = useCallSourceData();

    const [status, setStatus] = useState<DispositionFormStatus>();
    const [diagnoses, setDiagnoses] = useState<Diagnosis[]>([{ code: UNIQUE_KEY, label: '' }]);
    const [outOfScopeReasons, setOutOfScopeReasons] = useState<OutOfScopeReason[]>([
        { code: UNIQUE_KEY, label: '' },
    ]);
    const willOfferRefundOptions = useWillOfferOptions(formConfig?.customFields?.refund);
    const willOfferOutOfScopeOptions = useWillOfferOptions(formConfig?.customFields?.outOfScope);

    const roomId = detailSession.roomId.toString();
    const nonNullOutOfScopeReasons = outOfScopeReasons.filter(r => r.label !== '');
    const callSourceCode = callSource?.code?.toLocaleLowerCase();

    const diagnosisOptions = useMemo(() => {
        if (!formConfig || !Object.keys(formConfig).length) return [];
        setInitialFormValuesFromConfig(
            formConfig,
            willOfferRefundOptions,
            willOfferOutOfScopeOptions,
        );

        if (formConfig.diagnosis?.options?.length > 0) {
            setStatus('diagnosis');
            return buildDiagnosisListOptions(formConfig.diagnosis, log, trade, callSourceCode);
        } else {
            setStatus('dispositionOnly');
            return [];
        }
    }, [callSourceCode, formConfig, trade, willOfferOutOfScopeOptions, willOfferRefundOptions]);

    const isDoneDisabled = (values: RoomOutcomeFormPayload) => {
        return (
            values.disposition.code === '' ||
            (values.customFields?.refund?.shouldRefund &&
                values.customFields?.refund?.reason === undefined) ||
            (values.customFields?.outOfScope?.isOutOfScopeDiscussion &&
                nonNullOutOfScopeReasons.length === 0)
        );
    };

    const handleSetDiagnoses = (
        callback: (diagnosis: Diagnosis) => Diagnosis,
        newDiagnosis?: Diagnosis,
    ) => {
        if (newDiagnosis) {
            setDiagnoses(diagnoses => [...diagnoses, newDiagnosis]);
            return;
        } else {
            setDiagnoses(diagnoses => diagnoses?.map(d => callback(d)).filter(Boolean));
        }
    };

    useEffect(() => {
        log.info(`RoomOutcomeReport pending. roomId=${roomId} roomOutcomeEvent=report_pending`);
    }, [roomId]);

    return (
        <Formik
            enableReinitialize
            initialValues={initialFormValues}
            validateOnChange={true}
            onSubmit={formValues => {
                const customFields = {
                    training: formValues.customFields?.training,
                    ...(willOfferRefundOptions && {
                        refund: formValues.customFields?.refund,
                    }),
                    ...(willOfferOutOfScopeOptions && {
                        outOfScope: {
                            isOutOfScopeDiscussion:
                                formValues.customFields?.outOfScope?.isOutOfScopeDiscussion,
                            reasons: outOfScopeReasons.filter(r => r.label !== ''),
                        },
                    }),
                };
                const baseRoomOutcomeReport = {
                    status: streem.api.Artifact.Status.STATUS_FULFILLED,
                    diagnoses: formValues.diagnoses.map(d => {
                        // This function ensures the original label from the form config is used and NOT a concatenated version.
                        const match = formConfig.diagnosis.options.find(
                            opt => opt.entry.code === d.code,
                        );
                        return { code: match.entry.code, label: match.entry.label };
                    }),
                    disposition: formValues.disposition,
                    customFields: customFields,
                };
                const protobufRoomOutcomeReport =
                    convertRoomOutcomePayloadToProtobuf(baseRoomOutcomeReport);
                detailSession.personalizations.addRoomOutcomeReport(protobufRoomOutcomeReport);
                onSuccess();
            }}
        >
            {({ values }) => {
                return (
                    <Column
                        style={{
                            minHeight: '650px',
                            minWidth: '500px',
                            overflow: 'visible',
                            padding: '0 5px 10px 5px',
                        }}
                    >
                        <Form
                            data-testid="room-outcome-form"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                flexGrow: 1,
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter' || e.key === 'Return') {
                                    e.preventDefault();
                                }
                            }}
                        >
                            {status && (
                                <>
                                    <StatusHeading
                                        status={status}
                                        companyCallSourceName={callSource?.name}
                                        assetImageUrl={callSource?.logoUrl}
                                    />
                                    <DetailHeader status={status} />
                                    <Box flexGrow={1}>
                                        {status === 'diagnosis' && (
                                            <DiagnosisDetailSelection
                                                maxDiagnoses={callSource?.maxDiagnoses}
                                                diagnosisOptions={diagnosisOptions}
                                                diagnoses={diagnoses}
                                                handleSetDiagnoses={handleSetDiagnoses}
                                            />
                                        )}
                                        {(status === 'disposition' ||
                                            status === 'dispositionOnly') && (
                                            <DispositionDetailSection
                                                outOfScopeReasons={outOfScopeReasons}
                                                formValues={{
                                                    disposition: values.disposition,
                                                    shouldRefund:
                                                        values.customFields?.refund?.shouldRefund,
                                                    refundReason:
                                                        values.customFields?.refund?.reason,
                                                    training: values.customFields?.training,
                                                    isOutOfScopeDiscussion:
                                                        values.customFields?.outOfScope
                                                            ?.isOutOfScopeDiscussion,
                                                }}
                                                dispositionOptions={formConfig.disposition}
                                                customFieldOptions={formConfig.customFields}
                                                setOutOfScopeReasons={setOutOfScopeReasons}
                                            />
                                        )}
                                    </Box>
                                    <ButtonFooter
                                        disableNext={
                                            diagnoses.filter(d => d.label !== '').length === 0
                                        }
                                        disableDone={isDoneDisabled(values)}
                                        status={status}
                                        diagnoses={diagnoses}
                                        setStatus={setStatus}
                                        setDiagnoses={setDiagnoses}
                                    />
                                </>
                            )}
                        </Form>
                    </Column>
                );
            }}
        </Formik>
    );
};

const DetailHeader = ({ status }: { status: DispositionFormStatus }) => {
    return (
        <Column mt={4} mb={3}>
            <Box mb="20px">
                <AppText size="xlarge" headingFontFamily bold>
                    {`${status === 'diagnosis' ? 'Diagnosis' : 'Disposition'} Detail`}
                </AppText>
            </Box>
            <AppText size="medium">{`Select the ${
                status === 'diagnosis' ? 'Diagnosis' : 'Disposition'
            } that describes the call`}</AppText>
        </Column>
    );
};

const DiagnosisIndicatorGroup = ({ isActive }: { isActive: boolean }) => {
    const textColor = isActive ? undefined : 'grey40';

    return (
        <>
            <DiagnosisIndicatorIcon isActive={isActive} />
            <Box ml={3}>
                <AppText color={textColor} semibold>
                    Diagnosis
                </AppText>
            </Box>
        </>
    );
};

interface StatusHeadingProps {
    status: DispositionFormStatus;
    companyCallSourceName?: string;
    assetImageUrl?: string;
}

const StatusHeading = ({ status, companyCallSourceName, assetImageUrl }: StatusHeadingProps) => {
    const theme = useTheme();
    const { grey10 } = theme.colors;
    return (
        <Box borderBottom={`1px solid ${grey10}`}>
            <Row mb={5} style={{ gap: '16px' }}>
                {assetImageUrl && (
                    <CompanyWatermark src={assetImageUrl} width="2.25rem" height="2.25rem" />
                )}
                <AppText size="large" semibold>
                    {companyCallSourceName || 'Post Call Log'}
                </AppText>
            </Row>
            {status !== 'dispositionOnly' && (
                <Row ml={1} mb="28px">
                    <DiagnosisIndicatorGroup isActive={status === 'diagnosis'} />
                    <Row justifyContent="center" alignItems="center" mx={3}>
                        <Dash />
                    </Row>
                    <DispositionIndicatorGroup isActive={status === 'disposition'} />
                </Row>
            )}
        </Box>
    );
};

const DispositionIndicatorGroup = ({ isActive }: { isActive: boolean }) => {
    const textColor = isActive ? 'black' : 'grey40';
    return (
        <>
            <DispositionIndicatorIcon isActive={isActive} />
            <Box ml={3}>
                <AppText color={textColor} semibold>
                    Disposition
                </AppText>
            </Box>
        </>
    );
};
