import { observer } from 'mobx-react';
import { IFrameModal } from '@streem/ui-react';
import { StreemAPI } from '@streem/api';

import { MessageModal } from '../in_app_announcements/message_modal';
import { useGlobalStore } from '../../hooks/use_global_context';
import log from '../../util/logging/app_logger';
import './survey_form.css';

export const SurveyForm = observer(() => {
    const { uiStore, authStore } = useGlobalStore();
    // record that the user was presented with the streem post-call survey
    const saveSurveyStatus = async () => {
        try {
            await StreemAPI.users.saveStreemSurveyStatus(authStore.userId);
        } catch (e) {
            log.warn(`Failed to save Streem survey status for user ${authStore.userId}: `, e);
        }
    };

    if (uiStore.multiSurveyPromptVisible) {
        return (
            <MessageModal
                header="Feedback"
                mainMessage="Following are two surveys which we’d love for you to fill out. Your feedback helps us make our products better!"
                closeModal={async () => {
                    await saveSurveyStatus();
                    uiStore.clearSurveys();
                }}
                submitText="Get Started"
                isModalOpen
                submit={() => {
                    uiStore.setMultiSurveyPrompt(false);
                }}
                testId="multi-survey-prompt"
            />
        );
    } else if (uiStore.streemSurveyUrl) {
        return (
            <IFrameModal
                className={'survey-form'}
                src={uiStore.streemSurveyUrl.href}
                isOpen
                onOpen={async () => {
                    await saveSurveyStatus();
                }}
                onClose={() => {
                    uiStore.clearSurveys();
                }}
                submit={async () => {
                    uiStore.clearStreemSurveyUrl();
                }}
                submitText={uiStore.companySurveyUrl ? 'Next' : 'Close'}
            />
        );
    } else if (uiStore.companySurveyUrl) {
        return (
            <IFrameModal
                className={'survey-form'}
                src={uiStore.companySurveyUrl.href}
                isOpen
                onClose={() => {
                    uiStore.clearSurveys();
                }}
            />
        );
    } else {
        return <></>;
    }
});
