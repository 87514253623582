import { Company } from '../../types/project.types';
import {
    ListRowFeaturedCell,
    Column,
    AppIcon,
    TableDataRecord,
    LoadingOverlay,
} from '@streem/ui-react';
import { useObserver } from 'mobx-react';
import { displayableSettings } from '../../stores/company_settings_store';
import { useCompanySettings } from '../../hooks/company_settings_hooks';
import { useGetCompanyStore } from '../../hooks/detail_store_hooks';
import { useGlobalStore } from '../../hooks/use_global_context';
import { useKonamiCode } from '../../hooks/use_konami_code';
import { SettingField } from '../../components/companies/settings/setting_field';
import { SettingRow } from '../../components/companies/settings/settings_page.styles';
import { CompanyNameField } from '../../components/companies/settings/company_name_field.';
import { CompanyOwnerDropdown } from '../../components/companies/settings/company_owner_dropdown';
import DeveloperSettingsPanel from './developer_settings_panel';
import { Setting } from '../../util/company_settings';
import { PageColumn } from '../page_column';

interface Props {
    company?: Company;
}

type SettingRow = Setting & TableDataRecord;

const SettingsList: React.FC<Props> = () => {
    const { authStore } = useGlobalStore();
    const companyStore = useGetCompanyStore();
    const companySettingsStore = useCompanySettings();
    const enteredKonamiCode = useKonamiCode();
    const showDevSettings = window.location.pathname.endsWith('/settings/dev') || enteredKonamiCode;
    const { showAnonymousShareEnabled, showGpsEnabled } = companySettingsStore;

    return useObserver(() => {
        if (!companySettingsStore || !companySettingsStore.settings) {
            return null;
        }

        if (companySettingsStore.loading || companyStore.loading) {
            return <LoadingOverlay />;
        }

        const settingsWithKeys: SettingRow[] = companySettingsStore.settings
            .filter(s => s.superAdmin !== true || (s.superAdmin === true && authStore.isSuperAdmin))
            .map(setting => {
                const replace = new RegExp('#COMPANY', 'gi');

                setting.description = (
                    displayableSettings[setting.name].description as string
                ).replace(replace, companyStore.result?.name || '');

                return { ...setting, key: setting.name! };
            });

        return (
            <PageColumn dataTestId="settings-list">
                <SettingRow data-testid="company-name-row">
                    <ListRowFeaturedCell
                        headline="Company Name"
                        subHeadline="This will display on invitations to customers."
                        marginRight="auto"
                        paddingRight="16px"
                    />
                    <CompanyNameField
                        name={companyStore.result?.name || ''}
                        code={companyStore.result?.code || ''}
                        onSave={() => {
                            companyStore.refresh();
                        }}
                        width="40%"
                    />
                </SettingRow>
                {authStore.isSuperAdmin && (
                    <SettingRow data-testid="company-owner-row">
                        <Column marginRight={'5px'} flexShrink={0}>
                            <AppIcon name="SuperAdminIcon" />
                        </Column>

                        <ListRowFeaturedCell
                            headline="Company Owner"
                            subHeadline={`This is the main company contact between ${
                                companyStore.result?.name || 'your company'
                            } and Streem.`}
                            marginRight="auto"
                            paddingRight="16px"
                        />
                        <CompanyOwnerDropdown companyCode={companyStore.result?.code || ''} />
                    </SettingRow>
                )}
                {settingsWithKeys
                    .filter(setting =>
                        setting.name != 'ANONYMOUS_SHARE_ENABLED'
                            ? true
                            : authStore.isSuperAdmin || showAnonymousShareEnabled,
                    )
                    .filter(setting =>
                        setting.name != 'GPS_ENABLED'
                            ? true
                            : authStore.isSuperAdmin || showGpsEnabled,
                    )
                    .map(setting => {
                        if (
                            (setting.name === 'ANONYMOUS_SHARE_ENABLED' &&
                                !showAnonymousShareEnabled) ||
                            (setting.name === 'GPS_ENABLED' && !showGpsEnabled)
                        ) {
                            setting.superAdmin = true;
                        }

                        return setting;
                    })
                    .map(setting => (
                        <SettingRow
                            key={setting.key}
                            data-testid={`${setting.name.toLowerCase()}-row`}
                        >
                            {setting.superAdmin && (
                                <Column marginRight={'5px'} flexShrink={0}>
                                    <AppIcon name="SuperAdminIcon" />
                                </Column>
                            )}
                            <ListRowFeaturedCell
                                headline={setting.label}
                                subHeadline={setting.description}
                                marginRight="auto"
                                paddingRight="16px"
                                testid={setting.testId}
                            />
                            <SettingField setting={setting} minWidth="40px" />
                        </SettingRow>
                    ))}
                {authStore.isSuperAdmin &&
                    showDevSettings &&
                    companySettingsStore.developerSettings && (
                        <DeveloperSettingsPanel
                            companyName={companyStore.result?.name}
                            settings={companySettingsStore.developerSettings}
                        />
                    )}
            </PageColumn>
        );
    });
};

export default SettingsList;
