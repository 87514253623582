/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiRoom,
    StreemApiRoomFromJSON,
    StreemApiRoomFromJSONTyped,
    StreemApiRoomToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiEventsRoomUpdated
 */
export interface StreemApiEventsRoomUpdated {
    /**
     * 
     * @type {StreemApiRoom}
     * @memberof StreemApiEventsRoomUpdated
     */
    previousRoom?: StreemApiRoom;
    /**
     * 
     * @type {StreemApiRoom}
     * @memberof StreemApiEventsRoomUpdated
     */
    updatedRoom?: StreemApiRoom;
}

export function StreemApiEventsRoomUpdatedFromJSON(json: any): StreemApiEventsRoomUpdated {
    return StreemApiEventsRoomUpdatedFromJSONTyped(json, false);
}

export function StreemApiEventsRoomUpdatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiEventsRoomUpdated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'previousRoom': !exists(json, 'previous_room') ? undefined : StreemApiRoomFromJSON(json['previous_room']),
        'updatedRoom': !exists(json, 'updated_room') ? undefined : StreemApiRoomFromJSON(json['updated_room']),
    };
}

export function StreemApiEventsRoomUpdatedToJSON(value?: StreemApiEventsRoomUpdated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'previous_room': StreemApiRoomToJSON(value.previousRoom),
        'updated_room': StreemApiRoomToJSON(value.updatedRoom),
    };
}


