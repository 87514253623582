import { Box, Column, Row, styled } from '@streem/ui-react';

export const NameEntry = styled(Row)({});

export const EmailEntry = styled(Column)({});

export const RoleEntry = styled(Box)(({ theme }) => ({
    marginBottom: theme.space[3],
}));

export const TitleEntry = styled(Box)({});
