import { APITypes } from '@streem/api';
import { parsePhoneNumber } from './parse_phone_number';
import { CallLogRow } from '../components/calls/call_log_columns';
import {
    Theme,
    IconNames
} from '@streem/ui-react';

const inviteIcons = {
    onsite: 'HomeIcon' as const,
    phone: 'SMSIcon' as const,
    link: 'LinkIcon' as const,
};

/**
 * Determines if a call log entry has "ended"
 * @param callLogEntry
 */
export const callHasEnded = (callLogEntry: APITypes.StreemApiCallLogEntry): boolean =>
    callLogEntry.callStatus === 'STATUS_CANCELED' ||
    callLogEntry.callStatus === 'STATUS_REJECTED' ||
    callLogEntry.callStatus === 'STATUS_TIMED_OUT' ||
    !!callLogEntry.endTime;

/**
 * Extracts the "toUser" and "fromUser" from a call log entry. Uses the first invitation,
 * then falls back to the onsite check, and returns empty objects if that fails.
 * @param callLogEntry
 */
export const getToFromUsers = (callLogEntry: APITypes.StreemApiCallLogEntry) => {
    const invitations = callLogEntry.invitations || [];
    const callLogRoomInvitation = invitations.length > 0 ? invitations[0] : {};

    let onsiteParticipant;
    if (callLogEntry.onsite && callLogEntry.participants) {
        onsiteParticipant = callLogEntry.participants.find(participant => participant);
    }

    const fromUser = callLogRoomInvitation.fromUser || onsiteParticipant || {};
    const toUser = callLogRoomInvitation.toUser || {};

    const customer = onsiteParticipant ?? fromUser;
    const expert = onsiteParticipant ?? toUser;

    return {
        fromUser,
        toUser,
        customer,
        expert,
    };
};

/**
 * Is there a pending video recording
 * @param callLogEntry
 */
export const hasPendingRecording = (callLogEntry: APITypes.StreemApiCallLogEntry): boolean => {
    const hasRecordingTrack = !!callLogEntry?.artifactCounts?.recordingTrack;
    const hasRecording = !!callLogEntry?.artifactCounts?.recording;

    return hasRecordingTrack && !hasRecording;
};

/**
 * Returns a tuple of the type of invite used to start a Streem call and the title used for alt text for that invite type
 * @param callLogEntry
 */
type InviteType = 'onsite' | 'phone' | 'link';
type InviteTypeTitle = 'Onsite' | 'SMS' | 'Link';

export const getInviteType = (
    callLogEntry: APITypes.StreemApiCallLogEntry,
): [InviteType, InviteTypeTitle] => {
    const { onsite } = callLogEntry;
    if (onsite) {
        return ['onsite', 'Onsite'];
    }
    const { customer } = getToFromUsers(callLogEntry);
    // We're not currently setting the customer phone so checking to see if externalUserId is a phone number
    return typeof customer.phone === 'string' ||
        parsePhoneNumber(customer?.externalUserId)?.parsedPhone
        ? ['phone', 'SMS']
        : ['link', 'Link'];
};

/**
 * Returns a list of call log entries filtered by the reference ID that
 * matches a given integration ID
 * @param integrationId
 * @param callLogEntries
 */
export const filterCallLogEntriesByRefIdMatchingIntegrationId = (
    integrationId: string,
    callLogEntries: APITypes.StreemApiCallLogEntry[],
): APITypes.StreemApiCallLogEntry[] => {
    if (!integrationId) return [];

    /* STREEM-15109 Note: this behavior is a preliminary step to having our users switch from using a refId
     * to using an integrationId until backend support for this feature is implemented.
     * For the time being, these call log entries should be filtered down to those that have an integration ID
     * that matches a reference ID retrieved from the backend. Update after refId sunset grace period has ended.
     */
    return callLogEntries.filter(row => {
        const hasRefIdMatch =
            row.referenceId && row.referenceId.toLowerCase() === integrationId.toLowerCase();
        const hasIntegrationIdMatch =
            row.integrationId && row.integrationId.toLowerCase() === integrationId.toLowerCase();
        return hasRefIdMatch || hasIntegrationIdMatch;
    });
};

/**
 * Returns a list of Call Log Rows that use the room sid as a unique key value (for rendering)
 * @param callLogEntries
 */
export const mapUniqueKeyForCallLogEntries = (
    callLogEntries: APITypes.StreemApiCallLogEntry[],
): CallLogRow[] => {
    return callLogEntries.map(callLogEntry => ({
        key: callLogEntry.roomSid!,
        ...callLogEntry,
    }));
};

/* Filter Call Log Entries by integration ID that matches reference ID
 * and convert to a Call Log Rows by adding a unique key (roomSid) that
 * will be used for rendering
 * @param integrationId
 * @param callLogEntries
 */
export const convertCallLogEntriesToEmbedCallLogRows = (
    integrationId: string,
    callLogEntries: APITypes.StreemApiCallLogEntry[],
): CallLogRow[] => {
    return mapUniqueKeyForCallLogEntries(
        filterCallLogEntriesByRefIdMatchingIntegrationId(integrationId, callLogEntries),
    );
};

/* This functions determines what type of title, and icon to be used based on various call statuses
* */
export const getIconAvatarDetails = (row: CallLogRow, customer: APITypes.StreemApiCallLogParticipant,
                              theme: typeof Theme): {title: string, icon: IconNames, color: string} => {
    if(!customer) {
        return { title: '', icon: 'HelpIcon', color: theme.colors.darkBlue};
    }
    if (row.callStatus === 'STATUS_SUCCESSFUL') {
        const [inviteType, title] = getInviteType(row);
        return { title: title, icon: inviteIcons[inviteType], color: theme.colors.grey90};
    }
    let title = '';
    if (row.callStatus === 'STATUS_CANCELED') {
        title = 'Missed Call'
    }
    else if (row.callStatus === 'STATUS_REJECTED'){
        title = "Call rejected by Expert"
    }
    else if (row.callStatus === 'STATUS_TIMED_OUT'){
        title="Call timed out"
    }
    return { title: title, icon: 'MissedCallIcon', color: theme.colors.red50}
}
