import { datadogRum } from '@datadog/browser-rum';
import { LoginIdentityUser } from '@streem/auth';
import { getPermissionSettings } from './browser_notification_settings';
import config from '../config';

export function initializeDatadogRum(): string | undefined {
    if (config.datadogRumEnabled) {
        // Not using app_logger here, since that would make a circular dependency
        console.log('[datadog] Initializing...');

        datadogRum.init({
            applicationId: config.datadogApplicationId,
            clientToken: config.datadogClientToken,
            site: 'datadoghq.com',
            service: 'streem-web-app-ga', // must match project-data datadogService for sourcemaps
            env: config.reactAppEnv,
            sampleRate: 100,
            trackInteractions: true,
            allowedTracingOrigins: [/https:\/\/.*\.streem\.cloud/],
            useSecureSessionCookie: true,
            useCrossSiteSessionCookie: true,
            trackSessionAcrossSubdomains: true,
            version: config.buildTag,
        });

        // eslint-disable-next-line camelcase
        return datadogRum.getInternalContext()?.session_id;
    }
}

export function addUserToDatadogRumSession({ id }: LoginIdentityUser) {
    if (config.datadogRumEnabled) {
        datadogRum.setUser({ id });
    }
}

export function addRegionCodeToDatadogRumSession(regionCode: string) {
    if (config.datadogRumEnabled) {
        datadogRum.addRumGlobalContext('region_code', regionCode);
    }
}

export function addCompanyCodeToDatadogRumSession(companyCode: string) {
    if (config.datadogRumEnabled) {
        datadogRum.addRumGlobalContext('company_code', companyCode);
    }
}

export function addBrowserNotificationPermissionToDatadogRumSession(updatedPermission?: string) {
    if (config.datadogRumEnabled) {
        // get the initial permission setting from the browser on start of auth flow see: auth_store.ts
        const initialPermissionSetting = getPermissionSettings();
        // if the user updates their permission, we want to update the datadog session.
        // If they don't update it, we want to keep the initial value
        const permissionStatus = updatedPermission || initialPermissionSetting;
        datadogRum.addRumGlobalContext('permission_status', permissionStatus);
    }
}
