import { useEffect } from 'react';
import { useGlobalStore } from '../hooks/use_global_context';
import { ToastTypes, Column, AppText } from '@streem/ui-react';
import { v4 as uuidv4 } from 'uuid';
import { useObservable } from '@streem/sdk-react';
import { UserSession } from '@streem/sdk-core';
import config from '../config';

interface CurrentUserSession {
    currentUserSession: UserSession;
}

export function DeviceUpdateNotification({ currentUserSession }: CurrentUserSession) {
    const { uiStore } = useGlobalStore();
    const [userDeviceUpdate] = useObservable(currentUserSession.lastIdentifiedDevice);

    const toastContent = (
        <Column>
            <AppText as="p" bold>
                We see your account is being used in another location.
            </AppText>
            <AppText as="p">
                If this wasn't you, you may want to{' '}
                <a href={config.streemSupportUrl} target="_blank" rel="noopener noreferrer">
                    contact support
                </a>
                .
            </AppText>
        </Column>
    );

    useEffect(() => {
        if (userDeviceUpdate) {
            uiStore.addToast({
                content: toastContent,
                id: `lastIdentifiedDevice-${userDeviceUpdate.deviceSid || uuidv4()}`,
                flavor: ToastTypes.WARNING,
            });
        }
        // Adding the uiStore as a dependency here is unnecessary and blows up the app
        // eslint-disable-next-line
    }, [userDeviceUpdate]);

    return <></>;
}
