import { Recording } from '@streem/sdk-core';
import { WallItem } from 'streem-sdk-protobuf';

/**
 * Is there a recording preview while the recording is being processed (not ready)
 *
 * @param recArtifacts List of artifacts of type RECORDING
 */
export function isPreviewReady(recArtifacts: Recording[]) {
    return recArtifacts.some(
        artifact =>
            artifact.status !== WallItem.Artifact.Status.FULFILLED &&
            artifact.recording?.previewDownloadUrl,
    );
}

export function isRecordingPending(recArtifacts: Recording[]) {
    return recArtifacts.some(artifact => artifact.status !== WallItem.Artifact.Status.FULFILLED);
}

/**
 * Is there a recording marked as fulfilled with a ready downloadUrl
 *
 * @param recArtifacts List of artifacts of type RECORDING
 */
export function isRecordingReady(recArtifacts: Recording[]) {
    return recArtifacts.some(
        artifact =>
            artifact.status === WallItem.Artifact.Status.FULFILLED &&
            artifact.recording?.downloadUrl,
    );
}
