import { useEffect, useRef, useCallback } from 'react';
import { getDocumentHash } from '@streem/logger';
import { RoomId } from '@streem/domain-id';

interface Props {
    roomId?: RoomId;
}

export function VersionCheck({ roomId }: Props): JSX.Element {
    const reloadTimeout = useRef(0);

    const checkUpdatesInCode = async (): Promise<{ gitRef: string } | undefined> => {
        try {
            const response = await fetch(`/buildInfo.json?hash=${new Date().getTime()}`);
            return await response.json();
        } catch (e) {
            return;
        }
    };

    const checkReloadPage = useCallback(async () => {
        const buildInfo = await checkUpdatesInCode();
        const currentHash = getDocumentHash();
        const foundHash = buildInfo?.gitRef || '';
        const reload = foundHash.length !== 0 && foundHash !== currentHash;
        const tenMinutes = 10 * 60 * 1000;
        if (roomId && reload) {
            clearInterval(reloadTimeout.current);
            reloadTimeout.current = window.setInterval(checkReloadPage, tenMinutes);
        } else if (!roomId && reload) {
            window.location.reload();
        }
    }, [roomId, reloadTimeout]);

    useEffect(() => {
        const oneDay = 24 * 60 * 60 * 1000;
        reloadTimeout.current = window.setInterval(checkReloadPage, oneDay);
        return () => clearInterval(reloadTimeout.current);
    }, [checkReloadPage, reloadTimeout]);

    return <></>;
}
