import { APITypes } from '@streem/api';
import { TableDataRecord, TableColumn } from '@streem/ui-react';
import { NameColumn } from '../../companies/name_column';
import { StatusColumn } from '../../companies/status_column';
import { getHighestUserGroup } from '../../../util/roles';

export type AgentRow = APITypes.StreemApiUser & TableDataRecord;

export interface AgentColumnProps {
    refresh?: () => void;
    handleCellClickCallback?: (row: AgentRow) => void;
}

function nameColumn({ handleCellClickCallback }: AgentColumnProps): TableColumn<AgentRow> {
    return {
        key: 'name',
        name: 'Team Member',
        onCellClick: record => !!handleCellClickCallback && handleCellClickCallback(record),
        render: (row: AgentRow) => <NameColumn row={row} />,
        sortable: true,
    };
}

function roleColumn(): TableColumn<AgentRow> {
    return {
        key: 'roles',
        name: 'Role',
        render: (row: AgentRow) => getHighestUserGroup(row.roles || []).label,
        sortable: false,
    };
}

function statusColumn(): TableColumn<AgentRow> {
    return {
        key: 'active',
        name: 'Status',
        render: (row: AgentRow) => <StatusColumn row={row} />,
        sortable: true,
    };
}
export const columnBuilder = {
    nameColumn,
    roleColumn,
    statusColumn,
};
