import { FC } from 'react';
import { useObserver } from 'mobx-react';
import { Column } from '@streem/ui-react';

export const PageColumn: FC<{ dataTestId?: string; style?: {} }> = ({
    dataTestId,
    style,
    children,
}) => {
    return useObserver(() => (
        <Column data-testid={dataTestId} width="100%" {...style} height="100%">
            {children}
        </Column>
    ));
};
