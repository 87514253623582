import { useCallback, useState } from 'react';
import { LookerEmbedSDK } from '@looker/embed-sdk';
import { useActiveCompanyCode } from './use_active_company_code';
import config from '../config';
import appLogger from '../util/logging/app_logger';

const log = appLogger.extend('lookerDashboard');

const logDashboardLoadTime = (startTime: number) => {
    const endTime = performance.now();
    const timeElapsed = endTime - startTime;
    log.info(`Looker dashboard loaded duration=${timeElapsed} ms`);
};

export const useLookerDashboard = () => {
    const [dashboardLoaded, setDashboardLoaded] = useState(false);
    const [dashboardError, setDashboardError] = useState(false);
    const companyCode = useActiveCompanyCode();

    const initCreateLookerDashboard = useCallback(
        (ref: React.MutableRefObject<HTMLDivElement>, time: number) => {
            LookerEmbedSDK.createDashboardWithId(config.lookerDashboardId)
                .withDialogScroll(true)
                .withDynamicIFrameHeight(true)
                .withScrollMonitor(true)
                .appendTo(ref.current)
                .on('session:status', sessionStatus => {
                    if (sessionStatus.expired) {
                        ref.current.innerHTML = '';
                        setDashboardLoaded(false);
                        initCreateLookerDashboard(ref, time);
                        return;
                    }
                    if (sessionStatus.interrupted && sessionStatus.recoverable === false) {
                        ref.current.innerHTML = '';
                        setDashboardLoaded(false);
                        initCreateLookerDashboard(ref, time);
                        return;
                    }
                })
                .build()
                .connect()
                .then(() => {
                    setDashboardLoaded(true);
                    logDashboardLoadTime(time);
                })
                .catch(error => {
                    log.error(
                        `Error creating Looker dashboard for: ${companyCode} with id: ${config.lookerDashboardId}`,
                        error,
                    );
                    setDashboardError(true);
                });
        },
        [companyCode],
    );
    return { dashboardLoaded, dashboardError, initCreateLookerDashboard };
};
