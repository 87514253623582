import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { StreemAPI } from '@streem/api';
import { ToastTypes } from '@streem/ui-react';
import { useActiveCompanyCode } from '../../hooks/use_active_company_code';
import { createGetCompanyStore } from '../../hooks/detail_store_hooks';
import {
    ListGroupUsersProvider,
    createListGroupUsersStore,
    createListGroupMemberMetricsStore,
    ListGroupMemberMetricsProvider,
} from '../../hooks/list_store_hooks';
import { APINotFound } from '../../components/routing/error_handlers';
import { useGlobalStore } from '../../hooks/use_global_context';
import { PageColumn } from '../page_column';
import AddGroupMemberFormModal from '../../forms/edit_group_form';
import { GroupsPageHeader } from '../../components/page_header/groups_page_header';
import { mapRoomMetricsForGroup, RoomMetricsAggregateBy } from '../../util/metrics';
import appLogger from '../../util/logging/app_logger';
import GroupMetricsUsersList from '../../components/users/groups/group_metrics_details_view';

const log = appLogger.extend('Groups Details Page');

export const GroupMetricsDetailsPage: FC = observer(() => {
    const [showAddMemberModal, setShowAddMemberModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const [isSuccessfullyLoaded, setIsSuccessfullyLoaded] = useState(false);
    const [timeRange, setTimeRange] = useState(null);
    const [roomMetrics, setRoomMetrics] = useState(null);

    const { uiStore } = useGlobalStore();
    const companyCode = useActiveCompanyCode();
    const companyStore = createGetCompanyStore();
    const groupUsersStore = createListGroupUsersStore();
    const groupMemberMetricsStore = createListGroupMemberMetricsStore();

    const { groupName } = useParams<any>();

    useEffect(() => {
        const promises = [
            companyStore.fetch(companyCode),
            groupUsersStore.fetch(companyCode, groupName),
        ];
        return () => promises.forEach(p => p.cancel());
    }, [companyStore, groupUsersStore, companyCode, groupName]);

    useEffect(() => {
        if (companyStore.result && companyStore.result?.sid) {
            const promise = groupMemberMetricsStore.fetchFirstPage({
                companySid: companyStore.result?.sid,
                streemApiAggregateRoomMetricsForCompanyRequest: {
                    filter: {
                        groupName: groupName,
                    },
                    aggregateBy: RoomMetricsAggregateBy.USERSID,
                },
            });
            return () => promise.cancel();
        }
    }, [companyStore.result, companyStore.result?.sid, groupName, groupMemberMetricsStore]);

    useEffect(() => {
        setIsSuccessfullyLoaded(false);
        const fetchGroupMetrics = async () => {
            if (companyStore.result && companyStore.result?.sid && groupName) {
                try {
                    const companySid = companyStore.result.sid;
                    const aggregateRoomMetricsForCompanyResponse =
                        await StreemAPI.analytics.aggregateRoomMetricsForCompany(companySid, {
                            filter: {
                                groupName: groupName,
                            },
                            aggregateBy: RoomMetricsAggregateBy.GROUPNAME,
                        });

                    const { roomMetrics, timeRange } = mapRoomMetricsForGroup(
                        aggregateRoomMetricsForCompanyResponse,
                        groupName,
                    );
                    setTimeRange(timeRange);
                    setRoomMetrics(roomMetrics);
                    setIsSuccessfullyLoaded(true);
                } catch (e) {
                    throw new Error(
                        `There was an error fetching metrics for groupName: ${groupName} in companySid: ${companyStore.result.sid}: ${e}`,
                    );
                }
            }
        };

        fetchGroupMetrics().catch(e => log.error(e));
    }, [companyStore.result, companyStore.result?.sid, groupName]);

    const handleAddMember = async () => {
        try {
            await StreemAPI.groups.addGroupUser(companyCode, groupName, selectedUser);
            groupUsersStore.refresh();
            uiStore.addToast({
                content: `Successfully added new group member`,
                flavor: ToastTypes.SUCCESS,
                id: `group-member-added-${selectedUser}`,
            });
            setShowAddMemberModal(false);
        } catch (e) {
            log.error('Error adding group member', e);
            uiStore.addToast({
                content: `Error adding new group member: ${e}`,
                flavor: ToastTypes.ERROR,
                id: `group-member-added-${selectedUser}`,
            });
        }
    };

    const closeModal = () => setShowAddMemberModal(s => !s);

    return (
        <ListGroupUsersProvider value={groupUsersStore}>
            <ListGroupMemberMetricsProvider value={groupMemberMetricsStore}>
                <APINotFound stores={[companyStore, groupUsersStore, groupMemberMetricsStore]} />
                <AddGroupMemberFormModal
                    isOpen={showAddMemberModal}
                    closeModal={() => {
                        setSelectedUser('');
                        setShowAddMemberModal(false);
                    }}
                    handleSubmit={handleAddMember}
                    setSelectedUser={setSelectedUser}
                    selectedUser={selectedUser}
                    groupName={groupName}
                />
                <PageColumn
                    dataTestId="group-metric-details-page"
                    style={{
                        paddingLeft: '16px',
                        paddingRight: '16px',
                    }}
                >
                    <GroupsPageHeader
                        title={`${groupName} Group`}
                        contentSubheader="Members"
                        iconName="AddIcon"
                        iconContext={'Add New Member'}
                        handleClick={closeModal}
                        timeRange={timeRange}
                        roomMetrics={roomMetrics}
                        isSuccessfullyLoaded={isSuccessfullyLoaded}
                    />
                    <GroupMetricsUsersList groupName={groupName} />
                </PageColumn>
            </ListGroupMemberMetricsProvider>
        </ListGroupUsersProvider>
    );
});
