import { createContext, useContext, useMemo } from 'react';

/**
 * localStoreFactory is a factory method for generating strongly typed store builders, provider components, and use-hooks from a given Store and it's constructor arguments.
 * @param name A developer-friendly name for debugging the store in React context.
 * @param createLocalStore A method that will create the local store when invoked. This can take any number of args and will be wrapped with useMemo to retain value through re-renders.
 */
export function localStoreHookFactory<B extends (...args: any[]) => any>(
    name: string,
    createLocalStore: B,
) {
    const context = createContext<ReturnType<B> | undefined>(undefined);
    context.displayName = name;
    const LocalStoreProvider = context.Provider;
    function useLocalStore() {
        const store = useContext(context);
        if (!store) {
            throw new Error(`Unable to find a <${name}Provider /> in the parent tree.`);
        }
        return store;
    }
    function createLocalStoreMemo(...args: Parameters<B>): ReturnType<B> {
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
        return useMemo(() => createLocalStore(...args), args);
    }
    return [createLocalStoreMemo, LocalStoreProvider, useLocalStore] as const;
}
