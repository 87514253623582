import { MouseEventHandler } from 'react';
import { AppIcon, AppText, styled, Row, useTheme, Box } from '@streem/ui-react';

type IndicatorProps = {
    isActive: boolean;
};

type IndicatorStyleProps = {
    isActive: boolean;
    transparent?: boolean;
};
export const DiagnosisIndicatorIcon = ({ isActive }: IndicatorProps): JSX.Element => {
    const padding = !isActive ? '2px' : '0';
    return (
        <IndicatorIconContainer isActive={isActive} padding={padding}>
            {isActive && <AppText color="white">1</AppText>}
            {!isActive && <AppIcon name="DoneIcon" color="white" />}
        </IndicatorIconContainer>
    );
};

export const DispositionIndicatorIcon = ({ isActive }: IndicatorProps): JSX.Element => {
    const theme = useTheme();
    const border = isActive ? 'none' : `2px solid ${theme.colors.grey30}`;

    return (
        <IndicatorIconContainer border={border} isActive={isActive} transparent={!isActive}>
            {isActive && <AppText color="white">2</AppText>}
        </IndicatorIconContainer>
    );
};

const IndicatorIconContainer = styled(Row)<IndicatorStyleProps>(
    ({ theme, isActive, transparent }) => ({
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colors.white,
        width: '24px',
        height: '24px',
        borderRadius: '12px',
        background: isActive
            ? theme.colors.azure
            : transparent
            ? theme.colors.transparent
            : theme.colors.grey30,
    }),
);

export const Dash = styled(Box)(({ theme }) => ({
    borderTop: `2px solid ${theme.colors.grey30}`,
    width: '30px',
}));

const BaseInput = styled.input`
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 2px solid ${props => props.theme.colors.grey70};
    text-decoration: none;
    margin-right: 8px;
    margin-left: 8px;
    position: relative;
    :checked {
        border: none;
    }
    :checked::before {
        position: absolute;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        content: '';
        background-color: ${props => props.theme.colors.azure};
    }
    :checked::after {
        content: url('data:image/svg+xml; utf8, <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0711 0.179183C14.5244 0.494573 14.6363 1.11778 14.3209 1.57115L6.32091 13.0712C6.1518 13.3142 5.88364 13.4699 5.58868 13.4961C5.29372 13.5224 5.00229 13.4166 4.79289 13.2072L0.292893 8.7072C-0.0976311 8.31667 -0.0976311 7.68351 0.292893 7.29298C0.683417 6.90246 1.31658 6.90246 1.70711 7.29298L5.36178 10.9477L12.6791 0.429024C12.9945 -0.0243495 13.6177 -0.136207 14.0711 0.179183Z" fill="white"/></svg>');
        display: block;
        position: absolute;
        left: 5px;
        top: 5px;
    }
    :hover {
        cursor: pointer;
    }
`;

interface BlueCheckBoxProps {
    id: string;
    description: string;
    handleChange: () => void;
    checked?: boolean;
    dataTestId?: string;
}

export const BlueCheckBox = ({
    id,
    description,
    handleChange,
    checked,
    dataTestId,
}: BlueCheckBoxProps): JSX.Element => {
    return (
        <Row justifyContent="center" alignItems="center" my={3}>
            <BaseInput
                tabIndex={0}
                type="checkbox"
                id={id}
                data-testid={dataTestId}
                checked={checked}
                onChange={() => handleChange()}
            />
            <label htmlFor={id}>
                <AppText semibold>{description}</AppText>
            </label>
        </Row>
    );
};

const BaseButton = styled.button`
    width: 24px;
    height: 24px;
    border: none;
    padding: 0;
    margin: 0;
    border-radius: 12px;
    background: ${props => props.theme.colors.azure};
    :hover {
        cursor: pointer;
    }
`;

export const AddButton = ({
    onClick,
    label,
}: {
    onClick: MouseEventHandler<HTMLButtonElement>;
    label: string;
}): JSX.Element => {
    return (
        <BaseButton data-testid={`add-${label}-button`} type="button" onClick={onClick}>
            <AppIcon name="AddIcon" size="small" color="white" />
        </BaseButton>
    );
};
