import { getCompanyCodeFromUrl, getCompanyRegistration } from '@streem/toolbox';
import { useState, useEffect } from 'react';

import config from '../config';
import appLogger from '../util/logging/app_logger';

const log = appLogger.extend('useGetCompanyRegistration');

export interface UseGetCompanyRegistrationResult {
    error: boolean;
    regionalApiUrl: string;
    regionCode: string;
    wallUrl: string;
}

export const useGetCompanyRegistration = (): UseGetCompanyRegistrationResult => {
    const [error, setError] = useState(false);
    const [regionalApiUrl, setRegionalApiUrl] = useState(null);
    const [regionCode, setRegionCode] = useState(null);
    const [wallUrl, setWallUrl] = useState(null);

    useEffect(() => {
        async function fetchCompanyRegistration(companyCode: string) {
            try {
                const { apiUrl, companyRegistration, wallUrl } = await getCompanyRegistration({
                    companyCode,
                    globalDomain: config.globalDomain,
                });
                setRegionalApiUrl(apiUrl);
                setRegionCode(companyRegistration.regionCode);
                setWallUrl(wallUrl);
            } catch (e) {
                log.error(e);
                setError(true);
            }
        }

        fetchCompanyRegistration(getCompanyCodeFromUrl());
    }, []);

    return { error, regionalApiUrl, regionCode, wallUrl };
};
