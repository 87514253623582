import React, { useEffect, useCallback, useMemo } from 'react';
import { useActiveCompanyCode } from '../../hooks/use_active_company_code';
import { useGlobalStore } from '../../hooks/use_global_context';
import { useObserver } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { styled } from '@streem/ui-react';
import RedirectToWorkspacesPage from './redirect_to_workspaces_page';
import { LogoutSuccessPage } from './logout_success_page';
import {
    SESSION_SDK_TOKEN,
    SESSION_SWAGA_POST_LOGIN_REDIRECT,
    SESSION_SWAGA_POST_LOGOUT_SUCCESS,
} from '../../types/project.types';
import appLogger from '../../util/logging/app_logger';

interface LocationState {
    from?: {
        pathname: string;
        search: string;
    };
}

const log = appLogger.extend('LoginPage');

export const LoginPage: React.FC = () => {
    const { authStore } = useGlobalStore();
    const companyCode = useActiveCompanyCode();
    const authError = useObserver(() => authStore.error);
    const location = useLocation<LocationState | undefined>();

    const logoutSuccess = useMemo(() => {
        const value = localStorage.getItem(SESSION_SWAGA_POST_LOGOUT_SUCCESS);
        localStorage.removeItem(SESSION_SWAGA_POST_LOGOUT_SUCCESS);
        return value === 'true';
    }, []);

    useEffect(() => {
        if (authError === undefined && !logoutSuccess) {
            const path = location.state?.from?.pathname || '/';
            const params = location.state?.from?.search || '';

            window.sessionStorage.setItem(SESSION_SWAGA_POST_LOGIN_REDIRECT, path + params);

            const sdkToken = sessionStorage.getItem(SESSION_SDK_TOKEN);
            if (sdkToken) {
                authStore
                    .loginWithSdkToken(sdkToken, companyCode)
                    .catch(reason => log.error('Error logging in with SDK Token', reason));
            } else {
                authStore
                    .loginWithOauthRedirect(companyCode)
                    .catch(reason => log.error('Error logging in via OAuth redirect', reason));
            }
        }
    }, [authStore, companyCode, authError, location, logoutSuccess]);

    const logInAgainClickHandler = useCallback(() => {
        authStore
            .loginWithOauthRedirect(companyCode)
            .catch(reason => log.error('Error logging in via OAuth redirect', reason));
    }, [authStore, companyCode]);

    return authError ? (
        <RedirectToWorkspacesPage onLogOut={() => authStore.logout()} />
    ) : logoutSuccess ? (
        <LogoutSuccessPage logInAgain={logInAgainClickHandler} />
    ) : null;
};

export const LoginWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    padding: 0 64px;
    justify-items: center;
    align-items: center;
    height: 100vh;

    @media (min-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }
`;
