import { CallLogRow } from './call_log_columns';
import { AppText, Column, IconButton, Sensor, styled, Tooltip } from '@streem/ui-react';
import theme from '@streem/ui-react/lib/styles/theme';
import { FC, MouseEvent, useCallback, useRef, useState } from 'react';
import { recordElementClicked } from '@streem/analytics';

export const DropdownMenuColumn: FC<{
    row: CallLogRow;
    handleRoomArchive: (row: CallLogRow) => void;
    enableDropdown: boolean;
}> = ({ row, handleRoomArchive, enableDropdown }) => {
    const [showDropdownMenu, setShowDropdownMenu] = useState(false);
    const dropdownEllipsisRef = useRef<HTMLDivElement>(null);
    const dropdownButtonId = 'team-call-log-dropdown-button';

    const handleDropdownClick = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            if (!showDropdownMenu) {
                recordElementClicked('three-dot-call-log-menu-opened');
            }
            setShowDropdownMenu(!showDropdownMenu);
        },
        [showDropdownMenu],
    );

    const handleRoomArchiveSelected = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            recordElementClicked('three-dot-menu-deactivate-option-clicked');
            setShowDropdownMenu(false);
            handleRoomArchive(row);
        },
        [handleRoomArchive, row],
    );

    return (
        <>
            <div ref={dropdownEllipsisRef}>
                {enableDropdown ? (
                    <IconButton
                        iconName="VerticalEllipsesIcon"
                        label="Team Call Log Dropdown"
                        data-testid={dropdownButtonId}
                        onClick={handleDropdownClick}
                        fill={theme.colors.azure}
                        hoverFill={theme.colors.azure}
                        background="transparent"
                    />
                ) : (
                    <IconButton
                        iconName="VerticalEllipsesIcon"
                        label="Team Call Log Dropdown"
                        data-testid={dropdownButtonId}
                        fill={theme.colors.grey30}
                        style={{ cursor: 'not-allowed' }}
                    />
                )}
            </div>
            {showDropdownMenu && (
                <Sensor
                    onClickOutside={event => {
                        if (
                            event?.target instanceof Element &&
                            dropdownEllipsisRef?.current?.contains(event.target)
                        )
                            return;

                        setShowDropdownMenu(false);
                    }}
                    onEscape={() => setShowDropdownMenu(false)}
                >
                    <Tooltip
                        message={
                            <DropdownContainer>
                                <MenuOptionButton
                                    onClick={handleRoomArchiveSelected}
                                    data-testid="team-call-log-deactivate-button"
                                >
                                    <AppText>Deactivate Customer</AppText>
                                </MenuOptionButton>
                            </DropdownContainer>
                        }
                        showTooltip={showDropdownMenu}
                        arrowSize="0px"
                        distance={20}
                        offsetValue={-100}
                        placement="bottom"
                        fallbackPlacements={['bottom']}
                        dataTestId="team-call-log-dropdown"
                        padding="0px"
                    />
                </Sensor>
            )}
        </>
    );
};

const DropdownContainer = styled(Column)();

const MenuOptionButton = styled('button')(({ theme }) => ({
    border: 'none',
    padding: '16px',
    textAlign: 'left',
    backgroundColor: 'white',
    cursor: 'pointer',
    ':hover': {
        backgroundColor: theme.colors.blue05,
    },
}));
