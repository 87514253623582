import React from 'react';
import { RouteProps } from 'react-router-dom';
import { Observer, useObserver } from 'mobx-react';
import { CentralizedLoginView } from '../pages/centralized_login_view';
import { useCentralLoginStore } from '../hooks/use_central_login_context';
import { CentralLoginProtectedRoute } from './protected_route_central_login';
import { ev2UrlFromCompanyCode } from '../util/routing';
type Props = RouteProps;

export const CentralLoginBaseRoutes: React.FC<Props> = () => {
    return useObserver(() => {
        return <CentralLoginProtectedRoute path="/" exact={true} component={WorkspacePickerPage} />;
    });
};

const WorkspacePickerPage: React.FC = () => {
    const centralLoginStore = useCentralLoginStore();
    const { authStore, workspacesStore } = centralLoginStore;

    return (
        <Observer>
            {() => {
                if (!workspacesStore.workspaces) {
                    return null;
                }

                /**
                 * Users with only 1 company should
                 * skip the workspace picker screen.
                 */
                if (workspacesStore.workspaces?.length === 1) {
                    const url = ev2UrlFromCompanyCode(workspacesStore.workspaces[0].companyCode);
                    window.location.replace(url);
                    return null;
                }

                return (
                    <CentralizedLoginView
                        user={{
                            name: authStore.userName,
                            avatarUrl: authStore.userAvatarUrl,
                            email: authStore.userEmail,
                        }}
                        companies={workspacesStore.workspaces}
                        onLogOut={() => {
                            authStore.logout();
                        }}
                    />
                );
            }}
        </Observer>
    );
};
