import { FC, useState } from 'react';
import { google, streem } from 'streem-sdk-protobuf';
import { APITypes } from '@streem/api';
import {
    AppText,
    Box,
    Column,
    Flex,
    Header,
    IconButton,
    Row,
    styled,
    Subheader,
    Theme,
} from '@streem/ui-react';
import { BrandLogo, CallDetailItems, MemberInformation, RemSize } from '@streem/sdk-react';
import { useGroupReservationTimer } from './hooks/use_group_reservation_timer';
import { BannerContainer, ReconnectPill, ReservedPill } from './reservation_banner_content';
import { getReservationExpirationMessage } from './util/group_reservation_helpers';

/* The Reservation Banner component is used for companies that have our group reservation
 * features enabled (expert availability and group management) and additionally, have the ahs virtual
 * expert feature flag enabled. It requires that a company call source is available on the
 * reservation as well as the new structured model for call detail (ICallDetail) on the reservation.
 */
export const ReservationBanner: FC<{
    reservationCallDetail: streem.api.ICallDetail | null;
    reservationCallSource: APITypes.StreemApiCallSource;
    expirationTime: google.protobuf.ITimestamp;
    secondsReserved: number;
    isReconnect: boolean;
    groupName: string;
}> = ({
    reservationCallDetail,
    reservationCallSource,
    expirationTime,
    secondsReserved,
    isReconnect,
    groupName,
}) => {
    const [expanded, setExpanded] = useState(true);

    const handleSetExpanded = () => {
        setExpanded(!expanded);
    };

    const minutesToDisplay = useGroupReservationTimer(expirationTime, secondsReserved);

    return (
        <BannerContainer data-testid="reservation-banner-container">
            <BannerHeader
                callSourceName={reservationCallSource.name}
                isReconnect={isReconnect}
                minutesToDisplay={minutesToDisplay}
                expanded={expanded}
                handleSetExpanded={handleSetExpanded}
                logoUrl={reservationCallSource.logoUrl}
                data-testid="banner-header"
            />
            <BannerSubheader
                customerName={reservationCallDetail?.customer?.name}
                data-testid="banner-subheader"
            />
            {expanded && (
                <ExpandedBanner>
                    <BannerContent
                        groupName={groupName}
                        callDetailItems={reservationCallDetail?.items}
                        customer={reservationCallDetail?.customer}
                        data-testid="banner-content"
                    />
                </ExpandedBanner>
            )}
        </BannerContainer>
    );
};

const BannerHeader: FC<{
    callSourceName: string;
    isReconnect: boolean;
    minutesToDisplay: number;
    expanded: boolean;
    handleSetExpanded: () => void;
    logoUrl?: string;
}> = ({ callSourceName, isReconnect, minutesToDisplay, expanded, handleSetExpanded, logoUrl }) => {
    return (
        <Row
            style={{
                height: '2.625rem',
                width: '100%',
                marginBottom: '0.75rem',
                marginTop: '1rem',
            }}
            alignItems="center"
            justifyContent="space-between"
        >
            <Row alignItems="center">
                {logoUrl && <BrandLogo logoUrl={logoUrl} />}
                <Header
                    size="xxlarge"
                    style={{ padding: '0.1875rem 0', marginRight: '0.75rem' }}
                    data-testid="call-source-name"
                >
                    {callSourceName}
                </Header>
                <ReservedPill data-testid="reserved-icon" headingFontFamily bold>
                    Reserved
                </ReservedPill>
                {isReconnect && (
                    <ReconnectPill data-testid="reconnect-icon" headingFontFamily bold>
                        Reconnection
                    </ReconnectPill>
                )}
                <AppText data-testid="expiration-text" medium headingFontFamily semibold>
                    {getReservationExpirationMessage(minutesToDisplay)}
                </AppText>
            </Row>
            <IconButton
                iconName="CaretIcon"
                label="expand"
                fill={Theme.colors.black}
                data-testid="expand-banner-button"
                style={{
                    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.2s ease-in-out',
                }}
                onClick={handleSetExpanded}
            />
        </Row>
    );
};

const BannerSubheader: FC<{
    customerName: string;
}> = ({ customerName = '' }) => {
    return (
        <Row alignItems="baseline">
            <AppText semibold headingFontFamily style={{ color: Theme.colors.grey50 }}>
                Video Chat From&nbsp;
            </AppText>
            <Subheader semibold headingFontFamily data-testid="reservation-banner-customer-name">
                {customerName}
            </Subheader>
        </Row>
    );
};

const BannerContent: FC<{
    groupName: string;
    callDetailItems: APITypes.StreemApiCallDetailItem[];
    customer: streem.api.CallDetail.ICustomer;
}> = ({ groupName, callDetailItems = [], customer = {} }) => {
    const singleRow = callDetailItems.length < 2;

    const BannerContentWrapper = singleRow
        ? CallDetailItemsAndMemberInfoWrapperSingleRow
        : CallDetailItemsAndMemberInfoWrapperMultipleRows;

    return (
        <BannerContentWrapper>
            <CallDetailItems
                trade={groupName}
                items={callDetailItems}
                maxItemsPerRow={4}
                widthPercentage={singleRow ? 50 : 100}
                titleFontSize={Theme.text.size.smallMedium as RemSize}
                itemFontSize={Theme.text.size.medium as RemSize}
                itemTitleFontSize={Theme.text.size.notQuiteAllTheWayMediumLarge as RemSize}
                dataIdPrefix="reservation-banner"
            />
            <MemberInformation
                customer={customer}
                widthPercentage={singleRow ? 50 : 100}
                columnsPerRow={singleRow ? 1 : 3}
                titleFontSize={Theme.text.size.smallMedium as RemSize}
                itemFontSize={Theme.text.size.medium as RemSize}
                dataIdPrefix="reservation-banner"
            />
        </BannerContentWrapper>
    );
};

const ExpandedBanner = styled(Box)({
    width: '100%',
    paddingTop: '0.75rem',
});

const CallDetailItemsAndMemberInfoWrapperSingleRow = styled(Flex)({
    columnGap: '2rem',
    alignItems: 'stretch',
});

const CallDetailItemsAndMemberInfoWrapperMultipleRows = styled(Column)({
    rowGap: '.75rem',
});
