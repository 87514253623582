import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';

interface ParsedPhoneNumber {
    phone: string;
    countryCode: string;
    E164: string;
    parsedPhone: PhoneNumber | undefined;
}

/**
 * Formats a "raw" phone number received from the backend ("+19995551234").
 * @param rawPhone
 * @returns ParsedPhoneNumber
 */
export function parsePhoneNumber(rawPhone = '', urlCallingCode = ''): ParsedPhoneNumber {
    const parsed = parsePhoneNumberFromString(urlCallingCode + rawPhone);
    let callingCode;

    // use the calling code parsed from params if it's available
    if (urlCallingCode) {
        // documentation and examples i've seen all have a '+' before calling code,
        // but we dont need it at this point
        callingCode = urlCallingCode.replace('+', '');
    }

    if (parsed) {
        return {
            phone: parsed.nationalNumber as string,
            countryCode: parsed.countryCallingCode as string,
            E164: parsed.format('E.164'),
            parsedPhone: parsed,
        };
    } else {
        return {
            phone: rawPhone,
            countryCode: callingCode || '1',
            E164: '',
            parsedPhone: undefined,
        };
    }
}
