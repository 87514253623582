import { FC, SyntheticEvent, useState, useRef, DragEvent } from 'react';
import {
    useTheme,
    LogoPreview,
    Column,
    Row,
    Button,
    IconButton,
    AppText,
    Label,
} from '@streem/ui-react';
import { StreemAPI, CreateCompanyAssetOperationType } from '@streem/api';
import { useActiveCompanyCode } from '../../../hooks/use_active_company_code';
import { useCompanySettings } from '../../../hooks/company_settings_hooks';

interface Props {
    defaultLogo: any;
    customLogo: any;
    label: string;
    testid: string;
    type: CreateCompanyAssetOperationType;
}

const MAX_IMAGE_SIZE = 2000000;

const UPLOAD_IMAGE_ERRORS = {
    UploadImageFailedTryAgain: 'Failed to upload image. Please try again.',
    UnsupportedFileType: 'Unsupported file type',
    UploadFailedTryAgain: 'Upload failed. Please try again.',
    UploadFailedUnsupportedFileType: 'Upload failed. Unsupported file type.',
    TooLargeTryAgain: 'File is too large. Please try again',
};

const acceptList = ['image/png'];
export const LogoUploader: FC<Props> = ({ defaultLogo, customLogo, label, testid, type }) => {
    const logoSettingType = type.includes('LOGO')
        ? 'COMPANY_LOGO_URL'
        : type.includes('WATERMARK')
        ? 'COMPANY_WATERMARK_URL'
        : 'NOT_FOUND';
    const companySettingsStore = useCompanySettings();
    const activeCompany = useActiveCompanyCode();
    const logoRef = useRef<null | HTMLInputElement>(null);
    const [uploadError, setUploadError] = useState<null | string>(null);
    const theme = useTheme();

    const uploadedImageValidation = (files?: FileList) => {
        if (!files?.length) {
            return;
        }
        const file = files[0];
        if (!acceptList.includes(file.type)) {
            setUploadError(UPLOAD_IMAGE_ERRORS.UnsupportedFileType);
            setTimeout(() => {
                setUploadError(null);
            }, 2000);
            return;
        }

        if (file.size > MAX_IMAGE_SIZE) {
            setUploadError(UPLOAD_IMAGE_ERRORS.TooLargeTryAgain);
            return;
        }
        const reader = new FileReader();
        reader.onerror = () => {
            setUploadError(UPLOAD_IMAGE_ERRORS.UploadImageFailedTryAgain);
            reader.abort();
        };
        reader.onload = async event => {
            if (typeof event.target?.result != 'string') {
                throw new Error(UPLOAD_IMAGE_ERRORS.UploadFailedUnsupportedFileType);
            }
            const logoBase64 = event.target.result.split(',')[1];
            const response = await StreemAPI.companies.createCompanyAsset(activeCompany, type, {
                filename: file.name,
                httpBody: {
                    contentType: file.type,
                    data: logoBase64,
                },
            });
            if (!response.downloadUrl) {
                throw new Error(UPLOAD_IMAGE_ERRORS.UploadFailedTryAgain);
            }
            companySettingsStore.refresh();
        };
        reader.readAsDataURL(file);
    };

    const handleUpload = (e: SyntheticEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        uploadedImageValidation(e.currentTarget.files);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files;
        uploadedImageValidation(droppedFiles);
    };

    const handleDeleteImage = () => {
        companySettingsStore.updateSetting(logoSettingType, '').catch(error => {
            setUploadError(error?.message);
        });
        logoRef.current.value = '';
    };

    return (
        <Row>
            <Column onDrop={handleDrop} onDragOver={event => event.preventDefault()}>
                <LogoPreview logo={customLogo || defaultLogo} height={100} alt={label}>
                    <Label style={{ wordBreak: 'break-word', padding: '0.4rem' }} size="small">
                        Drag and drop your files here or
                    </Label>

                    <Button
                        variant="secondary"
                        data-testid={`${testid}-upload-button`}
                        onClick={() => logoRef.current!.click()}
                    >
                        Upload
                    </Button>
                </LogoPreview>
                <Row flexGrow={1} justifyContent="center" height="15px">
                    <AppText color="red50">{uploadError}</AppText>
                </Row>
                <Row alignSelf="flex-end" justifyContent="center">
                    <input
                        ref={logoRef}
                        type="file"
                        accept={acceptList.join(',')}
                        onChange={e => handleUpload(e)}
                        style={{ display: 'none' }}
                        data-testid={`${testid}-hidden-input`}
                    />
                    <IconButton
                        iconName="TrashIcon"
                        size="medium"
                        hoverFill={theme.colors.red50}
                        shape="square"
                        background="transparent"
                        label="delete logo image"
                        data-testid={`${testid}-delete-icon`}
                        onClick={handleDeleteImage}
                    />
                    <IconButton
                        iconName="AddFileIcon"
                        size="medium"
                        hoverFill={theme.colors.azure}
                        shape="square"
                        background="transparent"
                        label="upload logo image"
                        data-testid={`${testid}-upload-icon`}
                        onClick={() => logoRef.current!.click()}
                    />
                </Row>
            </Column>
        </Row>
    );
};
