import { useEffect, useState } from "react";
import { useInputEvent } from "./use_input_event";

const konamiCode = [ "ArrowUp", "ArrowUp", "ArrowDown", "ArrowDown", "ArrowLeft", "ArrowRight", "ArrowLeft", "ArrowRight", "KeyB", "KeyA",];

//Code snippet modified from https://blog.bitsrc.io/creating-the-konami-code-as-react-hook-1bb69756b67a
export const useKonamiCode = () => {
    const [count, setCount] = useState(0);
    const [success, setSuccess] = useState(false);
    const key = useInputEvent();

    useEffect(() => {
        if (key == null) {
            return;
        }
        if (key !== konamiCode[count]) {
            setCount(0);
            return;
        }

        setCount(count + 1);
        if (count + 1 === konamiCode.length) {
            setSuccess(true);
        }
    }, [key]);

    return success;
};
