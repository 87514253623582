import React from 'react';
import { Role } from '../../types/project.types';
import { useGlobalStore } from '../../hooks/use_global_context';
import { Observer } from 'mobx-react';

type PermissionsCheckProps =
    | {
          only: Role | Role[];
      }
    | {
          except: Role | Role[];
      };

const isAllowed = (props: PermissionsCheckProps, role: Role): boolean => {
    let allowed = false;

    if ('only' in props && Array.isArray(props.only)) {
        allowed = props.only.includes(role);
    } else if ('only' in props) {
        allowed = role === props.only;
    } else if ('except' in props && Array.isArray(props.except)) {
        allowed = !props.except.includes(role);
    } else if ('except' in props) {
        allowed = role !== props.except;
    }

    return allowed;
};

export function RolesAuth(
    props: React.PropsWithChildren<PermissionsCheckProps>,
): JSX.Element | null {
    const { authStore } = useGlobalStore();
    return (
        <Observer>
            {() => {
                const { children, ...rolesAuthProps } = props;
                const allowed = isAllowed(rolesAuthProps, authStore.role);

                if (typeof children === 'function') {
                    return children(allowed);
                }

                return allowed ? children : null;
            }}
        </Observer>
    );
}
