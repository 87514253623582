import { FC } from 'react';
import { AppText, Button, Column, Modal, Row, Subheader, Theme } from '@streem/ui-react';

export const SetReadyModal: FC<{
    message: string;
    closeModal: () => void;
    isOpen: boolean;
    setActive: () => Promise<void>;
}> = ({ message, setActive, isOpen, closeModal }) => {
    const handleOnClick = async () => {
        await setActive();
        closeModal();
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={closeModal}
            styleOverride={{
                content: {
                    maxWidth: '650px',
                    padding: Theme.spacing.l,
                },
            }}
        >
            <Column justifyContent="space-between">
                <Subheader style={{ marginBottom: '24px' }}>Set yourself to ready?</Subheader>
                <AppText>{message}</AppText>
                <Row alignSelf="flex-end" marginTop="36px">
                    <Button
                        ml={5}
                        variant="secondary"
                        onClick={closeModal}
                        data-testid="availability-away-modal-no-thanks-button"
                    >
                        No thanks
                    </Button>
                    <Button
                        onClick={handleOnClick}
                        ml={4}
                        data-testid="availability-away-modal-set-ready-button"
                    >
                        Set to ready
                    </Button>
                </Row>
            </Column>
        </Modal>
    );
};
