import { useParams } from 'react-router-dom';
import config from '../config';
import { useGlobalStore } from './use_global_context';

export const useActiveCompanyCode = () => {
    const { company } = useParams<any>(); // Used for super-admins.
    const { authStore } = useGlobalStore(); // Use for company admins and agents.

    if (!authStore.isUserSignedIn) {
        return config.companyCode;
    }

    return company || config.companyCode;
};
