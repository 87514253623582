import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { InputProps } from '@streem/ui-react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    component: React.FC<InputProps>;
    labelColor?: string;
    errorStatus?: boolean | string;
    helpText?: string;
}

export const ValidatedField: FC<Props> = ({
    label,
    name,
    errorStatus,
    labelColor,
    helpText,
    component: RenderComponent,
    ...rest
}) => {
    return (
        <Field name={name}>
            {({ field, meta }: FieldProps) => {
                return (
                    <RenderComponent
                        {...field}
                        {...rest}
                        name={name}
                        value={field.value}
                        error={meta.touched ? meta.error : ''}
                        status={errorStatus}
                        label={label}
                        // @ts-ignore
                        labelColor={labelColor}
                        labelSize="medium"
                        helpText={helpText}
                    />
                );
            }}
        </Field>
    );
};
