import { AppIcon, AppText, IconButton, styled, Theme, TooltipButton } from '@streem/ui-react';
import React, { KeyboardEvent as ReactKeyboardEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { removeSegments } from '../../util/routing';
import { ShareLink } from '../share/share_link';
import { WallItem } from 'streem-sdk-protobuf';
import { WallItemSubtype } from '@streem/sdk-types';
import { useGlobalStore } from '../../hooks/use_global_context';
import { useDetailSession } from '../../hooks/detail_session_hooks';
import { useAnonymousShareChecker } from '../../hooks/use_anonymous_share_checker';

/**
 * Share and Close buttons row for the streemshot details page
 */
export const ShareAndCloseButtons: React.FC<{
    roomSid: string;
    editing: boolean;
    setEditMode: () => void;
    setShowExitModal: () => void;
    artifact: WallItemSubtype<WallItem, 'streemshot'> & {
        streemshotRevision?: Partial<WallItem['streemshotRevision']>;
        streemshotId?: string | null;
        edited?: boolean;
    };
}> = ({ roomSid, editing, setEditMode, setShowExitModal, artifact }) => {
    const history = useHistory();
    const { authStore, companySettingsStore } = useGlobalStore();
    const detailSession = useDetailSession();
    const isAnonymousSharePage = useAnonymousShareChecker(authStore);

    const closeArtifactDetailsPage = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            history.push(removeSegments(history.location.pathname, 2));
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', closeArtifactDetailsPage);
        return () => {
            document.removeEventListener('keydown', closeArtifactDetailsPage);
        };
    });

    return (
        <ShareAndCloseButtonsWrapper>
            {companySettingsStore.postCallAnnotationsEnabled && (
                <>
                    {artifact?.streemshotRevision && artifact?.edited && !editing && (
                        <TooltipButton
                            tooltipMessage={
                                <AppText
                                    style={{
                                        padding: '16px',
                                        display: 'block',
                                        width: '300px',
                                    }}
                                >
                                    Remove all edits and revert to the original StreemShot.
                                </AppText>
                            }
                            tooltipPlacement="bottom"
                            buttonText="Revert"
                            style={{
                                marginRight: Theme.spacing.m,
                            }}
                            variant="secondary"
                            onClick={() =>
                                detailSession.streemshots.revertEditedStreemshot(
                                    artifact.streemshotId,
                                )
                            }
                        />
                    )}
                    {!editing && (
                        <TooltipButton
                            onClick={setEditMode}
                            buttonText="Edit"
                            tooltipPlacement="bottom"
                            tooltipMessage={
                                <AppText
                                    style={{ padding: '16px', display: 'block', width: '200px' }}
                                >
                                    Crop, rotate, and mark up this StreemShot.
                                </AppText>
                            }
                        />
                    )}
                </>
            )}
            {!isAnonymousSharePage && <ShareLink invert={true} roomId={roomSid} />}
            <IconButton
                style={{ marginLeft: '16px' }}
                iconName="CloseIcon"
                size="medium"
                shape="circle"
                label="Close details view"
                onClick={() => {
                    if (editing) setShowExitModal();
                    else history.push(removeSegments(history.location.pathname, 2));
                }}
                onKeyDown={event => {
                    if (event.key === 'Enter') {
                        history.push(removeSegments(history.location.pathname, 2));
                    }
                }}
                background="azure"
                fill="white"
                data-testid="artifact-details-close-icon-button"
            />
        </ShareAndCloseButtonsWrapper>
    );
};

/**
 * Previous and Next buttons for navigating between views of artifact details related to the same room
 */
export const PrevNextButtons: React.FC<{
    previousArtifactSid?: string;
    nextArtifactSid?: string;
}> = ({ previousArtifactSid, nextArtifactSid }) => {
    const history = useHistory();

    const buildArtifactUrl = (newArtifactID: string) => {
        return history.location.pathname.split('/').slice(0, -1).concat(newArtifactID).join('/');
    };

    const handleClick = (artifactSid: string) => {
        return () => history.push(buildArtifactUrl(artifactSid));
    };

    const buildAccessibleClickProps = (artifactSid: string) => {
        return {
            role: 'button',
            onClick: handleClick(artifactSid),
            onKeyDown: (event: ReactKeyboardEvent) => {
                if (event.key === 'Enter' || event.key === ' ') {
                    handleClick(artifactSid)();
                }
            },
            tabIndex: 0,
        };
    };

    return (
        <>
            {previousArtifactSid && (
                <PreviousArtifactIcon
                    aria-label="Previous Artifact"
                    data-testid="artifact-details-previous-button"
                    {...buildAccessibleClickProps(previousArtifactSid)}
                >
                    <AppIcon name="CaretIcon" color="white" size="large" />
                </PreviousArtifactIcon>
            )}
            {nextArtifactSid && (
                <NextArtifactIcon
                    aria-label="Next Artifact"
                    data-testid="artifact-details-next-button"
                    {...buildAccessibleClickProps(nextArtifactSid)}
                >
                    <AppIcon name="CaretIcon" color="white" size="large" />
                </NextArtifactIcon>
            )}
        </>
    );
};

const NavIcon = styled.div({
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    background: `rgba(0,0,0,0.8)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '64px',
    width: '64px',
    borderRadius: '50%',
});

const PreviousArtifactIcon = styled(NavIcon)({
    left: '32px',
    transform: 'translateY(-50%) rotate(90deg)',
});

const NextArtifactIcon = styled(NavIcon)(({ theme }) => ({
    right: '32px',
    transform: 'translateY(-50%) rotate(-90deg)',
}));

const ShareAndCloseButtonsWrapper = styled.div`
    z-index: ${({ theme }) => theme.layers.ui};
    position: absolute;
    top: 32px;
    right: 32px;
    display: flex;
    align-items: center;
`;
