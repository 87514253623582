import { Bookmark } from '@streem/sdk-core';
import {
    AppIcon,
    AppText,
    BaseInput,
    Box,
    IconButton,
    styled,
    Tooltip,
    useTheme,
} from '@streem/ui-react';
import { FC, useState } from 'react';
import { useTimeStampController } from '../../hooks/use_timestamp_controller';

interface BookmarkProps {
    bookmarkArtifact: Bookmark;
    deleteBookmark: () => void;
    updateBookmark: (label: string) => void;
    isBookmarkOwner: boolean;
    disableTimestamp: boolean;
    enableToolTip: boolean;
}

export const MediaGalleryBookmark: FC<BookmarkProps> = ({
    bookmarkArtifact,
    updateBookmark,
    deleteBookmark,
    isBookmarkOwner,
    disableTimestamp,
    enableToolTip,
}) => {
    const timeStampController = useTimeStampController();
    const theme = useTheme();
    const [inputValue, setInputValue] = useState(bookmarkArtifact.bookmark?.label || '');
    const [isFocused, setIsFocused] = useState(false);
    const [buttonHovered, setButtonHovered] = useState(false);
    const [disableDelete, setDisableDelete] = useState(false);

    if (!timeStampController.hasVideoArtifact) {
        return null;
    }
    const { bookmark } = bookmarkArtifact;
    const readableTimeStamp = timeStampController.getBookmarkTimeStamp(bookmark);
    const handleTimeStampClick = () => {
        const bookmarkTimeStamp =
            bookmark.timestamp?.seconds.low + bookmark.timestamp?.nanos / 1000000000;
        timeStampController.jumpVideoToTimeStamp(bookmarkTimeStamp);
    };
    return (
        <BookmarkRow>
            <Tooltip
                message={
                    <AppText color="dark" size="small" textAlign="center">
                        Links will be available after the video has processed
                    </AppText>
                }
                placement="top"
                fallbackPlacements={['top']}
                showTooltip={buttonHovered && enableToolTip}
            >
                <TimeStampButton
                    className={disableTimestamp ? 'no-hover' : ''}
                    data-testid={`bookmark-timestamp-button-${bookmarkArtifact.id}`}
                    onMouseEnter={() => setButtonHovered(true)}
                    onMouseLeave={() => setButtonHovered(false)}
                    onClick={() => {
                        if (!disableTimestamp) {
                            handleTimeStampClick();
                        }
                        return;
                    }}
                >
                    <TimeStampText hovered={!disableTimestamp && buttonHovered}>
                        <AppText
                            size="medium"
                            color={disableTimestamp ? 'black' : 'azure'}
                            style={{ fontWeight: 500 }}
                        >
                            {readableTimeStamp}
                        </AppText>
                    </TimeStampText>
                    <Box pt={'4px'} mx={2}>
                        <AppIcon
                            name="TimeStampArrowIcon"
                            size="medium"
                            color={disableTimestamp ? theme.colors.black : theme.colors.azure}
                        />
                    </Box>
                </TimeStampButton>
            </Tooltip>
            <LabelInputWrapper disabled={!isBookmarkOwner}>
                <BaseInput
                    maxLength={30}
                    fontSize={1}
                    backgroundColor={isFocused ? 'white' : 'transparent'}
                    border={isFocused ? '2px solid' : '0px solid'}
                    borderColor={theme.colors.azure}
                    borderRadius={'6px'}
                    py={3}
                    px={3}
                    hideLabel
                    value={inputValue}
                    placeholder="Untitled Bookmark"
                    onFocus={() => {
                        setIsFocused(true);
                    }}
                    onBlur={() => {
                        updateBookmark(inputValue);
                        setIsFocused(false);
                    }}
                    onChange={e => setInputValue(e.target.value)}
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            (document.activeElement as HTMLElement).blur();
                        }
                    }}
                    name={bookmark.label || ''}
                    disabled={!isBookmarkOwner}
                />
            </LabelInputWrapper>
            <IconButton
                label="Delete Bookmark"
                iconName="TrashIcon"
                fill={theme.colors.black}
                hoverFill={theme.colors.red50}
                background="transparent"
                onClick={async () => {
                    setDisableDelete(true);
                    try {
                        await deleteBookmark();
                    } catch (e) {
                        setDisableDelete(false);
                        console.error('Error deleting bookmark: ', e);
                    }
                }}
                disabled={!isBookmarkOwner || disableDelete}
            />
        </BookmarkRow>
    );
};
const TimeStampText = styled.span<{ hovered: boolean }>`
    border-bottom: 1px solid ${props => (props.hovered ? props.theme.colors.azure : 'transparent')};
`;

const LabelInputWrapper = styled.span<{ disabled: boolean }>`
    flex-grow: 1;
    &:hover {
        background: ${props => (props.disabled ? 'inherit' : 'rgba(93, 186, 255, 0.23)')};
        border-radius: 6px;
    }
`;
const TimeStampButton = styled.button`
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
    padding: 6px 8px 6px 2px;
    background: none;
    border-radius: 4px;
    /* background-color: ${({ theme }) => theme.colors.blackA40}; */
    &:hover {
        cursor: pointer;
    }
    &.no-hover {
        cursor: default;
    }
`;

const BookmarkRow = styled.div(({ theme }) => ({
    display: 'flex',
    marginBottom: '12px',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.grey05,
    borderRadius: 4,
    padding: theme.spacing.m,
    height: 50,
}));
