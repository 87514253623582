import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { AppIcon, ShareLinkPopup, styled } from '@streem/ui-react';
import { useActiveCompanyCode } from '../../hooks/use_active_company_code';
import {
    useGetCompanyStore,
    createGetCompanyStore,
    GetCompanyProvider,
} from '../../hooks/detail_store_hooks';
import { useGlobalStore } from '../../hooks/use_global_context';
import { useShareLink } from '@streem/sdk-react';

interface Props {
    roomId: string | undefined;
    // Invert icon color via css so it's visible against dark backgrounds.
    invert?: boolean;
}

export const BaseShareLink: React.FC<Props> = ({ roomId, invert }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { authStore, configStore } = useGlobalStore();
    const companyStore = useGetCompanyStore();
    const companyCode = useActiveCompanyCode();
    const [showPopup, setShowPopup] = useState(false);
    const [popupPosition, setPopupPosition] = useState<{
        top: number;
        right: number;
    }>({
        top: 0,
        right: 0,
    });

    const { token, roomSid, artifactSid } = useParams<{
        token: string;
        roomSid: string;
        artifactSid: string;
    }>();
    const anonymousShareEnabled = configStore.anonymousShareEnabled;

    const [link, refreshLink] = useShareLink({
        roomSid,
        anonymousShareEnabled,
        artifactSid,
        token,
    });

    const handleShareButtonClick = useCallback(() => {
        const shadowOffset = -24;
        const verticalOffset = 18;

        setPopupPosition({
            top: verticalOffset,
            right: shadowOffset,
        });
        setShowPopup(true);
    }, []);

    const disabled = !roomId || authStore.isSuperAdmin;

    useEffect(() => {
        if (!anonymousShareEnabled) {
            const promise = companyStore.fetch(companyCode);
            return () => promise.cancel();
        }
    }, [companyCode, companyStore, anonymousShareEnabled]);

    return (
        <Observer>
            {() => {
                return (
                    <div ref={containerRef} style={{ position: 'relative' }}>
                        <BaseShareLinkActionButton
                            data-testid="share-link-action-button"
                            aria-label="Share this call page"
                            onClick={handleShareButtonClick}
                            name="share-link"
                            disabled={disabled}
                            invert={!!invert}
                        >
                            <AppIcon name="ShareIconThick" />
                        </BaseShareLinkActionButton>
                        <ShareLinkPopup
                            open={showPopup}
                            onClose={() => setShowPopup(false)}
                            position={popupPosition}
                            containerRef={containerRef}
                            anonymousShareEnabled={anonymousShareEnabled}
                            companyName={companyStore.result?.name}
                            link={link}
                            refreshLink={refreshLink}
                            cardSize="530"
                        />
                    </div>
                );
            }}
        </Observer>
    );
};

const BaseShareLinkActionButton = styled.button<{ invert: boolean }>`
    display: block;
    text-align: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 20px;
    background: transparent;
    border: none;
    transform: translate(8px);
    filter: ${props => (props.invert ? 'invert(1)' : 'none')};
    :hover:enabled {
        cursor: pointer;
        background: #f2f8fe;
    }
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export const ShareLink: React.FC<Props> = props => {
    const companyStore = createGetCompanyStore();

    return (
        <GetCompanyProvider value={companyStore}>
            <BaseShareLink {...props} />
        </GetCompanyProvider>
    );
};
