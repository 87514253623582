import { FC, useState, useCallback } from 'react';
import { Formik } from 'formik';
import { APIError, APITypes } from '@streem/api';
import {
    AppText,
    Box,
    Button,
    ButtonRow,
    Header,
    Label,
    WhiteInput,
    Modal,
    ToastTypes,
} from '@streem/ui-react';
import {
    BottomRow,
    ContainedForm,
    ErrorContainer,
    FormButtons,
    Inputs,
    MiddleRow,
    TopRow,
    UserProfile,
} from './shared_form.styles';
import { EmailEntry } from './user_form.styles';
import { DropdownField } from '../components/inputs/dropdown_field';
import { RoleSelector } from '../components/inputs/role_selector';
import { ValidatedField } from '../components/inputs/validated_field';
import { UserFormStrategy } from '../types/project.types';
import { AvatarUploader } from '../components/companies/customizations/avatar_uploader/avatar_uploader';
import { AvatarAsset } from '../util/images';
import { useActiveCompanyCode } from '../hooks/use_active_company_code';
import appLogger from '../util/logging/app_logger';
import { useFormConfig, userFormValidation, UserFormValues } from './user_form.config';
import { getHighestUserGroup, userRoleOptions } from '../util/roles';
import { useGlobalStore } from '../hooks/use_global_context';
import { trimFormValues } from '../util/form';

interface Props {
    isOpen: boolean;
    strategy: UserFormStrategy;
    onSuccess?: (values?: UserFormValues) => void;
    onCancel: () => void;
    user?: APITypes.StreemApiUser;
}

const UserFormModal: FC<Props> = ({ strategy, user, onSuccess = () => {}, isOpen, onCancel }) => {
    const log = appLogger.extend('Create/Edit User Form');
    const [loading, setLoading] = useState(false);
    const [genericSubmitError, setGenericSubmitError] = useState(false);
    const activeCompanyCode = useActiveCompanyCode();
    const formConfig = useFormConfig(strategy, activeCompanyCode, user);
    const testIdBase = formConfig.testId.includes('self')
        ? formConfig.testId
        : `${formConfig.testId}-agent`;

    const { uiStore } = useGlobalStore();
    const handleCancel = useCallback(() => {
        onCancel();
        setGenericSubmitError(false);
    }, [onCancel]);

    return (
        <Modal isOpen={isOpen} onClose={handleCancel} closeDelayMS={200}>
            <Formik
                validateOnMount={true}
                initialValues={{
                    name: user?.name || '',
                    email: user?.email || '',
                    bio: user?.bio || '',
                    role: getHighestUserGroup(user?.roles ?? []).value || '',
                    avatar: {
                        dataUrl: user?.avatarUrl || '',
                        fileName: '',
                        contentType: '',
                    },
                }}
                validationSchema={userFormValidation}
                onSubmit={async (formValues, { setFieldError }) => {
                    setLoading(true);
                    setGenericSubmitError(false);
                    try {
                        const safeFormValues = trimFormValues(formValues, ['name', 'email', 'bio']);
                        await formConfig.handleSubmit(safeFormValues);
                        let toastMessage: string;
                        let toastId: string;
                        switch (strategy) {
                            case UserFormStrategy.CREATE:
                                toastMessage = `Team member ${formValues.name} added. A welcome email has been sent to ${formValues.email}.`;
                                toastId = 'new-user-added-' + Date.now().toString();
                                break;
                            default:
                            case UserFormStrategy.EDIT_OTHER:
                            case UserFormStrategy.EDIT_SELF:
                                toastMessage = `${formValues.name} updated.`;
                                toastId = 'user-updated-' + Date.now().toString();
                                break;
                        }
                        onSuccess(formValues);
                        uiStore.addToast({
                            content: toastMessage,
                            flavor: ToastTypes.SUCCESS,
                            id: toastId,
                            timeout: 6000,
                        });
                    } catch (error) {
                        log.error(formConfig.error, error);
                        if (error instanceof APIError && error.response?.status === 409) {
                            setFieldError(
                                'email',
                                'A user with this email already exists. If they are not a member of this company, use the Add Team Member button to invite them.',
                            );
                        }
                        setGenericSubmitError(true);
                    } finally {
                        setLoading(false);
                    }
                }}
            >
                {({ isValid, isSubmitting, values, setFieldValue, dirty }) => {
                    return (
                        <ContainedForm data-testid={`${testIdBase}-user-form`}>
                            <TopRow>
                                <Header
                                    style={{ wordBreak: 'break-word' }}
                                    size="xxlarge"
                                    color="dark"
                                >
                                    {formConfig.header}
                                </Header>
                            </TopRow>
                            <MiddleRow>
                                <Inputs>
                                    <ValidatedField
                                        autoFocus={true}
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        label="Name"
                                        component={WhiteInput}
                                        data-testid="user-name-field"
                                        required
                                        labelColor="dark"
                                    />
                                    {strategy !== UserFormStrategy.EDIT_SELF && (
                                        <EmailEntry>
                                            <ValidatedField
                                                type="email"
                                                name="email"
                                                label="Email"
                                                placeholder={'Company email address'}
                                                data-testid="user-email-field"
                                                component={WhiteInput}
                                                required
                                                labelColor="dark"
                                            />
                                        </EmailEntry>
                                    )}
                                    <ValidatedField
                                        type="text"
                                        name="bio"
                                        placeholder="Bio"
                                        label="Bio"
                                        component={WhiteInput}
                                        data-testid="user-bio-field"
                                        labelColor="dark"
                                        helpText="Enter a short bio (e.g. profession and years of experience) that will be shown to customers before entering the Streem call."
                                    />
                                    {strategy !== UserFormStrategy.EDIT_SELF && (
                                        <DropdownField
                                            type="text"
                                            name="role"
                                            data-testid="user-role-field"
                                            component={RoleSelector}
                                            options={userRoleOptions}
                                            required={false}
                                        />
                                    )}
                                </Inputs>
                                <UserProfile>
                                    <Box mb={3}>
                                        <Label headingFontFamily>Profile Photo</Label>
                                    </Box>
                                    <AvatarUploader
                                        name={values.name}
                                        avatar={values.avatar}
                                        changeValue={(val?: AvatarAsset) =>
                                            setFieldValue('avatar', val)
                                        }
                                    />
                                </UserProfile>
                            </MiddleRow>
                            {genericSubmitError && (
                                <ErrorContainer>
                                    <AppText color="red50">
                                        Form submission error. Please try again.
                                    </AppText>
                                </ErrorContainer>
                            )}
                            <BottomRow>
                                <FormButtons>
                                    <ButtonRow>
                                        <Button
                                            data-testid={`cancel-${testIdBase}`}
                                            variant="secondary"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            disabled={!dirty || !isValid || isSubmitting}
                                            type="submit"
                                            variant="primary"
                                            data-testid={`submit-${testIdBase}`}
                                            loading={loading}
                                        >
                                            {formConfig.cta}
                                        </Button>
                                    </ButtonRow>
                                </FormButtons>
                            </BottomRow>
                        </ContainedForm>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default UserFormModal;
