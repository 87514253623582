import { FC, MutableRefObject } from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    inputRef: MutableRefObject<HTMLInputElement>;
    testId?: string;
}

export const FileUploader: FC<Props> = ({
    inputRef,
    testId,
    onChange,
    type = 'file',
    accept,
    ...rest
}) => {
    return (
        <input
            type={type}
            accept={accept}
            style={{ display: 'none' }}
            ref={inputRef}
            onChange={onChange}
            data-testid={testId}
            {...rest}
        />
    );
};
