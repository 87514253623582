import React from 'react';
import appLogger from '../logging/app_logger';

interface ErrorBoundaryState {
    error: any;
    errorInfo: any;
}

export class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
    constructor(props: {}) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        appLogger.error('ErrorBoundary triggered: ', { error, errorInfo });
    }

    render() {
        if (this.state.error) {
            // we can display anything we like
            // as long as it is not from our component library
            return <div></div>;
        } else {
            return this.props.children;
        }
    }
}
