import { FC } from 'react';
import { AppText, Button, Modal, Row, Subheader, Theme } from '@streem/ui-react';

export const PageLoadModal: FC<{
    isOpen: boolean;
    closeModal: () => void;
    setNotReady: () => Promise<void>;
}> = ({ isOpen, closeModal, setNotReady }) => {
    const handleOnClick = async () => {
        await setNotReady();
        closeModal();
    };

    return (
        <Modal
            isOpen={isOpen}
            hideCloseButton
            closeOnEscape={false}
            onClose={closeModal}
            styleOverride={{
                content: {
                    width: '580px',
                    padding: Theme.spacing.l,
                },
            }}
        >
            <Subheader style={{ marginBottom: '24px' }}>Still ready to take calls?</Subheader>
            <AppText>Please confirm your availability.</AppText>
            <Row justifyContent={'flex-end'} width="100%" marginTop="36px">
                <Button
                    onClick={handleOnClick}
                    variant="secondary"
                    style={{ marginRight: '16px' }}
                    data-testid="availability-modal-not-ready-button"
                >
                    I'm not ready
                </Button>
                <Button onClick={closeModal} data-testid="availability-modal-ready-button">
                    I'm ready
                </Button>
            </Row>
        </Modal>
    );
};
