import { FC } from 'react';
import {
    MobileExampleLargeLogo,
    MobileExampleSmallLogo,
    MobileExampleURL,
} from '../../components/companies/customizations/mobile_ui_previews/mobile_ui_previews';
import { Column, Row, Box, AppText } from '@streem/ui-react';
import smallLogoDefault from '../../components/companies/customizations/mobile_ui_previews/assets/small_logo.svg';
import largeLogoDefault from '../../components/companies/customizations/mobile_ui_previews/assets/large_logo.svg';
import {
    Section,
    Customization,
} from '../../components/companies/customizations/customizations_page.styles';
import { SettingField } from '../../components/companies/settings/setting_field';
import { LogoUploader } from '../../components/companies/customizations/logo_uploader';
import { useCompanySettings } from '../../hooks/company_settings_hooks';
import { useGetCompanyStore } from '../../hooks/detail_store_hooks';
import { useObserver } from 'mobx-react';
import { displayableCustomizations } from '../../stores/company_settings_store';
import { CreateCompanyAssetOperationType } from '@streem/api';
import {
    SettingColumn,
    SettingFeaturedCell,
    SettingRow,
} from '../../components/companies/settings/settings_page.styles';
import { SettingAuth } from '../../components/authorization/setting_auth';
import { useGlobalStore } from '../../hooks/use_global_context';

export const CustomizationsPage: FC = () => {
    const companySettingsStore = useCompanySettings();
    const companyStore = useGetCompanyStore();
    const { authStore } = useGlobalStore();

    return useObserver(() => {
        const {
            customizations,
            showCustomerRedirectUrlEnabled,
            showCustomerCompanyWatermarkEnabled,
        } = companySettingsStore;

        if (!companySettingsStore || !customizations) {
            return null;
        }

        const streemBrandingSetting = customizations.find(
            c => c.name === 'SWAC_HIDE_STREEM_BRANDING',
        );
        const largeLogoSetting = customizations.find(c => c.name === 'COMPANY_LOGO_URL');
        const smallLogoSetting = customizations.find(c => c.name === 'COMPANY_WATERMARK_URL');
        const redirectSetting = customizations.find(c => c.name === 'CUSTOMER_REDIRECT_URL');

        return (
            <Column
                data-testId="settings-customizations-page"
                style={{
                    padding: '16px',
                    flexShrink: 0,
                }}
            >
                {' '}
                <Row my={5}>
                    <MobileExampleLargeLogo
                        logo={largeLogoSetting?.value || largeLogoDefault}
                        companyName={companyStore.result?.name}
                        streemBranding={streemBrandingSetting?.value === 'false'}
                    />
                    <Row mt={5} flexGrow={1} flexShrink={0}>
                        <Section title="Get Started">
                            <Customization
                                title={displayableCustomizations.COMPANY_LOGO_URL.label}
                                description={displayableCustomizations.COMPANY_LOGO_URL.description}
                                childOrientation="column"
                            >
                                <Row mt={5}>
                                    <LogoUploader
                                        label="large logo image upload"
                                        testid="company-customization-large-logo"
                                        defaultLogo={largeLogoDefault}
                                        customLogo={largeLogoSetting?.value}
                                        type={CreateCompanyAssetOperationType.LOGO}
                                    />
                                    <Box ml={5}>
                                        <AppText as="h4" headingFontFamily size="mediumLarge">
                                            Tips:
                                        </AppText>
                                        <br></br>
                                        <AppText>
                                            <li>Make sure the file is no bigger than 2MB</li>
                                            <li>Use only .png images</li>
                                            <li>Remove padding around the logo for best fit</li>
                                            <li>Keep it as simple as possible for a clean look</li>
                                        </AppText>
                                    </Box>
                                </Row>
                            </Customization>
                            {streemBrandingSetting && (
                                <SettingAuth setting={streemBrandingSetting}>
                                    <SettingRow>
                                        <SettingFeaturedCell
                                            setting={streemBrandingSetting}
                                            marginRight="auto"
                                            paddingRight="16px"
                                            width="400px"
                                        />
                                        <SettingField
                                            setting={streemBrandingSetting}
                                            width="60%"
                                            data-testid="company-customization-streem-branding-toggle"
                                        />
                                    </SettingRow>
                                </SettingAuth>
                            )}
                        </Section>
                    </Row>
                </Row>
                {smallLogoSetting &&
                    (showCustomerCompanyWatermarkEnabled || authStore.isSuperAdmin) && (
                        <Row my={5}>
                            <MobileExampleSmallLogo
                                logo={smallLogoSetting.value || smallLogoDefault}
                                companyName={companyStore.result?.name}
                                background
                            />
                            <Row mt={5} flexGrow={1} flexShrink={0}>
                                <Section title="Live Streem">
                                    <Row>
                                        <Customization
                                            title={
                                                displayableCustomizations.COMPANY_WATERMARK_URL
                                                    .label
                                            }
                                            description={
                                                displayableCustomizations.COMPANY_WATERMARK_URL
                                                    .description
                                            }
                                            childOrientation="column"
                                        >
                                            <Row mt={5}>
                                                <LogoUploader
                                                    defaultLogo={smallLogoDefault}
                                                    customLogo={smallLogoSetting?.value}
                                                    label="small logo image upload"
                                                    testid="company-customization-watermark"
                                                    type={CreateCompanyAssetOperationType.WATERMARK}
                                                />
                                                <Box ml={5}>
                                                    <AppText
                                                        as="h4"
                                                        headingFontFamily
                                                        size="mediumLarge"
                                                    >
                                                        Tips:
                                                    </AppText>
                                                    <br></br>
                                                    <AppText>
                                                        <li>
                                                            Make sure the file is no bigger than 2MB
                                                        </li>
                                                        <li>Use only .png images</li>
                                                        <li>
                                                            Remove padding around the logo for best
                                                            fit
                                                        </li>
                                                        <li>
                                                            Logo should be white or a single light
                                                            color
                                                        </li>
                                                    </AppText>
                                                </Box>
                                            </Row>
                                        </Customization>
                                    </Row>
                                </Section>
                            </Row>
                        </Row>
                    )}
                {(showCustomerRedirectUrlEnabled || authStore.isSuperAdmin) && (
                    <Row my={5}>
                        <MobileExampleURL
                            previewInputValue={redirectSetting?.value || 'www.streem.com'}
                        />
                        <Row mt={5} flexGrow={1}>
                            <Section title="Post Streem">
                                <Column flexShrink={0}>
                                    {redirectSetting && (
                                        <SettingAuth setting={redirectSetting}>
                                            <SettingColumn>
                                                <SettingFeaturedCell
                                                    setting={redirectSetting}
                                                    marginBottom="16px"
                                                    customization
                                                />
                                                <SettingField
                                                    setting={redirectSetting}
                                                    width="60%"
                                                />
                                            </SettingColumn>
                                        </SettingAuth>
                                    )}
                                </Column>
                            </Section>
                        </Row>
                    </Row>
                )}
            </Column>
        );
    });
};
