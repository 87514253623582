import React from 'react';
import { WhiteInput } from '@streem/ui-react';

interface Props {
    label: string;
    value: string | undefined;
    copy?: boolean;
    'data-testid': string;
}

export const ReadOnlyField: React.FC<Props> = ({ label, value, copy, 'data-testid': testId }) => {
    return (
        <WhiteInput
            name={label}
            labelColor="dark"
            label={label}
            labelSize="medium"
            value={value}
            copyToClipboard={Boolean(copy)}
            readOnly
            data-testid={testId}
        />
    );
};
