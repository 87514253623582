import { useEffect } from 'react';
import { recordScreenViewed } from '@streem/analytics';
import { useLocation } from 'react-router-dom';

export function useScreenViews() {
    const location = useLocation();
    useEffect(() => {
        const { href } = window.location;
        if (href) {
            recordScreenViewed(href);
        }
    }, [location]);
}
