import { AppText, Column, Row, styled } from '@streem/ui-react';

interface DateDurationReferenceProps {
    startDate?: Date;
    duration?: string;
    reference?: string;
}
export const DateDurationReference = ({
    startDate,
    duration,
    reference,
}: DateDurationReferenceProps): JSX.Element => {
    return (
        <DateDurationReferenceWrapper>
            {startDate && (
                <DateDurationReferenceColumn
                    header="Date and Time"
                    value={`${startDate.toLocaleDateString(undefined, {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    })} at ${startDate.toLocaleTimeString(undefined, {
                        hour: 'numeric',
                        minute: '2-digit',
                    })}`}
                />
            )}
            {duration && <DateDurationReferenceColumn header="Duration" value={duration} />}
            {reference && <DateDurationReferenceColumn header="Reference" value={reference} />}
        </DateDurationReferenceWrapper>
    );
};

interface DateDurationRefereceColumnProps {
    header: string;
    value: string;
}
const DateDurationReferenceColumn = ({ header, value }: DateDurationRefereceColumnProps) => {
    return (
        <DateDurationReferenceColumnWrapper>
            <AppText color="grey60" style={{ fontSize: '.875rem' }}>
                {header}
            </AppText>
            <AppText color="grey60" data-testid={`detail-${header}`}>
                {value}
            </AppText>
        </DateDurationReferenceColumnWrapper>
    );
};

const DateDurationReferenceWrapper = styled(Row)({
    padding: '0 .75rem 1rem .75rem',
    width: '100%',
});

const DateDurationReferenceColumnWrapper = styled(Column)({
    width: '33%', // roughly 1/3 of the width
});
