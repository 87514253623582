import { CompanySettingsStore } from '../stores/company_settings_store';
import { localStoreHookFactory } from './local_store_hook_factory';

export const [
    createCompanySettingsStore,
    CompanySettingsProvider,
    useCompanySettings,
] = localStoreHookFactory(
    'CompanySettings',
    (companyCode: string) => new CompanySettingsStore(companyCode),
);
