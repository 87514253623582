import { useHistory } from 'react-router-dom';
import { EmbedSessionParams, EmbedSessionKeys } from '../types/project.types';
import { isValidUrl } from '../util/is_valid_url';

export function useEmbedRedirect(): (redirectParam: string, roomId: string) => void {
    const history = useHistory();

    return (redirectParam: string, roomId: string) => {
        const urlParams = new URLSearchParams(window.location.search);
        /* STREEM-15109 Note: this behavior is a preliminary step to having our users switch from using a refId
         * to using an integrationId until backend support for this feature is implemented.
         * For the time being, an integrationId or refId can be used as the reference ID value we store in the
         * database when creating an invitation. Update after STREEM-14878 has been merged.
         */
        const integrationId = urlParams.get('integrationId') ?? urlParams.get('refId');
        const embedSessionParams: EmbedSessionParams = {
            refId: integrationId,
            integrationId: integrationId,
            phone: urlParams.get('phone'),
            name: urlParams.get('name'),
            email: urlParams.get('email'),
            callingCode: urlParams.get('callingCode'),
            redirect: urlParams.get('redirect'),
        };
        if (redirectParam === 'call') {
            sessionStorage.setItem(EmbedSessionKeys.INVITE, JSON.stringify(embedSessionParams));

            setTimeout(() => {
                history.push(`/embed/${roomId}`);
            }, 4000);
        } else if (isValidUrl(redirectParam)) {
            setTimeout(() => {
                window.location.href = redirectParam;
            }, 4000);
        }
    };
}
