/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiArtifact,
    StreemApiArtifactFromJSON,
    StreemApiArtifactFromJSONTyped,
    StreemApiArtifactToJSON,
    StreemApiRoom,
    StreemApiRoomFromJSON,
    StreemApiRoomFromJSONTyped,
    StreemApiRoomToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiEventsArtifactCreated
 */
export interface StreemApiEventsArtifactCreated {
    /**
     * 
     * @type {StreemApiRoom}
     * @memberof StreemApiEventsArtifactCreated
     */
    room?: StreemApiRoom;
    /**
     * 
     * @type {StreemApiArtifact}
     * @memberof StreemApiEventsArtifactCreated
     */
    artifact?: StreemApiArtifact;
}

export function StreemApiEventsArtifactCreatedFromJSON(json: any): StreemApiEventsArtifactCreated {
    return StreemApiEventsArtifactCreatedFromJSONTyped(json, false);
}

export function StreemApiEventsArtifactCreatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiEventsArtifactCreated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'room': !exists(json, 'room') ? undefined : StreemApiRoomFromJSON(json['room']),
        'artifact': !exists(json, 'artifact') ? undefined : StreemApiArtifactFromJSON(json['artifact']),
    };
}

export function StreemApiEventsArtifactCreatedToJSON(value?: StreemApiEventsArtifactCreated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room': StreemApiRoomToJSON(value.room),
        'artifact': StreemApiArtifactToJSON(value.artifact),
    };
}


