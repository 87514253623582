import { useMemo } from 'react';
import { APITypes } from '@streem/api';
import { useObservable } from '@streem/sdk-react';
import { useDetailSession } from './detail_session_hooks';
import { useGetCompanyStore } from './detail_store_hooks';
import { useGlobalStore } from './use_global_context';

export const useCallSourceData = (): { callSource: APITypes.StreemApiCallSource | undefined } => {
    const detailSession = useDetailSession();
    const { companySettingsStore } = useGlobalStore();
    const [room] = useObservable(detailSession.room.room);
    const companyStore = useGetCompanyStore();

    const callSource = useMemo(() => {
        if (companySettingsStore.ahsVirtualExpertEnabled && room?.callDetail?.callSourceSid) {
            const callSourceSid = room.callDetail.callSourceSid;
            const companyCallSources = companyStore.result.callSources;
            const callSource = companyCallSources.find(cs => cs.sid === callSourceSid);

            return callSource;
        }
        return undefined;
    }, [
        companySettingsStore.ahsVirtualExpertEnabled,
        room?.callDetail,
        companyStore.result?.callSources,
    ]);

    return {
        callSource,
    };
};
