import { ListStore } from '../stores/list_store';
import { StreemAPI } from '@streem/api';
import { localStoreHookFactory } from './local_store_hook_factory';
import { ListStorePaginated } from '../stores/list_store_paginated';

// Wraps the listCompanies method with a ListStore for frontend use.
export const [createListCompaniesStore, ListCompaniesProvider, useListCompaniesStore] =
    localStoreHookFactory(
        'ListCompaniesStore',
        () =>
            new ListStorePaginated(StreemAPI.companies.listCompaniesRaw.bind(StreemAPI.companies), {
                filter: r => (r.companies ? r.companies : []),
                fnName: 'listCompaniesRaw',
            }),
    );

// Wraps the listUsers method with a ListStore for frontend use.
export const [createListUsersStore, ListUsersProvider, useListUsersStore] = localStoreHookFactory(
    'ListUsersStore',
    () =>
        new ListStorePaginated(
            StreemAPI.companies.listUsersForCompanyRaw.bind(StreemAPI.companies),
            {
                filter: r => (r.users ? r.users : []),
                fnName: 'listUsersForCompanyRaw',
            },
        ),
);

export const [createGetCompanyCallLogStore, GetCompanyCallLogProvider, useGetCompanyCallLogStore] =
    localStoreHookFactory(
        'GetCompanyCallLogStore',
        () =>
            new ListStorePaginated(
                StreemAPI.companies.getCompanyCallLogRaw.bind(StreemAPI.companies),
                {
                    filter: r => (r.callLogEntries ? r.callLogEntries : []),
                    fnName: 'getCompanyCallLogRaw',
                },
            ),
    );

export const [createGetUserCallLogStore, GetUserCallLogProvider, useGetUserCallLogStore] =
    localStoreHookFactory(
        'GetUserCallLogStore',
        () =>
            new ListStorePaginated(StreemAPI.users.getUserCallLogRaw.bind(StreemAPI.users), {
                filter: r => (r.callLogEntries ? r.callLogEntries : []),
                fnName: 'getUserCallLogRaw',
            }),
    );

export const [
    createGetUserFavoritesCallLogStore,
    GetUserFavoritesCallLogProvider,
    useGetUserFavoritesCallLogStore,
] = localStoreHookFactory(
    'GetUserFavoritesCallLogStore',
    () =>
        new ListStorePaginated(StreemAPI.users.getUserCallLogRaw.bind(StreemAPI.users), {
            filter: r => (r.callLogEntries ? r.callLogEntries : []),
            fnName: 'getUserCallLogRaw',
        }),
);

export const [
    createListGroupMemberMetricsStore,
    ListGroupMemberMetricsProvider,
    useListGroupMemberMetricsStore,
] = localStoreHookFactory(
    'ListGroupMemberMetricsStore',
    () =>
        new ListStorePaginated(
            StreemAPI.analytics.aggregateRoomMetricsForCompanyRaw.bind(StreemAPI.analytics),
            {
                filter: r => r.results || [],
                fnName: 'aggregateRoomMetricsForCompanyRaw',
            },
        ),
);

export const [
    createListCompanyApiKeysStore,
    ListCompanyApiKeysProvider,
    useListCompanyApiKeysStore,
] = localStoreHookFactory(
    'ListCompanyApiKeysStore',
    () =>
        new ListStore(StreemAPI.companies.listCompanyApiKeys.bind(StreemAPI.companies), {
            filter: r => (r.apiKeys ? r.apiKeys : []),
            fnName: 'listCompanyApiKeys',
        }),
);

export const [createGetCountriesStore, GetCountriesProvider, useGetCountriesStore] =
    localStoreHookFactory(
        'GetCountriesStore',
        () =>
            new ListStore(StreemAPI.countries.getCountries.bind(StreemAPI.countries), {
                filter: r => (r.countries ? r.countries : []),
                fnName: 'getCountries',
            }),
    );

export const [createListGroupUsersStore, ListGroupUsersProvider, useListGroupUsersStore] =
    localStoreHookFactory(
        'ListGroupUsers',
        () =>
            new ListStore(StreemAPI.companies.listGroupUsers.bind(StreemAPI.companies), {
                filter: r => (r.users ? r.users : []),
                fnName: 'listGroupUsers',
            }),
    );

export const [createListGroupsStore, ListGroupsProvider, useListGroupsStore] =
    localStoreHookFactory(
        'ListGroups',
        () =>
            new ListStore(StreemAPI.groups.listGroups.bind(StreemAPI.groups), {
                filter: r => (r.groups ? r.groups : []),
                fnName: 'listGroups',
            }),
    );
