import { createContext, useContext } from 'react';
import { TimeStampController } from '../util/timestamp_controller';

const TimeStampControllerContext = createContext<TimeStampController>(null);

export const TimeStampControllerProvider = TimeStampControllerContext.Provider;
export const useTimeStampController = () => {
    const timeStampContext = useContext(TimeStampControllerContext);
    if (!timeStampContext) {
        throw new Error('Unable to find a <TimeStampControllerProvider /> in the parent tree.');
    }
    return timeStampContext;
};
