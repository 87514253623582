import { useCallback, useState } from 'react';
import {
    AppText,
    Flex,
    Header,
    IconButton,
    IconNames,
    Row,
    Tooltip,
    styled,
} from '@streem/ui-react';
import { GroupMetrics } from '../users/groups/group_metrics';
import { RoomMetrics, TimeRange } from '../../util/metrics';
import { recordTooltipViewed } from '@streem/analytics';
import { useHistory } from 'react-router-dom';
import { GroupsDetailToolbar } from '../users/groups/group_details_toolbar';

interface Props {
    title: string;
    contentSubheader?: string;
    iconName?: IconNames;
    iconContext?: string;
    handleClick?: () => void;
    timeRange?: TimeRange;
    roomMetrics: RoomMetrics;
    isSuccessfullyLoaded: boolean;
}

export const GroupsPageHeader: React.FC<Props> = ({
    title,
    contentSubheader,
    iconName,
    iconContext,
    handleClick,
    timeRange,
    roomMetrics,
    isSuccessfullyLoaded,
}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = 'blue-plus-group-icon-tooltip';

    const history = useHistory();

    const handleMouseEnter = useCallback(() => {
        setShowTooltip(true);
        recordTooltipViewed(tooltipId);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setShowTooltip(false);
    }, []);

    const isGroupPage = history.location.pathname.includes('/groups/');
    return (
        <>
            {isGroupPage && <GroupsDetailToolbar />}
            <Container>
                <Row style={{ marginRight: '100px' }}>
                    <Header style={{ wordBreak: 'break-word' }} size="xxlarge">
                        {title}
                    </Header>
                    {timeRange && (
                        <Flex paddingLeft="32px">
                            <TimeRange data-testid="groups-header-time-range">
                                <AppText
                                    as="h3"
                                    size="medium"
                                    color="medium"
                                    bold
                                    headingFontFamily
                                >
                                    {timeRange?.startDate} - {timeRange?.endDate}
                                </AppText>
                            </TimeRange>
                        </Flex>
                    )}
                </Row>
                {iconName && (
                    <Tooltip
                        message={iconContext}
                        showTooltip={showTooltip}
                        placement="bottom"
                        fallbackPlacements={['bottom']}
                    >
                        <IconButton
                            data-testid="groups-header-add-button"
                            iconName={iconName}
                            label={iconName}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            background={'azure'}
                            fill={'white'}
                            onClick={handleClick!}
                        />
                    </Tooltip>
                )}
            </Container>
            <GroupMetrics roomMetrics={roomMetrics} isSuccessfullyLoaded={isSuccessfullyLoaded} />
            {contentSubheader && (
                <SubheaderContainer>
                    <AppText as="h4" size="mediumLarge" color="dark" headingFontFamily>
                        {contentSubheader}
                    </AppText>
                </SubheaderContainer>
            )}
        </>
    );
};

const Container = styled(Flex)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '120px',
    marginLeft: theme.space[3],
    marginRight: theme.space[3],
}));

const SubheaderContainer = styled(Flex)(({ theme }) => ({
    alignItems: 'center',
    marginLeft: theme.space[3],
    marginRight: theme.space[3],
    marginBottom: theme.space[4],
}));

const TimeRange = styled(Flex)(({ theme }) => ({
    alignItems: 'center',
    alignSelf: 'center',
    background: theme.colors.grey05,
    borderRadius: '6px',
    padding: '2px 8px',
}));
