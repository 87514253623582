import { AvatarAsset, getAvatarUrl } from '../util/images';
import * as yup from 'yup';
import { UserFormStrategy } from '../types/project.types';
import { UserStore } from '../stores/user_store';
import { APITypes, StreemAPI, StreemApiCreateCompanyUserRequestUserType } from '@streem/api';
import { useGlobalStore } from '../hooks/use_global_context';
import { AuthStore } from '../stores/auth_store';
import { filterOutHigherGroups } from '../util/roles';

export interface UserFormValues {
    name: string;
    email: string;
    role: string;
    avatar: AvatarAsset | undefined;
    bio?: string;
}

const MAX_USER_NAME_LENGTH = 50;
const MAX_USER_TITLE_LENGTH = 50;
const MAX_USER_BIO_LENGTH = 100;

export const userFormValidation = yup.object().shape({
    title: yup
        .string()
        .max(
            MAX_USER_TITLE_LENGTH,
            `User title must be at most ${MAX_USER_TITLE_LENGTH} characters.`,
        ),
    role: yup.string(),
    name: yup
        .string()
        .matches(/\S/, 'Name must contain at least one character.')
        .max(MAX_USER_NAME_LENGTH, `User name must be at most ${MAX_USER_NAME_LENGTH} characters.`)
        .required('*Required'),
    email: yup.string().email('Invalid email address').required('*Required'),
    bio: yup
        .string()
        .max(MAX_USER_BIO_LENGTH, `Bio must be at most ${MAX_USER_BIO_LENGTH} characters.`),
});

export const useFormConfig = (
    strategy: UserFormStrategy,
    activeCompanyCode: string,
    user: APITypes.StreemApiUser | undefined,
) => {
    const { userStore, authStore } = useGlobalStore();
    switch (strategy) {
        case UserFormStrategy.CREATE:
            return {
                header: 'Add Team Member',
                cta: 'Add Team Member',
                testId: 'create',
                error: `Failed to create new user for company "${activeCompanyCode}"`,
                handleSubmit: async (formValues: UserFormValues) =>
                    await createUser(formValues, activeCompanyCode),
            };
        case UserFormStrategy.EDIT_SELF:
            return {
                header: 'Edit Your Profile',
                cta: 'Save',
                testId: 'edit-self',
                error: `Failed to edit user for company "${activeCompanyCode}", user attempted to edit themself`,
                handleSubmit: async (formValues: UserFormValues) =>
                    await updateSelf(user!, formValues, userStore, authStore),
            };
        default:
        case UserFormStrategy.EDIT_OTHER:
            return {
                header: `Edit ${user?.name}`,
                cta: 'Save',
                testId: 'edit',
                error: `Failed to edit user for company "${activeCompanyCode}", user attempted to edit another user`,
                handleSubmit: async (formValues: UserFormValues) => {
                    // ST-1942: Roles and Groups have become a bit intertwined unfortunately.  The user.roles list is
                    // the user's list of Groups, but we also use this for controlling their Role, by adding/removing
                    // known groups. This code filters out higher groups than the selected group/role, while leaving
                    // alone existing unknown groups, like Plumbing or HVAC (i.e. custom groups)... -SA
                    const currentGroups = user?.roles || [];
                    const highestGroup = formValues.role;
                    const groups = filterOutHigherGroups(currentGroups, highestGroup);
                    const newGroups = [...groups, highestGroup];

                    return await Promise.all([
                        updateUser(user!, formValues),
                        updateGroupsForUser(user!, newGroups),
                    ]);
                },
            };
    }
};

const createUser = async (formValues: UserFormValues, activeCompanyCode: string) => {
    const { avatar, role, name, email, bio } = formValues;

    // Creates a user account
    const createUserResponse = await StreemAPI.companies.createCompanyUser(activeCompanyCode, {
        name,
        email,
        userType: StreemApiCreateCompanyUserRequestUserType.LOGIN,
        groups: [role],
        bio,
    });
    // Error handling?
    const newUserId = createUserResponse.user?.sid;

    if (!newUserId) {
        throw new Error('Create user response is missing the userId, unable to continue.');
    }

    const payload: Partial<APITypes.StreemApiUser> = {
        name,
        email,
        avatarUrl: await getAvatarUrl(avatar, newUserId),
    };
    if (payload.avatarUrl) await StreemAPI.users.updateUser(newUserId, payload);
};

const updateUser = async (user: APITypes.StreemApiUser, formValues: UserFormValues) => {
    const { avatar, name, email, bio } = formValues;

    // Initial User payload without staged avatar data
    const payload: APITypes.StreemApiUpdateUserRequest = {
        name,
        email,
        avatarUrl: await getAvatarUrl(avatar, user.sid!),
        bio,
    };

    await StreemAPI.users.updateUser(user.sid!, payload);
};

const updateGroupsForUser = async (user: APITypes.StreemApiUser, groups: string[]) => {
    const payload: APITypes.StreemApiUpdateGroupsForUserRequest = {
        groups: groups,
    };

    await StreemAPI.users.updateGroupsForUser(user.sid!, payload);
};

const updateSelf = async (
    user: APITypes.StreemApiUser,
    formValues: UserFormValues,
    userStore: UserStore,
    authStore: AuthStore,
) => {
    const email = authStore.isAgent ? {} : { email: formValues.email };

    await userStore.updateUserMeta({
        name: formValues.name,
        bio: formValues.bio,
        avatarUrl: await getAvatarUrl(formValues.avatar, user.sid!),
        ...email,
    });
};
