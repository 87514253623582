import { useEffect } from 'react';
import { AppIcon, Button, styled, useTheme } from '@streem/ui-react';

import { useHistory, useParams } from 'react-router-dom';
import { isValidUrl } from '../../util/is_valid_url';
import { useEmbedRedirect } from '../../hooks/use_embed_redirect';
import { useSurveys } from '../../hooks/use_surveys';
import { useGlobalStore } from '../../hooks/use_global_context';
import { EmbedMessageScreen } from './embed_message_screen';
import { observer } from 'mobx-react';
import { CanvasEvents, usePublishCanvasEvent } from '../../hooks/use_publish_canvas_event';

export const EmbedPostCallScreen = observer(() => {
    const { authStore } = useGlobalStore();
    const theme = useTheme();
    const params = new URLSearchParams(window.location.search);
    const redirectParam = params.get('redirect');
    const redirecting = isValidUrl(redirectParam) || redirectParam === 'call';
    const history = useHistory();
    const displaySurveys = useSurveys();
    const handleEmbedRedirect = useEmbedRedirect();
    const { roomSid } = useParams<{
        roomSid: string;
    }>();
    const handlePublishCanvasEvent = usePublishCanvasEvent();

    if (params.get('source') === 'salesforce')
        handlePublishCanvasEvent(CanvasEvents.COMPLETED, { roomSid: roomSid });

    useEffect(() => {
        if (redirectParam) {
            handleEmbedRedirect(redirectParam, roomSid);
        } else {
            displaySurveys(authStore.userId, roomSid);
        }
    }, [authStore.userId, redirectParam, roomSid, displaySurveys, handleEmbedRedirect]);

    return (
        <EmbedMessageScreen message="Thank you for using Streem">
            {!redirecting && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '8px',
                    }}
                >
                    <BackButton
                        data-testid="back-to-invite-button"
                        color="azure"
                        variant="tertiary"
                        onClick={() => history.push('/embed' + window.location.search)}
                    >
                        <BackIcon name="BackIcon" color={theme.colors.azure} />
                        Back to Invite
                    </BackButton>
                </div>
            )}
        </EmbedMessageScreen>
    );
});

const BackButton = styled(Button)`
    margin-bottom: ${props => props.theme.spacing.xs};
`;

const BackIcon = styled(AppIcon)`
    margin-right: ${props => props.theme.spacing.xs};
`;
