import React, { useEffect, useState } from 'react';
import { Modal, Button, AppText, Header, styled } from '@streem/ui-react';
import { StreemAPI } from '@streem/api';
import appLogger from '../util/logging/app_logger';
import { useGlobalStore } from '../hooks/use_global_context';

const ONE_HOUR = 1000 * 60 * 60;
const FIFTEEN_MINUTES = 1000 * 60 * 15;
const log = appLogger;

const InactivityMonitor: React.FC<{ inactiveAfter?: number; interval?: number }> = ({
    inactiveAfter = ONE_HOUR,
    interval = FIFTEEN_MINUTES,
}) => {
    const { authStore } = useGlobalStore();
    const [inactive, setInactive] = useState(false);

    useEffect(() => {
        let lastActiveAt: number = Date.now();

        const checkSessionActivity = async () => {
            const diff = Date.now() - lastActiveAt;

            if (diff >= inactiveAfter!) {
                try {
                    const { user } = await StreemAPI.users.getUser(authStore.userId);
                    if (user) {
                        updateLastActiveAt();
                    }
                } catch (e) {
                    log.info('Session marked inactive');
                    setInactive(true);
                    clearInterval(intervalId);
                }
            }
        };

        const updateLastActiveAt = () => {
            lastActiveAt = Date.now();
        };

        window.addEventListener('focusin', updateLastActiveAt);
        const intervalId = setInterval(checkSessionActivity, interval);

        return () => {
            window.removeEventListener('focusin', updateLastActiveAt);
            clearInterval(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inactiveAfter, interval]);

    const handleRestoreSessionClick = () => {
        log.info('Restoring session with page refresh');
        window.location.reload();
    };

    const noop: () => void = () => void 0;

    return (
        <Modal isOpen={inactive} onClose={noop} hideCloseButton={true}>
            <ModalContent data-testid="session-inactivity-modal">
                <ModalHeader>Session Expired</ModalHeader>
                <MessageText as={'p'}>
                    Your session expired due to inactivity. You may safely close this browser tab or
                    click Restore Session to continue.
                </MessageText>

                <Footer>
                    <Button
                        variant="primary"
                        onClick={handleRestoreSessionClick}
                        data-testid="restore-session-button"
                    >
                        Restore Session
                    </Button>
                </Footer>
            </ModalContent>
        </Modal>
    );
};

const ModalContent = styled.div`
    width: 350px;
`;

const ModalHeader = styled(Header)`
    margin-bottom: 48px;
`;

const Footer = styled.div`
    margin-top: 48px;
    text-align: right;
`;

const MessageText = styled(AppText)`
    line-height: 25px;
    padding-right: 24px;
`;

export { InactivityMonitor };
