import { useEffect, useState } from 'react';
import { useGetEmbedEnabledSettings } from './use_get_embed_enabled_settings';
import { useGlobalStore } from './use_global_context';

export const useUserAuthenticatedAndSettingsLoaded = () => {
    const { authStore, companySettingsStore } = useGlobalStore();
    const { areCompanySettingsLoading, isEmbedView } = useGetEmbedEnabledSettings();

    const [useUserAuthenticatedAndSettingsLoaded, setUseUserAuthenticatedAndSettingsLoaded] =
        useState(false);

    useEffect(() => {
        if (
            authStore.isUserSignedIn &&
            authStore.acceptedLatestTCs &&
            !areCompanySettingsLoading &&
            !isEmbedView &&
            companySettingsStore.complete
        ) {
            setUseUserAuthenticatedAndSettingsLoaded(true);
        }
    }, [
        authStore.isUserSignedIn,
        authStore.acceptedLatestTCs,
        companySettingsStore.complete,
        areCompanySettingsLoading,
        isEmbedView,
    ]);

    return useUserAuthenticatedAndSettingsLoaded;
};
