import { FC } from 'react';
import { AppText, Box, Button, Card, MeshBackground, Row } from '@streem/ui-react';
import config from '../../config';
import { openLinkInNewTab } from '../../util/routing';

const UnavailablePage: FC = () => {
    return (
        <MeshBackground data-testid="unenabled-page">
            <Row width="90%" margin="auto" minWidth="1100px">
                <Row
                    data-testid="unenabled-page"
                    alignItems="center"
                    justifyContent="center"
                    height="100vh"
                    width="100%"
                >
                    <Card>
                        <Box mb={5}>
                            <AppText headingFontFamily size="xxlarge" as={'h1'}>
                                This page is currently unavailable.
                            </AppText>
                        </Box>
                        <Row justifyContent="center">
                            <Button
                                data-testid="open-support-window-button"
                                onClick={() => {
                                    openLinkInNewTab(config.streemSupportUrl);
                                }}
                            >
                                Contact Streem Support
                            </Button>
                        </Row>
                    </Card>
                </Row>
            </Row>
        </MeshBackground>
    );
};

export default UnavailablePage;
