import { FC, useEffect, useState } from 'react';
import { Observer } from 'mobx-react';
import { Row, ExtractProps, IconButton, useTheme, Column, ToastTypes } from '@streem/ui-react';
import { GroupUserPicker } from '../../inputs/group_user_picker';
import { StreemAPI, APITypes } from '@streem/api';
import appLogger from '../../../util/logging/app_logger';
import { StringSettingLabel } from './field.styles';
import { createListGroupUsersStore } from '../../../hooks/list_store_hooks';
import { useGlobalStore } from '../../../hooks/use_global_context';

interface Props extends ExtractProps<typeof Row> {
    companyCode: string;
}

export const CompanyOwnerDropdown: FC<Props> = ({ companyCode, ...rowProps }) => {
    const theme = useTheme();
    const log = appLogger.extend('change company owner');
    const ownerUsersStore = createListGroupUsersStore();
    const [editValue, setEditValue] = useState(false);
    const [selectedUser, setSelectedUser] = useState<APITypes.StreemApiUser | null>(null);
    const { uiStore } = useGlobalStore();

    useEffect(() => {
        const request = ownerUsersStore.fetch(companyCode, 'COMPANY_OWNER');
        return () => request.cancel();
    }, [ownerUsersStore, companyCode]);

    const changeOwner = async (
        owner: APITypes.StreemApiUser | null,
        user: APITypes.StreemApiUser | null,
    ) => {
        if (!(owner?.sid && user?.sid) || owner.sid === user.sid) {
            return;
        }
        try {
            if (!user.roles?.includes('COMPANY_OWNER')) {
                await StreemAPI.companies.addGroupUser(companyCode, 'COMPANY_OWNER', user.sid);
            }
            if (!owner.roles?.includes('NONE')) {
                if (!owner.roles?.includes('COMPANY_ADMIN')) {
                    await StreemAPI.companies.addGroupUser(companyCode, 'COMPANY_ADMIN', owner.sid);
                }
                await StreemAPI.companies.deleteGroupUser(companyCode, 'COMPANY_OWNER', owner.sid);
            }
            ownerUsersStore.refresh();
            uiStore.removeToast('company-owner-update-fail');
            setEditValue(false);
        } catch (error) {
            log.error('Error attempting to change company owner', error);
            uiStore.addToast({
                content:
                    'There was an error attempting to update the company owner. Please try again.',
                flavor: ToastTypes.ERROR,
                id: 'company-owner-update-fail',
            });
        }
    };

    return (
        <Observer>
            {() => {
                const missingOwner: APITypes.StreemApiUser = {
                    name: 'No owner set',
                    email: '',
                    roles: ['NONE'],
                    sid: 'usr_noOwner',
                };
                if (ownerUsersStore.loading) {
                    return <></>;
                }
                const companyOwner =
                    ownerUsersStore.results.find(user => user.roles?.includes('COMPANY_OWNER')) ||
                    missingOwner;
                return (
                    <Row
                        alignItems="center"
                        justifyContent="flex-end"
                        minHeight="40px"
                        alignSelf="flex-end"
                        {...rowProps}
                    >
                        {!editValue && (
                            <>
                                <Column>
                                    <StringSettingLabel title={companyOwner?.name}>
                                        {companyOwner?.name}
                                    </StringSettingLabel>
                                    <StringSettingLabel title={companyOwner?.email}>
                                        {companyOwner?.email}
                                    </StringSettingLabel>
                                </Column>
                                <IconButton
                                    data-testid="edit-company-owner"
                                    iconName="EditIcon"
                                    label={`Edit Company Owner`}
                                    onClick={() => {
                                        setEditValue(true);
                                    }}
                                    background="transparent"
                                />
                            </>
                        )}
                        {editValue && (
                            <Column flex="1">
                                <Row alignItems="center" mr={2} justifyContent="flex-end">
                                    <GroupUserPicker
                                        defaultSelectedUserId={companyOwner?.sid || ''}
                                        onlyActive={true}
                                        groupName="COMPANY_ADMIN"
                                        onSelect={user => {
                                            setSelectedUser(user);
                                        }}
                                    />
                                    <IconButton
                                        style={{
                                            position: 'relative',
                                            zIndex: theme.layers.ui,
                                        }}
                                        iconName="CloseIcon"
                                        label="Cancel"
                                        hoverFill={theme.colors.red50}
                                        onClick={() => {
                                            setEditValue(false);
                                        }}
                                        shape="square"
                                        background="transparent"
                                        data-testid="company-owner-close-icon"
                                    />
                                    <IconButton
                                        iconName="DoneIcon"
                                        label="Save Changes"
                                        hoverFill={theme.colors.azure}
                                        shape="square"
                                        disabled={!selectedUser}
                                        onClick={() => {
                                            changeOwner(companyOwner, selectedUser);
                                            setEditValue(false);
                                        }}
                                        background="transparent"
                                        data-testid="company-owner-submit-icon"
                                    />
                                </Row>
                            </Column>
                        )}
                    </Row>
                );
            }}
        </Observer>
    );
};
