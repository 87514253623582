import { StreemApiAggregateRoomMetricsForCompanyRequestAggregateBy } from '@streem/api/lib/openapi/models/StreemApiAggregateRoomMetricsForCompanyRequest';
import { StreemApiAggregatedRoomMetrics } from '@streem/api/lib/openapi/models/StreemApiAggregatedRoomMetrics';
import { StreemApiAggregateRoomMetricsForCompanyResponse } from '@streem/api/lib/openapi/models/StreemApiAggregateRoomMetricsForCompanyResponse';

export interface RoomMetrics {
    avgCallCountPerHour?: number;
    avgCallDurationInMins?: number;
    missedCallCount?: number;
    totalCallCount?: number;
    totalActiveHours?: number;
    unavailabilityPercentage?: number;
}

export interface TimeRange {
    startDate: string;
    endDate: string;
}

export interface RoomMetricResults {
    roomMetrics: RoomMetrics;
    timeRange: TimeRange;
}

export const RoomMetricsAggregateBy = StreemApiAggregateRoomMetricsForCompanyRequestAggregateBy;

export function mapRoomMetricsForCompany(
    aggregateRoomMetricsForCompanyResponse: StreemApiAggregateRoomMetricsForCompanyResponse,
    companySid: string,
): RoomMetricResults {
    if (!aggregateRoomMetricsForCompanyResponse || !companySid) {
        throw new Error('Failed to map response for company room metrics');
    }

    const [companyRoomMetrics] = aggregateRoomMetricsForCompanyResponse.results.filter(
        result => companySid === result.aggregatedBy.companySid,
    );

    return roomMetricsMapper(companyRoomMetrics);
}

export function mapRoomMetricsForGroup(
    aggregateRoomMetricsForCompanyResponse: StreemApiAggregateRoomMetricsForCompanyResponse,
    groupName: string,
): RoomMetricResults {
    if (!aggregateRoomMetricsForCompanyResponse || !groupName) {
        throw new Error('Failed to map response for group room metrics');
    }

    const [groupRoomMetrics] = aggregateRoomMetricsForCompanyResponse.results.filter(
        result => groupName === result.aggregatedBy.groupName,
    );

    return roomMetricsMapper(groupRoomMetrics);
}

export function roomMetricsMapper(
    aggregatedRoomMetricResponse: StreemApiAggregatedRoomMetrics,
): RoomMetricResults {
    if (
        aggregatedRoomMetricResponse &&
        aggregatedRoomMetricResponse.metrics &&
        aggregatedRoomMetricResponse.timeRange
    ) {
        const roomMetrics: RoomMetrics = {
            avgCallCountPerHour: aggregatedRoomMetricResponse.metrics.avgCallCountPerHour,
            avgCallDurationInMins: getMinutesFromSeconds(
                aggregatedRoomMetricResponse.metrics.avgCallDuration?.seconds,
            ),
            totalCallCount: aggregatedRoomMetricResponse.metrics.totalCallCount,
            missedCallCount: aggregatedRoomMetricResponse.metrics.missedCallCount,
            totalActiveHours: getHoursFromSeconds(
                aggregatedRoomMetricResponse.metrics.activeDuration?.seconds,
            ),
            unavailabilityPercentage: aggregatedRoomMetricResponse.metrics.unavailabilityPercentage,
        };

        const timeRange: TimeRange = {
            startDate: getMonthDateAndYear(aggregatedRoomMetricResponse.timeRange.startTime),
            endDate: getMonthDateAndYear(aggregatedRoomMetricResponse.timeRange.endTime),
        };

        return { roomMetrics: roomMetrics, timeRange: timeRange };
    }

    return { roomMetrics: null, timeRange: null };
}

/* Takes a Date and converts it to the Month, Date, and
 * year format expected by our TimeRange component
 * (ie "Apr 8, 2023")
 */
export function getMonthDateAndYear(date: Date): string {
    if (!date) return '';
    return date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });
}

/* Convert seconds to floor of minutes
 * for avgCallDurationInMins metric
 */
export function getMinutesFromSeconds(seconds: number): number {
    if (!seconds) return 0;
    return Math.floor(seconds / 60);
}

/* Convert seconds to floor of hours
 * for totalActiveHours metric
 */
export function getHoursFromSeconds(seconds: number): number {
    if (!seconds) return 0;
    return Math.floor(seconds / 3600);
}
