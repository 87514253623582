import { useCallback } from 'react';
import { useGetCompanyCallLogStore } from './list_store_hooks';
import { useActiveCompanyCode } from './use_active_company_code';

export const useCompanyCallLogRequest = () => {
    const PAGE_SIZE = 100;
    const companyCallLogStore = useGetCompanyCallLogStore();
    const requestFilter = companyCallLogStore.baseRequest?.filter;
    const numberOfResults = companyCallLogStore.totalSizeOfResults;
    const companyCode = useActiveCompanyCode();

    const fetchCallLog = useCallback(
        (groupFilters?: string[]) => {
            companyCallLogStore.fetchFirstPage({
                companyCodeOrSid: companyCode,
                pageSize: PAGE_SIZE,
                filter: requestFilter,
                groupNames: groupFilters,
            });
        },
        [requestFilter, companyCallLogStore, companyCode],
    );

    return { fetchCallLog, numberOfResults, loading: companyCallLogStore.loading };
};
