import { FC } from 'react';
import {
    AppText,
    Button,
    Column,
    Flex,
    Row,
    styled,
    Box,
    IconButton,
    useTheme,
} from '@streem/ui-react';
import repAvatar from './assets/customer_rep_sample.png';
import streemBackground from './assets/streem_background.jpg';
import urlBackground from './assets/url_background.png';
import poweredByStreem from './assets/powered_by_streem.png';
import { BackgroundImage, MobileExample } from './mobile_ui_previews.styles';

export const MobileExampleLargeLogo: FC<{
    logo?: any;
    streemBranding?: boolean;
    companyName?: string;
}> = ({ logo, streemBranding, companyName }) => {
    return (
        <MobileExample background="white">
            <Column alignItems="center" flexGrow={1} mt={6} mb={6}>
                <img
                    src={logo}
                    alt="large logo"
                    height={56}
                    width={56}
                    style={{ objectFit: 'contain' }}
                    data-testid="company-customization-large-logo-example"
                />
                <AppText size="small">Join a Streem video call with</AppText>
                <img
                    src={repAvatar}
                    alt="rep avatar"
                    width={80}
                    style={{ borderRadius: '50%', marginTop: '12px' }}
                />
                <AppText size="large" bold headingFontFamily>
                    Karla A.
                </AppText>
                <AppText>{companyName || 'Streem'}</AppText>
                <Button mt={4} mb={2} width="200px" tabIndex={-1} variant="primary">
                    Get Started
                </Button>
                <AppText size="small">Invitation expires in 60 minutes</AppText>
            </Column>
            {streemBranding && (
                <Box mb={4}>
                    <img src={poweredByStreem} alt="powered by Streem" height="40px" />
                </Box>
            )}
        </MobileExample>
    );
};

export const MobileExampleSmallLogo = (props: any) => {
    const theme = useTheme();
    return (
        <MobileExample background="rgba(0,0,0, 0.4)">
            <BackgroundImage src={streemBackground} alt="example streem" top={0} height={'100%'} />
            <Column justifyContent="space-between" alignItems="center" flexGrow={1} mt={6} mb={4}>
                <img
                    src={props.logo}
                    alt="small logo"
                    height={50}
                    width={150}
                    style={{ objectFit: 'contain' }}
                    data-testid="company-customization-watermark-example"
                />
                <Column alignItems="center" flexGrow={1}>
                    <img src={repAvatar} alt="avatar" width={50} style={{ borderRadius: '50%' }} />
                    <br />
                    <AppText bold color="light" headingFontFamily>
                        Karla A.
                    </AppText>
                    <AppText color="light">{props.companyName || 'Streem'}</AppText>
                </Column>
                <Column justifyContent="flex-end" alignItems="center" height={150} width="100%">
                    <Row width="180px" mt={4} justifyContent="space-between">
                        <IconButton
                            label="mute"
                            iconName="MuteIcon"
                            style={{ backgroundColor: theme.colors.grey50, opacity: 0.7 }}
                            fill="white"
                        />
                        <IconButton
                            label="end call"
                            iconName="PhoneIconFilled"
                            style={{ backgroundColor: theme.colors.red50 }}
                            fill="white"
                        />
                        <IconButton
                            label="camera"
                            iconName="CameraIcon"
                            style={{ backgroundColor: theme.colors.grey50, opacity: 0.7 }}
                            fill="white"
                        />
                    </Row>
                </Column>
            </Column>
        </MobileExample>
    );
};

const URLBackground = styled(Flex)(() => ({
    width: '100%',
    height: '70px',
    background: '#35363a',
    alignItems: 'center',
    justifyContent: 'center',
}));

const BrowserURLBar: FC<{ value: string }> = ({ value }) => {
    return (
        <URLBackground>
            <URLInput
                data-testid="company-customization-redirect-url-example"
                type="text"
                placeholder="www.streem.com"
                disabled
                value={value}
            />
        </URLBackground>
    );
};

const URLInput = styled.input(({ theme }) => ({
    background: '#202124',
    outline: 'none',
    border: 'none',
    width: '95%',
    height: '40px',
    padding: `${theme.space[1]}px ${theme.space[4]}px`,
    color: theme.colors.white,
    textOverflow: 'ellipsis',
    fontSize: theme.fontSizes[0],
    borderRadius: theme.radii[4],
    fontFamily: theme.text.bodyFontFamily,

    '::placeholder': {
        color: theme.colors.white,
    },
}));

export const MobileExampleURL: FC<{ previewInputValue: string }> = ({ previewInputValue }) => {
    return (
        <MobileExample>
            <BrowserURLBar value={previewInputValue} />
            <BackgroundImage src={urlBackground} alt="url example" top={70} bottom={0} />
        </MobileExample>
    );
};
