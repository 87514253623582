import protobuf from 'protobufjs/minimal';
import Long from 'long';
protobuf.util.Long = Long;
protobuf.configure();

import { google } from './streem_protobuf.es';
const {
    protobuf: { Timestamp },
} = google;

export { Timestamp, Long };
export * from './streem_protobuf.es';
