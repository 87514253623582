import { useState } from 'react';
import { ExtractProps, IconButton, Row, ToastTypes } from '@streem/ui-react';
import appLogger from '../../../util/logging/app_logger';
import { StringSettingLabel } from './field.styles';
import { StringSettingInput } from './string_setting_input';
import { StreemAPI } from '@streem/api';
import { useGlobalStore } from '../../../hooks/use_global_context';

interface Props extends ExtractProps<typeof Row> {
    name: string;
    code: string;
    onSave?: () => void;
}

export function CompanyNameField({ name, code, onSave, ...rowProps }: Props) {
    const [editValue, setEditValue] = useState(false);
    const { uiStore } = useGlobalStore();

    const updateCompanyName = async (value: string) => {
        try {
            await StreemAPI.companies.updateCompany(code, {
                name: value,
            });
            uiStore.removeToast('company-name-fail');
            onSave && onSave();
        } catch (error) {
            appLogger.error('Error Updating', 'Company Name', error);
            uiStore.addToast({
                content: `Uh oh, we were unable to save the changes to 'Company Name' at this time.\n\nPlease try refreshing the page and trying again. You may also contact our support staff for assistance.`,
                flavor: ToastTypes.ERROR,
                id: 'company-name-fail',
            });
        } finally {
            setEditValue(false);
        }
    };

    return (
        <Row
            alignItems="center"
            justifyContent="flex-end"
            minHeight="40px"
            alignSelf="flex-end"
            {...rowProps}
        >
            {!editValue && (
                <>
                    <StringSettingLabel title={name}>{name}</StringSettingLabel>
                    <IconButton
                        iconName="EditIcon"
                        label={`Edit Company Name`}
                        onClick={() => {
                            setEditValue(true);
                        }}
                        background="transparent"
                    />
                </>
            )}
            {editValue && (
                <StringSettingInput
                    name="COMPANY_NAME"
                    label="Company Name"
                    value={name}
                    onCancel={() => setEditValue(false)}
                    onSave={updateCompanyName}
                    validate={(value: string) => {
                        let valid = true;
                        let message = '';
                        if (value.length < 2) {
                            valid = false;
                            message = 'Company name must be at least two characters.';
                        } else if (value.length > 50) {
                            valid = false;
                            message = 'Company name must be at most fifty characters.';
                        }
                        return {
                            valid,
                            message,
                        };
                    }}
                />
            )}
        </Row>
    );
}
