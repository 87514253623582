import { FC, useEffect, useRef, useState } from 'react';
import { streem } from 'streem-sdk-protobuf';
import { StreemAPI } from '@streem/api';
import { observer } from 'mobx-react';
import {
    AppIcon,
    AppText,
    Flex,
    Select,
    styled,
    Theme,
    ToastTypes,
    Tooltip,
} from '@streem/ui-react';
import { useGlobalStore } from '../../hooks/use_global_context';
import { LegacyReservationBanner } from './legacy_reservation_banner';
import { statusOptions, StatusOptionValue, StatusOptionValueType } from './status_option_value';
import { useReservationAudio } from '@streem/sdk-react';
import appLogger from '../../util/logging/app_logger';
import { getPreferredAudioOutputId } from '@streem/toolbox';
import { WallItemSubtype } from '@streem/sdk-types';
import { PageLoadModal } from './modals/page_load_modal';
import { SetReadyModal } from './modals/set_ready_modal';
import { convertProtobufToSeconds } from './util/group_reservation_helpers';
import { useGetCompanyStore } from '../../hooks/detail_store_hooks';
import { ReservationBanner } from './reservation_banner';

const log = appLogger.extend('AvailabilityIndicator');

const AvailabilityStatus = streem.api.AvailabilityStatus;
const GroupReservationStatus = streem.api.GroupReservationStatus;

const wallResponseType = Object.entries(streem.api.AvailabilityStatus).map(([key, value]) => {
    return [value, key];
});
const apiResponseType = Object.fromEntries(wallResponseType);

const shouldShowBanner = (
    availabilityStatusWallItem:
        | WallItemSubtype<streem.user.UserWallItem, 'userAvailability'>
        | undefined,
    groupReservationWallItem:
        | WallItemSubtype<streem.user.UserWallItem, 'lastGroupReservation'>
        | undefined,
): boolean => {
    return (
        availabilityStatusWallItem?.availabilityStatus ===
            AvailabilityStatus.AVAILABILITY_STATUS_RESERVED &&
        groupReservationWallItem?.reservationStatus ===
            GroupReservationStatus.GROUP_RESERVATION_STATUS_MATCHED
    );
};

const shouldClearBanner = (
    availabilityStatusWallItem:
        | WallItemSubtype<streem.user.UserWallItem, 'userAvailability'>
        | undefined,
    groupReservationWallItem:
        | WallItemSubtype<streem.user.UserWallItem, 'lastGroupReservation'>
        | undefined,
): boolean => {
    return (
        availabilityStatusWallItem?.availabilityStatus !==
            AvailabilityStatus.AVAILABILITY_STATUS_RESERVED ||
        !groupReservationWallItem ||
        ![
            GroupReservationStatus.GROUP_RESERVATION_STATUS_MATCHED,
            GroupReservationStatus.GROUP_RESERVATION_STATUS_INITIATED,
        ].includes(groupReservationWallItem?.reservationStatus)
    );
};

const ACTIVE_MODAL_MESSAGES = {
    UPDATE_AVAILABILITY_STATUS: `It looks like you've been away for a while. Would you like to update your
                    availability status?`,
    BAD_NETWORK_STATUS:
        'Due to bad network connectivity, we have changed your status to Not Ready.',
};
export const AvailabilityIndicator: FC = observer(() => {
    const { authStore, companySettingsStore, sdkStore, uiStore } = useGlobalStore();
    const companyStore = useGetCompanyStore();

    const [previousAvailabilityStatus, setPreviousAvailabilityStatus] = useState<
        number | undefined
    >();

    const availabilityStatusWallItem = sdkStore?.userAvailabilityStatus?.current;
    const groupReservationWallItem = sdkStore?.groupReservation?.current;
    const defaultNotAvailableOption = statusOptions.find(option =>
        option.value.keyArray.includes(AvailabilityStatus.AVAILABILITY_STATUS_NOT_AVAILABLE),
    );
    const [selectedOption, setSelectedOption] = useState(defaultNotAvailableOption);
    const [isLoading, setIsLoading] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const [activeModalState, setActiveModalState] = useState({
        showModal: false,
        modalMessage: ACTIVE_MODAL_MESSAGES.UPDATE_AVAILABILITY_STATUS,
    });

    const [needsPageLoadModal, setNeedsPageLoadModal] = useState(true);
    const [pageLoadModalOpen, setPageLoadModalOpen] = useState(false);

    const { playReservationAudio, pauseReservationAudio } = useReservationAudio();
    const setOptionThatMatchesWallStatus = () => {
        log.info(
            `Availability status from user wall: ${
                AvailabilityStatus[availabilityStatusWallItem?.availabilityStatus]
            }`,
        );

        const matchedStatus = statusOptions.find(option => {
            return option.value.keyArray.includes(availabilityStatusWallItem?.availabilityStatus);
        });
        if (!matchedStatus) {
            log.info(
                `Matched AvailabilityStatus not found, meaning it is INVALID or OFFLINE. Defaulting selector to the NOT_AVAILABLE status`,
            );
            setSelectedOption(defaultNotAvailableOption);
            return defaultNotAvailableOption;
        } else {
            log.info(`Matched AvailabilityStatus: ${matchedStatus?.label}`);
            setSelectedOption(matchedStatus);
            return matchedStatus;
        }
    };

    const apiCall = async (option: StatusOptionValueType) => {
        setNeedsPageLoadModal(false);
        if (option.value.key !== selectedOption.value.key) {
            const newStatus = apiResponseType[option.value.key];
            setIsLoading(true);

            try {
                await StreemAPI.users.updateUserAvailability(authStore.userId, {
                    availabilityStatus: newStatus,
                });
                log.info(`Updated availability status via API to: ${newStatus}`);
            } catch (e) {
                setIsLoading(false);
                const revertedOption = setOptionThatMatchesWallStatus();
                log.error(
                    `Error updating user availability status via API to: ${newStatus}, reverted selected option to: ${revertedOption?.label}`,
                    e,
                );
                uiStore.addToastWithManualClose({
                    content: 'There was an error changing your status',
                    flavor: ToastTypes.ERROR,
                    id: 'user-availability-status-update-fail',
                });
            }
        }
    };

    const isReserved =
        selectedOption.label === 'Reserved' &&
        (groupReservationWallItem?.reservationStatus ===
            GroupReservationStatus.GROUP_RESERVATION_STATUS_MATCHED ||
            groupReservationWallItem?.reservationStatus ===
                GroupReservationStatus.GROUP_RESERVATION_STATUS_CONFIRMING);

    const isInCall =
        selectedOption.label === 'In Call' &&
        groupReservationWallItem?.reservationStatus ===
            GroupReservationStatus.GROUP_RESERVATION_STATUS_CONNECTED;

    const isDisabledStatus = isReserved || isInCall || isLoading;

    const getToolTipMessage = () => {
        if (isReserved) return 'reserved';
        if (isInCall) return 'in a call';
    };

    const timeout = useRef(null);
    const tenMinutesInMilliSeconds = 600000;

    useEffect(() => {
        if (availabilityStatusWallItem) {
            const updatedOption = setOptionThatMatchesWallStatus();
            if (
                previousAvailabilityStatus === AvailabilityStatus.AVAILABILITY_STATUS_CONFIRMING &&
                availabilityStatusWallItem?.availabilityStatus ===
                    AvailabilityStatus.AVAILABILITY_STATUS_NOT_AVAILABLE
            ) {
                setActiveModalState({
                    showModal: true,
                    modalMessage: ACTIVE_MODAL_MESSAGES.BAD_NETWORK_STATUS,
                });
            }
            log.info(
                `Received new wall item availability status: ${
                    AvailabilityStatus[availabilityStatusWallItem?.availabilityStatus]
                }, updated selected option to: ${updatedOption?.label}`,
            );
            setPreviousAvailabilityStatus(availabilityStatusWallItem?.availabilityStatus);
            setIsLoading(false);
        }

        if (
            availabilityStatusWallItem?.availabilityStatus ===
            AvailabilityStatus.AVAILABILITY_STATUS_NOT_AVAILABLE
        ) {
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                setActiveModalState({
                    showModal: true,
                    modalMessage: ACTIVE_MODAL_MESSAGES.UPDATE_AVAILABILITY_STATUS,
                });
            }, tenMinutesInMilliSeconds);
        }

        if (
            availabilityStatusWallItem?.availabilityStatus !==
                AvailabilityStatus.AVAILABILITY_STATUS_NOT_AVAILABLE &&
            timeout.current
        ) {
            clearTimeout(timeout.current);
            setActiveModalState({
                showModal: false,
                modalMessage: ACTIVE_MODAL_MESSAGES.UPDATE_AVAILABILITY_STATUS,
            });
        }

        if (
            availabilityStatusWallItem?.availabilityStatus ===
                AvailabilityStatus.AVAILABILITY_STATUS_AVAILABLE &&
            needsPageLoadModal
        ) {
            setPageLoadModalOpen(true);
            log.info(`Showing page load modal for user ${authStore.userId}`);
            setNeedsPageLoadModal(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availabilityStatusWallItem]);

    useEffect(() => {
        const playReservationAudioAsync = async () => {
            try {
                log.info(
                    `Playing reservation ringtone for reservationSid=${
                        groupReservationWallItem.reservationSid
                    } with preferredAudioOutputId=${getPreferredAudioOutputId()}`,
                );
                await playReservationAudio(groupReservationWallItem.reservationSid, 4, 5000);
            } catch (error) {
                log.warn(
                    `Failed to play Reservation ringtone for reservationSid=${
                        groupReservationWallItem.reservationSid
                    } with preferredAudioOutputId=${getPreferredAudioOutputId()} ${error}`,
                );
            }
        };

        if (
            availabilityStatusWallItem?.availabilityStatus ===
            AvailabilityStatus.AVAILABILITY_STATUS_AVAILABLE
        ) {
            pauseReservationAudio();
        }
        if (shouldShowBanner(availabilityStatusWallItem, groupReservationWallItem)) {
            log.info(
                `Showing Upcoming Call banner for reservation: ${groupReservationWallItem.reservationSid}`,
            );
            if (
                companySettingsStore.ahsVirtualExpertEnabled &&
                groupReservationWallItem.callDetail
            ) {
                log.debug('AHS Virtual Expert is enabled, showing the new reservation banner');
                sdkStore.setGroupReservationCallSource(companyStore.result?.callSources);
                uiStore.addBannerNotification(() => (
                    <ReservationBanner
                        reservationCallDetail={groupReservationWallItem.callDetail}
                        reservationCallSource={sdkStore.groupReservationCallSource}
                        expirationTime={groupReservationWallItem.reservedUntil}
                        secondsReserved={groupReservationWallItem.reserveForSeconds}
                        isReconnect={!!groupReservationWallItem.reconnectPreviousReservationSid}
                        groupName={groupReservationWallItem.group.name}
                    />
                ));
            } else {
                uiStore.addBannerNotification(() => (
                    <LegacyReservationBanner
                        expirationTime={groupReservationWallItem.reservedUntil}
                        secondsReserved={groupReservationWallItem.reserveForSeconds}
                        customerDetails={groupReservationWallItem.details}
                        isReconnect={!!groupReservationWallItem.reconnectPreviousReservationSid}
                    />
                ));
            }

            playReservationAudioAsync();
        }
        if (shouldClearBanner(availabilityStatusWallItem, groupReservationWallItem)) {
            uiStore.clearBannerNotification();
            sdkStore?.clearGroupReservationCallSource();
        }
    }, [
        availabilityStatusWallItem,
        groupReservationWallItem,
        uiStore,
        playReservationAudio,
        pauseReservationAudio,
        companySettingsStore.ahsVirtualExpertEnabled,
        companyStore.result?.callSources,
        sdkStore,
    ]);

    useEffect(() => {
        // Show a toast if the user cancels the reservation, but make sure it's not an old reservation that's still the most recent one
        if (
            groupReservationWallItem?.reservationStatus ===
                GroupReservationStatus.GROUP_RESERVATION_STATUS_CANCELED &&
            convertProtobufToSeconds(groupReservationWallItem?.reservedUntil) > Date.now() / 1000
        ) {
            uiStore.addToast({
                flavor: ToastTypes.WARNING,
                id: `group-reservation-canceled-${groupReservationWallItem.id}`,
                content: (
                    <AppText>
                        Your upcoming call was canceled. <br />
                        Your status has been set back to Ready.
                    </AppText>
                ),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupReservationWallItem?.reservationStatus]);

    return (
        <>
            <Tooltip
                message={
                    <AppText size="small" style={{ whiteSpace: 'nowrap' }}>
                        Cannot set status while {getToolTipMessage()}
                    </AppText>
                }
                offsetValue={-13}
                placement={'bottom'}
                showTooltip={showTooltip}
            >
                <AvailabilityWrapper
                    data-testid="availability-container"
                    onMouseEnter={() => {
                        if (isReserved || isInCall) {
                            setShowTooltip(true);
                        }
                    }}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    <Select<StatusOptionValue>
                        setExternallyControlledOption={selectedOption =>
                            setSelectedOption(selectedOption)
                        }
                        externallyControlledOption={selectedOption}
                        options={statusOptions}
                        onSelect={apiCall}
                        initialValue={selectedOption}
                        isDisabled={isDisabledStatus}
                        isSearchable={false}
                        filterOption={option => {
                            const { data } = option as { data: StatusOptionValueType };
                            return data.label !== 'Reserved' && data.label !== 'In Call';
                        }}
                        components={{
                            ClearIndicator: () => null,
                            IndicatorSeparator: () => null,
                            Input: () => null,
                            SingleValue: ({ data }: { data: StatusOptionValueType }) => {
                                return (
                                    <SelectedStatusContainer data-testid="selected-status">
                                        {data.value.icon}
                                        <SelectedStatusLabel
                                            headingFontFamily
                                            data-testid={`selected-status-label-${data.label}`}
                                            style={{
                                                color: isDisabledStatus
                                                    ? Theme.colors.grey60
                                                    : undefined,
                                            }}
                                        >
                                            {data.label}
                                        </SelectedStatusLabel>
                                    </SelectedStatusContainer>
                                );
                            },
                        }}
                        formatOptionLabel={option => {
                            const { value } = option as {
                                value: StatusOptionValue;
                            };
                            return <OptionLabel value={value} selectedOption={selectedOption} />;
                        }}
                        styles={{
                            control: (base: any, { selectProps }) => ({
                                ...base,
                                width: '170px',
                                height: '40px',
                                borderRadius: '32px',
                                border: 'none',
                                cursor: 'pointer',
                                ':hover': {
                                    background: Theme.colors.blue05,
                                },
                                background: selectProps.isDisabled
                                    ? Theme.colors.grey10
                                    : undefined,
                            }),
                            menu: (base: any) => ({
                                ...base,
                                marginTop: '6px',
                                borderRadius: 4,
                                right: 0,
                                width: '242px',
                            }),
                            menuList: (base: any) => ({
                                ...base,
                                padding: 0,
                                width: '242px',
                            }),
                            option: (base: any, { isFocused }) => ({
                                ...base,
                                borderRadius: '4px',
                                backgroundColor: isFocused ? Theme.colors.blue05 : undefined,
                                cursor: 'pointer',
                                ':active': {
                                    backgroundColor: Theme.colors.blue05,
                                },
                            }),
                            valueContainer: () => ({
                                height: '100%',
                            }),
                            dropdownIndicator: (base: any, { selectProps }) => ({
                                ...base,
                                transform: selectProps.menuIsOpen ? 'rotate(180deg)' : null,
                                transition: 'all .2s ease',
                                color: selectProps.isDisabled
                                    ? Theme.colors.grey10
                                    : Theme.colors.grey50,
                                ':hover': {
                                    color: Theme.colors.grey50,
                                },
                            }),
                        }}
                    />
                </AvailabilityWrapper>
            </Tooltip>
            <SetReadyModal
                message={activeModalState.modalMessage}
                setActive={() =>
                    apiCall(
                        statusOptions.find(
                            option =>
                                option.value.key ===
                                AvailabilityStatus.AVAILABILITY_STATUS_AVAILABLE,
                        ),
                    )
                }
                isOpen={activeModalState.showModal}
                closeModal={() =>
                    setActiveModalState({
                        showModal: false,
                        modalMessage: ACTIVE_MODAL_MESSAGES.UPDATE_AVAILABILITY_STATUS,
                    })
                }
            />
            <PageLoadModal
                isOpen={pageLoadModalOpen}
                closeModal={() => {
                    log.info(
                        `${authStore.userId} has interacted with the page and closed the page load modal`,
                    );
                    setPageLoadModalOpen(false);
                }}
                setNotReady={() =>
                    apiCall(
                        statusOptions.find(
                            option =>
                                option.value.key ===
                                AvailabilityStatus.AVAILABILITY_STATUS_NOT_AVAILABLE,
                        ),
                    )
                }
            />
        </>
    );
});

AvailabilityIndicator.displayName = 'AvailabilityIndicator';

const AvailabilityWrapper = styled.div({
    display: 'flex',
    marginRight: '24px',
});

const StatusCircle = styled.div<{ label: string }>(({ label, theme }) => ({
    height: '10px',
    width: '10px',
    borderRadius: 50,
    backgroundColor: label === 'Ready' ? theme.colors.green60 : theme.colors.red60,
    marginRight: theme.spacing.s,
}));

const SelectedStatusContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginLeft: '12px',
});

const SelectedStatusLabel = styled(AppText)({
    width: '88px',
    display: 'block',
    textAlign: 'center',
});

const OptionContainer = styled(Flex)(({ theme }) => ({
    alignItems: 'baseline',
    padding: theme.spacing.xs,
}));

const OptionLabel: FC<{
    value: StatusOptionValue;
    selectedOption: StatusOptionValueType;
}> = ({ value, selectedOption }) => (
    <OptionContainer
        data-testid={`availability-${value.label.toLowerCase().replace(/\s+/g, '-')}-button`}
    >
        <StatusCircle label={value.label} />
        <div>
            <AppText style={{ display: 'block', marginBottom: '4px' }}>{value.label}</AppText>
            <AppText size="small">{value.description}</AppText>
        </div>
        {selectedOption.label === value.label && (
            <AppIcon
                name="DoneIcon"
                color={Theme.colors.blue40}
                style={{ alignSelf: 'center', marginLeft: '18px', flexGrow: 1 }}
            />
        )}
    </OptionContainer>
);
