import { APITypes } from '@streem/api';
import { TableDataRecord, TableColumn, Column, Box, AppText } from '@streem/ui-react';
import { formatDateColumn } from '@streem/toolbox';
import { History } from 'history';
import config from '../../config';

export type CompanyRow = APITypes.StreemApiCompany & TableDataRecord;

export interface CompaniesListColumnProps {
    history: History;
}

function nameColumn(): TableColumn<CompanyRow> {
    return {
        key: 'name',
        name: 'Company',
        sortable: true,
        initialSortColumn: true,
        render: (row: CompanyRow) => (
            <Column>
                <AppText as="h4" headingFontFamily size="large">
                    {row.name}
                </AppText>
                <Box mt={2}>
                    <AppText>{row.code + '.' + config.vanityDomain}</AppText>
                </Box>
            </Column>
        ),
    };
}

function statusColumn(): TableColumn<CompanyRow> {
    return {
        key: 'active',
        name: 'Status',
        sortable: true,
        render: (row: CompanyRow) => (row.active ? 'ACTIVE' : 'DEACTIVATED'),
    };
}

function dateColumn(): TableColumn<CompanyRow> {
    return {
        key: 'created',
        name: 'Created',
        sortable: false,
        render: row => {
            if (!row?.createdAt) {
                return <Column></Column>;
            }
            const [date] = formatDateColumn(row.createdAt.toISOString(), {
                returnDayAndTime: true,
                dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric' },
            });
            const Paragraph = AppText.withComponent('p');
            return (
                <Box>
                    <Paragraph>
                        <time dateTime={row.createdAt.toISOString()}>{date}</time>
                    </Paragraph>
                </Box>
            );
        },
    };
}

export const columnBuilder = {
    nameColumn,
    statusColumn,
    dateColumn,
};
