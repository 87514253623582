import { useEffect, useState } from 'react';
import { useActiveCompanyCode } from './use_active_company_code';
import { useGetCompanyCallLogStore } from './list_store_hooks';
import { CallLogRow } from '../components/calls/call_log_columns';
import appLogger from '../util/logging/app_logger';
import { convertCallLogEntriesToEmbedCallLogRows } from '../util/calls';

const log = appLogger.extend('useGetEmbedCallLogEntries');

export const useGetEmbedCallLogRows = (integrationId: string): CallLogRow[] => {
    const [callLogEntryRows, setCallLogEntryRows] = useState(null);
    const companyCode = useActiveCompanyCode();
    const companyCallLogStore = useGetCompanyCallLogStore();

    useEffect(() => {
        async function fetchFirstCallLogPage(companyCode: string, integrationId: string) {
            const PAGE_SIZE = 100;
            /* STREEM-15109 Note: this behavior is a preliminary step to having our users switch from using a refId
             * to using an integrationId until backend support for this feature is implemented.
             * For the time being, an integrationId or refId can be used to retrieve results that
             * match a refId in the database. Update after STREEM-14888 has been merged.
             */
            try {
                await companyCallLogStore.fetchFirstPage({
                    companyCodeOrSid: companyCode,
                    pageSize: PAGE_SIZE,
                    filter: integrationId,
                });
            } catch (e) {
                log.error(
                    'Embed 2.0: There was an error fetching the first page of Call Log Entries',
                    e,
                );
            }
        }
        if (integrationId) {
            fetchFirstCallLogPage(companyCode, integrationId);
        }
    }, [companyCallLogStore, companyCode, integrationId]);

    useEffect(() => {
        if (integrationId && companyCallLogStore.complete) {
            setCallLogEntryRows(
                convertCallLogEntriesToEmbedCallLogRows(integrationId, companyCallLogStore.results),
            );
        }
    }, [companyCallLogStore, companyCallLogStore.complete, integrationId]);

    return callLogEntryRows;
};
