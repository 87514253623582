import { APITypes } from '@streem/api';
import { CallLogRow } from '../components/calls/call_log_columns';
import { getSecondsFromProtobufDuration } from './protobuf';

export const getCallDurationStringFromRoom = (room: APITypes.StreemApiRoom): string | null => {
    if (!room.closedAt) {
        return 'Live';
    }

    let durationInSeconds = getSecondsFromProtobufDuration(room.callDuration);
    // fallback for null or 0 values until call_duration_in_seconds in room db table is backfilled in all environments
    if (!durationInSeconds) {
        if (!room.openedAt) return '';
        durationInSeconds = Math.round((room.closedAt.getTime() - room.openedAt.getTime()) / 1000);
    }

    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    if (minutes < 1) {
        return `${seconds} sec`;
    }
    return `${minutes} min ${seconds} sec`;
};

const getCallDurationInSecondsFromCallLogRow = (callLogRow: CallLogRow): number | null => {
    if (callLogRow.callDuration) {
        const seconds = getSecondsFromProtobufDuration(callLogRow.callDuration);
        // if callDuration is 0, use fallback logic
        if (seconds > 0) {
            return seconds;
        }
    }

    // fallback until call_duration_in_secs in room db table is backfilled in all environments
    if (!callLogRow.startTime || !callLogRow.endTime) return null;
    return Math.round((callLogRow.endTime.getTime() - callLogRow.startTime.getTime()) / 1000);
};

export const getFormattedCallDuration = (callLogRow: CallLogRow): string => {
    const durationInSeconds = getCallDurationInSecondsFromCallLogRow(callLogRow);
    if (!durationInSeconds) return '';

    const hours = Math.floor(durationInSeconds / 3600) % 24;
    const minutes = Math.floor(durationInSeconds / 60) % 60;
    const seconds = durationInSeconds % 60;

    const hoursString = hours > 9 ? `${hours}` : `0${hours}`;
    const minutesString = minutes > 9 ? `${minutes}` : `0${minutes}`;
    const secondsString = seconds > 9 ? `${seconds}` : `0${seconds}`;
    return `${hoursString}:${minutesString}:${secondsString}`;
};
