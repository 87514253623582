import { useEffect } from 'react';

export function useRestoreFocus(
    activeElement: HTMLElement | null = document.activeElement as HTMLElement,
): void {
    useEffect(() => {
        return () => {
            if (activeElement && typeof activeElement.focus === 'function') {
                requestAnimationFrame(() => activeElement.focus());
            }
        };
        // TypeScript doesn't like not providing activeElement, but
        // we're unable to provide document.activeElement because it's
        // a mutatable outer value.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
