import { flow, observable, computed, isFlowCancellationError } from 'mobx';
import appLogger from '../util/logging/app_logger';
import { Logger } from '@streem/logger';
import { APIError } from '@streem/api';

export type APICall = (...args: any[]) => PromiseLike<unknown>;

export enum APICallState {
    loading,
    complete,
    error,
    refreshing,
    loadingMore,
}

export type APICallResponse<T extends APICall> = ReturnType<T> extends PromiseLike<infer R>
    ? R
    : never;

export class APICallStore<T extends APICall> {
    private log: Logger;
    private lastArgs?: Parameters<T>;

    @observable
    public response?: APICallResponse<T>;

    @observable
    public state: APICallState = APICallState.loading;

    @observable
    public lastError?: APIError;

    constructor(private readonly apiCall: T, apiCallName: string) {
        this.log = appLogger.extend(`APICallStore:${apiCallName}`);
    }

    @computed
    public get loading() {
        return this.state === APICallState.loading;
    }

    @computed
    public get complete() {
        return this.state === APICallState.complete;
    }

    public fetch(...args: Parameters<T>) {
        const promise = this._fetchFlow(...args);
        promise.catch((err: Error) => {
            if (!isFlowCancellationError(err)) {
                throw err;
            }
        });
        return promise;
    }

    private _fetchFlow = flow(this._fetch);

    private *_fetch(...args: Parameters<T>): Generator<unknown> {
        this.response = undefined;
        this.state = APICallState.loading;
        this.lastError = undefined as APIError;

        try {
            const response = yield this.apiCall(...args);
            this.lastArgs = args;
            this.response = response as APICallResponse<T>;
            this.state = APICallState.complete;
        } catch (e: unknown) {
            if (e instanceof APIError) {
                this.log.error(e);
                this.lastError = e;
                this.state = APICallState.error;
            }
        }
    }

    public refreshable() {
        return this.lastArgs !== undefined;
    }

    public refresh() {
        const promise = this._refreshFlow();
        promise.catch((err: Error) => {
            if (!isFlowCancellationError(err)) {
                throw err;
            }
        });
        return promise;
    }

    private _refreshFlow = flow(this._refresh);

    private *_refresh(): Generator<unknown> {
        if (!this.lastArgs) {
            this.log.warn('Unable to refresh API call without lastArgs.');
            return;
        }

        this.state = APICallState.refreshing;
        this.lastError = undefined;

        try {
            const response = yield this.apiCall(...this.lastArgs);
            this.response = response as APICallResponse<T>;
            this.state = APICallState.complete;
        } catch (e: unknown) {
            if (e instanceof APIError) {
                this.log.error(e);
                this.lastError = e;
                this.state = APICallState.error;
            }
        }
    }
}
