import { Button, Row } from '@streem/ui-react';
import { useFormikContext } from 'formik';
import { Diagnosis, RoomOutcomeFormPayload } from '../../types/disposition.types';
import { DispositionFormStatus } from './room_outcome_form';

interface ButtonFooterProps {
    status: DispositionFormStatus;
    disableNext: boolean;
    disableDone: boolean;
    diagnoses: Diagnosis[];
    setStatus: (status: DispositionFormStatus) => void;
    setDiagnoses: (diagnoses: Diagnosis[]) => void;
}

export const ButtonFooter = ({
    status,
    disableNext,
    disableDone,
    diagnoses,
    setStatus,
    setDiagnoses,
}: ButtonFooterProps): JSX.Element => {
    const { values, setFieldValue } = useFormikContext<RoomOutcomeFormPayload>();

    const handleBack = () => {
        setStatus('diagnosis');

        setFieldValue('customFields', {
            ...values.customFields,
            refund: {
                shouldRefund: false,
                reason: undefined,
            },
            outOfScope: {
                isOutOfScopeDiscussion: false,
                reason: undefined,
            },
        });
    };

    const handleNext = () => {
        const nonNullDiagnoses = diagnoses.filter(d => d.label !== '');
        setFieldValue('diagnoses', nonNullDiagnoses);
        setDiagnoses(nonNullDiagnoses);
        setStatus('disposition');
    };

    return (
        <>
            {status === 'diagnosis' && (
                <Row justifyContent={'space-between'}>
                    <Button
                        width="150px"
                        data-testid="diagnosis-back-btn"
                        type="button"
                        variant="secondary"
                        disabled
                    >
                        Back
                    </Button>
                    <Button
                        width="150px"
                        data-testid="diagnosis-next-btn"
                        type="button"
                        variant="primary"
                        onClick={handleNext}
                        disabled={disableNext}
                    >
                        Next
                    </Button>
                </Row>
            )}
            {status === 'disposition' && (
                <Row justifyContent={'space-between'}>
                    <Button
                        width="150px"
                        data-testid="disposition-back-btn"
                        type="button"
                        variant="secondary"
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                    <Button
                        width="150px"
                        data-testid="disposition-submit-btn"
                        type="submit"
                        variant="primary"
                        disabled={disableDone}
                    >
                        Done
                    </Button>
                </Row>
            )}
            {status === 'dispositionOnly' && (
                <Button
                    width="150px"
                    data-testid="disposition-submit-btn"
                    type="submit"
                    variant="primary"
                    disabled={disableDone}
                    style={{ alignSelf: 'flex-end' }}
                >
                    Done
                </Button>
            )}
        </>
    );
};
