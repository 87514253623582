interface FormValues {
    [key: string]: any;
}
export function trimFormValues<T extends FormValues>(formValues: T, fields: string[]): T {
    return fields.reduce((formValues, field) => {
        if (formValues[field]) {
            (formValues as FormValues)[field] = formValues[field].trim();
        }
        return formValues;
    }, formValues);
}
