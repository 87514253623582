import { APITypes } from '@streem/api';
import { AppText, TableDataRecord, TableColumn, AppIcon, Row } from '@streem/ui-react';
import { NameColumn } from '../../companies/name_column';
import { RoomMetrics } from '../../../util/metrics';

export type GroupRow = APITypes.StreemApiGroup & TableDataRecord;

type GroupMetricsRow = RoomMetrics & GroupRow;

export interface GroupColumnProps {
    handleDelete?: (row: GroupMetricsRow, e: any) => void;
}

function nameColumn(isGroup: boolean): TableColumn<GroupMetricsRow> {
    return {
        key: 'name',
        name: `${isGroup ? 'Group' : 'Member'} Name`,
        render: (row: GroupMetricsRow) => <NameColumn row={row} />,
        sortable: false,
    };
}

function descriptionColumn(): TableColumn<GroupMetricsRow> {
    return {
        key: 'description',
        name: 'Description',
        render: (row: GroupMetricsRow) => <AppText>{row.description}</AppText>,
        sortable: false,
    };
}

function deleteColumn({ handleDelete }: GroupColumnProps): TableColumn<GroupMetricsRow> {
    return {
        key: 'delete',
        name: 'Delete',
        contentJustify: 'center',
        onCellClick: (record: GroupMetricsRow, e: any) => handleDelete(record, e),
        render: () => (
            <Row flexGrow={1} justifyContent="center" textAlign="right">
                <AppIcon name="TrashIcon" />
            </Row>
        ),
        sortable: false,
    };
}

function avgCallCountPerHourColumn(): TableColumn<GroupMetricsRow> {
    return {
        key: 'avgCallsPerHour',
        name: 'Calls Per Hour',
        render: (row: GroupMetricsRow) => <AppText>{row.avgCallCountPerHour}</AppText>,
        sortable: false,
    };
}
function avgCallDurationInMinsColumn(): TableColumn<GroupMetricsRow> {
    return {
        key: 'avgHandleTime',
        name: 'Avg Handle Time',
        render: (row: GroupMetricsRow) => <AppText>{row.avgCallDurationInMins}</AppText>,
        sortable: false,
    };
}

function totalCallCountColumn(): TableColumn<GroupMetricsRow> {
    return {
        key: 'totalCallCount',
        name: 'Total Calls',
        render: (row: GroupMetricsRow) => <AppText>{row.totalCallCount}</AppText>,
        sortable: false,
    };
}

function missedCallCountColumn(): TableColumn<GroupMetricsRow> {
    return {
        key: 'missedCallCount',
        name: 'Missed Calls',
        render: (row: GroupMetricsRow) => <AppText>{row.missedCallCount}</AppText>,
        sortable: false,
    };
}

function totalActiveHoursColumn(): TableColumn<GroupMetricsRow> {
    return {
        key: 'totalActiveHours',
        name: 'Active Hours',
        render: (row: GroupMetricsRow) => <AppText>{row.totalActiveHours}</AppText>,
        sortable: false,
    };
}
function unavailabilityPercentageColumn(): TableColumn<GroupMetricsRow> {
    return {
        key: 'unavailabilityPercentage',
        name: '% Unavailable',
        render: (row: GroupMetricsRow) => (
            <AppText>
                {row.unavailabilityPercentage === null || row.unavailabilityPercentage === undefined
                    ? ''
                    : `${row.unavailabilityPercentage}%`}
            </AppText>
        ),
        sortable: false,
    };
}

export const columnBuilder = {
    nameColumn,
    descriptionColumn,
    deleteColumn,
    avgCallCountPerHourColumn,
    avgCallDurationInMinsColumn,
    totalCallCountColumn,
    missedCallCountColumn,
    totalActiveHoursColumn,
    unavailabilityPercentageColumn,
};
