import Lottie from 'react-lottie';
import { MeshBackground, Row, Card, Column, AppText } from '@streem/ui-react';
import StreemAnimated from '../../assets/animation-logo.json';

interface EmbedMessageScreenProps {
    message: string;
    children?: JSX.Element;
}

export const EmbedMessageScreen = ({ message, children }: EmbedMessageScreenProps): JSX.Element => (
    <div style={{ width: '100%', overflow: 'hidden' }}>
        <MeshBackground>
            <Row width="90%" margin="auto" minWidth="1100px">
                <Row alignItems="center" justifyContent="center" height="100vh" width="100vw">
                    <Card
                        style={{ height: '300px', width: '400px' }}
                        data-testid="embed-waiting-screen"
                    >
                        <Lottie
                            style={{
                                height: '56px',
                                width: '56px',
                                marginTop: '16px',
                                marginBottom: '16px',
                            }}
                            isClickToPauseDisabled
                            options={{
                                animationData: StreemAnimated,
                                autoplay: true,
                                rendererSettings: {
                                    preserveAspectRatio: 'xMidYMid slice',
                                },
                            }}
                        />
                        <Column style={{ textAlign: 'center' }}>
                            <AppText bold headingFontFamily size="xlarge">
                                {message}
                            </AppText>
                            {children}
                        </Column>
                    </Card>
                </Row>
            </Row>
        </MeshBackground>
    </div>
);
