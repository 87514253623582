import {
    AppIcon,
    AppText,
    Box,
    CallLogWarningMessage,
    Column,
    IconAvatarCell,
    IconNames,
    Row,
    TableColumn,
    TableDataRecord,
    TeamMember,
    Theme,
    Tooltip,
} from '@streem/ui-react';
import { APITypes } from '@streem/api';
import { formatDateColumn } from '@streem/toolbox';
import { callHasEnded, getIconAvatarDetails, getToFromUsers } from '../../util/calls';
import { useGlobalStore } from '../../hooks/use_global_context';
import appLogger from '../../util/logging/app_logger';
import { FavoriteButton } from '../buttons/favorite_button';
import { recordTooltipViewed } from '@streem/analytics';
import { getFormattedCallDuration } from '../../util/call_duration_helpers';
import { DropdownMenuColumn } from './dropdown_menu_column';
import { FC, useState } from 'react';
import { StreemApiCallLogEntryCallStatus } from '@streem/api/lib/openapi';

export type CallLogRow = APITypes.StreemApiCallLogEntry & TableDataRecord;

export interface CallLogColumnProps {
    theme: typeof Theme;
    hideGPSLocations?: boolean;
    showCallOutcomeWarning?: boolean;
    handleRoomArchive?: (row: CallLogRow) => void;
    showDeactivateColumn?: boolean;
}

const CustomerColumn: FC<{ row: CallLogRow; theme: typeof Theme }> = ({ row, theme }) => {
    const { customer } = getToFromUsers(row);
    const { title, icon, color } = getIconAvatarDetails(row, customer, theme);
    return (
        <IconAvatarCell
            headline={customer.name ?? 'No Customer Found'}
            subHeadline={row.referenceId ?? 'No reference ID available'}
            icon={icon}
            fillColor={color}
            backgroundColor={''}
            title={title}
        />
    );
};

function customerColumn(props: CallLogColumnProps): TableColumn<CallLogRow> {
    return {
        key: 'customer',
        name: 'Customer',
        sortable: false,
        render: CallLogRow => {
            const callOutcomeReportNotSubmitted = CallLogRow.artifactCounts.roomOutcomeReport === 0;
            const { showCallOutcomeWarning } = props;
            return (
                <Row alignItems="center" flexGrow={1}>
                    <Row flexBasis="300px" mr={4}>
                        <CustomerColumn row={CallLogRow} theme={props.theme} />
                    </Row>
                    {showCallOutcomeWarning &&
                        callOutcomeReportNotSubmitted &&
                        CallLogRow.callStatus === 'STATUS_SUCCESSFUL' && (
                            <CallLogWarningMessage message="Call Disposition not submitted. Action needed." />
                        )}
                </Row>
            );
        },
    };
}

interface TeamMemberCellProps {
    row: CallLogRow;
}

function TeamMemberCell({ row }: TeamMemberCellProps) {
    const { expert: teamMember } = getToFromUsers(row);

    return <TeamMember name={teamMember.name} avatarUrl={teamMember.avatarUrl} />;
}

function teamMemberColumn(): TableColumn<CallLogRow> {
    return {
        key: 'teamMember',
        name: 'Team Member',
        sortable: false,
        render: row => <TeamMemberCell row={row} />,
    };
}

const FavoriteColumnWithUserId: FC<{
    initialValue: boolean;
    roomSid: string;
    disabled: boolean;
    refreshCallback: () => void;
    debounceDelay?: number;
}> = ({ initialValue, roomSid, disabled, refreshCallback, debounceDelay = 0 }) => {
    const { authStore } = useGlobalStore();
    const logger = appLogger.extend('call log');

    return (
        <Box ml="16px" mr="20px">
            <FavoriteButton
                initialValue={initialValue}
                roomSid={roomSid}
                userSid={authStore.userId}
                handleError={(message: string, e: Error) => logger.error(message, roomSid, e)}
                disabled={disabled}
                debounceDelay={debounceDelay}
                onToggleFavorite={refreshCallback}
            />
        </Box>
    );
};

function favoriteCallColumn(
    listStoreRefresh: () => void,
    debounceDelay?: number,
): TableColumn<CallLogRow> {
    return {
        key: 'favorite',
        name: 'Favorite',
        sortable: false,
        render: row => (
            <FavoriteColumnWithUserId
                roomSid={row.roomSid}
                initialValue={row.favorite}
                disabled={row.callStatus !== 'STATUS_SUCCESSFUL'}
                refreshCallback={listStoreRefresh}
                debounceDelay={debounceDelay}
            />
        ),
    };
}

function geoLocationColumn(props: CallLogColumnProps): TableColumn<CallLogRow> {
    return {
        key: 'geolocation',
        name: 'Last Location',
        sortable: false,
        render: row => {
            if (!row?.latestGpsPosition) {
                return (
                    <Box display="grid" gridTemplateColumns="1fr" alignItems="center">
                        <AppText color="medium">No location available</AppText>
                    </Box>
                );
            }

            return (
                <Box display="grid" gridTemplateColumns="40px 1fr" alignItems="center">
                    <AppIcon name="GPSIcon" size="large" color={props.theme.colors.grey90} />
                    <AppText>{row.latestGpsPosition.formattedAddress}</AppText>
                </Box>
            );
        },
    };
}

function artifactColumn(props: CallLogColumnProps): TableColumn<CallLogRow> {
    return {
        key: 'artifact',
        name: 'Call Details',
        contentJustify: 'center',
        sortable: false,
        render: row => {
            if (
                row.callStatus !== 'STATUS_SUCCESSFUL' ||
                (row.artifactCounts.streemshot === 0 &&
                    row.artifactCounts.gpsPosition === 0 &&
                    row.artifactCounts.bookmark === 0)
            ) {
                return null;
            }
            return (
                <Row justifyContent="center" width={'100%'}>
                    {row.artifactCounts.streemshot > 0 && (
                        <ArtifactCountIcon
                            icon="StreemshotIcon"
                            count={row.artifactCounts.streemshot}
                        />
                    )}
                    {row.artifactCounts.gpsPosition > 0 && !props.hideGPSLocations && (
                        <ArtifactCountIcon icon="GPSIcon" count={row.artifactCounts.gpsPosition} />
                    )}
                    {row.artifactCounts.bookmark > 0 && (
                        <ArtifactCountIcon
                            icon="BookmarkIcon"
                            count={row.artifactCounts.bookmark}
                        />
                    )}
                </Row>
            );
        },
    };
}

const ArtifactCountIcon: FC<{ count: number; icon: IconNames }> = ({ count, icon }) => {
    return (
        <Row
            mr={3}
            maxWidth={'58px'}
            borderRadius={8}
            backgroundColor={'#f4f7f9'}
            padding="6px"
            alignItems="center"
            justifyContent="space-between"
        >
            <AppIcon iconSize={'18'} name={icon} />
            <AppText
                style={{
                    marginLeft: icon === 'StreemshotIcon' ? '4px' : '0px',
                    whiteSpace: 'nowrap',
                }}
            >
                {count}
            </AppText>
        </Row>
    );
};

const CustomerCanceledMessage = ({ duration }: { duration: string }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const customerCanceledTooltipId = 'duration-column-customer-canceled-tooltip';
    const handleMouseEnter = () => {
        setShowTooltip(true);
        recordTooltipViewed(customerCanceledTooltipId);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <>
            <Tooltip
                message={
                    <AppText style={{ whiteSpace: 'nowrap' }} size="small">
                        Customer canceled after {duration} secs
                    </AppText>
                }
                showTooltip={showTooltip}
                distance={18}
                placement="top"
            >
                <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <AppText color="red50">Customer Canceled</AppText>
                </div>
            </Tooltip>
        </>
    );
};

function durationColumn(): TableColumn<CallLogRow> {
    return {
        key: 'duration',
        name: 'Duration',
        sortable: false,
        contentJustify: 'center',
        render: row => {
            if (row?.callStatus === 'STATUS_CANCELED') {
                return (
                    <Row flexGrow={1} justifyContent="center">
                        <CustomerCanceledMessage duration={row?.canceledCallRingTimeInSecs} />
                    </Row>
                );
            }
            if (row?.callStatus === 'STATUS_TIMED_OUT') {
                return (
                    <Row flexGrow={1} justifyContent="center">
                        <AppText color="red50">Timed Out</AppText>
                    </Row>
                );
            }
            if (row?.callStatus === 'STATUS_REJECTED') {
                return (
                    <Row flexGrow={1} justifyContent="center">
                        <AppText color="red50">Rejected</AppText>
                    </Row>
                );
            }
            if (!callHasEnded(row)) {
                return (
                    <Row flexGrow={1} justifyContent="center">
                        <AppText>Live</AppText>
                    </Row>
                );
            }
            if (!row?.startTime) {
                return <Column></Column>;
            }
            const duration = getFormattedCallDuration(row);
            return (
                <Row flexGrow={1} justifyContent="center">
                    <AppText>{duration}</AppText>
                </Row>
            );
        },
    };
}

function dateColumn(): TableColumn<CallLogRow> {
    return {
        key: 'date',
        name: 'Time of Call',
        sortable: false,
        contentJustify: 'flex-end',
        render: row => {
            if (!row?.startTime) {
                return <Column></Column>;
            }
            const [day, time] = formatDateColumn(row.startTime.toISOString(), {
                returnDayAndTime: true,
                dateFormat: { month: 'short', day: 'numeric', year: 'numeric' },
            });
            const Paragraph = AppText.withComponent('p');
            return (
                <Row flexGrow={1} justifyContent="flex-end" textAlign="right">
                    <Paragraph color={row.callStatus !== 'STATUS_SUCCESSFUL' ? 'red50' : 'dark'}>
                        <time dateTime={row.startTime.toISOString()}>
                            {time}
                            <br />
                            {day}
                        </time>
                    </Paragraph>
                </Row>
            );
        },
    };
}

function dropdownMenuColumn(props: CallLogColumnProps): TableColumn<CallLogRow> {
    function enableDropdown(
        callStatus: StreemApiCallLogEntryCallStatus | undefined,
        endTime: Date | undefined,
    ) {
        return (
            !['STATUS_REJECTED', 'STATUS_TIMED_OUT', 'STATUS_CANCELED'].includes(callStatus) &&
            endTime != null
        );
    }

    return {
        key: 'menu',
        name: '',
        sortable: false,
        contentJustify: 'center',
        render: (row: CallLogRow) => {
            return (
                <DropdownMenuColumn
                    row={row}
                    handleRoomArchive={props.handleRoomArchive}
                    enableDropdown={enableDropdown(row?.callStatus, row?.endTime)}
                />
            );
        },
    };
}

export const columnBuilder = {
    customerColumn,
    geoLocationColumn,
    favoriteCallColumn,
    teamMemberColumn,
    artifactColumn,
    durationColumn,
    dateColumn,
    dropdownMenuColumn,
};
