import { AppText, Button, Modal, styled } from '@streem/ui-react';

interface OkayModalProps {
    handleClose: () => void;
    body: string;
    headerText: string;
    open: boolean;
    testId: string;
}
export const OkayModal = ({
    handleClose,
    body,
    headerText,
    open,
    testId,
}: OkayModalProps): JSX.Element => {
    return (
        <Modal
            closeOnOverlayClick={false}
            hideCloseButton={true}
            isOpen={open}
            onClose={handleClose}
            styleOverride={{
                content: {
                    maxWidth: '45rem',
                },
            }}
        >
            <AppText
                size="xlarge"
                bold
                data-testid={`${testId}-header`}
                headingFontFamily
                style={{
                    display: 'block',
                }}
            >
                {headerText}
            </AppText>
            <AppText
                size="mediumLarge"
                style={{
                    display: 'block',
                    marginTop: '1.5rem',
                }}
                data-testid={`${testId}-body`}
            >
                {body}
            </AppText>
            <ButtonWrapper>
                <Button onClick={handleClose} data-testid={`${testId}-okay-button`}>
                    Okay
                </Button>
            </ButtonWrapper>
        </Modal>
    );
};

const ButtonWrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '2.75rem',
});
