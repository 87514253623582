import { useTheme, Row, BasicInput, IconButton, AppText, Column } from '@streem/ui-react';
import { useRestoreFocus } from '../../../hooks/use_restore_focus';
import { useCallback, ChangeEvent, useState, KeyboardEvent, FormEvent } from 'react';
import { kebabCase } from '../../../util/strings';

const FormRow = Row.withComponent('form');
interface Validation {
    valid: boolean;
    message: string;
}
interface Props {
    name: string;
    label: string;
    value: string;
    onCancel: () => void;
    onSave: (value: string) => void;
    validate?: (value: string) => Validation | Promise<Validation>;
    monochrome?: boolean;
}

export function StringSettingInput({
    name,
    label,
    value,
    onCancel,
    onSave,
    validate,
    monochrome,
}: Props) {
    const theme = useTheme();
    useRestoreFocus();
    const [inputValue, setInputValue] = useState(value);
    const [validation, setValidation] = useState<Validation>({ valid: true, message: '' });
    const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Escape') {
                onCancel();
            }
        },
        [onCancel],
    );
    const handleChange = useCallback(
        async (e: ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.currentTarget.value);
            if (validate) {
                const validation = await validate(e.currentTarget.value);
                setValidation(validation);
            }
        },
        [validate],
    );
    const handleSubmit = useCallback(
        (e: FormEvent<any>) => {
            e.preventDefault();
            validation.valid && onSave(inputValue);
        },
        [inputValue, onSave, validation.valid],
    );
    return (
        <Column flex="1">
            <FormRow alignItems="center" mr={2} onSubmit={handleSubmit}>
                <BasicInput
                    color={monochrome ? theme.colors.phosphorGreen : undefined}
                    autoFocus={true}
                    border={
                        validation.valid ? '1px solid black' : `2px solid ${theme.colors.red50}`
                    }
                    margin={0}
                    hideLabel={true}
                    name={name}
                    value={inputValue}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder={label}
                    label={label}
                    data-testid={`${kebabCase(name)}-text-input`}
                />
                <IconButton
                    iconName="CloseIcon"
                    label="Cancel"
                    fill={monochrome ? theme.colors.phosphorGreen : undefined}
                    hoverFill={monochrome ? undefined : theme.colors.red50}
                    onClick={onCancel}
                    shape="square"
                    background="transparent"
                    data-testid={`${kebabCase(name)}-close-icon`}
                />
                <IconButton
                    type="submit"
                    iconName="DoneIcon"
                    label="Save Changes"
                    disabled={!validation.valid}
                    fill={monochrome ? theme.colors.phosphorGreen : undefined}
                    hoverFill={monochrome ? undefined : theme.colors.azure}
                    shape="square"
                    background="transparent"
                    data-testid={`${kebabCase(name)}-submit-icon`}
                />
            </FormRow>
            <AppText color={monochrome ? 'phosphorGreen' : 'red50'} style={{ height: '20px' }}>
                {validation.message}
            </AppText>
        </Column>
    );
}
