import { useState } from 'react';
import { CompanyRegistration } from '@streem/toolbox';
import {
    styled,
    AppIcon,
    Avatar,
    AppText,
    RoundedSearch,
    ProfileBlub,
    LoadingOverlay,
    Sensor,
    Button,
    Box,
    CSSTransition,
} from '@streem/ui-react';
import { ev2UrlFromCompanyCode } from '../util/routing';
import { IFrameLogoutMonitor } from '../components/iframe_logout_monitor';

interface UserMeta {
    name: string | undefined;
    avatarUrl: string | undefined;
    email: string | undefined;
}

interface CentralizedLoginViewProps {
    user: UserMeta;
    companies: CompanyRegistration[];
    onLogOut: () => void;
}

export const CentralizedLoginView: React.FC<CentralizedLoginViewProps> = ({
    user,
    companies,
    onLogOut,
}) => {
    const [filter, setFilter] = useState<string>();
    const [showProfileBlub, setShowProfileBlub] = useState(false);
    const [isProfileBlubMounted, setIsProfileBlubMounted] = useState(false);

    return (
        <CentralizedLoginViewWrapper>
            <IFrameLogoutMonitor />
            <NavBar>
                <StreemLogo href="https://streem.com">
                    <AppIcon name="StreemLogo" style={{ transform: 'scale(1.2)' }} />
                </StreemLogo>
                <UserWrapper
                    onClick={() =>
                        !isProfileBlubMounted ? setShowProfileBlub(!showProfileBlub) : null
                    }
                    data-testid={'nav-user-avatar'}
                >
                    <Avatar
                        src={user.avatarUrl || ''}
                        displayName={user.name || 'User'}
                        size="small"
                    />
                    <div>
                        <AppText as="h2" size="medium" bold>
                            {user.name || 'User'}
                        </AppText>
                    </div>
                </UserWrapper>
            </NavBar>
            <CSSTransition
                in={showProfileBlub}
                timeout={200}
                classNames="profile-blub"
                onEntering={() => setIsProfileBlubMounted(true)}
                onExited={() => setIsProfileBlubMounted(false)}
            >
                <ProfileBlubWrapper>
                    {isProfileBlubMounted && (
                        <Sensor
                            onEscape={() => setShowProfileBlub(false)}
                            onClickOutside={() => setShowProfileBlub(false)}
                        >
                            <ProfileBlub
                                name={user.name || 'User'}
                                avatarUrl={user.avatarUrl}
                                email={user.email!}
                                onLogOut={onLogOut}
                            />
                        </Sensor>
                    )}
                </ProfileBlubWrapper>
            </CSSTransition>
            {companies.length > 0 ? (
                <BodyWrapper>
                    <WorkSpaceTitle>
                        <AppText as="h1" size="xxlarge" bold headingFontFamily>
                            Choose a Workspace
                        </AppText>
                    </WorkSpaceTitle>
                    <AppText as="h2" size="large" color="medium">
                        This email has access to multiple workspaces. Please choose one to continue.
                    </AppText>
                    <WorkspacesWrapper>
                        <RoundedSearch
                            name="Search workspaces"
                            placeholder="Search workspaces"
                            onChange={e => {
                                e.persist();
                                setFilter(e.target.value);
                            }}
                            label={'filter companies'}
                            style={{ width: '100%' }}
                            value={filter}
                        />
                        <WorkSpaceGrid data-testid="workspace-grid">
                            {companies
                                .filter(company => filterCompanies(company, filter))
                                .sort(sortCompanies)
                                .map(company =>
                                    company?.companyCode ? (
                                        <WorkSpace
                                            key={company?.companyCode}
                                            href={ev2UrlFromCompanyCode(company?.companyCode)}
                                            data-testid={`workspace-${company?.companyCode}`}
                                        >
                                            <WorkSpaceImage logoUrl={company?.companyLogoUrl}>
                                                {!company?.companyLogoUrl && (
                                                    <AppText
                                                        as="p"
                                                        bold
                                                        size="medium"
                                                        color="light"
                                                    >
                                                        {company?.companyName?.charAt(0)}
                                                    </AppText>
                                                )}
                                            </WorkSpaceImage>
                                            <div>
                                                <WorkSpaceCompanyName
                                                    as="h3"
                                                    size="medium"
                                                    bold
                                                    headingFontFamily
                                                >
                                                    {company?.companyName}
                                                </WorkSpaceCompanyName>
                                                <WorkSpaceCompanyUrl as="h4" size="small">
                                                    {/* Regex removes everything up to and including // in the url*/}
                                                    {ev2UrlFromCompanyCode(
                                                        company.companyCode,
                                                    ).replace(/(^\w+:|^)\/\//, '')}
                                                </WorkSpaceCompanyUrl>
                                            </div>
                                        </WorkSpace>
                                    ) : (
                                        <LoadingOverlay key={company?.companyCode} />
                                    ),
                                )}
                        </WorkSpaceGrid>
                    </WorkspacesWrapper>
                </BodyWrapper>
            ) : (
                <NoWorkspacesWrapper>
                    <Box mb={'16px'}>
                        <AppText as="h1" size="xlarge" bold>
                            Sorry, you don't have access to any workspaces.
                        </AppText>
                    </Box>
                    <Box mb={'8px'}>
                        <AppText as="p" color={'medium'}>
                            {`Your account ${user.email} doesn't have access to any workspaces.`}
                        </AppText>
                    </Box>
                    <Box mb={'8px'}>
                        <AppText as="p" color={'medium'}>
                            Please check your email for an invitation or contact your admin.
                        </AppText>
                    </Box>
                    <Box mt={'16px'}>
                        <Button
                            onClick={() => {
                                onLogOut();
                            }}
                        >
                            Log Out
                        </Button>
                    </Box>
                </NoWorkspacesWrapper>
            )}
        </CentralizedLoginViewWrapper>
    );
};

const filterCompanies = (company: CompanyRegistration, filter: string | undefined) => {
    if (!filter) return true;
    return (
        company?.companyName?.toLowerCase().includes(filter.toLowerCase().trim()) ||
        company?.companyCode?.toLowerCase().includes(filter.toLowerCase().trim())
    );
};

const sortCompanies = (companyA: CompanyRegistration, companyB: CompanyRegistration) => {
    const companyAName = companyA?.companyName?.toUpperCase() || '';
    const companyBName = companyB.companyName?.toUpperCase() || '';
    switch (true) {
        case companyAName > companyBName:
            return 1;
        case companyAName < companyBName:
            return -1;
        default:
            return 0;
    }
};

const WorkSpaceGrid = styled.div(({ theme }) => ({
    marginTop: theme.spacing.l,
    display: 'grid',
    gridGap: '24px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(296px, 1fr))',
}));

const WorkSpaceCompanyName = styled(AppText)`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: anywhere;
`;

const WorkSpaceCompanyUrl = styled(AppText)`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: anywhere;
    font-size: 0.875rem;
`;

const WorkSpace = styled.a(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    padding: theme.spacing.s,
    borderRadius: '10px',
    ':hover': {
        backgroundColor: theme.colors.hoverBlue,
    },
}));

const WorkSpaceImage = styled.div<{ logoUrl?: string }>(({ theme, logoUrl }) => ({
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${logoUrl})`,
    backgroundColor: logoUrl ? theme.colors.white : theme.colors.darkGradientTop,
    height: '48px',
    width: '48px',
    border: `1px solid ${theme.colors.grey10}`,
    borderRadius: '10px',
    marginRight: theme.spacing.s,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const WorkSpaceTitle = styled.div(() => ({
    marginBottom: '12px',
}));

const BodyWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '40px',
    paddingRight: theme.spacing.xl,
    paddingLeft: theme.spacing.xl,
    paddingBottom: '40px',
    overflow: 'auto',
}));

const WorkspacesWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: '32px',
    marginRight: theme.spacing.l,
    marginLeft: theme.spacing.l,
    width: '100%',
    maxWidth: '960px',
}));

const NavBar = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing.xs} ${theme.spacing.l}`,
    borderBottom: `1px solid ${theme.colors.grey10}`,
}));

const StreemLogo = styled.a(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: theme.spacing.m,
    alignItems: 'center',
}));

const UserWrapper = styled.button(({ theme }) => ({
    background: 'none',
    cursor: 'pointer',
    padding: `${theme.spacing.s} ${theme.spacing.m} ${theme.spacing.s} ${theme.spacing.s}`,
    border: 'none',
    borderRadius: '28px',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: theme.spacing.s,
    alignItems: 'center',
    ':hover': {
        backgroundColor: theme.colors.hoverBlue,
    },
}));

const ProfileBlubWrapper = styled.div(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing.l,
    transition: 'all 0.2s cubic-bezier(0, 0.25, 0.25, 1)',
    opacity: 0,
    transform: 'scale(0)',
    transformOrigin: '100% 0',
}));

const CentralizedLoginViewWrapper = styled.div(() => ({
    position: 'relative',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    '[class*="profile-blub-enter"]': {
        opacity: 1,
        transform: 'scale(1)',
        transformOrigin: '100% 0',
    },
}));

const NoWorkspacesWrapper = styled.div(() => ({
    height: 'calc(100vh - 65px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
}));
