import { useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { Observer } from 'mobx-react';
import { LookerEmbedSDK } from '@looker/embed-sdk';
import { AppText, Button, Column, Row, Spinner, styled, Box } from '@streem/ui-react';
import { PageColumn } from '../page_column';
import PageHeader from '../../components/page_header/page_header';
import { createGetCompanyStore } from '../../hooks/detail_store_hooks';
import { useActiveCompanyCode } from '../../hooks/use_active_company_code';
import { possession } from '../../util/strings';
import { useLookerDashboard } from '../../hooks/use_looker_dashboard';
import config from '../../config';
import { useGlobalStore } from '../../hooks/use_global_context';

export const CompanyReportsPage = () => {
    const dashboardRef = useRef<HTMLDivElement>(null);
    const { authStore, companySettingsStore } = useGlobalStore();
    const { dashboardLoaded, dashboardError, initCreateLookerDashboard } = useLookerDashboard();
    const companyStore = createGetCompanyStore();
    const companyCode = useActiveCompanyCode();

    // This is needed to correctly display the company name in the header when viewing the page
    // as super admin in the admin dashboard.
    // TODO: Investigate if we should move this to a custom hook since we have several areas where
    // we do this. Also investigate if we should move this to the page header component or
    // the nav bar component to only have to do this once. https://streempro.atlassian.net/browse/ST-3775
    useEffect(() => {
        const promises = [companyStore.fetch(companyCode)];
        return () => promises.forEach(p => p.cancel());
    }, [companyStore, companyCode]);

    useEffect(() => {
        if (companySettingsStore.loading || !companySettingsStore.embeddedReportsEnabled) return;

        // Used to monitor latency of board loading time
        const startTime = performance.now();
        LookerEmbedSDK.initCookieless(
            config.lookerHost,
            () => authStore.getEmbedReportsSessionTokens(),
            () => authStore.refreshEmbedReportsSessionTokens(),
        );
        initCreateLookerDashboard(dashboardRef, startTime);
    }, [
        initCreateLookerDashboard,
        authStore,
        companySettingsStore.loading,
        companySettingsStore.embeddedReportsEnabled,
    ]);

    if (companySettingsStore.loading) {
        return <Spinner />;
    }

    if (!companySettingsStore.embeddedReportsEnabled) {
        return <Redirect to="/" />;
    }

    return (
        <PageColumn
            dataTestId="reports-page"
            style={{
                paddingLeft: '16px',
                paddingRight: '16px',
            }}
        >
            <Observer>
                {() => (
                    <PageHeader
                        title={
                            companyStore.result?.name
                                ? `${possession(companyStore.result.name)} Reports`
                                : ''
                        }
                    />
                )}
            </Observer>

            {!dashboardError ? (
                <EmbedReportsContainer data-testid="reports-looker-iframe" ref={dashboardRef}>
                    {!dashboardLoaded && (
                        <SpinnerWrapper>
                            <Spinner />
                        </SpinnerWrapper>
                    )}
                </EmbedReportsContainer>
            ) : (
                <ErrorEncountered />
            )}
        </PageColumn>
    );
};

const EmbedReportsContainer = styled(Box)({
    height: '100%',
    width: '100%',
    '& > iframe': {
        height: '99% !important',
        width: '100%',
    },
});

const SpinnerWrapper = styled(Box)({
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
});

const ErrorEncountered = () => {
    return (
        <Column style={{ gap: '12px', marginTop: '15rem' }}>
            <Row justifyContent="center">
                <AppText color="black" headingFontFamily style={{ fontSize: '18px' }}>
                    Looks like we encountered an unexpected problem
                </AppText>
            </Row>
            <Row justifyContent="center">
                <AppText color="grey50">Please refresh the page and try again</AppText>
            </Row>
            <Row justifyContent="center">
                <Button
                    fontSize="small"
                    variant="tertiary"
                    onClick={() => window.location.reload()}
                >
                    Refresh
                </Button>
            </Row>
        </Column>
    );
};
