import {
    MeshBackground,
    RadialFade,
    Row,
    StreemStackedLogoLegal,
    styled,
    Card,
    Header,
    AppText,
    Button,
} from '@streem/ui-react';

export const LoginWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    padding: 0 64px;
    justify-items: center;
    align-items: center;
    height: 100vh;

    @media (min-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }
`;

export function CookiesDisabledPage() {
    return (
        <MeshBackground>
            <LoginWrapper>
                <Row alignItems="center" justifyContent="center">
                    <RadialFade>
                        <StreemStackedLogoLegal color="white" width="400px" height="300px" />
                    </RadialFade>
                </Row>
                <Card shadow="large">
                    <Header style={{ marginBottom: 16 }}>Allow Cookies to Login</Header>
                    <AppText as="p" style={{ marginBottom: 16 }}>
                        Please adjust your browser settings or preferences to allow cookies, then
                        refresh this page. Cookie preferences may be located in the privacy or
                        security tab of your browser.
                    </AppText>
                    <Button variant="primary" onClick={() => window.location.reload()}>
                        Refresh
                    </Button>
                </Card>
            </LoginWrapper>
        </MeshBackground>
    );
}
