import { useCallback, useState } from 'react';
import { Modal, AppText, Tooltip } from '@streem/ui-react';
import { InviteCard, InviteValues } from './invite_card';
import { useGlobalStore } from '../../hooks/use_global_context';
import { observer } from 'mobx-react';

interface ChildProps {
    open: boolean;
    onClick: () => void;
}
interface Props {
    children: (props: ChildProps) => React.ReactNode;
    initialFormData?: InviteValues;
    disableTooltip?: boolean;
}

export const InviteToStreemButton: React.FC<Props> = observer(
    ({ children, disableTooltip, initialFormData }) => {
        const { authStore } = useGlobalStore();
        const [showModal, setShowModal] = useState(false);
        const [showTooltip, setShowTooltip] = useState(false);

        const handleInviteClick = useCallback(() => {
            setShowTooltip(false);
            setShowModal(true);
        }, [setShowModal]);

        const handleClose = useCallback(() => {
            setShowModal(false);
        }, [setShowModal]);

        const handleMouseEnter = () => setShowTooltip(true);
        const handleMouseOut = () => setShowTooltip(false);

        if (!authStore.isUserSignedIn) {
            return null;
        }

        return (
            <>
                <Tooltip
                    message={
                        <AppText style={{ whiteSpace: 'nowrap' }} size="small">
                            Invite to Streem Call
                        </AppText>
                    }
                    showTooltip={!disableTooltip && showTooltip}
                    distance={18}
                    placement="right"
                >
                    <div
                        onMouseEnter={handleMouseEnter}
                        onMouseOver={handleMouseEnter}
                        onMouseOut={handleMouseOut}
                        onFocus={handleMouseEnter}
                        onBlur={handleMouseOut}
                    >
                        {children({
                            open: showModal,
                            onClick: handleInviteClick,
                        })}
                    </div>
                </Tooltip>
                {showModal && (
                    <Modal isOpen={true} onClose={handleClose}>
                        <InviteCard initialFormData={initialFormData} onDone={handleClose} />
                    </Modal>
                )}
            </>
        );
    },
);
