import { useCallback } from 'react';
import { Switch, ToastTypes } from '@streem/ui-react';
import { useCompanySettings } from '../../../hooks/company_settings_hooks';
import appLogger from '../../../util/logging/app_logger';
import { useGlobalStore } from '../../../hooks/use_global_context';
import { Setting } from '../../../util/company_settings';

interface Props {
    setting: Setting;
    monochrome?: boolean;
}

function getCheckedFromValue(value?: string): boolean {
    if (value === undefined || value.toLowerCase() === 'false') return false;
    return Boolean(value);
}

export function BooleanSettingField({ setting, monochrome }: Props) {
    const companySettingsStore = useCompanySettings();
    const { uiStore } = useGlobalStore();

    const handleToggle = useCallback(
        async (checked: boolean) => {
            if (!companySettingsStore) {
                return;
            }
            try {
                await companySettingsStore.updateSetting(setting.name, checked.toString());
                uiStore.removeToast(`setting-${setting.label}-fail`);
            } catch (error) {
                appLogger.error('Error Updating Setting', setting.name, error);
                uiStore.addToast({
                    content: `Uh oh, we were unable to save the changes to '${setting.label}' at this time.\n\nPlease try refreshing the page and trying again. You may also contact our support staff for assistance.`,
                    flavor: ToastTypes.ERROR,
                    id: `setting-${setting.label}-fail`,
                });
            }
        },
        [companySettingsStore, setting, uiStore],
    );

    return (
        <Switch
            checked={getCheckedFromValue(setting.value)}
            monochrome={monochrome}
            label={`Toggle ${setting.name}`}
            onToggle={handleToggle}
        />
    );
}
