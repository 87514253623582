import { useState, useEffect } from 'react';

export enum LocalStorageKey {
    HIDE_INACTIVE_USERS = 'STREEM_WEB_GA:HIDE_INACTIVE_USERS',
}

/**
 *
 * @param key maps to LocalStorageKey enum for type safety
 * @param fallbackValue if no value is found in local storage, this value will be used as the iniital default until updated
 * @returns
 */
export function useLocalStorage<T>(
    key: LocalStorageKey,
    fallbackValue: T,
): { value: T; setValue: (val: T) => void } {
    if (!Object.values(LocalStorageKey).includes(key)) {
        throw new Error(`Invalid local storage key: ${key}`);
    }
    const initialValue = localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem(key))
        : fallbackValue;
    const [value, _setValue] = useState<T>(initialValue);
    useEffect(() => {
        const stringifiedValue = JSON.stringify(value);
        localStorage.setItem(key, stringifiedValue);
    }, [value, key]);

    const setValue = (val: T) => {
        if (typeof val !== typeof fallbackValue) {
            throw new Error(
                `Invalid value type: ${typeof val}. Value type must match fallback value type: ${typeof fallbackValue}`,
            );
        }
        _setValue(val);
    };

    return { value, setValue };
}
