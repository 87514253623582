import { APITypes } from '@streem/api';

export interface Setting extends APITypes.StreemApiSetting, SettingMeta {
    name: string;
}

export interface SettingMeta {
    label: string;
    description: string;
    superAdmin?: boolean;
    order: number;
    visible?: () => boolean;
    fieldType?: string;
    testId?: string;
    helpLinkHref?: string;
    helpLinkDataTestId?: string;
    /** hide the toggle for a setting. useful for integer type settings */
    hideToggle?: boolean;
}

export interface SettingsDictionary {
    [key: string]: APITypes.StreemApiSetting;
}

export interface DisplayableSettingsDictionary {
    [key: string]: SettingMeta;
}

export function createSettingsDictionary(settings: APITypes.StreemApiSetting[]) {
    return settings.reduce(
        (humanFriendlySettings: { [key: string]: APITypes.StreemApiSetting }, setting) => {
            if (setting.name) humanFriendlySettings[setting.name] = setting;
            return humanFriendlySettings;
        },
        {},
    );
}
