import { CSSProperties, useEffect, useState } from 'react';
import { audio } from '@streem/sdk-react';
import { AppIcon, AppText, Button, styled } from '@streem/ui-react';

interface AudioButtonProps {
    styles?: CSSProperties;
    audioPermissionDenied?: boolean;
}

const PlayingAudio = ({ styles }: AudioButtonProps) => {
    return (
        // need to make this the same height as the button (44px)
        <Wrapper
            styles={{ height: '2.75rem', ...styles }}
            data-testid="speaker-settings-audio-playing-indicator"
        >
            <AppIcon name="WaveformCircleIcon" size="1.25rem" color="blue50" />
            <AppText size="medium" semibold color="grey30" style={{ marginLeft: '.5rem' }}>
                Playing...
            </AppText>
        </Wrapper>
    );
};

interface TestAudioButtonProps extends AudioButtonProps {
    onClick: () => void;
}
const PlayAudioButton = ({ styles, onClick, audioPermissionDenied }: TestAudioButtonProps) => {
    return (
        <Wrapper styles={styles}>
            <ButtonWrapper
                onClick={onClick}
                variant="tertiary"
                data-testid="speaker-settings-test-speaker-button"
                disabled={audioPermissionDenied}
            >
                <AppIcon
                    name="SpeakerWithoutAnimationIcon"
                    size="1.25rem"
                    color={audioPermissionDenied ? 'grey30' : 'blue50'}
                />
                <AppText
                    size="medium"
                    semibold
                    color={audioPermissionDenied ? 'grey30' : 'blue50'}
                    style={{ marginLeft: '.5rem' }}
                >
                    Test Speaker
                </AppText>
            </ButtonWrapper>
        </Wrapper>
    );
};

const TestAudioButton = ({ styles, audioPermissionDenied }: AudioButtonProps) => {
    const [playing, setPlaying] = useState(false);
    const handleClick = () => {
        setPlaying(true);
        audio.playRingtoneAudio();
        setTimeout(() => {
            setPlaying(false);
            // for some reason this doesn't work: setTimeout(audio.pauseRingtoneAudio, 3000)
            audio.pauseRingtoneAudio();
        }, 3000);
    };

    useEffect(() => {
        return () => {
            setPlaying(false);
            audio.pauseRingtoneAudio();
        };
    }, []);

    if (playing) {
        return <PlayingAudio styles={styles} />;
    }
    return (
        <PlayAudioButton
            styles={styles}
            onClick={handleClick}
            audioPermissionDenied={audioPermissionDenied}
        />
    );
};

const Wrapper = styled.span(({ styles }: AudioButtonProps) => ({
    display: 'inline-flex',
    alignItems: 'center',
    ...styles,
}));

const ButtonWrapper = styled(Button)({
    left: '1rem', // this accounts for the right padding on the button
    position: 'relative',
});

export default TestAudioButton;
