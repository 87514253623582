import { FC, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { APIError } from '@streem/api';
import { Header, WhiteInput, AppText, Button, Modal } from '@streem/ui-react';
import {
    ContainedForm,
    TopRow,
    MiddleRow,
    BottomRow,
    Inputs,
    ErrorContainer,
    FormButtons,
} from './shared_form.styles';
import appLogger from '../util/logging/app_logger';
import { ValidatedField } from '../components/inputs/validated_field';
import { trimFormValues } from '../util/form';

interface Props {
    handleCancel: () => void;
    handleSubmit: ({ name, description }: { name: string; description: string }) => void;
    isOpen: boolean;
}

const CreateGroupForm: FC<Props> = ({ handleCancel, handleSubmit, isOpen }) => {
    const MIN_GROUP_NAME_LENGTH = 2;
    const MAX_GROUP_NAME_LENGTH = 50;
    const [genericSubmitError, setGenericSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);
    const log = appLogger.extend('create group form');
    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                setGenericSubmitError(false);
                handleCancel();
            }}
            closeDelayMS={200}
        >
            <Formik
                validateOnMount={true}
                initialValues={{
                    name: '',
                    description: '',
                }}
                validationSchema={yup.object().shape({
                    name: yup
                        .string()
                        .required('*Required')
                        .min(
                            MIN_GROUP_NAME_LENGTH,
                            `Group name must be at least ${MIN_GROUP_NAME_LENGTH} characters`,
                        )
                        .max(
                            MAX_GROUP_NAME_LENGTH,
                            `Group name must be at most ${MAX_GROUP_NAME_LENGTH} characters`,
                        )
                        .matches(
                            /^[A-Za-z0-9][-A-Za-z0-9--]*[A-Za-z0-9]$/,
                            'The group identifier can contain letters, numbers, or dashes, but cannot start or end with a dash.',
                        ),
                })}
                onSubmit={async (values, { setFieldError }) => {
                    const safeFormValues = trimFormValues(values, ['name']);
                    setLoading(true);
                    try {
                        setGenericSubmitError(false);
                        await handleSubmit(safeFormValues);
                        handleCancel();
                    } catch (error) {
                        setGenericSubmitError(true);
                        if (error instanceof APIError) {
                            const status = error.response.status;
                            status === 409 &&
                                setFieldError(
                                    'name',
                                    'A group with that group name already exists',
                                );
                        }

                        log.error('Failed to create group', error);
                    } finally {
                        setLoading(false);
                    }
                }}
            >
                {({ isValid, isSubmitting, dirty }) => {
                    return (
                        <ContainedForm data-testid="create-group-form">
                            <TopRow>
                                <Header
                                    style={{ wordBreak: 'break-word' }}
                                    size="xxlarge"
                                    color="dark"
                                >
                                    Create a New Group
                                </Header>
                            </TopRow>
                            <MiddleRow>
                                <Inputs>
                                    <ValidatedField
                                        autoFocus={true}
                                        required
                                        type="text"
                                        name="name"
                                        placeholder="Add group name/identifier"
                                        data-testid="group-name-field"
                                        component={WhiteInput}
                                        label="group name/identifier"
                                        labelColor="dark"
                                        helpText="The group identifier can contain letters, numbers, or dashes, but cannot start or end with a dash."
                                    />
                                    <ValidatedField
                                        autoFocus={false}
                                        type="text"
                                        name="description"
                                        placeholder="Add group description"
                                        data-testid="group-description-field"
                                        component={WhiteInput}
                                        label="group description"
                                        labelColor="dark"
                                    />
                                </Inputs>
                            </MiddleRow>
                            {genericSubmitError && (
                                <ErrorContainer>
                                    <AppText color="red50">
                                        Form submission error. Please try again.
                                    </AppText>
                                </ErrorContainer>
                            )}
                            <BottomRow>
                                <FormButtons>
                                    <Button
                                        variant="secondary"
                                        data-testid="cancel-create-group"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        variant="primary"
                                        disabled={!dirty || !isValid || isSubmitting}
                                        type="submit"
                                        data-testid="submit-create-group"
                                        loading={loading}
                                    >
                                        Create Group
                                    </Button>
                                </FormButtons>
                            </BottomRow>
                        </ContainedForm>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default CreateGroupForm;
