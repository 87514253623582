import { DetailStore } from '../stores/detail_store';
import { localStoreHookFactory } from './local_store_hook_factory';
import { StreemAPI } from '@streem/api';

// Wraps the getCompany method with an DetailStore for frontend use.
export const [
    createGetCompanyStore,
    GetCompanyProvider,
    useGetCompanyStore,
] = localStoreHookFactory(
    'GetCompanyStore',
    () =>
        new DetailStore(StreemAPI.companies.getCompany.bind(StreemAPI.companies), {
            filter: r => (r.company ? r.company : undefined),
            fnName: 'getCompany',
        }),
);

export const [
    createGetShareTokenStore,
    GetShareTokenProvider,
    useGetShareTokenStore,
] = localStoreHookFactory(
    'GetShareTokenStore',
    () =>
        new DetailStore(StreemAPI.rooms.createRoomShareToken.bind(StreemAPI.rooms), {
            filter: r => (r.shareToken ? r.shareToken : undefined),
            fnName: 'createRoomShareToken',
        }),
);
